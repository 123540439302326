import { parse as parseFont } from 'opentype.js'
import { LoadFont, LoadSvg } from '@orangelv/svg-renderer'
import { stringToSvg } from '@orangelv/utils-svg'
import {
  getPatternFileName,
  LoadSize,
  LoadPattern,
  getSizeFileName,
} from '@orangelv/utils-olvpf'

import http from '../client/http'
import getAsset from '../getAsset'
import { sharedMemo } from '../common/shared-memo'

export const loadSvg: LoadSvg = sharedMemo(
  async (path) => {
    const { data } = await http.get(
      path.startsWith('https://') || path.startsWith('/') ?
        path
      : getAsset(`${path}.svg`),
    )
    return stringToSvg(data, path)
  },
  (path) => path,
)

export const loadFont: LoadFont = sharedMemo(
  async (path) => {
    const { data } = await http.get(
      path.startsWith('https://') || path.startsWith('/') ?
        path
      : getAsset(`fonts/${path}`),
      { responseType: 'arraybuffer' },
    )
    return parseFont(data)
  },
  (path) => path,
)

export const loadPattern: LoadPattern = sharedMemo(
  async (patternPackage, patternName) => {
    const { data } = await http.get(
      getAsset(`${patternPackage}/${getPatternFileName(patternName)}`),
    )
    return data
  },
  (patternPackage, patternName) => `${patternPackage}/${patternName}`,
)

export const loadSize: LoadSize = sharedMemo(
  async (patternPackage, patternName, pieceIndex, sizeIndex) => {
    const { data } = await http.get(
      getAsset(
        `${patternPackage}/${getSizeFileName(
          patternName,
          pieceIndex,
          sizeIndex,
        )}`,
      ),
    )
    return data
  },
  (patternPackage, patternName, pieceIndex, sizeIndex) =>
    `${patternPackage}/${patternName}/${pieceIndex}/${sizeIndex}`,
)

function debugFixSvg(name: string, svgString: string) {
  const parser = new DOMParser()

  const doc = parser.parseFromString(svgString, 'text/xml')

  doc.querySelectorAll('*').forEach((el: any) => {
    // Makes sure there are no duplicated IDs.
    if (el.id) {
      el.setAttribute('id', `${name}-${el.id}`)
    }
    Array.from(el.attributes).forEach((attr: any) => {
      const { nodeName: k, nodeValue: v } = attr
      if (v.startsWith('url(#')) {
        const [, id] = v.match(/url\(#(.+)\)/)
        el.setAttribute(k, `url(#${name}-${id})`)
      }
    })
  })

  return doc.documentElement.outerHTML
}

export type DebugView = 'default' | 'horizontal'

export function debugHtml(output: Record<string, string>, view?: DebugView) {
  let html = '<title>Debug Textures</title>'

  html += '<style>'
  html += 'svg {'
  html += 'border: 1px dashed red;'
  html += '}'
  if (view === undefined || view === 'default') {
    html += 'svg {'
    html += 'max-width: 100%;'
    html += 'height: auto;'
    html += '}'
  } else {
    html += '.container {'
    html += 'display: flex;'
    html += '}'
    html += '.index, .container > h2 {'
    html += 'display: none;'
    html += '}'
    html += '.svgContainer {'
    html += 'padding-right: 10px;'
    html += '}'
    html += 'svg {'
    html += 'max-height: 90vh;'
    html += 'width: auto;'
    html += '}'
  }

  html += '</style>'

  html += '<h1>Debug Textures</h1>'
  html += '<div class="container">'
  html += '<div class="index">'
  for (const key of Object.keys(output)) {
    html += `<p><a href="#${key}">${key}</a></p>`
  }

  html += '</div>'
  for (const [key, value] of Object.entries(output)) {
    html += `<h2 id="${key}">${key}</h2>`
    html += `<div class="svgContainer" title="${key}">`
    html += debugFixSvg(key, value)
    html += '</div>'
  }

  html += '</div>'

  const debugWindow = window.open()
  if (!debugWindow) {
    console.log(
      'ERROR: Cannot open a new tab! Make sure you have allowed pop-up windows in browser settings.',
    )
    return
  }

  debugWindow.document.write(html)
}
