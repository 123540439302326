import { ColorId, FillId } from '../common/sheets'
import { lookUpColor, lookUpFill } from './getPlacements'

const getFill = (
  fillId: FillId | null | undefined,
  color1Id: ColorId | null | undefined,
  color2Id: ColorId | null | undefined,
  color3Id: ColorId | null | undefined,
  color4Id: ColorId | null | undefined,
) => {
  const fill = lookUpFill(fillId)
  const color1 = lookUpColor(color1Id)
  if (!fill || !color1) return
  if (fill.id === 'solid') return color1.props.hex
  const color2 = lookUpColor(color2Id)
  const color3 = lookUpColor(color3Id)
  const color4 = lookUpColor(color4Id)
  return {
    name: `fills/${fill.props.assetId}`,
    colors: (
      [
        [color1, fill.props.color1],
        [color2, fill.props.color2],
        [color3, fill.props.color3],
        [color4, fill.props.color4],
      ] as const
    ).reduce((a, [color, ids]) => {
      if (!color || !ids) return a
      for (const id of ids) a[`#${id}`] = color.props.hex
      return a
    }, {}),
  }
}

export default getFill
