import * as _ from '@technically/lodash'

import { Recipe } from '../common/typings'
import { SizeId } from '../common/sheets'

export type Player = {
  size: string
  quantity: number
  number: string
  name: string
}

const getPlayers = (
  recipe: Recipe,
): {
  jerseySize?: SizeId
  pantsSize?: SizeId
  quantity: number
  number: string
  name: string
}[] => {
  const rosterIds = _.map(
    _.filter(_.keys(recipe), (key) => {
      return (
        _.startsWith(key, 'details.roster.') && _.endsWith(key, '.quantity')
      )
    }),
    (key) => {
      /* eslint-disable no-param-reassign */
      key = key.replace(/^details\.roster\./, '')
      key = key.replace(/\.quantity/, '')
      /* eslint-enable no-param-reassign */
      const rosterId = _.parseInt(key)
      return rosterId
    },
  )

  return _.map(rosterIds, (rosterId) => {
    let quantity = _.parseInt(recipe[`details.roster.${rosterId}.quantity`])
    return {
      jerseySize: recipe[`details.roster.${rosterId}.jerseySize`],
      pantsSize: recipe[`details.roster.${rosterId}.pantsSize`],
      quantity: Number.isNaN(quantity) ? 0 : quantity,
      number: recipe[`details.roster.${rosterId}.number`],
      name: recipe[`details.roster.${rosterId}.name`],
    }
  })
}

const getJerseyPlayers = (recipe: Recipe): Player[] =>
  _.map(getPlayers(recipe), ({ jerseySize: size, quantity, number, name }) => ({
    size: size!,
    quantity,
    number,
    name,
  }))

const getPantsPlayers = (recipe: Recipe): Player[] =>
  _.map(getPlayers(recipe), ({ pantsSize: size, quantity, number, name }) => ({
    size: size!,
    quantity,
    number,
    name,
  }))

export { getPlayers, getJerseyPlayers, getPantsPlayers }
