type RGB = { r: number; g: number; b: number }

const hexToRgb = (hex: string): RGB => {
  const number = parseInt(hex.slice(1), 16)
  /* eslint-disable no-bitwise */
  const r = (number >> 16) & 255
  const g = (number >> 8) & 255
  const b = number & 255
  /* eslint-enable no-bitwise */

  return { r, g, b }
}

export const normalizeRgb = ({ r, g, b }: RGB): RGB => ({
  r: r / 255,
  g: g / 255,
  b: b / 255,
})

// https://bottosson.github.io/posts/oklab/
export const getPerceivedLightness = ({ r, g, b }: RGB): number =>
  0.2104542553 *
    Math.cbrt(0.4122214708 * r + 0.5363325363 * g + 0.0514459929 * b) +
  0.793617785 *
    Math.cbrt(0.2119034982 * r + 0.6806995451 * g + 0.1073969566 * b) -
  0.0040720468 *
    Math.cbrt(0.0883024619 * r + 0.2817188376 * g + 0.6299787005 * b)

export default hexToRgb
