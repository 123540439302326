// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ProductRow } from '../typings';

const data = [
  {
    "id": "350771",
    "youthSku": "350772",
    "name": "FULL BUTTON SET-IN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "fullButton",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350773",
    "youthSku": "350774",
    "name": "2 BUTTON SET-IN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "twoButton",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350775",
    "youthSku": "350776",
    "name": "CREW NECK SET-IN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350777",
    "youthSku": "350778",
    "name": "V-NECK SET-IN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350785",
    "youthSku": "350793",
    "name": "FULL BUTTON RAGLAN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "fullButton",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350786",
    "youthSku": "350791",
    "name": "FULL BUTTON SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "fullButton",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350787",
    "youthSku": "350792",
    "name": "2 BUTTON SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "twoButton",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350788",
    "youthSku": "350794",
    "name": "2 BUTTON RAGLAN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "twoButton",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350789",
    "youthSku": "350795",
    "name": "CREW NECK RAGLAN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350790",
    "youthSku": "350796",
    "name": "V-NECK RAGLAN SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350797",
    "youthSku": "350803",
    "name": "FULL BUTTON SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "fullButton",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350798",
    "youthSku": "350804",
    "name": "2 BUTTON SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "twoButton",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350799",
    "youthSku": "350805",
    "name": "V-NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350800",
    "youthSku": "350806",
    "name": "FULL BUTTON SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "fullButton",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350801",
    "youthSku": "350807",
    "name": "2 BUTTON SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "twoButton",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": true,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350802",
    "youthSku": "350808",
    "name": "V-NECK SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "350843",
    "youthSku": "350844",
    "name": "FULL LENGTH BASEBALL PANT",
    "props": {
      "type": "pants",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 444,
        "CAD": 555,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": false,
        "sleeve": false,
        "sideSeam": true
      }
    }
  },
  {
    "id": "350845",
    "youthSku": "350846",
    "name": "SHORT BASEBALL PANT",
    "props": {
      "type": "pants",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 444,
        "CAD": 555,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": false,
        "sleeve": false,
        "sideSeam": true
      }
    }
  },
  {
    "id": "350847",
    "youthSku": "350848",
    "name": "SOFTBALL PANT",
    "props": {
      "type": "pants",
      "isEnabled": true,
      "sport": "softball",
      "price": {
        "USD": 444,
        "CAD": 555,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": false,
        "sleeve": false,
        "sideSeam": true
      }
    }
  },
  {
    "id": "351079",
    "youthSku": "351083",
    "name": "SUBLIMATED TAPERED PANT",
    "props": {
      "type": "pants",
      "isEnabled": true,
      "sport": "baseball",
      "price": {
        "USD": 444,
        "CAD": 555,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": false,
        "sleeve": false,
        "sideSeam": true
      }
    }
  },
  {
    "id": "440773",
    "youthSku": "440774",
    "name": "V-NECK LONG SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440775",
    "youthSku": "440776",
    "name": "CREW NECK LONG SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440777",
    "youthSku": "440778",
    "name": "V-NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440779",
    "youthSku": "440780",
    "name": "CREW NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440781",
    "youthSku": "440782",
    "name": "V-NECK SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440783",
    "youthSku": "440784",
    "name": "CREW NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440785",
    "youthSku": "440786",
    "name": "CREW NECK SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440797",
    "name": "LOOSE CREW NECK LONG SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440798",
    "name": "LOOSE V-NECK LONG SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440799",
    "name": "LOOSE CREW NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440800",
    "name": "LOOSE V-NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440801",
    "youthSku": "440803",
    "name": "V-NECK SHORT SLEEVE",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440802",
    "youthSku": "440804",
    "name": "V-NECK  SLEEVELESS",
    "props": {
      "type": "jersey",
      "style": "neck",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": true
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": false
      }
    }
  },
  {
    "id": "440805",
    "youthSku": "440806",
    "name": "SUBLIMATED SPANDEX",
    "props": {
      "type": "jersey",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": true,
        "sleeve": true,
        "sideSeam": true
      }
    }
  },
  {
    "id": "440811",
    "name": "SUBLIMATED ARM SLEEVES",
    "props": {
      "type": "jersey",
      "isEnabled": false,
      "sport": "volleyball",
      "price": {
        "USD": 777,
        "CAD": 888,
        "EUR": 0
      },
      "hasButtonThreads": false,
      "isSleeveLess": false
    },
    "isAvailable": {
      "braidPosition": {
        "neck": false,
        "sleeve": false,
        "sideSeam": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ProductRow[]>> = data;

export type Product = typeof typed[number];

export const PRODUCT_INDEX_KEY = "id";
export type ProductIndexKey = "id";
export type ProductId = Product["id"];

let i = 0;
export const PRODUCT_DICT = {
  "350771": typed[i++],
  "350773": typed[i++],
  "350775": typed[i++],
  "350777": typed[i++],
  "350785": typed[i++],
  "350786": typed[i++],
  "350787": typed[i++],
  "350788": typed[i++],
  "350789": typed[i++],
  "350790": typed[i++],
  "350797": typed[i++],
  "350798": typed[i++],
  "350799": typed[i++],
  "350800": typed[i++],
  "350801": typed[i++],
  "350802": typed[i++],
  "350843": typed[i++],
  "350845": typed[i++],
  "350847": typed[i++],
  "351079": typed[i++],
  "440773": typed[i++],
  "440775": typed[i++],
  "440777": typed[i++],
  "440779": typed[i++],
  "440781": typed[i++],
  "440783": typed[i++],
  "440785": typed[i++],
  "440797": typed[i++],
  "440798": typed[i++],
  "440799": typed[i++],
  "440800": typed[i++],
  "440801": typed[i++],
  "440802": typed[i++],
  "440805": typed[i++],
  "440811": typed[i++]
} as const;

export { typed as PRODUCTS };
