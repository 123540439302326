// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FreeFormPieceDataRow } from '../typings';

const data = [
  {
    "id": "350771-front",
    "productId": "350771",
    "groupId": "front",
    "pieceNames": [
      "frontLeft",
      "frontRight"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 50,
      "width": 2600,
      "height": 2500
    },
    "youth": {
      "x": 0,
      "y": 37,
      "width": 1950,
      "height": 1875
    },
    "centerX": 618,
    "offsetX": {
      "XXS": 0,
      "XS": 856,
      "S": 896,
      "M": 944,
      "L": 996,
      "XL": 1069,
      "XXL": 1144,
      "XXXL": 0,
      "YS": 714,
      "YM": 766,
      "YL": 834,
      "YXL": 910
    }
  },
  {
    "id": "350773-front",
    "productId": "350773",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 0,
      "width": 1440,
      "height": 2520
    },
    "youth": {
      "x": 0,
      "y": 0,
      "width": 1108,
      "height": 1940
    }
  },
  {
    "id": "350775-front",
    "productId": "350775",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 50,
      "width": 1440,
      "height": 2650
    },
    "youth": {
      "x": 0,
      "y": 39,
      "width": 1109,
      "height": 2041
    }
  },
  {
    "id": "350777-front",
    "productId": "350777",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 40,
      "width": 1440,
      "height": 2670
    },
    "youth": {
      "x": 0,
      "y": 45,
      "width": 1109,
      "height": 2056
    }
  },
  {
    "id": "350785-front",
    "productId": "350785",
    "groupId": "front",
    "pieceNames": [
      "frontLeft",
      "frontRight"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 0,
      "width": 2600,
      "height": 2400
    },
    "youth": {
      "x": 0,
      "y": 0,
      "width": 1950,
      "height": 1800
    },
    "centerX": 618,
    "offsetX": {
      "XXS": 0,
      "XS": 862,
      "S": 914,
      "M": 966,
      "L": 1010,
      "XL": 1078,
      "XXL": 1158,
      "XXXL": 1222,
      "YS": 664,
      "YM": 716,
      "YL": 788,
      "YXL": 856
    }
  },
  {
    "id": "350786-front",
    "productId": "350786",
    "groupId": "front",
    "pieceNames": [
      "frontLeft",
      "frontRight"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 50,
      "width": 2400,
      "height": 2500
    },
    "youth": {
      "x": 0,
      "y": 37,
      "width": 1800,
      "height": 1875
    },
    "centerX": 632,
    "offsetX": {
      "XXS": 0,
      "XS": 890,
      "S": 930,
      "M": 986,
      "L": 1025,
      "XL": 1102,
      "XXL": 1174,
      "XXXL": 1250,
      "YS": 648,
      "YM": 696,
      "YL": 764,
      "YXL": 840
    }
  },
  {
    "id": "350787-front",
    "productId": "350787",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": -20,
      "width": 1500,
      "height": 2620
    },
    "youth": {
      "x": 0,
      "y": -40,
      "width": 1155,
      "height": 2017
    }
  },
  {
    "id": "350788-front",
    "productId": "350788",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 50,
      "width": 1350,
      "height": 2250
    },
    "youth": {
      "x": 0,
      "y": 40,
      "width": 1067,
      "height": 1778
    }
  },
  {
    "id": "350789-front",
    "productId": "350789",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 100,
      "width": 1300,
      "height": 2350
    },
    "youth": {
      "x": 0,
      "y": 80,
      "width": 1040,
      "height": 1880
    }
  },
  {
    "id": "350790-front",
    "productId": "350790",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 90,
      "width": 1250,
      "height": 2380
    },
    "youth": {
      "x": 0,
      "y": 72,
      "width": 1000,
      "height": 1904
    }
  },
  {
    "id": "350797-front",
    "productId": "350797",
    "groupId": "front",
    "pieceNames": [
      "frontLeft",
      "frontRight"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 50,
      "width": 2200,
      "height": 2400
    },
    "youth": {
      "x": 0,
      "y": 37,
      "width": 1650,
      "height": 1800
    },
    "centerX": 560,
    "offsetX": {
      "XXS": 0,
      "XS": 787,
      "S": 831,
      "M": 883,
      "L": 923,
      "XL": 999,
      "XXL": 1071,
      "XXXL": 1140,
      "YS": 698,
      "YM": 750,
      "YL": 814,
      "YXL": 890
    }
  },
  {
    "id": "350798-front",
    "productId": "350798",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 150,
      "width": 1150,
      "height": 2180
    },
    "youth": {
      "x": 0,
      "y": 100,
      "width": 920,
      "height": 1744
    }
  },
  {
    "id": "350799-front",
    "productId": "350799",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 140,
      "width": 1150,
      "height": 2380
    },
    "youth": {
      "x": 0,
      "y": 80,
      "width": 920,
      "height": 1904
    }
  },
  {
    "id": "350800-front",
    "productId": "350800",
    "groupId": "front",
    "pieceNames": [
      "frontLeft",
      "frontRight"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 50,
      "width": 2100,
      "height": 2400
    },
    "youth": {
      "x": 0,
      "y": 37,
      "width": 1575,
      "height": 1800
    },
    "centerX": 558,
    "offsetX": {
      "XXS": 0,
      "XS": 774,
      "S": 822,
      "M": 870,
      "L": 918,
      "XL": 986,
      "XXL": 1058,
      "XXXL": 1138,
      "YS": 708,
      "YM": 758,
      "YL": 826,
      "YXL": 906
    }
  },
  {
    "id": "350801-front",
    "productId": "350801",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 140,
      "width": 1200,
      "height": 2200
    },
    "youth": {
      "x": 0,
      "y": 100,
      "width": 960,
      "height": 1760
    }
  },
  {
    "id": "350802-front",
    "productId": "350802",
    "groupId": "front",
    "pieceNames": [
      "front"
    ],
    "isDisabled": false,
    "adult": {
      "x": 0,
      "y": 150,
      "width": 1200,
      "height": 2380
    },
    "youth": {
      "x": 0,
      "y": 80,
      "width": 960,
      "height": 1904
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FreeFormPieceDataRow[]>> = data;

export type FreeFormPieceData = typeof typed[number];

export const FREE_FORM_PIECE_DATA_INDEX_KEY = "id";
export type FreeFormPieceDataIndexKey = "id";
export type FreeFormPieceDataId = FreeFormPieceData["id"];

let i = 0;
export const FREE_FORM_PIECE_DATA_DICT = {
  "350771-front": typed[i++],
  "350773-front": typed[i++],
  "350775-front": typed[i++],
  "350777-front": typed[i++],
  "350785-front": typed[i++],
  "350786-front": typed[i++],
  "350787-front": typed[i++],
  "350788-front": typed[i++],
  "350789-front": typed[i++],
  "350790-front": typed[i++],
  "350797-front": typed[i++],
  "350798-front": typed[i++],
  "350799-front": typed[i++],
  "350800-front": typed[i++],
  "350801-front": typed[i++],
  "350802-front": typed[i++]
} as const;

export { typed as FREE_FORM_PIECE_DATA };
