// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SizeRow } from '../typings';

const data = [
  {
    "id": "XXS",
    "name": "XXS",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": false,
        "350773": false,
        "350775": false,
        "350777": false,
        "350785": false,
        "350786": false,
        "350787": false,
        "350788": false,
        "350789": false,
        "350790": false,
        "350797": false,
        "350798": false,
        "350799": false,
        "350800": false,
        "350801": false,
        "350802": false,
        "350843": false,
        "350845": false,
        "350847": false,
        "351079": false,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "XS",
    "name": "XS",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "S",
    "name": "S",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "M",
    "name": "M",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "L",
    "name": "L",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "XL",
    "name": "XL",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "XXL",
    "name": "XXL",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "XXXL",
    "name": "XXXL",
    "isYouth": false,
    "availableFor": {
      "product": {
        "350771": false,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": false,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": false
      }
    }
  },
  {
    "id": "YXXS",
    "name": "YXXS",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": false,
        "350773": false,
        "350775": false,
        "350777": false,
        "350785": false,
        "350786": false,
        "350787": false,
        "350788": false,
        "350789": false,
        "350790": false,
        "350797": false,
        "350798": false,
        "350799": false,
        "350800": false,
        "350801": false,
        "350802": false,
        "350843": false,
        "350845": false,
        "350847": false,
        "351079": false,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": false
      }
    }
  },
  {
    "id": "YXS",
    "name": "YXS",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": false,
        "350773": false,
        "350775": false,
        "350777": false,
        "350785": false,
        "350786": false,
        "350787": false,
        "350788": false,
        "350789": false,
        "350790": false,
        "350797": false,
        "350798": false,
        "350799": false,
        "350800": false,
        "350801": false,
        "350802": false,
        "350843": false,
        "350845": false,
        "350847": false,
        "351079": false,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": true
      }
    }
  },
  {
    "id": "YS",
    "name": "YS",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": true
      }
    }
  },
  {
    "id": "YM",
    "name": "YM",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "YL",
    "name": "YL",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": true,
        "440775": true,
        "440777": true,
        "440779": true,
        "440781": true,
        "440783": true,
        "440785": true,
        "440797": true,
        "440798": true,
        "440799": true,
        "440800": true,
        "440801": true,
        "440802": true,
        "440805": true,
        "440811": true
      }
    }
  },
  {
    "id": "YXL",
    "name": "YXL",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": true,
        "350773": true,
        "350775": true,
        "350777": true,
        "350785": true,
        "350786": true,
        "350787": true,
        "350788": true,
        "350789": true,
        "350790": true,
        "350797": true,
        "350798": true,
        "350799": true,
        "350800": true,
        "350801": true,
        "350802": true,
        "350843": true,
        "350845": true,
        "350847": true,
        "351079": true,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": true
      }
    }
  },
  {
    "id": "YXXL",
    "name": "YXXL",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": false,
        "350773": false,
        "350775": false,
        "350777": false,
        "350785": false,
        "350786": false,
        "350787": false,
        "350788": false,
        "350789": false,
        "350790": false,
        "350797": false,
        "350798": false,
        "350799": false,
        "350800": false,
        "350801": false,
        "350802": false,
        "350843": true,
        "350845": true,
        "350847": false,
        "351079": false,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": false
      }
    }
  },
  {
    "id": "YXXXL",
    "name": "YXXXL",
    "isYouth": true,
    "availableFor": {
      "product": {
        "350771": false,
        "350773": false,
        "350775": false,
        "350777": false,
        "350785": false,
        "350786": false,
        "350787": false,
        "350788": false,
        "350789": false,
        "350790": false,
        "350797": false,
        "350798": false,
        "350799": false,
        "350800": false,
        "350801": false,
        "350802": false,
        "350843": false,
        "350845": false,
        "350847": false,
        "351079": false,
        "440773": false,
        "440775": false,
        "440777": false,
        "440779": false,
        "440781": false,
        "440783": false,
        "440785": false,
        "440797": false,
        "440798": false,
        "440799": false,
        "440800": false,
        "440801": false,
        "440802": false,
        "440805": false,
        "440811": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SizeRow[]>> = data;

export type Size = typeof typed[number];

export const SIZE_INDEX_KEY = "id";
export type SizeIndexKey = "id";
export type SizeId = Size["id"];

let i = 0;
export const SIZE_DICT = {
  "XXS": typed[i++],
  "XS": typed[i++],
  "S": typed[i++],
  "M": typed[i++],
  "L": typed[i++],
  "XL": typed[i++],
  "XXL": typed[i++],
  "XXXL": typed[i++],
  "YXXS": typed[i++],
  "YXS": typed[i++],
  "YS": typed[i++],
  "YM": typed[i++],
  "YL": typed[i++],
  "YXL": typed[i++],
  "YXXL": typed[i++],
  "YXXXL": typed[i++]
} as const;

export { typed as SIZES };
