import store from '../store'
import controlTree from '../controlTree'

import './SportChanger.css'

const SportChanger = () => (
  <div className="sportChanger">
    <button
      onClick={() => {
        const values = {
          'filters.sport': null,
        }

        store.dispatch(controlTree.commitChanges())
        store.dispatch(controlTree.setValues(values))
      }}
    >
      Change Sport
    </button>
  </div>
)

export default SportChanger
