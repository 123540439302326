import { Player } from '@orangelv/contracts'

import getAsset from '../../../platform/getAsset'

import { Recipe } from '../common/typings'
import { PRODUCT_DICT, FREE_FORM_PIECE_DATA, ProductId } from '../common/sheets'
import createCanvasTexture from './createCanvasTexture'
import { DEFAULT_FABRIC, DEFAULT_SIZE } from './defaults'
import createFabricTexture from './createFabricTexture'
import getPlacements, { lookUpColor } from './getPlacements'
import { PATTERN_PACKAGE, getPatternName } from '@orangelv/mizuno-utils'
import { loadPattern } from '../../../platform/client/svg-renderer-utils'
import { Immutable } from '@orangelv/utils'

function getMaterialName(x: string) {
  switch (x) {
    case 'pLeftFront':
      return 'frontLeft'
    case 'pRightFront':
      return 'frontRight'
    case 'pLeftBack':
      return 'backLeft'
    case 'pRightBack':
      return 'backRight'
    case 'pBackLoop':
      return 'backBeltLoop'
    case 'pFrontLoop1':
      return 'leftBeltLoop'
    case 'pFrontLoop2':
      return 'rightBeltLoop'
    default:
      return x
  }
}

export default async (productId: ProductId, recipe: Recipe, player: Player) => {
  // We don't consider youthSku because the model is adult
  const patternName = getPatternName(productId, DEFAULT_FABRIC)
  const pattern = await loadPattern(PATTERN_PACKAGE, patternName)

  const freeFormPieceData = FREE_FORM_PIECE_DATA.filter(
    (x) => x.productId === productId,
  )

  const product = PRODUCT_DICT[productId]

  const prefix = product.props.type

  const fabricId = recipe[`${prefix}.fabric`]

  const fabricOutsideNormalTexture = {
    url: getAsset(
      `normals/${fabricId === 'H5261' ? 'gridOutside' : fabricId}.png`,
    ),
  }

  const materials = await Promise.all(
    pattern.pieceMapping
      .filter(
        ({ name }) =>
          name !== 'pBackPocketWallLeft1' &&
          name !== 'pBackPocketWallLeft2' &&
          name !== 'pBackPocketWallRight1' &&
          name !== 'pBackPocketWallRight2' &&
          name !== 'pFly' &&
          name !== 'pLeftPocket' &&
          name !== 'pRightPocket',
      )
      .map(async (pieceMappingItem) => {
        // The singular form of data is called datum
        const freeFormPieceDatum = freeFormPieceData.find(({ pieceNames }) =>
          (pieceNames as Immutable<string[]>).includes(pieceMappingItem.name),
        )

        const placements = getPlacements(
          recipe,
          product,
          DEFAULT_SIZE,
          pieceMappingItem.name,
        )

        const diffuseTexture =
          (
            freeFormPieceDatum &&
            recipe[`${prefix}.${freeFormPieceDatum.groupId}.mode`] ===
              'freeForm'
          ) ?
            await createFabricTexture({
              pieceMappingItem,
              player,
              placements,
              recipe,
              freeFormPieceDatum,
              pattern,
              patternName,
              product,
            })
          : await createCanvasTexture({
              product,
              pattern,
              patternName,
              pieceMappingItem,
              recipe,
              player,
              placements,
            })

        return [
          `${productId}_${getMaterialName(pieceMappingItem.name)}Mat`,
          {
            diffuseColor: '#ffffff',
            diffuseTexture,
            normalTexture: fabricOutsideNormalTexture,
          },
        ] as const
      }),
  )

  return {
    ...Object.fromEntries(materials),
    [`${productId}_insideMat`]: {
      normalTexture: {
        url: getAsset(
          `normals/${fabricId === 'H5261' ? 'gridInside' : fabricId}.png`,
        ),
      },
    },
    ...(product.props.hasButtonThreads ?
      {
        buttonThreadsMat: {
          // Assumes that the primary color of the design is the 1st color.
          // This might not be accurate for all the designs.
          diffuseColor:
            lookUpColor(recipe[`${prefix}.design.color1`])?.props.hex ??
            lookUpColor(recipe[`${prefix}.body.color1`])?.props.hex ??
            '#ffffff',
        },
      }
    : undefined),
  }
}
