import { Anchor } from '@orangelv/svg-renderer'

import assert from '../../../platform/assert'
import {
  ProductId,
  DecorationId,
  PlacementId,
  SizeId,
  ANCHORS,
  SIZE_DICT,
  PRODUCT_DICT,
  FREE_FORM_PIECE_DATA,
} from '../common/sheets'

const getAnchorAndBoundingBox = (
  productId: ProductId,
  content: DecorationId,
  placementId: PlacementId,
  pieceName: string,
  sizeId: SizeId,
  position?: { x: number; y: number; w: number; h: number },
) => {
  const product = PRODUCT_DICT[productId]
  const size = SIZE_DICT[sizeId]

  const { isYouth } = size

  if (position) {
    const freeFormPieceData = FREE_FORM_PIECE_DATA.find(
      (box) =>
        box.productId === productId &&
        (box.pieceNames as readonly string[]).includes(pieceName),
    )

    assert(freeFormPieceData)

    const { adult: adultBb, youth: youthBb } = freeFormPieceData

    const ratio =
      isYouth ?
        Math.min(youthBb.width / adultBb.width, youthBb.height / adultBb.height)
      : 1

    let offsetX: number = position.x * ratio

    let anchorTo: Anchor['to'] = 'document-center'

    const centerX = freeFormPieceData.centerX ?? 0
    const offsetXForM = freeFormPieceData.offsetX?.M ?? 0

    if (pieceName === 'frontLeft') {
      anchorTo = 'left'
      offsetX = (position.x + centerX) * ratio
    } else if (pieceName === 'frontRight') {
      anchorTo = 'right'
      offsetX = (position.x - centerX + offsetXForM) * ratio
    }

    const anchor = {
      from: 'center-center' as const,
      to: anchorTo,
      offset: {
        x: offsetX,
        y: position.y * ratio,
      },
    }
    const boundingBox = {
      width:
        content === 'playerNumber' ?
          // For player numbers, set the width to a big number so that different numbers are all aligned to center.
          adultBb.width * 2
        : position.w * ratio,
      height: position.h * ratio,
    }

    return { anchor, boundingBox }
  }

  const relevantAnchors = ANCHORS.filter(
    (x) => x.placementId === placementId && x.pieceName === pieceName,
  )

  let productAnchorAndBoundingBox = relevantAnchors.find(
    (x) => x.productId === (isYouth ? product.youthSku : productId),
  )

  // If we didn't find a matching anchor, fallback to using anchor for adult.
  // TODO: Remove fallback when all data contains anchors for both adult and youth.
  if (!productAnchorAndBoundingBox && isYouth) {
    productAnchorAndBoundingBox = relevantAnchors.find(
      (x) => x.productId === productId,
    )
  }

  if (!productAnchorAndBoundingBox) {
    return undefined
  }

  const { from, to, offsetX, offsetY } = productAnchorAndBoundingBox.anchor

  const anchor = {
    from,
    to,
    offset: {
      x: offsetX,
      y: offsetY,
    },
  }

  const { boundingBox } = productAnchorAndBoundingBox

  return { anchor, boundingBox }
}

export { getAnchorAndBoundingBox }
