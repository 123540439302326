import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { s } from 'hastscript'
import { svgToCanvas } from '@orangelv/utils-dom'

import { getBlobFromCanvas } from '../utils'
import { COLOR_DICT, TAILS } from '../../common/sheets'
import { loadSvg } from '../../../../platform/client/svg-renderer-utils'

const WIDTH = 512
const HEIGHT = 340
const OUTLINE_RATIO = 0.02
const PADDING_RATIO = 0.2

export default async () => {
  console.time('generateIconsForTails')

  const zip = new JSZip()

  for (const tail of TAILS.filter((x) => x.isEnabled)) {
    const svgData = await loadSvg(`tails/${tail.id}`)

    const svgElement = svgData.root.children[0]
    if (svgElement.type !== 'element' || !svgElement.properties) {
      throw new Error('Bad SVG')
    }

    const strokePath = svgElement.children[0]
    if (strokePath.type !== 'element' || !strokePath.properties) {
      throw new Error('Bad SVG')
    }

    strokePath.properties.stroke = COLOR_DICT['gray_+2'].props.hex
    strokePath.properties.strokeWidth = svgData.width * OUTLINE_RATIO

    svgElement.properties.overflow = 'visible'
    svgElement.children.push(
      s('path', {
        d: strokePath.properties.d,
        fill: COLOR_DICT.coolGray.props.hex,
      }),
    )

    const padding = svgData.width * PADDING_RATIO
    svgData.root = s(
      undefined,
      s(
        'svg',
        {
          viewBox: `${-padding} ${-padding} ${svgData.width + padding * 2} ${
            svgData.height + padding * 2
          }`,
        },
        svgData.root,
      ),
    )

    const canvas = await svgToCanvas(svgData, { width: WIDTH, height: HEIGHT })

    const blob = await getBlobFromCanvas(canvas)
    zip.file(`${tail.id}.png`, blob)
  }

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'icons-for-tails.zip')

  console.timeEnd('generateIconsForTails')
}
