import FileSaver from 'file-saver'
import JSZip from 'jszip'

import { GeneratePreviews } from '@orangelv/bjs-renderer'

import { ProductId, PRODUCT_ICONS, PRODUCTS } from '../../common/sheets'
import controlTree from '../../client/controlTree'
import getRendererConfig from '../getRendererConfig'
import getPropValue from '../../common/getPropValue'

const getValuesForProduct = (productId: ProductId) => {
  const rows = PRODUCT_ICONS.filter((x) => x.productId === productId)
  return Object.fromEntries(
    rows.map((row) => [row.propId, getPropValue(row.propValue ?? '')]),
  )
}

export default (generatePreviews: GeneratePreviews) => async () => {
  console.time('generateIconsForProducts')

  const defaultView = 'default'

  const zip = new JSZip()

  for (const product of PRODUCTS.filter((x) => x.props.isEnabled)) {
    const productValues = getValuesForProduct(product.id)

    const state = {
      controlTree: {
        pendingChanges: { auto: {}, user: {} },
        preferredValues: {},
        repeatedNodes: {
          'details.roster': [1],
        },
        values: {
          'product.sku': product.id,
          ...productValues,
        },
      },
    }

    const nodes = controlTree.getNodes(state)
    const recipe = controlTree.getPublicRecipe(state)

    const rendererConfig = await getRendererConfig(
      nodes,
      recipe,
      true,
      true,
      '1',
    )

    if (rendererConfig.camera) {
      // Use the same camera radius so that scale is comparable between icons.
      rendererConfig.camera.defaultRadius =
        product.props.type === 'jersey' ? 1.2 : 1.5

      // Don't face the camera directly.
      rendererConfig.camera.defaultAlpha = 1.5
      rendererConfig.camera.defaultBeta = 1.5
    }

    const blobs = await generatePreviews({
      size: { width: 1024, height: 1024 },
      rendererConfig,
    })

    const blob = blobs[defaultView]

    zip.file(`${product.id}.png`, blob)
  }

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'icons-for-products.zip')

  console.timeEnd('generateIconsForProducts')
}
