const defaultViewAngleId = 'front'

const viewAngles = {
  front: {
    label: 'Front',
    exposeForRecipePreviewImages: true,
    exposeForUI: true,
  },
  back: {
    label: 'Back',
    exposeForRecipePreviewImages: true,
    exposeForUI: true,
  },
  left: {
    label: 'Left',
    exposeForRecipePreviewImages: true,
    exposeForUI: true,
  },
  right: {
    label: 'Right',
    exposeForRecipePreviewImages: true,
    exposeForUI: true,
  },

  jerseyFront: {
    label: 'Jersey Front',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
  jerseyBack: {
    label: 'Jersey Back',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
  jerseyLeft: {
    label: 'Jersey Left',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
  jerseyRight: {
    label: 'Jersey Right',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },

  pantsFront: {
    label: 'Pants Front',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
  pantsBack: {
    label: 'Pants Back',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
  pantsLeft: {
    label: 'Pants Left',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
  pantsRight: {
    label: 'Pants Right',
    exposeForRecipePreviewImages: true,
    exposeForUI: false,
    isOptional: true,
  },
}

export default viewAngles
export { defaultViewAngleId }
