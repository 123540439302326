import * as _ from '@technically/lodash'

import ContentHead from '../../../_mizuno/client/components/atoms/ContentHead'
import ContentLabel from '../../../_mizuno/client/components/atoms/ContentLabel'
import ContentBody from '../../../_mizuno/client/components/atoms/ContentBody'
import HorizontalAlign from '../../../_mizuno/client/components/atoms/HorizontalAlign'

import {
  TileSelectControl,
  ControlMenu,
  getItemComponent,
} from '../../../_mizuno/client/components/Sidebar'

import { Nodes } from '../../common/typings'
import {
  PLACEMENT_GROUPS,
  PLACEMENT_DICT,
  ProductTypeId,
  PLACEMENTS,
  PlacementId,
} from '../../common/sheets'

import './SidebarPlacements.css'

const ITEMS = [
  'content',
  'text',
  'font',
  'layout',
  'tail',
  'tailText',
  'tailTextFont',
  'fill',
  'fillColor1',
  'fillColor2',
  'fillColor3',
  'fillColor4',
  'outlineColor1',
  'outlineColor2',
  'tailTextColor',
  'file',
  'scaleFactor',
] as const

type Props = {
  nodes: Nodes
  basePath: ProductTypeId
  rosterPreviewId: string
}

const getItem = (
  parentPath: string,
  itemId: (typeof ITEMS)[number],
  rosterId: string,
  nodes: Nodes,
  placementId: PlacementId,
) => {
  const contentNode = nodes[`${parentPath}.content`]
  const contentType = contentNode.value

  let componentName: string | undefined
  let propId: string | undefined

  if (
    placementId !== 'frontChest' &&
    ['tail', 'tailText', 'tailTextFont', 'tailTextColor'].includes(itemId)
  ) {
    return
  }

  if (itemId === 'content') {
    componentName = 'TileSelectControl'
    propId = `${parentPath}.content`
  } else if (itemId === 'text') {
    componentName = 'TextInputForPlacementControl'

    if (contentType === 'teamName') {
      propId = 'details.teamName.text'
    } else if (contentType === 'playerName') {
      propId = `details.roster.${rosterId}.name`
    } else if (contentType === 'playerNumber') {
      propId = `details.roster.${rosterId}.number`
    }
  } else if (itemId === 'font') {
    componentName = 'TileSelectControl'
    propId = `${parentPath}.font`
  } else if (itemId === 'layout') {
    componentName = 'TileSelectControl'
    propId = `${parentPath}.layout`
  } else if (itemId === 'tail') {
    componentName = 'TileSelectControl'
    propId = `${parentPath}.tail`
  } else if (itemId === 'tailText') {
    componentName = 'TextInputForPlacementControl'
    propId = `${parentPath}.tailText`
  } else if (itemId === 'tailTextFont') {
    componentName = 'TileSelectControl'
    propId = `${parentPath}.tailTextFont`
  } else if (itemId === 'fill') {
    componentName = 'TileSelectControl'
    propId = `${parentPath}.fill`
  } else if (itemId === 'fillColor1') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.fillColor1`
  } else if (itemId === 'fillColor2') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.fillColor2`
  } else if (itemId === 'fillColor3') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.fillColor3`
  } else if (itemId === 'fillColor4') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.fillColor4`
  } else if (itemId === 'outlineColor1') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.outlineColor1`
  } else if (itemId === 'outlineColor2') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.outlineColor2`
  } else if (itemId === 'tailTextColor') {
    componentName = 'ColorSelectWithTonesControl'
    propId = `${parentPath}.tailTextColor`
  } else if (itemId === 'file') {
    componentName = 'ImageUploadControl'
    propId = `${parentPath}.file`
  } else if (itemId === 'scaleFactor') {
    componentName = 'RangeControl'
    propId = `${parentPath}.scaleFactor`
  } else {
    throw Error(`Unhandled item: ${itemId}`)
  }

  if (!propId) {
    return
  }

  return {
    id: propId,
    propId,
    contentType: componentName,
  }
}

const getPlacementsBody = ({ rosterPreviewId, basePath, nodes }: Props) => {
  return PLACEMENT_GROUPS.map((placementGroup) => {
    const placements = _.filter(PLACEMENTS, { groupId: placementGroup.id })

    const group = _.map(placements, (placement) => {
      const { id: placementId } = placement

      const parentPath = `${basePath}.${placementId}`
      const path = `${parentPath}.content`

      return { placementId, parentPath, path, node: nodes[path] }
    })

    const isEmpty = _.every(group, ({ node }) => !node || !node.isAvailable)

    if (isEmpty) {
      return null
    }

    return (
      <div key={placementGroup.id} className="placementGroup">
        <div className="name">{placementGroup.name}</div>

        <div className="mode">
          <TileSelectControl path={`${basePath}.${placementGroup.id}.mode`} />
        </div>

        <div>
          {_.map(group, ({ placementId, parentPath, path, node }) => {
            if (!node || !node.isAvailable) {
              return undefined
            }

            const isTouched = !!nodes[`${parentPath}.isTouched`].value

            const primaryLabel =
              isTouched ? 'Free Form' : PLACEMENT_DICT[placementId].shortName

            return (
              <ControlMenu
                key={placementId}
                path={path}
                primaryLabel={primaryLabel}
              >
                {ITEMS.map((itemId) => {
                  const item = getItem(
                    parentPath,
                    itemId,
                    rosterPreviewId,
                    nodes,
                    placementId,
                  )
                  if (!item) {
                    return null
                  }
                  return getItemComponent(item, nodes)
                })}
              </ControlMenu>
            )
          })}
        </div>
      </div>
    )
  })
}

const SidebarPlacements = (props: Props) => {
  const decorationsBody = getPlacementsBody(props)

  if (decorationsBody.every((x) => x === undefined)) return null

  return (
    <div className="SidebarPlacements">
      <div className="section mods-on">
        <ContentHead classMods={['section']}>
          <HorizontalAlign classMods={['staticRight']}>
            <ContentLabel classMods={['section']}>
              <span>Decorate</span>
            </ContentLabel>
          </HorizontalAlign>
        </ContentHead>
        <ContentBody classMods={['section']}>{decorationsBody}</ContentBody>
      </div>
    </div>
  )
}

export default SidebarPlacements
