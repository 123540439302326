// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FullCustomPieceDataRow } from '../typings';

const data = [
  {
    "id": "350771",
    "productId": "350771",
    "isDisabled": false
  },
  {
    "id": "350773",
    "productId": "350773",
    "isDisabled": false
  },
  {
    "id": "350775",
    "productId": "350775",
    "isDisabled": false
  },
  {
    "id": "350777",
    "productId": "350777",
    "isDisabled": false
  },
  {
    "id": "350785",
    "productId": "350785",
    "isDisabled": false
  },
  {
    "id": "350786",
    "productId": "350786",
    "isDisabled": false
  },
  {
    "id": "350787",
    "productId": "350787",
    "isDisabled": false
  },
  {
    "id": "350788",
    "productId": "350788",
    "isDisabled": false
  },
  {
    "id": "350789",
    "productId": "350789",
    "isDisabled": false
  },
  {
    "id": "350790",
    "productId": "350790",
    "isDisabled": false
  },
  {
    "id": "350797",
    "productId": "350797",
    "isDisabled": false
  },
  {
    "id": "350798",
    "productId": "350798",
    "isDisabled": false
  },
  {
    "id": "350799",
    "productId": "350799",
    "isDisabled": false
  },
  {
    "id": "350800",
    "productId": "350800",
    "isDisabled": false
  },
  {
    "id": "350801",
    "productId": "350801",
    "isDisabled": false
  },
  {
    "id": "350802",
    "productId": "350802",
    "isDisabled": false
  },
  {
    "id": "350843",
    "productId": "350843",
    "isDisabled": false
  },
  {
    "id": "350845",
    "productId": "350845",
    "isDisabled": false
  },
  {
    "id": "350847",
    "productId": "350847",
    "isDisabled": false
  },
  {
    "id": "351079",
    "productId": "351079",
    "isDisabled": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FullCustomPieceDataRow[]>> = data;

export type FullCustomPieceData = typeof typed[number];

export const FULL_CUSTOM_PIECE_DATA_INDEX_KEY = "id";
export type FullCustomPieceDataIndexKey = "id";
export type FullCustomPieceDataId = FullCustomPieceData["id"];

let i = 0;
export const FULL_CUSTOM_PIECE_DATA_DICT = {
  "350771": typed[i++],
  "350773": typed[i++],
  "350775": typed[i++],
  "350777": typed[i++],
  "350785": typed[i++],
  "350786": typed[i++],
  "350787": typed[i++],
  "350788": typed[i++],
  "350789": typed[i++],
  "350790": typed[i++],
  "350797": typed[i++],
  "350798": typed[i++],
  "350799": typed[i++],
  "350800": typed[i++],
  "350801": typed[i++],
  "350802": typed[i++],
  "350843": typed[i++],
  "350845": typed[i++],
  "350847": typed[i++],
  "351079": typed[i++]
} as const;

export { typed as FULL_CUSTOM_PIECE_DATA };
