// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FabricRow } from '../typings';

const data = [
  {
    "id": "H5261",
    "groupId": "H5261.H5221.106713W.04672W",
    "name": "Grid",
    "props": {
      "isAvailable": {
        "product": {
          "350771": true,
          "350773": true,
          "350775": true,
          "350777": true,
          "350785": true,
          "350786": true,
          "350787": true,
          "350788": true,
          "350789": true,
          "350790": true,
          "350797": false,
          "350798": false,
          "350799": false,
          "350800": true,
          "350801": true,
          "350802": true,
          "350843": false,
          "350845": false,
          "350847": false,
          "351079": false,
          "440773": false,
          "440775": false,
          "440777": false,
          "440779": false,
          "440781": false,
          "440783": false,
          "440785": false,
          "440797": false,
          "440798": false,
          "440799": false,
          "440800": false,
          "440801": false,
          "440802": false,
          "440805": false,
          "440811": false
        }
      }
    }
  },
  {
    "id": "H5221",
    "groupId": "H5261.H5221.106713W.04672W",
    "name": "Mock Mesh",
    "props": {
      "isAvailable": {
        "product": {
          "350771": true,
          "350773": true,
          "350775": true,
          "350777": true,
          "350785": true,
          "350786": true,
          "350787": true,
          "350788": true,
          "350789": true,
          "350790": true,
          "350797": true,
          "350798": true,
          "350799": true,
          "350800": true,
          "350801": true,
          "350802": true,
          "350843": false,
          "350845": false,
          "350847": false,
          "351079": false,
          "440773": false,
          "440775": false,
          "440777": false,
          "440779": false,
          "440781": false,
          "440783": false,
          "440785": false,
          "440797": false,
          "440798": false,
          "440799": false,
          "440800": false,
          "440801": false,
          "440802": false,
          "440805": false,
          "440811": false
        }
      }
    }
  },
  {
    "id": "106713W",
    "groupId": "H5261.H5221.106713W.04672W",
    "name": "Stretch Mesh",
    "props": {
      "isAvailable": {
        "product": {
          "350771": true,
          "350773": true,
          "350775": true,
          "350777": true,
          "350785": true,
          "350786": true,
          "350787": true,
          "350788": true,
          "350789": true,
          "350790": true,
          "350797": true,
          "350798": true,
          "350799": true,
          "350800": true,
          "350801": true,
          "350802": true,
          "350843": false,
          "350845": false,
          "350847": false,
          "351079": false,
          "440773": true,
          "440775": true,
          "440777": true,
          "440779": true,
          "440781": true,
          "440783": true,
          "440785": true,
          "440797": true,
          "440798": true,
          "440799": true,
          "440800": true,
          "440801": true,
          "440802": true,
          "440805": false,
          "440811": true
        }
      }
    }
  },
  {
    "id": "04672W",
    "groupId": "H5261.H5221.106713W.04672W",
    "name": "Stretch Jersey",
    "props": {
      "isAvailable": {
        "product": {
          "350771": true,
          "350773": true,
          "350775": true,
          "350777": true,
          "350785": true,
          "350786": true,
          "350787": true,
          "350788": true,
          "350789": true,
          "350790": true,
          "350797": true,
          "350798": true,
          "350799": true,
          "350800": true,
          "350801": true,
          "350802": true,
          "350843": false,
          "350845": false,
          "350847": false,
          "351079": false,
          "440773": true,
          "440775": true,
          "440777": true,
          "440779": true,
          "440781": true,
          "440783": true,
          "440785": true,
          "440797": true,
          "440798": true,
          "440799": true,
          "440800": true,
          "440801": true,
          "440802": true,
          "440805": false,
          "440811": true
        }
      }
    }
  },
  {
    "id": "P-2027",
    "groupId": "P-2027",
    "name": "Warp Knit",
    "props": {
      "isAvailable": {
        "product": {
          "350771": false,
          "350773": false,
          "350775": false,
          "350777": false,
          "350785": false,
          "350786": false,
          "350787": false,
          "350788": false,
          "350789": false,
          "350790": false,
          "350797": false,
          "350798": false,
          "350799": false,
          "350800": false,
          "350801": false,
          "350802": false,
          "350843": false,
          "350845": false,
          "350847": false,
          "351079": false,
          "440773": false,
          "440775": false,
          "440777": false,
          "440779": false,
          "440781": false,
          "440783": false,
          "440785": false,
          "440797": false,
          "440798": false,
          "440799": false,
          "440800": false,
          "440801": false,
          "440802": false,
          "440805": false,
          "440811": false
        }
      }
    }
  },
  {
    "id": "ST89WK",
    "groupId": "P-2027",
    "name": "Stretch Knit",
    "props": {
      "isAvailable": {
        "product": {
          "350771": true,
          "350773": true,
          "350775": true,
          "350777": true,
          "350785": true,
          "350786": true,
          "350787": true,
          "350788": true,
          "350789": true,
          "350790": true,
          "350797": true,
          "350798": true,
          "350799": true,
          "350800": true,
          "350801": true,
          "350802": true,
          "350843": true,
          "350845": true,
          "350847": true,
          "351079": true,
          "440773": false,
          "440775": false,
          "440777": false,
          "440779": false,
          "440781": false,
          "440783": false,
          "440785": false,
          "440797": false,
          "440798": false,
          "440799": false,
          "440800": false,
          "440801": false,
          "440802": false,
          "440805": false,
          "440811": false
        }
      }
    }
  },
  {
    "id": "046923W",
    "groupId": "046923W",
    "name": "BG PRIME",
    "props": {
      "isAvailable": {
        "product": {
          "350771": false,
          "350773": false,
          "350775": false,
          "350777": false,
          "350785": false,
          "350786": false,
          "350787": false,
          "350788": false,
          "350789": false,
          "350790": false,
          "350797": false,
          "350798": false,
          "350799": false,
          "350800": false,
          "350801": false,
          "350802": false,
          "350843": false,
          "350845": false,
          "350847": false,
          "351079": false,
          "440773": false,
          "440775": false,
          "440777": false,
          "440779": false,
          "440781": false,
          "440783": false,
          "440785": false,
          "440797": false,
          "440798": false,
          "440799": false,
          "440800": false,
          "440801": false,
          "440802": false,
          "440805": true,
          "440811": false
        }
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FabricRow[]>> = data;

export type Fabric = typeof typed[number];

export const FABRIC_INDEX_KEY = "id";
export type FabricIndexKey = "id";
export type FabricId = Fabric["id"];

let i = 0;
export const FABRIC_DICT = {
  "H5261": typed[i++],
  "H5221": typed[i++],
  "106713W": typed[i++],
  "04672W": typed[i++],
  "P-2027": typed[i++],
  "ST89WK": typed[i++],
  "046923W": typed[i++]
} as const;

export { typed as FABRICS };
