import fp from 'lodash/fp.js'
import { handleActions, concat } from 'redux-fp'

import controlTree from './controlTree'
// import sheets from '../common/sheets'

type State = any

const initialApproval = {
  isRosterApproved: false,
  areUploadColorsApproved: false,
  isFinalApproved: false,
}

const initialState = {
  closedBlocks: {},
  ...initialApproval,
}

const getDefaultState = () => (state: State) => fp.assign(initialState, state)

// const guaranteeSheets = () => state =>
//   state.sheets ? state : fp.set('sheets', sheets)(state)

const uiUpdater = handleActions({
  'approval/roster': ({ payload }) => fp.set('isRosterApproved', payload),
  'approval/uploadColors': ({ payload }) =>
    fp.set('areUploadColorsApproved', payload),
  'approval/final': ({ payload }) => fp.set('isFinalApproved', payload),
  resume: () => (state: State) => ({
    ...state,
    ...initialApproval,
  }),
})

export default concat(
  getDefaultState,
  // guaranteeSheets,
  uiUpdater,
  controlTree.updater,
)

export type { State }
