// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FontRow } from '../typings';

const data = [
  {
    "id": "pirate",
    "type": "ttf",
    "name": "PIRATE",
    "iconText": "Pirate",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "tiffany",
    "type": "ttf",
    "name": "TIFFANY",
    "iconText": "Tiffany",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "proBlock",
    "type": "ttf",
    "name": "PRO BLOCK",
    "iconText": "Pro Block",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "varsity",
    "type": "ttf",
    "name": "VARSITY",
    "iconText": "Varsity",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": false,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "brush",
    "type": "ttf",
    "name": "BRUSH",
    "iconText": "Brush",
    "isEnabled": true,
    "isScript": true,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "script",
    "type": "ttf",
    "name": "SCRIPT",
    "iconText": "Script",
    "isEnabled": false,
    "isScript": true,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "spartan",
    "type": "otf",
    "name": "SPARTAN",
    "iconText": "Spartan",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "allCourt",
    "type": "otf",
    "name": "ALL COURT",
    "iconText": "All Court",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "techItalic",
    "type": "ttf",
    "name": "TECH - ITALIC",
    "iconText": "Tech Italic",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "mizunoElite",
    "type": "otf",
    "name": "MIZUNO ELITE",
    "iconText": "Elite",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "franchise",
    "type": "ttf",
    "name": "FRANCHISE",
    "iconText": "Franchise",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "racer",
    "type": "ttf",
    "name": "RACER",
    "iconText": "Racer",
    "isEnabled": false,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "bigShow",
    "type": "otf",
    "name": "BIG SHOW",
    "iconText": "Big Show",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": true,
      "outline": true
    }
  },
  {
    "id": "vintageSport",
    "type": "ttf",
    "name": "VINTAGE SPORT",
    "iconText": "Vintage",
    "isEnabled": true,
    "isScript": false,
    "isAvailable": {
      "uppercase": false,
      "lowercase": true,
      "numbers": true,
      "outline": false
    }
  },
  {
    "id": "ballpark",
    "type": "ttf",
    "name": "BALLPARK",
    "iconText": "Ballpark",
    "isEnabled": true,
    "isScript": true,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": false,
      "outline": true
    }
  },
  {
    "id": "noble",
    "type": "otf",
    "name": "NOBLE",
    "iconText": "Noble",
    "isEnabled": true,
    "isScript": true,
    "isAvailable": {
      "uppercase": true,
      "lowercase": true,
      "numbers": false,
      "outline": true
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FontRow[]>> = data;

export type Font = typeof typed[number];

export const FONT_INDEX_KEY = "id";
export type FontIndexKey = "id";
export type FontId = Font["id"];

let i = 0;
export const FONT_DICT = {
  "pirate": typed[i++],
  "tiffany": typed[i++],
  "proBlock": typed[i++],
  "varsity": typed[i++],
  "brush": typed[i++],
  "script": typed[i++],
  "spartan": typed[i++],
  "allCourt": typed[i++],
  "techItalic": typed[i++],
  "mizunoElite": typed[i++],
  "franchise": typed[i++],
  "racer": typed[i++],
  "bigShow": typed[i++],
  "vintageSport": typed[i++],
  "ballpark": typed[i++],
  "noble": typed[i++]
} as const;

export { typed as FONTS };
