import { MaterialPropertyCanvas } from '@orangelv/bjs-renderer'
import { Player } from '@orangelv/contracts'
import { SvgElement } from '@orangelv/svg-renderer'
import { Pattern, PieceMappingItem } from '@orangelv/utils-olvpf'
import { SvgData } from '@orangelv/utils-svg'
import { svgToCanvas } from '@orangelv/utils-dom'

import { DEFAULT_SIZE } from './defaults'
import { Product } from '../common/sheets'
import { loadFont, loadSvg } from '../../../platform/client/svg-renderer-utils'
import { renderPieceFromRecipe } from '../common/svg-renderer-utils'
import { Placement } from './getPlacements'
import { Recipe } from '../common/typings'
import { getSize } from '../client/svg-renderer-utils'

const createCanvasTexture = async ({
  product,
  pattern,
  patternName,
  pieceMappingItem,
  recipe,
  player,
  placements = [],
  extraImageElements = [],
}: {
  product: Product
  pattern: Pattern
  patternName: string
  pieceMappingItem: PieceMappingItem
  recipe: Recipe
  player: Player
  placements?: Placement[]
  extraImageElements?: SvgElement[]
}): Promise<MaterialPropertyCanvas> => {
  const pieceName = pieceMappingItem.name
  let svg: SvgData | undefined
  try {
    svg = await renderPieceFromRecipe({
      product,
      pattern,
      patternName,
      pieceMappingItem,
      sizeName: DEFAULT_SIZE,
      recipe,
      player,
      getSize,
      loadSvg,
      loadFont,
      placements,
      extraImageElements,
    })
  } catch (e) {
    console.error(`Could not render ${patternName}/${pieceName}`)
    console.error(e)
  }

  return {
    key: svg,
    getCanvas: async (canvas: HTMLCanvasElement) => {
      try {
        if (!svg) throw new Error('Not rendered')
        await svgToCanvas(svg, { canvas })
      } catch (e) {
        console.error(`Could not rasterize ${patternName}/${pieceName}`)
        console.error(e)
      }
    },
  }
}

export default createCanvasTexture
