import { hasLengthAtLeast, type Tuple } from '@orangelv/utils';

type SubBraidSetup = {
  offsetDelta: number;
  radiusDelta: number;
  thickness: number;
  color: string;
};

export function getSubBraidSetups(
  colors: Tuple<string, 3>,
  thickness: number,
): SubBraidSetup[] {
  const setups = [];

  // Done to avoid aliasing:
  // https://github.com/OrangeLV/blocks/assets/2584727/bfafdbe8-664f-45af-8fa5-b8876947f05d
  // Comes before the other colors in the z-stack so that they clip off the
  // extra thickness.
  if (colors[1] !== '') {
    if (colors[0] !== '') {
      if (colors[2] === '') {
        const extraThickness = thickness / 2;
        setups.push({
          offsetDelta: thickness * 1.5 - extraThickness / 2,
          radiusDelta: -thickness * 0.5 + extraThickness / 4,
          thickness: thickness + extraThickness,
          color: colors[1],
        });
      } else {
        setups.push({
          offsetDelta: thickness * 1.5,
          radiusDelta: -thickness * 0.5,
          thickness: thickness * 2,
          color: colors[1],
        });
      }
    } else if (colors[2] === '') {
      setups.push({
        offsetDelta: thickness * 0.5,
        radiusDelta: -thickness * 0.5,
        thickness,
        color: colors[1],
      });
    } else {
      const extraThickness = thickness / 2;
      setups.push({
        offsetDelta: thickness * 0.5 + extraThickness / 2,
        radiusDelta: -thickness * 0.5 - extraThickness / 4,
        thickness: thickness + extraThickness,
        color: colors[1],
      });
    }
  }

  if (colors[0] !== '') {
    setups.push({
      offsetDelta: thickness * 0.5,
      radiusDelta: 0,
      thickness,
      color: colors[0],
    });
  }

  if (colors[2] !== '') {
    setups.push({
      offsetDelta:
        thickness *
        (colors[0] === '' ?
          colors[1] === '' ?
            0.5
          : 1.5
        : 2.5),
      radiusDelta:
        colors[0] === '' ?
          colors[1] === '' ?
            0
          : -thickness * 0.5
        : -thickness,
      thickness,
      color: colors[2],
    });
  }

  return setups;
}

export function getVisibleThickness(setups: readonly SubBraidSetup[]): number {
  if (!hasLengthAtLeast(setups, 1)) return 0;

  let [minSetup, maxSetup] = [setups[0], setups[0]];
  for (const setup of setups.slice(1)) {
    if (setup.offsetDelta < minSetup.offsetDelta) {
      minSetup = setup;
    } else if (setup.offsetDelta > maxSetup.offsetDelta) {
      maxSetup = setup;
    }
  }

  return (
    maxSetup.offsetDelta -
    minSetup.offsetDelta +
    minSetup.thickness / 2 +
    maxSetup.thickness / 2
  );
}
