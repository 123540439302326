import * as _ from '@technically/lodash'
import FileSaver from 'file-saver'
import JSZip from 'jszip'

import { PartialWithUndefined } from '@orangelv/utils'
import { GeneratePreviews } from '@orangelv/bjs-renderer'

import { ProductId, DesignId, PRODUCTS, DESIGNS } from '../../common/sheets'
import controlTree from '../../client/controlTree'
import getRendererConfig from '../getRendererConfig'

type DesignAngles = PartialWithUndefined<
  Record<DesignId, { alpha?: number; beta?: number }>
>

const sideAngle = { alpha: Math.PI * 2 }
const backAngle = { alpha: -Math.PI / 2 }

const designAngles: DesignAngles = {
  burst: backAngle,
  blocked: sideAngle,
  dualStripe: sideAngle,
  varsity: sideAngle,
  racer: sideAngle,
  stripedFade: backAngle,
  'p-8': sideAngle,
  'p-9': sideAngle,
  'p-blocked': sideAngle,
}

type Options = {
  productIds?: ProductId[]
  designIds?: DesignId[]
}

export default (generatePreviews: GeneratePreviews) =>
  async (options?: Options) => {
    console.time('generateIconsForDesigns')

    const defaultView = 'default'

    const zip = new JSZip()

    const products = PRODUCTS.filter(
      (product) =>
        product.props.isEnabled &&
        (options?.productIds ?
          _.includes(options.productIds, product.id)
        : true),
    )

    for (const product of products) {
      const designs = DESIGNS.filter(
        (design) =>
          design.props.isAvailable.sport[product.props.sport] &&
          design.props.isAvailable.productType[product.props.type] &&
          (options?.designIds ?
            _.includes(options.designIds, design.id)
          : true),
      )

      for (const design of designs) {
        const isJersey = product.props.type === 'jersey'
        const isPants = !isJersey

        const isFullCustom = design.id === 'fullCustom'

        const values = {
          'product.sku': product.id,
        }

        if (isJersey) {
          values['jersey.design.design'] = design.id

          if (isFullCustom) {
            values['jersey.design.file'] = {
              id: 'full-custom-example-design',
              name: 'full-custom-example-design.svg',
            }
            values['jersey.body.color1'] = 'black'
            values['jersey.sleeve.color1'] = 'black'
            values['jersey.collar.color1'] = 'black'
          }
        } else if (isPants) {
          values['pants.design.design'] = design.id

          if (isFullCustom) {
            values['pants.design.file'] = {
              id: 'full-custom-example-design',
              name: 'full-custom-example-design.svg',
            }
            values['pants.beltLoop.color1'] = 'black'
            values['pants.body.color1'] = 'black'
          }
        }

        const state = {
          controlTree: {
            pendingChanges: { auto: {}, user: {} },
            preferredValues: {},
            repeatedNodes: {},
            values,
          },
        }

        const nodes = controlTree.getNodes(state)
        const recipe = controlTree.getPublicRecipe(state)

        const rendererConfig = await getRendererConfig(
          nodes,
          recipe,
          true,
          true,
        )

        if (rendererConfig.camera) {
          let radius = 1.5
          if (isPants) {
            radius = product.id === '350843' ? 2.2 : 2
          }

          rendererConfig.camera.upperRadius = radius
          rendererConfig.camera.defaultRadius = radius

          const designAngle = designAngles[design.id]

          // Don't face the camera directly.
          rendererConfig.camera.defaultAlpha = designAngle?.alpha ?? 1.25
          rendererConfig.camera.defaultBeta = designAngle?.beta ?? 1.5
        }

        const blobs = await generatePreviews({
          size: { width: 512, height: 512 },
          rendererConfig,
        })

        const blob = blobs[defaultView]

        zip.file(`${product.id}/${design.id}.png`, blob)
      }
    }

    const content = await zip.generateAsync({ type: 'blob' })
    FileSaver.saveAs(content, 'icons-for-designs.zip')

    console.timeEnd('generateIconsForDesigns')
  }
