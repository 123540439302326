// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ProductIconRow } from '../typings';

const data = [
  {
    "id": "350771-jersey.fabric",
    "name": "350771-jersey.fabric",
    "productId": "350771",
    "propId": "jersey.fabric",
    "propValue": "04672W"
  },
  {
    "id": "350771-jersey.design.design",
    "name": "350771-jersey.design.design",
    "productId": "350771",
    "propId": "jersey.design.design",
    "propValue": "horizon"
  },
  {
    "id": "350771-jersey.design.color1",
    "name": "350771-jersey.design.color1",
    "productId": "350771",
    "propId": "jersey.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350771-jersey.design.color2",
    "name": "350771-jersey.design.color2",
    "productId": "350771",
    "propId": "jersey.design.color2",
    "propValue": "royal"
  },
  {
    "id": "350771-jersey.body.color1",
    "name": "350771-jersey.body.color1",
    "productId": "350771",
    "propId": "jersey.body.color1",
    "propValue": "gray"
  },
  {
    "id": "350771-jersey.sleeve.color1",
    "name": "350771-jersey.sleeve.color1",
    "productId": "350771",
    "propId": "jersey.sleeve.color1",
    "propValue": "gray"
  },
  {
    "id": "350771-jersey.collar.color1",
    "name": "350771-jersey.collar.color1",
    "productId": "350771",
    "propId": "jersey.collar.color1",
    "propValue": "gray"
  },
  {
    "id": "350771-jersey.runbird.innerColor",
    "name": "350771-jersey.runbird.innerColor",
    "productId": "350771",
    "propId": "jersey.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350771-jersey.runbird.outerColor",
    "name": "350771-jersey.runbird.outerColor",
    "productId": "350771",
    "propId": "jersey.runbird.outerColor",
    "propValue": "white"
  },
  {
    "id": "350771-jersey.frontChest.content",
    "name": "350771-jersey.frontChest.content",
    "productId": "350771",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350771-jersey.frontChest.fillColor1",
    "name": "350771-jersey.frontChest.fillColor1",
    "productId": "350771",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350771-jersey.frontChest.font",
    "name": "350771-jersey.frontChest.font",
    "productId": "350771",
    "propId": "jersey.frontChest.font",
    "propValue": "allCourt"
  },
  {
    "id": "350771-jersey.frontChest.outlineColor1",
    "name": "350771-jersey.frontChest.outlineColor1",
    "productId": "350771",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350771-jersey.frontChest.outlineColor2",
    "name": "350771-jersey.frontChest.outlineColor2",
    "productId": "350771",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "white"
  },
  {
    "id": "350771-jersey.frontMiddleLeft.content",
    "name": "350771-jersey.frontMiddleLeft.content",
    "productId": "350771",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350771-jersey.frontMiddleLeft.fillColor1",
    "name": "350771-jersey.frontMiddleLeft.fillColor1",
    "productId": "350771",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350771-jersey.frontMiddleLeft.font",
    "name": "350771-jersey.frontMiddleLeft.font",
    "productId": "350771",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "allCourt"
  },
  {
    "id": "350771-jersey.frontMiddleLeft.outlineColor1",
    "name": "350771-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350771",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350771-jersey.frontMiddleLeft.outlineColor2",
    "name": "350771-jersey.frontMiddleLeft.outlineColor2",
    "productId": "350771",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "propValue": "gray"
  },
  {
    "id": "350771-jersey.upperBack.content",
    "name": "350771-jersey.upperBack.content",
    "productId": "350771",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350771-jersey.upperBack.fillColor1",
    "name": "350771-jersey.upperBack.fillColor1",
    "productId": "350771",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350771-jersey.upperBack.font",
    "name": "350771-jersey.upperBack.font",
    "productId": "350771",
    "propId": "jersey.upperBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350771-jersey.upperBack.outlineColor1",
    "name": "350771-jersey.upperBack.outlineColor1",
    "productId": "350771",
    "propId": "jersey.upperBack.outlineColor1"
  },
  {
    "id": "350771-jersey.middleBack.content",
    "name": "350771-jersey.middleBack.content",
    "productId": "350771",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350771-jersey.middleBack.fillColor1",
    "name": "350771-jersey.middleBack.fillColor1",
    "productId": "350771",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350771-jersey.middleBack.font",
    "name": "350771-jersey.middleBack.font",
    "productId": "350771",
    "propId": "jersey.middleBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350771-jersey.middleBack.outlineColor1",
    "name": "350771-jersey.middleBack.outlineColor1",
    "productId": "350771",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350771-jersey.middleBack.outlineColor2",
    "name": "350771-jersey.middleBack.outlineColor2",
    "productId": "350771",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "royal"
  },
  {
    "id": "350771-details.teamName.text",
    "name": "350771-details.teamName.text",
    "productId": "350771",
    "propId": "details.teamName.text",
    "propValue": "BLUE DEVILS"
  },
  {
    "id": "350771-details.roster.1.quantity",
    "name": "350771-details.roster.1.quantity",
    "productId": "350771",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350771-details.roster.1.jerseySize",
    "name": "350771-details.roster.1.jerseySize",
    "productId": "350771",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350771-details.roster.1.name",
    "name": "350771-details.roster.1.name",
    "productId": "350771",
    "propId": "details.roster.1.name",
    "propValue": "DAVIS"
  },
  {
    "id": "350771-details.roster.1.number",
    "name": "350771-details.roster.1.number",
    "productId": "350771",
    "propId": "details.roster.1.number",
    "propValue": "6"
  },
  {
    "id": "350773-jersey.fabric",
    "name": "350773-jersey.fabric",
    "productId": "350773",
    "propId": "jersey.fabric",
    "propValue": "106713W"
  },
  {
    "id": "350773-jersey.design.design",
    "name": "350773-jersey.design.design",
    "productId": "350773",
    "propId": "jersey.design.design",
    "propValue": "banded"
  },
  {
    "id": "350773-jersey.design.color1",
    "name": "350773-jersey.design.color1",
    "productId": "350773",
    "propId": "jersey.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350773-jersey.design.color2",
    "name": "350773-jersey.design.color2",
    "productId": "350773",
    "propId": "jersey.design.color2",
    "propValue": "charcoal"
  },
  {
    "id": "350773-jersey.body.fill",
    "name": "350773-jersey.body.fill",
    "productId": "350773",
    "propId": "jersey.body.fill",
    "propValue": "halftoneCamo"
  },
  {
    "id": "350773-jersey.body.color1",
    "name": "350773-jersey.body.color1",
    "productId": "350773",
    "propId": "jersey.body.color1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.body.color2",
    "name": "350773-jersey.body.color2",
    "productId": "350773",
    "propId": "jersey.body.color2",
    "propValue": "white_+2"
  },
  {
    "id": "350773-jersey.sleeve.fill",
    "name": "350773-jersey.sleeve.fill",
    "productId": "350773",
    "propId": "jersey.sleeve.fill",
    "propValue": "halftoneCamo"
  },
  {
    "id": "350773-jersey.sleeve.color1",
    "name": "350773-jersey.sleeve.color1",
    "productId": "350773",
    "propId": "jersey.sleeve.color1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.sleeve.color2",
    "name": "350773-jersey.sleeve.color2",
    "productId": "350773",
    "propId": "jersey.sleeve.color2",
    "propValue": "white_+2"
  },
  {
    "id": "350773-jersey.collar.fill",
    "name": "350773-jersey.collar.fill",
    "productId": "350773",
    "propId": "jersey.collar.fill",
    "propValue": "halftoneCamo"
  },
  {
    "id": "350773-jersey.collar.color1",
    "name": "350773-jersey.collar.color1",
    "productId": "350773",
    "propId": "jersey.collar.color1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.collar.color2",
    "name": "350773-jersey.collar.color2",
    "productId": "350773",
    "propId": "jersey.collar.color2",
    "propValue": "white_+2"
  },
  {
    "id": "350773-jersey.runbird.innerColor",
    "name": "350773-jersey.runbird.innerColor",
    "productId": "350773",
    "propId": "jersey.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350773-jersey.runbird.outerColor",
    "name": "350773-jersey.runbird.outerColor",
    "productId": "350773",
    "propId": "jersey.runbird.outerColor",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.frontChest.content",
    "name": "350773-jersey.frontChest.content",
    "productId": "350773",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350773-jersey.frontChest.fillColor1",
    "name": "350773-jersey.frontChest.fillColor1",
    "productId": "350773",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350773-jersey.frontChest.font",
    "name": "350773-jersey.frontChest.font",
    "productId": "350773",
    "propId": "jersey.frontChest.font",
    "propValue": "proBlock"
  },
  {
    "id": "350773-jersey.frontChest.outlineColor1",
    "name": "350773-jersey.frontChest.outlineColor1",
    "productId": "350773",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.frontChest.outlineColor2",
    "name": "350773-jersey.frontChest.outlineColor2",
    "productId": "350773",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "charcoal"
  },
  {
    "id": "350773-jersey.frontMiddleLeft.content",
    "name": "350773-jersey.frontMiddleLeft.content",
    "productId": "350773",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350773-jersey.frontMiddleLeft.fillColor1",
    "name": "350773-jersey.frontMiddleLeft.fillColor1",
    "productId": "350773",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350773-jersey.frontMiddleLeft.font",
    "name": "350773-jersey.frontMiddleLeft.font",
    "productId": "350773",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "varsity"
  },
  {
    "id": "350773-jersey.frontMiddleLeft.outlineColor1",
    "name": "350773-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350773",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.frontMiddleLeft.outlineColor2",
    "name": "350773-jersey.frontMiddleLeft.outlineColor2",
    "productId": "350773",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "propValue": "charcoal"
  },
  {
    "id": "350773-jersey.upperBack.content",
    "name": "350773-jersey.upperBack.content",
    "productId": "350773",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350773-jersey.upperBack.fillColor1",
    "name": "350773-jersey.upperBack.fillColor1",
    "productId": "350773",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350773-jersey.upperBack.font",
    "name": "350773-jersey.upperBack.font",
    "productId": "350773",
    "propId": "jersey.upperBack.font",
    "propValue": "varsity"
  },
  {
    "id": "350773-jersey.upperBack.outlineColor1",
    "name": "350773-jersey.upperBack.outlineColor1",
    "productId": "350773",
    "propId": "jersey.upperBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.middleBack.content",
    "name": "350773-jersey.middleBack.content",
    "productId": "350773",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350773-jersey.middleBack.fillColor1",
    "name": "350773-jersey.middleBack.fillColor1",
    "productId": "350773",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350773-jersey.middleBack.font",
    "name": "350773-jersey.middleBack.font",
    "productId": "350773",
    "propId": "jersey.middleBack.font",
    "propValue": "varsity"
  },
  {
    "id": "350773-jersey.middleBack.outlineColor1",
    "name": "350773-jersey.middleBack.outlineColor1",
    "productId": "350773",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350773-jersey.middleBack.outlineColor2",
    "name": "350773-jersey.middleBack.outlineColor2",
    "productId": "350773",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "charcoal"
  },
  {
    "id": "350773-details.teamName.text",
    "name": "350773-details.teamName.text",
    "productId": "350773",
    "propId": "details.teamName.text",
    "propValue": "SPARTANS"
  },
  {
    "id": "350773-details.roster.1.quantity",
    "name": "350773-details.roster.1.quantity",
    "productId": "350773",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350773-details.roster.1.jerseySize",
    "name": "350773-details.roster.1.jerseySize",
    "productId": "350773",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350773-details.roster.1.name",
    "name": "350773-details.roster.1.name",
    "productId": "350773",
    "propId": "details.roster.1.name",
    "propValue": "SIMMONS"
  },
  {
    "id": "350773-details.roster.1.number",
    "name": "350773-details.roster.1.number",
    "productId": "350773",
    "propId": "details.roster.1.number",
    "propValue": "12"
  },
  {
    "id": "350775-jersey.fabric",
    "name": "350775-jersey.fabric",
    "productId": "350775",
    "propId": "jersey.fabric",
    "propValue": "H5261"
  },
  {
    "id": "350775-jersey.design.design",
    "name": "350775-jersey.design.design",
    "productId": "350775",
    "propId": "jersey.design.design",
    "propValue": "varsity"
  },
  {
    "id": "350775-jersey.design.color1",
    "name": "350775-jersey.design.color1",
    "productId": "350775",
    "propId": "jersey.design.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-jersey.design.color2",
    "name": "350775-jersey.design.color2",
    "productId": "350775",
    "propId": "jersey.design.color2",
    "propValue": "white"
  },
  {
    "id": "350775-jersey.body.fill",
    "name": "350775-jersey.body.fill",
    "productId": "350775",
    "propId": "jersey.body.fill",
    "propValue": "geoCamo"
  },
  {
    "id": "350775-jersey.body.color1",
    "name": "350775-jersey.body.color1",
    "productId": "350775",
    "propId": "jersey.body.color1",
    "propValue": "royal"
  },
  {
    "id": "350775-jersey.body.color2",
    "name": "350775-jersey.body.color2",
    "productId": "350775",
    "propId": "jersey.body.color2",
    "propValue": "royal_+2"
  },
  {
    "id": "350775-jersey.body.color3",
    "name": "350775-jersey.body.color3",
    "productId": "350775",
    "propId": "jersey.body.color3",
    "propValue": "royal_+1"
  },
  {
    "id": "350775-jersey.body.color4",
    "name": "350775-jersey.body.color4",
    "productId": "350775",
    "propId": "jersey.body.color4",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-jersey.sleeve.fill",
    "name": "350775-jersey.sleeve.fill",
    "productId": "350775",
    "propId": "jersey.sleeve.fill",
    "propValue": "geoCamo"
  },
  {
    "id": "350775-jersey.sleeve.color1",
    "name": "350775-jersey.sleeve.color1",
    "productId": "350775",
    "propId": "jersey.sleeve.color1",
    "propValue": "royal"
  },
  {
    "id": "350775-jersey.sleeve.color2",
    "name": "350775-jersey.sleeve.color2",
    "productId": "350775",
    "propId": "jersey.sleeve.color2",
    "propValue": "royal_+1"
  },
  {
    "id": "350775-jersey.sleeve.color3",
    "name": "350775-jersey.sleeve.color3",
    "productId": "350775",
    "propId": "jersey.sleeve.color3",
    "propValue": "royal_+2"
  },
  {
    "id": "350775-jersey.sleeve.color4",
    "name": "350775-jersey.sleeve.color4",
    "productId": "350775",
    "propId": "jersey.sleeve.color4",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-jersey.collar.color1",
    "name": "350775-jersey.collar.color1",
    "productId": "350775",
    "propId": "jersey.collar.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-jersey.runbird.innerColor",
    "name": "350775-jersey.runbird.innerColor",
    "productId": "350775",
    "propId": "jersey.runbird.innerColor",
    "propValue": "white"
  },
  {
    "id": "350775-jersey.runbird.outerColor",
    "name": "350775-jersey.runbird.outerColor",
    "productId": "350775",
    "propId": "jersey.runbird.outerColor",
    "propValue": "royal"
  },
  {
    "id": "350775-jersey.frontChest.content",
    "name": "350775-jersey.frontChest.content",
    "productId": "350775",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350775-jersey.frontChest.fillColor1",
    "name": "350775-jersey.frontChest.fillColor1",
    "productId": "350775",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350775-jersey.frontChest.font",
    "name": "350775-jersey.frontChest.font",
    "productId": "350775",
    "propId": "jersey.frontChest.font",
    "propValue": "proBlock"
  },
  {
    "id": "350775-jersey.frontChest.outlineColor1",
    "name": "350775-jersey.frontChest.outlineColor1",
    "productId": "350775",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350775-jersey.frontChest.outlineColor2",
    "name": "350775-jersey.frontChest.outlineColor2",
    "productId": "350775",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-jersey.frontMiddleLeft.content",
    "name": "350775-jersey.frontMiddleLeft.content",
    "productId": "350775",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350775-jersey.frontMiddleLeft.fillColor1",
    "name": "350775-jersey.frontMiddleLeft.fillColor1",
    "productId": "350775",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350775-jersey.frontMiddleLeft.font",
    "name": "350775-jersey.frontMiddleLeft.font",
    "productId": "350775",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "bigShow"
  },
  {
    "id": "350775-jersey.frontMiddleLeft.outlineColor1",
    "name": "350775-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350775",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350775-jersey.frontMiddleLeft.outlineColor2",
    "name": "350775-jersey.frontMiddleLeft.outlineColor2",
    "productId": "350775",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-jersey.middleBack.content",
    "name": "350775-jersey.middleBack.content",
    "productId": "350775",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350775-jersey.middleBack.fillColor1",
    "name": "350775-jersey.middleBack.fillColor1",
    "productId": "350775",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350775-jersey.middleBack.font",
    "name": "350775-jersey.middleBack.font",
    "productId": "350775",
    "propId": "jersey.middleBack.font",
    "propValue": "bigShow"
  },
  {
    "id": "350775-jersey.middleBack.outlineColor1",
    "name": "350775-jersey.middleBack.outlineColor1",
    "productId": "350775",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350775-jersey.middleBack.outlineColor2",
    "name": "350775-jersey.middleBack.outlineColor2",
    "productId": "350775",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350775-details.teamName.text",
    "name": "350775-details.teamName.text",
    "productId": "350775",
    "propId": "details.teamName.text",
    "propValue": "REBELS"
  },
  {
    "id": "350775-details.roster.1.quantity",
    "name": "350775-details.roster.1.quantity",
    "productId": "350775",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350775-details.roster.1.jerseySize",
    "name": "350775-details.roster.1.jerseySize",
    "productId": "350775",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350775-details.roster.1.number",
    "name": "350775-details.roster.1.number",
    "productId": "350775",
    "propId": "details.roster.1.number",
    "propValue": "3"
  },
  {
    "id": "350777-jersey.fabric",
    "name": "350777-jersey.fabric",
    "productId": "350777",
    "propId": "jersey.fabric",
    "propValue": "H5221"
  },
  {
    "id": "350777-jersey.body.color1",
    "name": "350777-jersey.body.color1",
    "productId": "350777",
    "propId": "jersey.body.color1",
    "propValue": "royal"
  },
  {
    "id": "350777-jersey.sleeve.color1",
    "name": "350777-jersey.sleeve.color1",
    "productId": "350777",
    "propId": "jersey.sleeve.color1",
    "propValue": "royal"
  },
  {
    "id": "350777-jersey.collar.color1",
    "name": "350777-jersey.collar.color1",
    "productId": "350777",
    "propId": "jersey.collar.color1",
    "propValue": "royal"
  },
  {
    "id": "350777-jersey.runbird.innerColor",
    "name": "350777-jersey.runbird.innerColor",
    "productId": "350777",
    "propId": "jersey.runbird.innerColor",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350777-jersey.runbird.outerColor",
    "name": "350777-jersey.runbird.outerColor",
    "productId": "350777",
    "propId": "jersey.runbird.outerColor",
    "propValue": "royal"
  },
  {
    "id": "350777-jersey.braid.position",
    "name": "350777-jersey.braid.position",
    "productId": "350777",
    "propId": "jersey.braid.position",
    "propValue": "[\"sleeve\", \"neck\"]"
  },
  {
    "id": "350777-jersey.braid.color1",
    "name": "350777-jersey.braid.color1",
    "productId": "350777",
    "propId": "jersey.braid.color1",
    "propValue": "white"
  },
  {
    "id": "350777-jersey.braid.color2",
    "name": "350777-jersey.braid.color2",
    "productId": "350777",
    "propId": "jersey.braid.color2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350777-jersey.braid.color3",
    "name": "350777-jersey.braid.color3",
    "productId": "350777",
    "propId": "jersey.braid.color3",
    "propValue": "white"
  },
  {
    "id": "350777-jersey.frontChest.content",
    "name": "350777-jersey.frontChest.content",
    "productId": "350777",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350777-jersey.frontChest.fill",
    "name": "350777-jersey.frontChest.fill",
    "productId": "350777",
    "propId": "jersey.frontChest.fill",
    "propValue": "halftoneCamo"
  },
  {
    "id": "350777-jersey.frontChest.fillColor1",
    "name": "350777-jersey.frontChest.fillColor1",
    "productId": "350777",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350777-jersey.frontChest.fillColor2",
    "name": "350777-jersey.frontChest.fillColor2",
    "productId": "350777",
    "propId": "jersey.frontChest.fillColor2",
    "propValue": "white_+3"
  },
  {
    "id": "350777-jersey.frontChest.font",
    "name": "350777-jersey.frontChest.font",
    "productId": "350777",
    "propId": "jersey.frontChest.font",
    "propValue": "bigShow"
  },
  {
    "id": "350777-jersey.frontChest.outlineColor1",
    "name": "350777-jersey.frontChest.outlineColor1",
    "productId": "350777",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350777-jersey.frontMiddleLeft.content",
    "name": "350777-jersey.frontMiddleLeft.content",
    "productId": "350777",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350777-jersey.frontMiddleLeft.fill",
    "name": "350777-jersey.frontMiddleLeft.fill",
    "productId": "350777",
    "propId": "jersey.frontMiddleLeft.fill",
    "propValue": "halftoneCamo"
  },
  {
    "id": "350777-jersey.frontMiddleLeft.fillColor1",
    "name": "350777-jersey.frontMiddleLeft.fillColor1",
    "productId": "350777",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350777-jersey.frontMiddleLeft.fillColor2",
    "name": "350777-jersey.frontMiddleLeft.fillColor2",
    "productId": "350777",
    "propId": "jersey.frontMiddleLeft.fillColor2",
    "propValue": "white_+3"
  },
  {
    "id": "350777-jersey.frontMiddleLeft.font",
    "name": "350777-jersey.frontMiddleLeft.font",
    "productId": "350777",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "bigShow"
  },
  {
    "id": "350777-jersey.frontMiddleLeft.outlineColor1",
    "name": "350777-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350777",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350777-jersey.upperBack.content",
    "name": "350777-jersey.upperBack.content",
    "productId": "350777",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350777-jersey.upperBack.fillColor1",
    "name": "350777-jersey.upperBack.fillColor1",
    "productId": "350777",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350777-jersey.upperBack.font",
    "name": "350777-jersey.upperBack.font",
    "productId": "350777",
    "propId": "jersey.upperBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350777-jersey.upperBack.outlineColor1",
    "name": "350777-jersey.upperBack.outlineColor1",
    "productId": "350777",
    "propId": "jersey.upperBack.outlineColor1"
  },
  {
    "id": "350777-jersey.middleBack.content",
    "name": "350777-jersey.middleBack.content",
    "productId": "350777",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350777-jersey.middleBack.fill",
    "name": "350777-jersey.middleBack.fill",
    "productId": "350777",
    "propId": "jersey.middleBack.fill",
    "propValue": "halftoneCamo"
  },
  {
    "id": "350777-jersey.middleBack.fillColor1",
    "name": "350777-jersey.middleBack.fillColor1",
    "productId": "350777",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350777-jersey.middleBack.fillColor2",
    "name": "350777-jersey.middleBack.fillColor2",
    "productId": "350777",
    "propId": "jersey.middleBack.fillColor2",
    "propValue": "white_+3"
  },
  {
    "id": "350777-jersey.middleBack.font",
    "name": "350777-jersey.middleBack.font",
    "productId": "350777",
    "propId": "jersey.middleBack.font",
    "propValue": "bigShow"
  },
  {
    "id": "350777-jersey.middleBack.outlineColor1",
    "name": "350777-jersey.middleBack.outlineColor1",
    "productId": "350777",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350777-details.teamName.text",
    "name": "350777-details.teamName.text",
    "productId": "350777",
    "propId": "details.teamName.text",
    "propValue": "ACES"
  },
  {
    "id": "350777-details.roster.1.quantity",
    "name": "350777-details.roster.1.quantity",
    "productId": "350777",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350777-details.roster.1.jerseySize",
    "name": "350777-details.roster.1.jerseySize",
    "productId": "350777",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350777-details.roster.1.name",
    "name": "350777-details.roster.1.name",
    "productId": "350777",
    "propId": "details.roster.1.name",
    "propValue": "THORPE"
  },
  {
    "id": "350777-details.roster.1.number",
    "name": "350777-details.roster.1.number",
    "productId": "350777",
    "propId": "details.roster.1.number",
    "propValue": "8"
  },
  {
    "id": "350785-jersey.fabric",
    "name": "350785-jersey.fabric",
    "productId": "350785",
    "propId": "jersey.fabric",
    "propValue": "P-2027"
  },
  {
    "id": "350785-jersey.body.fill",
    "name": "350785-jersey.body.fill",
    "productId": "350785",
    "propId": "jersey.body.fill",
    "propValue": "pinStripe"
  },
  {
    "id": "350785-jersey.body.color1",
    "name": "350785-jersey.body.color1",
    "productId": "350785",
    "propId": "jersey.body.color1",
    "propValue": "gray"
  },
  {
    "id": "350785-jersey.body.color2",
    "name": "350785-jersey.body.color2",
    "productId": "350785",
    "propId": "jersey.body.color2",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.sleeve.color1",
    "name": "350785-jersey.sleeve.color1",
    "productId": "350785",
    "propId": "jersey.sleeve.color1",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.collar.color1",
    "name": "350785-jersey.collar.color1",
    "productId": "350785",
    "propId": "jersey.collar.color1",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.runbird.innerColor",
    "name": "350785-jersey.runbird.innerColor",
    "productId": "350785",
    "propId": "jersey.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.runbird.outerColor",
    "name": "350785-jersey.runbird.outerColor",
    "productId": "350785",
    "propId": "jersey.runbird.outerColor",
    "propValue": "gray"
  },
  {
    "id": "350785-jersey.frontChest.content",
    "name": "350785-jersey.frontChest.content",
    "productId": "350785",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350785-jersey.frontChest.fillColor1",
    "name": "350785-jersey.frontChest.fillColor1",
    "productId": "350785",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.frontChest.font",
    "name": "350785-jersey.frontChest.font",
    "productId": "350785",
    "propId": "jersey.frontChest.font",
    "propValue": "techItalic"
  },
  {
    "id": "350785-jersey.frontChest.outlineColor1",
    "name": "350785-jersey.frontChest.outlineColor1",
    "productId": "350785",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350785-jersey.frontMiddleLeft.content",
    "name": "350785-jersey.frontMiddleLeft.content",
    "productId": "350785",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350785-jersey.frontMiddleLeft.fillColor1",
    "name": "350785-jersey.frontMiddleLeft.fillColor1",
    "productId": "350785",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.frontMiddleLeft.font",
    "name": "350785-jersey.frontMiddleLeft.font",
    "productId": "350785",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "techItalic"
  },
  {
    "id": "350785-jersey.frontMiddleLeft.outlineColor1",
    "name": "350785-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350785",
    "propId": "jersey.frontMiddleLeft.outlineColor1"
  },
  {
    "id": "350785-jersey.frontMiddleLeft.outlineColor2",
    "name": "350785-jersey.frontMiddleLeft.outlineColor2",
    "productId": "350785",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "propValue": "gray"
  },
  {
    "id": "350785-jersey.upperBack.content",
    "name": "350785-jersey.upperBack.content",
    "productId": "350785",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350785-jersey.upperBack.fillColor1",
    "name": "350785-jersey.upperBack.fillColor1",
    "productId": "350785",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.upperBack.font",
    "name": "350785-jersey.upperBack.font",
    "productId": "350785",
    "propId": "jersey.upperBack.font",
    "propValue": "techItalic"
  },
  {
    "id": "350785-jersey.upperBack.outlineColor1",
    "name": "350785-jersey.upperBack.outlineColor1",
    "productId": "350785",
    "propId": "jersey.upperBack.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350785-jersey.middleBack.content",
    "name": "350785-jersey.middleBack.content",
    "productId": "350785",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350785-jersey.middleBack.fillColor1",
    "name": "350785-jersey.middleBack.fillColor1",
    "productId": "350785",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350785-jersey.middleBack.font",
    "name": "350785-jersey.middleBack.font",
    "productId": "350785",
    "propId": "jersey.middleBack.font",
    "propValue": "techItalic"
  },
  {
    "id": "350785-jersey.middleBack.outlineColor1",
    "name": "350785-jersey.middleBack.outlineColor1",
    "productId": "350785",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350785-details.teamName.text",
    "name": "350785-details.teamName.text",
    "productId": "350785",
    "propId": "details.teamName.text",
    "propValue": "DAWGS"
  },
  {
    "id": "350785-details.roster.1.quantity",
    "name": "350785-details.roster.1.quantity",
    "productId": "350785",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350785-details.roster.1.jerseySize",
    "name": "350785-details.roster.1.jerseySize",
    "productId": "350785",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350785-details.roster.1.name",
    "name": "350785-details.roster.1.name",
    "productId": "350785",
    "propId": "details.roster.1.name",
    "propValue": "HARPER"
  },
  {
    "id": "350785-details.roster.1.number",
    "name": "350785-details.roster.1.number",
    "productId": "350785",
    "propId": "details.roster.1.number",
    "propValue": "14"
  },
  {
    "id": "350786-jersey.fabric",
    "name": "350786-jersey.fabric",
    "productId": "350786",
    "propId": "jersey.fabric",
    "propValue": "H5221"
  },
  {
    "id": "350786-jersey.design.design",
    "name": "350786-jersey.design.design",
    "productId": "350786",
    "propId": "jersey.design.design",
    "propValue": "bigStripe"
  },
  {
    "id": "350786-jersey.design.color1",
    "name": "350786-jersey.design.color1",
    "productId": "350786",
    "propId": "jersey.design.color1",
    "propValue": "white"
  },
  {
    "id": "350786-jersey.design.color2",
    "name": "350786-jersey.design.color2",
    "productId": "350786",
    "propId": "jersey.design.color2",
    "propValue": "royal"
  },
  {
    "id": "350786-jersey.body.color1",
    "name": "350786-jersey.body.color1",
    "productId": "350786",
    "propId": "jersey.body.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350786-jersey.sleeve.color1",
    "name": "350786-jersey.sleeve.color1",
    "productId": "350786",
    "propId": "jersey.sleeve.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350786-jersey.collar.color1",
    "name": "350786-jersey.collar.color1",
    "productId": "350786",
    "propId": "jersey.collar.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350786-jersey.runbird.innerColor",
    "name": "350786-jersey.runbird.innerColor",
    "productId": "350786",
    "propId": "jersey.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350786-jersey.runbird.outerColor",
    "name": "350786-jersey.runbird.outerColor",
    "productId": "350786",
    "propId": "jersey.runbird.outerColor",
    "propValue": "charcoal"
  },
  {
    "id": "350786-jersey.frontChest.content",
    "name": "350786-jersey.frontChest.content",
    "productId": "350786",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350786-jersey.frontChest.fillColor1",
    "name": "350786-jersey.frontChest.fillColor1",
    "productId": "350786",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350786-jersey.frontChest.font",
    "name": "350786-jersey.frontChest.font",
    "productId": "350786",
    "propId": "jersey.frontChest.font",
    "propValue": "tiffany"
  },
  {
    "id": "350786-jersey.frontChest.outlineColor1",
    "name": "350786-jersey.frontChest.outlineColor1",
    "productId": "350786",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "charcoal"
  },
  {
    "id": "350786-jersey.frontMiddleLeft.content",
    "name": "350786-jersey.frontMiddleLeft.content",
    "productId": "350786",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350786-jersey.frontMiddleLeft.fillColor1",
    "name": "350786-jersey.frontMiddleLeft.fillColor1",
    "productId": "350786",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350786-jersey.frontMiddleLeft.font",
    "name": "350786-jersey.frontMiddleLeft.font",
    "productId": "350786",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "tiffany"
  },
  {
    "id": "350786-jersey.frontMiddleLeft.outlineColor1",
    "name": "350786-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350786",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350786-jersey.middleBack.content",
    "name": "350786-jersey.middleBack.content",
    "productId": "350786",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350786-jersey.middleBack.fillColor1",
    "name": "350786-jersey.middleBack.fillColor1",
    "productId": "350786",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "charcoal"
  },
  {
    "id": "350786-jersey.middleBack.font",
    "name": "350786-jersey.middleBack.font",
    "productId": "350786",
    "propId": "jersey.middleBack.font",
    "propValue": "tiffany"
  },
  {
    "id": "350786-jersey.middleBack.outlineColor1",
    "name": "350786-jersey.middleBack.outlineColor1",
    "productId": "350786",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350786-jersey.middleBack.outlineColor2",
    "name": "350786-jersey.middleBack.outlineColor2",
    "productId": "350786",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "white"
  },
  {
    "id": "350786-details.teamName.text",
    "name": "350786-details.teamName.text",
    "productId": "350786",
    "propId": "details.teamName.text",
    "propValue": "OCEAN"
  },
  {
    "id": "350786-details.roster.1.quantity",
    "name": "350786-details.roster.1.quantity",
    "productId": "350786",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350786-details.roster.1.jerseySize",
    "name": "350786-details.roster.1.jerseySize",
    "productId": "350786",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350786-details.roster.1.number",
    "name": "350786-details.roster.1.number",
    "productId": "350786",
    "propId": "details.roster.1.number",
    "propValue": "22"
  },
  {
    "id": "350787-jersey.fabric",
    "name": "350787-jersey.fabric",
    "productId": "350787",
    "propId": "jersey.fabric",
    "propValue": "04672W"
  },
  {
    "id": "350787-jersey.design.design",
    "name": "350787-jersey.design.design",
    "productId": "350787",
    "propId": "jersey.design.design",
    "propValue": "throwback"
  },
  {
    "id": "350787-jersey.design.color1",
    "name": "350787-jersey.design.color1",
    "productId": "350787",
    "propId": "jersey.design.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350787-jersey.design.color2",
    "name": "350787-jersey.design.color2",
    "productId": "350787",
    "propId": "jersey.design.color2",
    "propValue": "royal"
  },
  {
    "id": "350787-jersey.body.fill",
    "name": "350787-jersey.body.fill",
    "productId": "350787",
    "propId": "jersey.body.fill",
    "propValue": "woodlandCamo"
  },
  {
    "id": "350787-jersey.body.color1",
    "name": "350787-jersey.body.color1",
    "productId": "350787",
    "propId": "jersey.body.color1",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.body.color2",
    "name": "350787-jersey.body.color2",
    "productId": "350787",
    "propId": "jersey.body.color2",
    "propValue": "white_+3"
  },
  {
    "id": "350787-jersey.body.color3",
    "name": "350787-jersey.body.color3",
    "productId": "350787",
    "propId": "jersey.body.color3",
    "propValue": "white_+1"
  },
  {
    "id": "350787-jersey.body.color4",
    "name": "350787-jersey.body.color4",
    "productId": "350787",
    "propId": "jersey.body.color4",
    "propValue": "white_+2"
  },
  {
    "id": "350787-jersey.sleeve.color1",
    "name": "350787-jersey.sleeve.color1",
    "productId": "350787",
    "propId": "jersey.sleeve.color1",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.collar.fill",
    "name": "350787-jersey.collar.fill",
    "productId": "350787",
    "propId": "jersey.collar.fill",
    "propValue": "woodlandCamo"
  },
  {
    "id": "350787-jersey.collar.color1",
    "name": "350787-jersey.collar.color1",
    "productId": "350787",
    "propId": "jersey.collar.color1",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.collar.color2",
    "name": "350787-jersey.collar.color2",
    "productId": "350787",
    "propId": "jersey.collar.color2",
    "propValue": "white_+1"
  },
  {
    "id": "350787-jersey.collar.color3",
    "name": "350787-jersey.collar.color3",
    "productId": "350787",
    "propId": "jersey.collar.color3",
    "propValue": "white_+2"
  },
  {
    "id": "350787-jersey.collar.color4",
    "name": "350787-jersey.collar.color4",
    "productId": "350787",
    "propId": "jersey.collar.color4",
    "propValue": "white_+3"
  },
  {
    "id": "350787-jersey.runbird.innerColor",
    "name": "350787-jersey.runbird.innerColor",
    "productId": "350787",
    "propId": "jersey.runbird.innerColor",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350787-jersey.runbird.outerColor",
    "name": "350787-jersey.runbird.outerColor",
    "productId": "350787",
    "propId": "jersey.runbird.outerColor",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.frontChest.content",
    "name": "350787-jersey.frontChest.content",
    "productId": "350787",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350787-jersey.frontChest.fillColor1",
    "name": "350787-jersey.frontChest.fillColor1",
    "productId": "350787",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.frontChest.font",
    "name": "350787-jersey.frontChest.font",
    "productId": "350787",
    "propId": "jersey.frontChest.font",
    "propValue": "proBlock"
  },
  {
    "id": "350787-jersey.frontChest.outlineColor1",
    "name": "350787-jersey.frontChest.outlineColor1",
    "productId": "350787",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350787-jersey.frontChest.outlineColor2",
    "name": "350787-jersey.frontChest.outlineColor2",
    "productId": "350787",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350787-jersey.frontMiddleRight.content",
    "name": "350787-jersey.frontMiddleRight.content",
    "productId": "350787",
    "propId": "jersey.frontMiddleRight.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350787-jersey.frontMiddleRight.fillColor1",
    "name": "350787-jersey.frontMiddleRight.fillColor1",
    "productId": "350787",
    "propId": "jersey.frontMiddleRight.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.frontMiddleRight.font",
    "name": "350787-jersey.frontMiddleRight.font",
    "productId": "350787",
    "propId": "jersey.frontMiddleRight.font",
    "propValue": "proBlock"
  },
  {
    "id": "350787-jersey.frontMiddleRight.outlineColor1",
    "name": "350787-jersey.frontMiddleRight.outlineColor1",
    "productId": "350787",
    "propId": "jersey.frontMiddleRight.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350787-jersey.frontMiddleRight.outlineColor2",
    "name": "350787-jersey.frontMiddleRight.outlineColor2",
    "productId": "350787",
    "propId": "jersey.frontMiddleRight.outlineColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350787-jersey.upperBack.content",
    "name": "350787-jersey.upperBack.content",
    "productId": "350787",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350787-jersey.upperBack.fillColor1",
    "name": "350787-jersey.upperBack.fillColor1",
    "productId": "350787",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350787-jersey.upperBack.font",
    "name": "350787-jersey.upperBack.font",
    "productId": "350787",
    "propId": "jersey.upperBack.font",
    "propValue": "proBlock"
  },
  {
    "id": "350787-jersey.upperBack.outlineColor1",
    "name": "350787-jersey.upperBack.outlineColor1",
    "productId": "350787",
    "propId": "jersey.upperBack.outlineColor1"
  },
  {
    "id": "350787-jersey.middleBack.content",
    "name": "350787-jersey.middleBack.content",
    "productId": "350787",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350787-jersey.middleBack.fillColor1",
    "name": "350787-jersey.middleBack.fillColor1",
    "productId": "350787",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350787-jersey.middleBack.font",
    "name": "350787-jersey.middleBack.font",
    "productId": "350787",
    "propId": "jersey.middleBack.font",
    "propValue": "proBlock"
  },
  {
    "id": "350787-jersey.middleBack.outlineColor1",
    "name": "350787-jersey.middleBack.outlineColor1",
    "productId": "350787",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350787-jersey.middleBack.outlineColor2",
    "name": "350787-jersey.middleBack.outlineColor2",
    "productId": "350787",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350787-details.teamName.text",
    "name": "350787-details.teamName.text",
    "productId": "350787",
    "propId": "details.teamName.text",
    "propValue": "REBELS"
  },
  {
    "id": "350787-details.roster.1.quantity",
    "name": "350787-details.roster.1.quantity",
    "productId": "350787",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350787-details.roster.1.jerseySize",
    "name": "350787-details.roster.1.jerseySize",
    "productId": "350787",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350787-details.roster.1.name",
    "name": "350787-details.roster.1.name",
    "productId": "350787",
    "propId": "details.roster.1.name",
    "propValue": "LEWIS"
  },
  {
    "id": "350787-details.roster.1.number",
    "name": "350787-details.roster.1.number",
    "productId": "350787",
    "propId": "details.roster.1.number",
    "propValue": "18"
  },
  {
    "id": "350788-jersey.fabric",
    "name": "350788-jersey.fabric",
    "productId": "350788",
    "propId": "jersey.fabric",
    "propValue": "H5221"
  },
  {
    "id": "350788-jersey.design.design",
    "name": "350788-jersey.design.design",
    "productId": "350788",
    "propId": "jersey.design.design",
    "propValue": "warrior"
  },
  {
    "id": "350788-jersey.design.color1",
    "name": "350788-jersey.design.color1",
    "productId": "350788",
    "propId": "jersey.design.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350788-jersey.design.color2",
    "name": "350788-jersey.design.color2",
    "productId": "350788",
    "propId": "jersey.design.color2",
    "propValue": "royal"
  },
  {
    "id": "350788-jersey.body.color1",
    "name": "350788-jersey.body.color1",
    "productId": "350788",
    "propId": "jersey.body.color1",
    "propValue": "gray"
  },
  {
    "id": "350788-jersey.sleeve.color1",
    "name": "350788-jersey.sleeve.color1",
    "productId": "350788",
    "propId": "jersey.sleeve.color1",
    "propValue": "gray"
  },
  {
    "id": "350788-jersey.collar.color1",
    "name": "350788-jersey.collar.color1",
    "productId": "350788",
    "propId": "jersey.collar.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350788-jersey.runbird.innerColor",
    "name": "350788-jersey.runbird.innerColor",
    "productId": "350788",
    "propId": "jersey.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350788-jersey.runbird.outerColor",
    "name": "350788-jersey.runbird.outerColor",
    "productId": "350788",
    "propId": "jersey.runbird.outerColor",
    "propValue": "gray"
  },
  {
    "id": "350788-jersey.frontChest.content",
    "name": "350788-jersey.frontChest.content",
    "productId": "350788",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350788-jersey.frontChest.fillColor1",
    "name": "350788-jersey.frontChest.fillColor1",
    "productId": "350788",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350788-jersey.frontChest.font",
    "name": "350788-jersey.frontChest.font",
    "productId": "350788",
    "propId": "jersey.frontChest.font",
    "propValue": "allCourt"
  },
  {
    "id": "350788-jersey.frontChest.outlineColor1",
    "name": "350788-jersey.frontChest.outlineColor1",
    "productId": "350788",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350788-jersey.frontMiddleLeft.content",
    "name": "350788-jersey.frontMiddleLeft.content",
    "productId": "350788",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350788-jersey.frontMiddleLeft.fillColor1",
    "name": "350788-jersey.frontMiddleLeft.fillColor1",
    "productId": "350788",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350788-jersey.frontMiddleLeft.font",
    "name": "350788-jersey.frontMiddleLeft.font",
    "productId": "350788",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "allCourt"
  },
  {
    "id": "350788-jersey.frontMiddleLeft.outlineColor1",
    "name": "350788-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350788",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350788-jersey.middleBack.content",
    "name": "350788-jersey.middleBack.content",
    "productId": "350788",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350788-jersey.middleBack.fillColor1",
    "name": "350788-jersey.middleBack.fillColor1",
    "productId": "350788",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350788-jersey.middleBack.font",
    "name": "350788-jersey.middleBack.font",
    "productId": "350788",
    "propId": "jersey.middleBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350788-jersey.middleBack.outlineColor1",
    "name": "350788-jersey.middleBack.outlineColor1",
    "productId": "350788",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350788-details.teamName.text",
    "name": "350788-details.teamName.text",
    "productId": "350788",
    "propId": "details.teamName.text",
    "propValue": "WARRIORS"
  },
  {
    "id": "350788-details.roster.1.quantity",
    "name": "350788-details.roster.1.quantity",
    "productId": "350788",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350788-details.roster.1.jerseySize",
    "name": "350788-details.roster.1.jerseySize",
    "productId": "350788",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350788-details.roster.1.number",
    "name": "350788-details.roster.1.number",
    "productId": "350788",
    "propId": "details.roster.1.number",
    "propValue": "6"
  },
  {
    "id": "350789-jersey.fabric",
    "name": "350789-jersey.fabric",
    "productId": "350789",
    "propId": "jersey.fabric",
    "propValue": "04672W"
  },
  {
    "id": "350789-jersey.design.design",
    "name": "350789-jersey.design.design",
    "productId": "350789",
    "propId": "jersey.design.design",
    "propValue": "trifade"
  },
  {
    "id": "350789-jersey.design.color1",
    "name": "350789-jersey.design.color1",
    "productId": "350789",
    "propId": "jersey.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350789-jersey.design.color2",
    "name": "350789-jersey.design.color2",
    "productId": "350789",
    "propId": "jersey.design.color2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350789-jersey.body.color1",
    "name": "350789-jersey.body.color1",
    "productId": "350789",
    "propId": "jersey.body.color1",
    "propValue": "white"
  },
  {
    "id": "350789-jersey.sleeve.color1",
    "name": "350789-jersey.sleeve.color1",
    "productId": "350789",
    "propId": "jersey.sleeve.color1",
    "propValue": "white"
  },
  {
    "id": "350789-jersey.collar.color1",
    "name": "350789-jersey.collar.color1",
    "productId": "350789",
    "propId": "jersey.collar.color1",
    "propValue": "royal"
  },
  {
    "id": "350789-jersey.runbird.innerColor",
    "name": "350789-jersey.runbird.innerColor",
    "productId": "350789",
    "propId": "jersey.runbird.innerColor",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350789-jersey.runbird.outerColor",
    "name": "350789-jersey.runbird.outerColor",
    "productId": "350789",
    "propId": "jersey.runbird.outerColor",
    "propValue": "white"
  },
  {
    "id": "350789-jersey.frontChest.content",
    "name": "350789-jersey.frontChest.content",
    "productId": "350789",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350789-jersey.frontChest.fillColor1",
    "name": "350789-jersey.frontChest.fillColor1",
    "productId": "350789",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350789-jersey.frontChest.font",
    "name": "350789-jersey.frontChest.font",
    "productId": "350789",
    "propId": "jersey.frontChest.font",
    "propValue": "spartan"
  },
  {
    "id": "350789-jersey.frontChest.outlineColor1",
    "name": "350789-jersey.frontChest.outlineColor1",
    "productId": "350789",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350789-jersey.frontChest.outlineColor2",
    "name": "350789-jersey.frontChest.outlineColor2",
    "productId": "350789",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "white"
  },
  {
    "id": "350789-jersey.middleBack.content",
    "name": "350789-jersey.middleBack.content",
    "productId": "350789",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350789-jersey.middleBack.fillColor1",
    "name": "350789-jersey.middleBack.fillColor1",
    "productId": "350789",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350789-jersey.middleBack.font",
    "name": "350789-jersey.middleBack.font",
    "productId": "350789",
    "propId": "jersey.middleBack.font",
    "propValue": "spartan"
  },
  {
    "id": "350789-jersey.middleBack.outlineColor1",
    "name": "350789-jersey.middleBack.outlineColor1",
    "productId": "350789",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350789-jersey.middleBack.outlineColor2",
    "name": "350789-jersey.middleBack.outlineColor2",
    "productId": "350789",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "white"
  },
  {
    "id": "350789-details.teamName.text",
    "name": "350789-details.teamName.text",
    "productId": "350789",
    "propId": "details.teamName.text",
    "propValue": "LIONS"
  },
  {
    "id": "350789-details.roster.1.quantity",
    "name": "350789-details.roster.1.quantity",
    "productId": "350789",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350789-details.roster.1.jerseySize",
    "name": "350789-details.roster.1.jerseySize",
    "productId": "350789",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350789-details.roster.1.number",
    "name": "350789-details.roster.1.number",
    "productId": "350789",
    "propId": "details.roster.1.number",
    "propValue": "28"
  },
  {
    "id": "350790-jersey.fabric",
    "name": "350790-jersey.fabric",
    "productId": "350790",
    "propId": "jersey.fabric",
    "propValue": "106713W"
  },
  {
    "id": "350790-jersey.design.design",
    "name": "350790-jersey.design.design",
    "productId": "350790",
    "propId": "jersey.design.design",
    "propValue": "racer"
  },
  {
    "id": "350790-jersey.design.color1",
    "name": "350790-jersey.design.color1",
    "productId": "350790",
    "propId": "jersey.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350790-jersey.design.color2",
    "name": "350790-jersey.design.color2",
    "productId": "350790",
    "propId": "jersey.design.color2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.body.color1",
    "name": "350790-jersey.body.color1",
    "productId": "350790",
    "propId": "jersey.body.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350790-jersey.sleeve.color1",
    "name": "350790-jersey.sleeve.color1",
    "productId": "350790",
    "propId": "jersey.sleeve.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350790-jersey.collar.color1",
    "name": "350790-jersey.collar.color1",
    "productId": "350790",
    "propId": "jersey.collar.color1",
    "propValue": "royal"
  },
  {
    "id": "350790-jersey.runbird.innerColor",
    "name": "350790-jersey.runbird.innerColor",
    "productId": "350790",
    "propId": "jersey.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350790-jersey.runbird.outerColor",
    "name": "350790-jersey.runbird.outerColor",
    "productId": "350790",
    "propId": "jersey.runbird.outerColor",
    "propValue": "charcoal"
  },
  {
    "id": "350790-jersey.frontChest.content",
    "name": "350790-jersey.frontChest.content",
    "productId": "350790",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350790-jersey.frontChest.fill",
    "name": "350790-jersey.frontChest.fill",
    "productId": "350790",
    "propId": "jersey.frontChest.fill",
    "propValue": "razorStripe"
  },
  {
    "id": "350790-jersey.frontChest.fillColor1",
    "name": "350790-jersey.frontChest.fillColor1",
    "productId": "350790",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350790-jersey.frontChest.fillColor2",
    "name": "350790-jersey.frontChest.fillColor2",
    "productId": "350790",
    "propId": "jersey.frontChest.fillColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.frontChest.font",
    "name": "350790-jersey.frontChest.font",
    "productId": "350790",
    "propId": "jersey.frontChest.font",
    "propValue": "bigShow"
  },
  {
    "id": "350790-jersey.frontChest.outlineColor1",
    "name": "350790-jersey.frontChest.outlineColor1",
    "productId": "350790",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.frontMiddleLeft.content",
    "name": "350790-jersey.frontMiddleLeft.content",
    "productId": "350790",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350790-jersey.frontMiddleLeft.fill",
    "name": "350790-jersey.frontMiddleLeft.fill",
    "productId": "350790",
    "propId": "jersey.frontMiddleLeft.fill",
    "propValue": "razorStripe"
  },
  {
    "id": "350790-jersey.frontMiddleLeft.fillColor1",
    "name": "350790-jersey.frontMiddleLeft.fillColor1",
    "productId": "350790",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350790-jersey.frontMiddleLeft.fillColor2",
    "name": "350790-jersey.frontMiddleLeft.fillColor2",
    "productId": "350790",
    "propId": "jersey.frontMiddleLeft.fillColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.frontMiddleLeft.font",
    "name": "350790-jersey.frontMiddleLeft.font",
    "productId": "350790",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "bigShow"
  },
  {
    "id": "350790-jersey.frontMiddleLeft.outlineColor1",
    "name": "350790-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350790",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.upperBack.content",
    "name": "350790-jersey.upperBack.content",
    "productId": "350790",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350790-jersey.upperBack.fillColor1",
    "name": "350790-jersey.upperBack.fillColor1",
    "productId": "350790",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.upperBack.font",
    "name": "350790-jersey.upperBack.font",
    "productId": "350790",
    "propId": "jersey.upperBack.font",
    "propValue": "bigShow"
  },
  {
    "id": "350790-jersey.upperBack.outlineColor1",
    "name": "350790-jersey.upperBack.outlineColor1",
    "productId": "350790",
    "propId": "jersey.upperBack.outlineColor1"
  },
  {
    "id": "350790-jersey.middleBack.content",
    "name": "350790-jersey.middleBack.content",
    "productId": "350790",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350790-jersey.middleBack.fill",
    "name": "350790-jersey.middleBack.fill",
    "productId": "350790",
    "propId": "jersey.middleBack.fill",
    "propValue": "razorStripe"
  },
  {
    "id": "350790-jersey.middleBack.fillColor1",
    "name": "350790-jersey.middleBack.fillColor1",
    "productId": "350790",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350790-jersey.middleBack.fillColor2",
    "name": "350790-jersey.middleBack.fillColor2",
    "productId": "350790",
    "propId": "jersey.middleBack.fillColor2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-jersey.middleBack.font",
    "name": "350790-jersey.middleBack.font",
    "productId": "350790",
    "propId": "jersey.middleBack.font",
    "propValue": "bigShow"
  },
  {
    "id": "350790-jersey.middleBack.outlineColor1",
    "name": "350790-jersey.middleBack.outlineColor1",
    "productId": "350790",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350790-details.teamName.text",
    "name": "350790-details.teamName.text",
    "productId": "350790",
    "propId": "details.teamName.text",
    "propValue": "BLADES"
  },
  {
    "id": "350790-details.roster.1.quantity",
    "name": "350790-details.roster.1.quantity",
    "productId": "350790",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350790-details.roster.1.jerseySize",
    "name": "350790-details.roster.1.jerseySize",
    "productId": "350790",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350790-details.roster.1.name",
    "name": "350790-details.roster.1.name",
    "productId": "350790",
    "propId": "details.roster.1.name",
    "propValue": "ROSSI"
  },
  {
    "id": "350790-details.roster.1.number",
    "name": "350790-details.roster.1.number",
    "productId": "350790",
    "propId": "details.roster.1.number",
    "propValue": "12"
  },
  {
    "id": "350797-jersey.fabric",
    "name": "350797-jersey.fabric",
    "productId": "350797",
    "propId": "jersey.fabric",
    "propValue": "04672W"
  },
  {
    "id": "350797-jersey.body.fill",
    "name": "350797-jersey.body.fill",
    "productId": "350797",
    "propId": "jersey.body.fill",
    "propValue": "bio"
  },
  {
    "id": "350797-jersey.body.color1",
    "name": "350797-jersey.body.color1",
    "productId": "350797",
    "propId": "jersey.body.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350797-jersey.body.color2",
    "name": "350797-jersey.body.color2",
    "productId": "350797",
    "propId": "jersey.body.color2",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.body.color3",
    "name": "350797-jersey.body.color3",
    "productId": "350797",
    "propId": "jersey.body.color3",
    "propValue": "royal_-1"
  },
  {
    "id": "350797-jersey.sleeve.fill",
    "name": "350797-jersey.sleeve.fill",
    "productId": "350797",
    "propId": "jersey.sleeve.fill",
    "propValue": "bio"
  },
  {
    "id": "350797-jersey.sleeve.color1",
    "name": "350797-jersey.sleeve.color1",
    "productId": "350797",
    "propId": "jersey.sleeve.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350797-jersey.sleeve.color2",
    "name": "350797-jersey.sleeve.color2",
    "productId": "350797",
    "propId": "jersey.sleeve.color2",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.sleeve.color3",
    "name": "350797-jersey.sleeve.color3",
    "productId": "350797",
    "propId": "jersey.sleeve.color3",
    "propValue": "royal_-1"
  },
  {
    "id": "350797-jersey.collar.fill",
    "name": "350797-jersey.collar.fill",
    "productId": "350797",
    "propId": "jersey.collar.fill",
    "propValue": "bio"
  },
  {
    "id": "350797-jersey.collar.color1",
    "name": "350797-jersey.collar.color1",
    "productId": "350797",
    "propId": "jersey.collar.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350797-jersey.collar.color2",
    "name": "350797-jersey.collar.color2",
    "productId": "350797",
    "propId": "jersey.collar.color2",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.collar.color3",
    "name": "350797-jersey.collar.color3",
    "productId": "350797",
    "propId": "jersey.collar.color3",
    "propValue": "royal_-1"
  },
  {
    "id": "350797-jersey.runbird.innerColor",
    "name": "350797-jersey.runbird.innerColor",
    "productId": "350797",
    "propId": "jersey.runbird.innerColor",
    "propValue": "white"
  },
  {
    "id": "350797-jersey.runbird.outerColor",
    "name": "350797-jersey.runbird.outerColor",
    "productId": "350797",
    "propId": "jersey.runbird.outerColor",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.frontChest.content",
    "name": "350797-jersey.frontChest.content",
    "productId": "350797",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350797-jersey.frontChest.fillColor1",
    "name": "350797-jersey.frontChest.fillColor1",
    "productId": "350797",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350797-jersey.frontChest.font",
    "name": "350797-jersey.frontChest.font",
    "productId": "350797",
    "propId": "jersey.frontChest.font",
    "propValue": "allCourt"
  },
  {
    "id": "350797-jersey.frontChest.outlineColor1",
    "name": "350797-jersey.frontChest.outlineColor1",
    "productId": "350797",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.frontChest.outlineColor2",
    "name": "350797-jersey.frontChest.outlineColor2",
    "productId": "350797",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "blazingOrange"
  },
  {
    "id": "350797-jersey.frontMiddleLeft.content",
    "name": "350797-jersey.frontMiddleLeft.content",
    "productId": "350797",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350797-jersey.frontMiddleLeft.fillColor1",
    "name": "350797-jersey.frontMiddleLeft.fillColor1",
    "productId": "350797",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350797-jersey.frontMiddleLeft.font",
    "name": "350797-jersey.frontMiddleLeft.font",
    "productId": "350797",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "allCourt"
  },
  {
    "id": "350797-jersey.frontMiddleLeft.outlineColor1",
    "name": "350797-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350797",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.frontMiddleLeft.outlineColor2",
    "name": "350797-jersey.frontMiddleLeft.outlineColor2",
    "productId": "350797",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "propValue": "blazingOrange"
  },
  {
    "id": "350797-jersey.upperBack.content",
    "name": "350797-jersey.upperBack.content",
    "productId": "350797",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350797-jersey.upperBack.fillColor1",
    "name": "350797-jersey.upperBack.fillColor1",
    "productId": "350797",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350797-jersey.upperBack.font",
    "name": "350797-jersey.upperBack.font",
    "productId": "350797",
    "propId": "jersey.upperBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350797-jersey.upperBack.outlineColor1",
    "name": "350797-jersey.upperBack.outlineColor1",
    "productId": "350797",
    "propId": "jersey.upperBack.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.middleBack.content",
    "name": "350797-jersey.middleBack.content",
    "productId": "350797",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350797-jersey.middleBack.fillColor1",
    "name": "350797-jersey.middleBack.fillColor1",
    "productId": "350797",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350797-jersey.middleBack.font",
    "name": "350797-jersey.middleBack.font",
    "productId": "350797",
    "propId": "jersey.middleBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350797-jersey.middleBack.outlineColor1",
    "name": "350797-jersey.middleBack.outlineColor1",
    "productId": "350797",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350797-jersey.middleBack.outlineColor2",
    "name": "350797-jersey.middleBack.outlineColor2",
    "productId": "350797",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "blazingOrange"
  },
  {
    "id": "350797-details.teamName.text",
    "name": "350797-details.teamName.text",
    "productId": "350797",
    "propId": "details.teamName.text",
    "propValue": "ELITE"
  },
  {
    "id": "350797-details.roster.1.quantity",
    "name": "350797-details.roster.1.quantity",
    "productId": "350797",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350797-details.roster.1.jerseySize",
    "name": "350797-details.roster.1.jerseySize",
    "productId": "350797",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350797-details.roster.1.name",
    "name": "350797-details.roster.1.name",
    "productId": "350797",
    "propId": "details.roster.1.name",
    "propValue": "CUTLER"
  },
  {
    "id": "350797-details.roster.1.number",
    "name": "350797-details.roster.1.number",
    "productId": "350797",
    "propId": "details.roster.1.number",
    "propValue": "4"
  },
  {
    "id": "350798-jersey.fabric",
    "name": "350798-jersey.fabric",
    "productId": "350798",
    "propId": "jersey.fabric",
    "propValue": "106713W"
  },
  {
    "id": "350798-jersey.design.design",
    "name": "350798-jersey.design.design",
    "productId": "350798",
    "propId": "jersey.design.design",
    "propValue": "blocked"
  },
  {
    "id": "350798-jersey.design.color1",
    "name": "350798-jersey.design.color1",
    "productId": "350798",
    "propId": "jersey.design.color1",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.design.color2",
    "name": "350798-jersey.design.color2",
    "productId": "350798",
    "propId": "jersey.design.color2",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.design.color3",
    "name": "350798-jersey.design.color3",
    "productId": "350798",
    "propId": "jersey.design.color3",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.body.fill",
    "name": "350798-jersey.body.fill",
    "productId": "350798",
    "propId": "jersey.body.fill",
    "propValue": "razorStripe"
  },
  {
    "id": "350798-jersey.body.color1",
    "name": "350798-jersey.body.color1",
    "productId": "350798",
    "propId": "jersey.body.color1",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.body.color2",
    "name": "350798-jersey.body.color2",
    "productId": "350798",
    "propId": "jersey.body.color2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.sleeve.color1",
    "name": "350798-jersey.sleeve.color1",
    "productId": "350798",
    "propId": "jersey.sleeve.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.collar.color1",
    "name": "350798-jersey.collar.color1",
    "productId": "350798",
    "propId": "jersey.collar.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.runbird.innerColor",
    "name": "350798-jersey.runbird.innerColor",
    "productId": "350798",
    "propId": "jersey.runbird.innerColor",
    "propValue": "white"
  },
  {
    "id": "350798-jersey.runbird.outerColor",
    "name": "350798-jersey.runbird.outerColor",
    "productId": "350798",
    "propId": "jersey.runbird.outerColor",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.frontChest.content",
    "name": "350798-jersey.frontChest.content",
    "productId": "350798",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350798-jersey.frontChest.fillColor1",
    "name": "350798-jersey.frontChest.fillColor1",
    "productId": "350798",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.frontChest.font",
    "name": "350798-jersey.frontChest.font",
    "productId": "350798",
    "propId": "jersey.frontChest.font",
    "propValue": "ballpark"
  },
  {
    "id": "350798-jersey.frontChest.outlineColor1",
    "name": "350798-jersey.frontChest.outlineColor1",
    "productId": "350798",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350798-jersey.frontChest.outlineColor2",
    "name": "350798-jersey.frontChest.outlineColor2",
    "productId": "350798",
    "propId": "jersey.frontChest.outlineColor2",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.frontMiddleLeft.content",
    "name": "350798-jersey.frontMiddleLeft.content",
    "productId": "350798",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350798-jersey.frontMiddleLeft.fillColor1",
    "name": "350798-jersey.frontMiddleLeft.fillColor1",
    "productId": "350798",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.frontMiddleLeft.font",
    "name": "350798-jersey.frontMiddleLeft.font",
    "productId": "350798",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "techItalic"
  },
  {
    "id": "350798-jersey.frontMiddleLeft.outlineColor1",
    "name": "350798-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350798",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350798-jersey.frontMiddleLeft.outlineColor2",
    "name": "350798-jersey.frontMiddleLeft.outlineColor2",
    "productId": "350798",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.upperBack.content",
    "name": "350798-jersey.upperBack.content",
    "productId": "350798",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350798-jersey.upperBack.fillColor1",
    "name": "350798-jersey.upperBack.fillColor1",
    "productId": "350798",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.upperBack.font",
    "name": "350798-jersey.upperBack.font",
    "productId": "350798",
    "propId": "jersey.upperBack.font",
    "propValue": "franchise"
  },
  {
    "id": "350798-jersey.upperBack.outlineColor1",
    "name": "350798-jersey.upperBack.outlineColor1",
    "productId": "350798",
    "propId": "jersey.upperBack.outlineColor1",
    "propValue": "black"
  },
  {
    "id": "350798-jersey.middleBack.content",
    "name": "350798-jersey.middleBack.content",
    "productId": "350798",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350798-jersey.middleBack.fillColor1",
    "name": "350798-jersey.middleBack.fillColor1",
    "productId": "350798",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350798-jersey.middleBack.font",
    "name": "350798-jersey.middleBack.font",
    "productId": "350798",
    "propId": "jersey.middleBack.font",
    "propValue": "franchise"
  },
  {
    "id": "350798-jersey.middleBack.outlineColor1",
    "name": "350798-jersey.middleBack.outlineColor1",
    "productId": "350798",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350798-jersey.middleBack.outlineColor2",
    "name": "350798-jersey.middleBack.outlineColor2",
    "productId": "350798",
    "propId": "jersey.middleBack.outlineColor2",
    "propValue": "black"
  },
  {
    "id": "350798-details.teamName.text",
    "name": "350798-details.teamName.text",
    "productId": "350798",
    "propId": "details.teamName.text",
    "propValue": "Fliers"
  },
  {
    "id": "350798-details.roster.1.quantity",
    "name": "350798-details.roster.1.quantity",
    "productId": "350798",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350798-details.roster.1.jerseySize",
    "name": "350798-details.roster.1.jerseySize",
    "productId": "350798",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350798-details.roster.1.name",
    "name": "350798-details.roster.1.name",
    "productId": "350798",
    "propId": "details.roster.1.name",
    "propValue": "GRIFFIN"
  },
  {
    "id": "350798-details.roster.1.number",
    "name": "350798-details.roster.1.number",
    "productId": "350798",
    "propId": "details.roster.1.number",
    "propValue": "13"
  },
  {
    "id": "350799-jersey.fabric",
    "name": "350799-jersey.fabric",
    "productId": "350799",
    "propId": "jersey.fabric",
    "propValue": "04672W"
  },
  {
    "id": "350799-jersey.design.design",
    "name": "350799-jersey.design.design",
    "productId": "350799",
    "propId": "jersey.design.design",
    "propValue": "dualStripe"
  },
  {
    "id": "350799-jersey.design.color1",
    "name": "350799-jersey.design.color1",
    "productId": "350799",
    "propId": "jersey.design.color1",
    "propValue": "blazingOrange"
  },
  {
    "id": "350799-jersey.design.color2",
    "name": "350799-jersey.design.color2",
    "productId": "350799",
    "propId": "jersey.design.color2",
    "propValue": "blazingOrange"
  },
  {
    "id": "350799-jersey.body.fill",
    "name": "350799-jersey.body.fill",
    "productId": "350799",
    "propId": "jersey.body.fill",
    "propValue": "leopard"
  },
  {
    "id": "350799-jersey.body.color1",
    "name": "350799-jersey.body.color1",
    "productId": "350799",
    "propId": "jersey.body.color1",
    "propValue": "royal"
  },
  {
    "id": "350799-jersey.body.color2",
    "name": "350799-jersey.body.color2",
    "productId": "350799",
    "propId": "jersey.body.color2",
    "propValue": "royal_-1"
  },
  {
    "id": "350799-jersey.sleeve.fill",
    "name": "350799-jersey.sleeve.fill",
    "productId": "350799",
    "propId": "jersey.sleeve.fill",
    "propValue": "leopard"
  },
  {
    "id": "350799-jersey.sleeve.color1",
    "name": "350799-jersey.sleeve.color1",
    "productId": "350799",
    "propId": "jersey.sleeve.color1",
    "propValue": "royal"
  },
  {
    "id": "350799-jersey.sleeve.color2",
    "name": "350799-jersey.sleeve.color2",
    "productId": "350799",
    "propId": "jersey.sleeve.color2",
    "propValue": "royal_-1"
  },
  {
    "id": "350799-jersey.collar.color1",
    "name": "350799-jersey.collar.color1",
    "productId": "350799",
    "propId": "jersey.collar.color1",
    "propValue": "royal"
  },
  {
    "id": "350799-jersey.runbird.innerColor",
    "name": "350799-jersey.runbird.innerColor",
    "productId": "350799",
    "propId": "jersey.runbird.innerColor",
    "propValue": "blazingOrange"
  },
  {
    "id": "350799-jersey.runbird.outerColor",
    "name": "350799-jersey.runbird.outerColor",
    "productId": "350799",
    "propId": "jersey.runbird.outerColor",
    "propValue": "royal"
  },
  {
    "id": "350799-jersey.frontChest.content",
    "name": "350799-jersey.frontChest.content",
    "productId": "350799",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350799-jersey.frontChest.fillColor1",
    "name": "350799-jersey.frontChest.fillColor1",
    "productId": "350799",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "blazingOrange"
  },
  {
    "id": "350799-jersey.frontChest.font",
    "name": "350799-jersey.frontChest.font",
    "productId": "350799",
    "propId": "jersey.frontChest.font",
    "propValue": "varsity"
  },
  {
    "id": "350799-jersey.frontChest.outlineColor1",
    "name": "350799-jersey.frontChest.outlineColor1",
    "productId": "350799",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350799-jersey.frontMiddleLeft.content",
    "name": "350799-jersey.frontMiddleLeft.content",
    "productId": "350799",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350799-jersey.frontMiddleLeft.fillColor1",
    "name": "350799-jersey.frontMiddleLeft.fillColor1",
    "productId": "350799",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "blazingOrange"
  },
  {
    "id": "350799-jersey.frontMiddleLeft.font",
    "name": "350799-jersey.frontMiddleLeft.font",
    "productId": "350799",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "varsity"
  },
  {
    "id": "350799-jersey.frontMiddleLeft.outlineColor1",
    "name": "350799-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350799",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350799-jersey.middleBack.content",
    "name": "350799-jersey.middleBack.content",
    "productId": "350799",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350799-jersey.middleBack.fillColor1",
    "name": "350799-jersey.middleBack.fillColor1",
    "productId": "350799",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "blazingOrange"
  },
  {
    "id": "350799-jersey.middleBack.font",
    "name": "350799-jersey.middleBack.font",
    "productId": "350799",
    "propId": "jersey.middleBack.font",
    "propValue": "varsity"
  },
  {
    "id": "350799-jersey.middleBack.outlineColor1",
    "name": "350799-jersey.middleBack.outlineColor1",
    "productId": "350799",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "royal"
  },
  {
    "id": "350799-details.teamName.text",
    "name": "350799-details.teamName.text",
    "productId": "350799",
    "propId": "details.teamName.text",
    "propValue": "Jags"
  },
  {
    "id": "350799-details.roster.1.quantity",
    "name": "350799-details.roster.1.quantity",
    "productId": "350799",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350799-details.roster.1.jerseySize",
    "name": "350799-details.roster.1.jerseySize",
    "productId": "350799",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350799-details.roster.1.number",
    "name": "350799-details.roster.1.number",
    "productId": "350799",
    "propId": "details.roster.1.number",
    "propValue": "7"
  },
  {
    "id": "350800-jersey.fabric",
    "name": "350800-jersey.fabric",
    "productId": "350800",
    "propId": "jersey.fabric",
    "propValue": "H5221"
  },
  {
    "id": "350800-jersey.design.design",
    "name": "350800-jersey.design.design",
    "productId": "350800",
    "propId": "jersey.design.design",
    "propValue": "trifade"
  },
  {
    "id": "350800-jersey.design.color1",
    "name": "350800-jersey.design.color1",
    "productId": "350800",
    "propId": "jersey.design.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350800-jersey.design.color2",
    "name": "350800-jersey.design.color2",
    "productId": "350800",
    "propId": "jersey.design.color2",
    "propValue": "black"
  },
  {
    "id": "350800-jersey.body.color1",
    "name": "350800-jersey.body.color1",
    "productId": "350800",
    "propId": "jersey.body.color1",
    "propValue": "black"
  },
  {
    "id": "350800-jersey.sleeve.color1",
    "name": "350800-jersey.sleeve.color1",
    "productId": "350800",
    "propId": "jersey.sleeve.color1",
    "propValue": "white"
  },
  {
    "id": "350800-jersey.collar.color1",
    "name": "350800-jersey.collar.color1",
    "productId": "350800",
    "propId": "jersey.collar.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350800-jersey.runbird.innerColor",
    "name": "350800-jersey.runbird.innerColor",
    "productId": "350800",
    "propId": "jersey.runbird.innerColor",
    "propValue": "white"
  },
  {
    "id": "350800-jersey.runbird.outerColor",
    "name": "350800-jersey.runbird.outerColor",
    "productId": "350800",
    "propId": "jersey.runbird.outerColor",
    "propValue": "black"
  },
  {
    "id": "350800-jersey.frontChest.content",
    "name": "350800-jersey.frontChest.content",
    "productId": "350800",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350800-jersey.frontChest.fillColor1",
    "name": "350800-jersey.frontChest.fillColor1",
    "productId": "350800",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350800-jersey.frontChest.font",
    "name": "350800-jersey.frontChest.font",
    "productId": "350800",
    "propId": "jersey.frontChest.font",
    "propValue": "allCourt"
  },
  {
    "id": "350800-jersey.frontChest.outlineColor1",
    "name": "350800-jersey.frontChest.outlineColor1",
    "productId": "350800",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350800-jersey.frontMiddleLeft.content",
    "name": "350800-jersey.frontMiddleLeft.content",
    "productId": "350800",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350800-jersey.frontMiddleLeft.fillColor1",
    "name": "350800-jersey.frontMiddleLeft.fillColor1",
    "productId": "350800",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350800-jersey.frontMiddleLeft.font",
    "name": "350800-jersey.frontMiddleLeft.font",
    "productId": "350800",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "allCourt"
  },
  {
    "id": "350800-jersey.frontMiddleLeft.outlineColor1",
    "name": "350800-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350800",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350800-jersey.middleBack.content",
    "name": "350800-jersey.middleBack.content",
    "productId": "350800",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350800-jersey.middleBack.fillColor1",
    "name": "350800-jersey.middleBack.fillColor1",
    "productId": "350800",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350800-jersey.middleBack.font",
    "name": "350800-jersey.middleBack.font",
    "productId": "350800",
    "propId": "jersey.middleBack.font",
    "propValue": "allCourt"
  },
  {
    "id": "350800-jersey.middleBack.outlineColor1",
    "name": "350800-jersey.middleBack.outlineColor1",
    "productId": "350800",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350800-details.teamName.text",
    "name": "350800-details.teamName.text",
    "productId": "350800",
    "propId": "details.teamName.text",
    "propValue": "NORTH"
  },
  {
    "id": "350800-details.roster.1.quantity",
    "name": "350800-details.roster.1.quantity",
    "productId": "350800",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350800-details.roster.1.jerseySize",
    "name": "350800-details.roster.1.jerseySize",
    "productId": "350800",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350800-details.roster.1.number",
    "name": "350800-details.roster.1.number",
    "productId": "350800",
    "propId": "details.roster.1.number",
    "propValue": "16"
  },
  {
    "id": "350801-jersey.fabric",
    "name": "350801-jersey.fabric",
    "productId": "350801",
    "propId": "jersey.fabric",
    "propValue": "H5221"
  },
  {
    "id": "350801-jersey.design.design",
    "name": "350801-jersey.design.design",
    "productId": "350801",
    "propId": "jersey.design.design",
    "propValue": "horizon"
  },
  {
    "id": "350801-jersey.design.color1",
    "name": "350801-jersey.design.color1",
    "productId": "350801",
    "propId": "jersey.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350801-jersey.design.color2",
    "name": "350801-jersey.design.color2",
    "productId": "350801",
    "propId": "jersey.design.color2",
    "propValue": "royal"
  },
  {
    "id": "350801-jersey.body.fill",
    "name": "350801-jersey.body.fill",
    "productId": "350801",
    "propId": "jersey.body.fill",
    "propValue": "oldSchoolCamo"
  },
  {
    "id": "350801-jersey.body.color1",
    "name": "350801-jersey.body.color1",
    "productId": "350801",
    "propId": "jersey.body.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350801-jersey.body.color2",
    "name": "350801-jersey.body.color2",
    "productId": "350801",
    "propId": "jersey.body.color2",
    "propValue": "columbiaBlue_-2"
  },
  {
    "id": "350801-jersey.body.color3",
    "name": "350801-jersey.body.color3",
    "productId": "350801",
    "propId": "jersey.body.color3",
    "propValue": "columbiaBlue_-1"
  },
  {
    "id": "350801-jersey.body.color4",
    "name": "350801-jersey.body.color4",
    "productId": "350801",
    "propId": "jersey.body.color4",
    "propValue": "columbiaBlue_+1"
  },
  {
    "id": "350801-jersey.sleeve.color1",
    "name": "350801-jersey.sleeve.color1",
    "productId": "350801",
    "propId": "jersey.sleeve.color1",
    "propValue": "white"
  },
  {
    "id": "350801-jersey.collar.fill",
    "name": "350801-jersey.collar.fill",
    "productId": "350801",
    "propId": "jersey.collar.fill",
    "propValue": "oldSchoolCamo"
  },
  {
    "id": "350801-jersey.collar.color1",
    "name": "350801-jersey.collar.color1",
    "productId": "350801",
    "propId": "jersey.collar.color1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350801-jersey.collar.color2",
    "name": "350801-jersey.collar.color2",
    "productId": "350801",
    "propId": "jersey.collar.color2",
    "propValue": "columbiaBlue_-2"
  },
  {
    "id": "350801-jersey.collar.color3",
    "name": "350801-jersey.collar.color3",
    "productId": "350801",
    "propId": "jersey.collar.color3",
    "propValue": "columbiaBlue_-1"
  },
  {
    "id": "350801-jersey.collar.color4",
    "name": "350801-jersey.collar.color4",
    "productId": "350801",
    "propId": "jersey.collar.color4",
    "propValue": "columbiaBlue_+1"
  },
  {
    "id": "350801-jersey.runbird.innerColor",
    "name": "350801-jersey.runbird.innerColor",
    "productId": "350801",
    "propId": "jersey.runbird.innerColor",
    "propValue": "white"
  },
  {
    "id": "350801-jersey.runbird.outerColor",
    "name": "350801-jersey.runbird.outerColor",
    "productId": "350801",
    "propId": "jersey.runbird.outerColor",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350801-jersey.frontChest.content",
    "name": "350801-jersey.frontChest.content",
    "productId": "350801",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350801-jersey.frontChest.fillColor1",
    "name": "350801-jersey.frontChest.fillColor1",
    "productId": "350801",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350801-jersey.frontChest.font",
    "name": "350801-jersey.frontChest.font",
    "productId": "350801",
    "propId": "jersey.frontChest.font",
    "propValue": "franchise"
  },
  {
    "id": "350801-jersey.frontChest.outlineColor1",
    "name": "350801-jersey.frontChest.outlineColor1",
    "productId": "350801",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350801-jersey.frontMiddleLeft.content",
    "name": "350801-jersey.frontMiddleLeft.content",
    "productId": "350801",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350801-jersey.frontMiddleLeft.fillColor1",
    "name": "350801-jersey.frontMiddleLeft.fillColor1",
    "productId": "350801",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350801-jersey.frontMiddleLeft.font",
    "name": "350801-jersey.frontMiddleLeft.font",
    "productId": "350801",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "franchise"
  },
  {
    "id": "350801-jersey.frontMiddleLeft.outlineColor1",
    "name": "350801-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350801",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350801-jersey.upperBack.content",
    "name": "350801-jersey.upperBack.content",
    "productId": "350801",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350801-jersey.upperBack.fillColor1",
    "name": "350801-jersey.upperBack.fillColor1",
    "productId": "350801",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350801-jersey.upperBack.font",
    "name": "350801-jersey.upperBack.font",
    "productId": "350801",
    "propId": "jersey.upperBack.font",
    "propValue": "franchise"
  },
  {
    "id": "350801-jersey.upperBack.outlineColor1",
    "name": "350801-jersey.upperBack.outlineColor1",
    "productId": "350801",
    "propId": "jersey.upperBack.outlineColor1"
  },
  {
    "id": "350801-jersey.middleBack.content",
    "name": "350801-jersey.middleBack.content",
    "productId": "350801",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350801-jersey.middleBack.fillColor1",
    "name": "350801-jersey.middleBack.fillColor1",
    "productId": "350801",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "white"
  },
  {
    "id": "350801-jersey.middleBack.font",
    "name": "350801-jersey.middleBack.font",
    "productId": "350801",
    "propId": "jersey.middleBack.font",
    "propValue": "franchise"
  },
  {
    "id": "350801-jersey.middleBack.outlineColor1",
    "name": "350801-jersey.middleBack.outlineColor1",
    "productId": "350801",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350801-details.teamName.text",
    "name": "350801-details.teamName.text",
    "productId": "350801",
    "propId": "details.teamName.text",
    "propValue": "SHARKS"
  },
  {
    "id": "350801-details.roster.1.quantity",
    "name": "350801-details.roster.1.quantity",
    "productId": "350801",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350801-details.roster.1.jerseySize",
    "name": "350801-details.roster.1.jerseySize",
    "productId": "350801",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350801-details.roster.1.name",
    "name": "350801-details.roster.1.name",
    "productId": "350801",
    "propId": "details.roster.1.name",
    "propValue": "SMITTY"
  },
  {
    "id": "350801-details.roster.1.number",
    "name": "350801-details.roster.1.number",
    "productId": "350801",
    "propId": "details.roster.1.number",
    "propValue": "22"
  },
  {
    "id": "350802-jersey.fabric",
    "name": "350802-jersey.fabric",
    "productId": "350802",
    "propId": "jersey.fabric",
    "propValue": "106713W"
  },
  {
    "id": "350802-jersey.body.fill",
    "name": "350802-jersey.body.fill",
    "productId": "350802",
    "propId": "jersey.body.fill",
    "propValue": "pinStripe"
  },
  {
    "id": "350802-jersey.body.color1",
    "name": "350802-jersey.body.color1",
    "productId": "350802",
    "propId": "jersey.body.color1",
    "propValue": "royal"
  },
  {
    "id": "350802-jersey.body.color2",
    "name": "350802-jersey.body.color2",
    "productId": "350802",
    "propId": "jersey.body.color2",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350802-jersey.sleeve.color1",
    "name": "350802-jersey.sleeve.color1",
    "productId": "350802",
    "propId": "jersey.sleeve.color1",
    "propValue": "white"
  },
  {
    "id": "350802-jersey.collar.color1",
    "name": "350802-jersey.collar.color1",
    "productId": "350802",
    "propId": "jersey.collar.color1",
    "propValue": "royal"
  },
  {
    "id": "350802-jersey.runbird.innerColor",
    "name": "350802-jersey.runbird.innerColor",
    "productId": "350802",
    "propId": "jersey.runbird.innerColor",
    "propValue": "white"
  },
  {
    "id": "350802-jersey.runbird.outerColor",
    "name": "350802-jersey.runbird.outerColor",
    "productId": "350802",
    "propId": "jersey.runbird.outerColor",
    "propValue": "royal"
  },
  {
    "id": "350802-jersey.frontChest.content",
    "name": "350802-jersey.frontChest.content",
    "productId": "350802",
    "propId": "jersey.frontChest.content",
    "propValue": "teamName"
  },
  {
    "id": "350802-jersey.frontChest.fill",
    "name": "350802-jersey.frontChest.fill",
    "productId": "350802",
    "propId": "jersey.frontChest.fill",
    "propValue": "bio"
  },
  {
    "id": "350802-jersey.frontChest.fillColor1",
    "name": "350802-jersey.frontChest.fillColor1",
    "productId": "350802",
    "propId": "jersey.frontChest.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350802-jersey.frontChest.fillColor2",
    "name": "350802-jersey.frontChest.fillColor2",
    "productId": "350802",
    "propId": "jersey.frontChest.fillColor2",
    "propValue": "columbiaBlue_-2"
  },
  {
    "id": "350802-jersey.frontChest.fillColor3",
    "name": "350802-jersey.frontChest.fillColor3",
    "productId": "350802",
    "propId": "jersey.frontChest.fillColor3",
    "propValue": "columbiaBlue_-1"
  },
  {
    "id": "350802-jersey.frontChest.font",
    "name": "350802-jersey.frontChest.font",
    "productId": "350802",
    "propId": "jersey.frontChest.font",
    "propValue": "bigShow"
  },
  {
    "id": "350802-jersey.frontChest.outlineColor1",
    "name": "350802-jersey.frontChest.outlineColor1",
    "productId": "350802",
    "propId": "jersey.frontChest.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.content",
    "name": "350802-jersey.frontMiddleLeft.content",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.fill",
    "name": "350802-jersey.frontMiddleLeft.fill",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.fill",
    "propValue": "bio"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.fillColor1",
    "name": "350802-jersey.frontMiddleLeft.fillColor1",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.fillColor2",
    "name": "350802-jersey.frontMiddleLeft.fillColor2",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.fillColor2",
    "propValue": "columbiaBlue_-1"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.fillColor3",
    "name": "350802-jersey.frontMiddleLeft.fillColor3",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.fillColor3",
    "propValue": "columbiaBlue_-2"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.font",
    "name": "350802-jersey.frontMiddleLeft.font",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.font",
    "propValue": "bigShow"
  },
  {
    "id": "350802-jersey.frontMiddleLeft.outlineColor1",
    "name": "350802-jersey.frontMiddleLeft.outlineColor1",
    "productId": "350802",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350802-jersey.upperBack.content",
    "name": "350802-jersey.upperBack.content",
    "productId": "350802",
    "propId": "jersey.upperBack.content",
    "propValue": "playerName"
  },
  {
    "id": "350802-jersey.upperBack.fill",
    "name": "350802-jersey.upperBack.fill",
    "productId": "350802",
    "propId": "jersey.upperBack.fill",
    "propValue": "bio"
  },
  {
    "id": "350802-jersey.upperBack.fillColor1",
    "name": "350802-jersey.upperBack.fillColor1",
    "productId": "350802",
    "propId": "jersey.upperBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350802-jersey.upperBack.fillColor2",
    "name": "350802-jersey.upperBack.fillColor2",
    "productId": "350802",
    "propId": "jersey.upperBack.fillColor2",
    "propValue": "columbiaBlue_-1"
  },
  {
    "id": "350802-jersey.upperBack.fillColor3",
    "name": "350802-jersey.upperBack.fillColor3",
    "productId": "350802",
    "propId": "jersey.upperBack.fillColor3",
    "propValue": "columbiaBlue_-2"
  },
  {
    "id": "350802-jersey.upperBack.font",
    "name": "350802-jersey.upperBack.font",
    "productId": "350802",
    "propId": "jersey.upperBack.font",
    "propValue": "bigShow"
  },
  {
    "id": "350802-jersey.upperBack.outlineColor1",
    "name": "350802-jersey.upperBack.outlineColor1",
    "productId": "350802",
    "propId": "jersey.upperBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350802-jersey.middleBack.content",
    "name": "350802-jersey.middleBack.content",
    "productId": "350802",
    "propId": "jersey.middleBack.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350802-jersey.middleBack.fill",
    "name": "350802-jersey.middleBack.fill",
    "productId": "350802",
    "propId": "jersey.middleBack.fill",
    "propValue": "bio"
  },
  {
    "id": "350802-jersey.middleBack.fillColor1",
    "name": "350802-jersey.middleBack.fillColor1",
    "productId": "350802",
    "propId": "jersey.middleBack.fillColor1",
    "propValue": "columbiaBlue"
  },
  {
    "id": "350802-jersey.middleBack.fillColor2",
    "name": "350802-jersey.middleBack.fillColor2",
    "productId": "350802",
    "propId": "jersey.middleBack.fillColor2",
    "propValue": "columbiaBlue_-2"
  },
  {
    "id": "350802-jersey.middleBack.fillColor3",
    "name": "350802-jersey.middleBack.fillColor3",
    "productId": "350802",
    "propId": "jersey.middleBack.fillColor3",
    "propValue": "columbiaBlue_-1"
  },
  {
    "id": "350802-jersey.middleBack.font",
    "name": "350802-jersey.middleBack.font",
    "productId": "350802",
    "propId": "jersey.middleBack.font",
    "propValue": "bigShow"
  },
  {
    "id": "350802-jersey.middleBack.outlineColor1",
    "name": "350802-jersey.middleBack.outlineColor1",
    "productId": "350802",
    "propId": "jersey.middleBack.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "350802-details.teamName.text",
    "name": "350802-details.teamName.text",
    "productId": "350802",
    "propId": "details.teamName.text",
    "propValue": "STARS"
  },
  {
    "id": "350802-details.roster.1.quantity",
    "name": "350802-details.roster.1.quantity",
    "productId": "350802",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350802-details.roster.1.jerseySize",
    "name": "350802-details.roster.1.jerseySize",
    "productId": "350802",
    "propId": "details.roster.1.jerseySize",
    "propValue": "M"
  },
  {
    "id": "350802-details.roster.1.name",
    "name": "350802-details.roster.1.name",
    "productId": "350802",
    "propId": "details.roster.1.name",
    "propValue": "HOWELL"
  },
  {
    "id": "350802-details.roster.1.number",
    "name": "350802-details.roster.1.number",
    "productId": "350802",
    "propId": "details.roster.1.number",
    "propValue": "42"
  },
  {
    "id": "350843-pants.body.fill",
    "name": "350843-pants.body.fill",
    "productId": "350843",
    "propId": "pants.body.fill",
    "propValue": "pinStripe"
  },
  {
    "id": "350843-pants.body.color1",
    "name": "350843-pants.body.color1",
    "productId": "350843",
    "propId": "pants.body.color1",
    "propValue": "gray"
  },
  {
    "id": "350843-pants.body.color2",
    "name": "350843-pants.body.color2",
    "productId": "350843",
    "propId": "pants.body.color2",
    "propValue": "royal"
  },
  {
    "id": "350843-pants.beltLoop.fill",
    "name": "350843-pants.beltLoop.fill",
    "productId": "350843",
    "propId": "pants.beltLoop.fill",
    "propValue": "pinStripe"
  },
  {
    "id": "350843-pants.beltLoop.color1",
    "name": "350843-pants.beltLoop.color1",
    "productId": "350843",
    "propId": "pants.beltLoop.color1",
    "propValue": "gray"
  },
  {
    "id": "350843-pants.beltLoop.color2",
    "name": "350843-pants.beltLoop.color2",
    "productId": "350843",
    "propId": "pants.beltLoop.color2",
    "propValue": "royal"
  },
  {
    "id": "350843-pants.runbird.innerColor",
    "name": "350843-pants.runbird.innerColor",
    "productId": "350843",
    "propId": "pants.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350843-pants.runbird.outerColor",
    "name": "350843-pants.runbird.outerColor",
    "productId": "350843",
    "propId": "pants.runbird.outerColor",
    "propValue": "gray"
  },
  {
    "id": "350843-pants.backBeltLoop.content",
    "name": "350843-pants.backBeltLoop.content",
    "productId": "350843",
    "propId": "pants.backBeltLoop.content",
    "propValue": "teamName"
  },
  {
    "id": "350843-pants.backBeltLoop.fillColor1",
    "name": "350843-pants.backBeltLoop.fillColor1",
    "productId": "350843",
    "propId": "pants.backBeltLoop.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350843-pants.backBeltLoop.font",
    "name": "350843-pants.backBeltLoop.font",
    "productId": "350843",
    "propId": "pants.backBeltLoop.font",
    "propValue": "techItalic"
  },
  {
    "id": "350843-pants.backBeltLoop.outlineColor1",
    "name": "350843-pants.backBeltLoop.outlineColor1",
    "productId": "350843",
    "propId": "pants.backBeltLoop.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350843-pants.leftLeg.content",
    "name": "350843-pants.leftLeg.content",
    "productId": "350843",
    "propId": "pants.leftLeg.content",
    "propValue": "playerNumber"
  },
  {
    "id": "350843-pants.leftLeg.fillColor1",
    "name": "350843-pants.leftLeg.fillColor1",
    "productId": "350843",
    "propId": "pants.leftLeg.fillColor1",
    "propValue": "royal"
  },
  {
    "id": "350843-pants.leftLeg.font",
    "name": "350843-pants.leftLeg.font",
    "productId": "350843",
    "propId": "pants.leftLeg.font",
    "propValue": "techItalic"
  },
  {
    "id": "350843-pants.leftLeg.outlineColor1",
    "name": "350843-pants.leftLeg.outlineColor1",
    "productId": "350843",
    "propId": "pants.leftLeg.outlineColor1",
    "propValue": "gray"
  },
  {
    "id": "350843-details.teamName.text",
    "name": "350843-details.teamName.text",
    "productId": "350843",
    "propId": "details.teamName.text",
    "propValue": "DAWGS"
  },
  {
    "id": "350843-details.roster.1.quantity",
    "name": "350843-details.roster.1.quantity",
    "productId": "350843",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "350843-details.roster.1.pantsSize",
    "name": "350843-details.roster.1.pantsSize",
    "productId": "350843",
    "propId": "details.roster.1.pantsSize",
    "propValue": "M"
  },
  {
    "id": "350843-details.roster.1.number",
    "name": "350843-details.roster.1.number",
    "productId": "350843",
    "propId": "details.roster.1.number",
    "propValue": "14"
  },
  {
    "id": "350845-pants.design.design",
    "name": "350845-pants.design.design",
    "productId": "350845",
    "propId": "pants.design.design",
    "propValue": "p-8"
  },
  {
    "id": "350845-pants.design.color1",
    "name": "350845-pants.design.color1",
    "productId": "350845",
    "propId": "pants.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350845-pants.design.color2",
    "name": "350845-pants.design.color2",
    "productId": "350845",
    "propId": "pants.design.color2",
    "propValue": "white"
  },
  {
    "id": "350845-pants.body.color1",
    "name": "350845-pants.body.color1",
    "productId": "350845",
    "propId": "pants.body.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350845-pants.beltLoop.color1",
    "name": "350845-pants.beltLoop.color1",
    "productId": "350845",
    "propId": "pants.beltLoop.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350845-pants.runbird.innerColor",
    "name": "350845-pants.runbird.innerColor",
    "productId": "350845",
    "propId": "pants.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350845-pants.runbird.outerColor",
    "name": "350845-pants.runbird.outerColor",
    "productId": "350845",
    "propId": "pants.runbird.outerColor",
    "propValue": "charcoal"
  },
  {
    "id": "350847-pants.design.design",
    "name": "350847-pants.design.design",
    "productId": "350847",
    "propId": "pants.design.design",
    "propValue": "p-8"
  },
  {
    "id": "350847-pants.design.color1",
    "name": "350847-pants.design.color1",
    "productId": "350847",
    "propId": "pants.design.color1",
    "propValue": "royal"
  },
  {
    "id": "350847-pants.design.color2",
    "name": "350847-pants.design.color2",
    "productId": "350847",
    "propId": "pants.design.color2",
    "propValue": "white"
  },
  {
    "id": "350847-pants.body.color1",
    "name": "350847-pants.body.color1",
    "productId": "350847",
    "propId": "pants.body.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350847-pants.beltLoop.color1",
    "name": "350847-pants.beltLoop.color1",
    "productId": "350847",
    "propId": "pants.beltLoop.color1",
    "propValue": "charcoal"
  },
  {
    "id": "350847-pants.runbird.innerColor",
    "name": "350847-pants.runbird.innerColor",
    "productId": "350847",
    "propId": "pants.runbird.innerColor",
    "propValue": "royal"
  },
  {
    "id": "350847-pants.runbird.outerColor",
    "name": "350847-pants.runbird.outerColor",
    "productId": "350847",
    "propId": "pants.runbird.outerColor",
    "propValue": "charcoal"
  },
  {
    "id": "351079-pants.design.design",
    "name": "351079-pants.design.design",
    "productId": "351079",
    "propId": "pants.design.design",
    "propValue": "solid"
  },
  {
    "id": "351079-pants.body.color1",
    "name": "351079-pants.body.color1",
    "productId": "351079",
    "propId": "pants.body.color1",
    "propValue": "black"
  },
  {
    "id": "351079-pants.beltLoop.color1",
    "name": "351079-pants.beltLoop.color1",
    "productId": "351079",
    "propId": "pants.beltLoop.color1",
    "propValue": "vegasGold"
  },
  {
    "id": "351079-pants.runbird.innerColor",
    "name": "351079-pants.runbird.innerColor",
    "productId": "351079",
    "propId": "pants.runbird.innerColor",
    "propValue": "vegasGold"
  },
  {
    "id": "351079-pants.runbird.outerColor",
    "name": "351079-pants.runbird.outerColor",
    "productId": "351079",
    "propId": "pants.runbird.outerColor",
    "propValue": "black"
  },
  {
    "id": "351079-pants.rightLeg.content",
    "name": "351079-pants.rightLeg.content",
    "productId": "351079",
    "propId": "pants.rightLeg.content",
    "propValue": "playerNumber"
  },
  {
    "id": "351079-pants.rightLeg.fillColor1",
    "name": "351079-pants.rightLeg.fillColor1",
    "productId": "351079",
    "propId": "pants.rightLeg.fillColor1",
    "propValue": "vegasGold"
  },
  {
    "id": "351079-pants.rightLeg.font",
    "name": "351079-pants.rightLeg.font",
    "productId": "351079",
    "propId": "pants.rightLeg.font",
    "propValue": "pirate"
  },
  {
    "id": "351079-pants.rightLeg.outlineColor1",
    "name": "351079-pants.rightLeg.outlineColor1",
    "productId": "351079",
    "propId": "pants.rightLeg.outlineColor1",
    "propValue": "white"
  },
  {
    "id": "351079-details.roster.1.quantity",
    "name": "351079-details.roster.1.quantity",
    "productId": "351079",
    "propId": "details.roster.1.quantity",
    "propValue": "1"
  },
  {
    "id": "351079-details.roster.1.pantsSize",
    "name": "351079-details.roster.1.pantsSize",
    "productId": "351079",
    "propId": "details.roster.1.pantsSize",
    "propValue": "M"
  },
  {
    "id": "351079-details.roster.1.number",
    "name": "351079-details.roster.1.number",
    "productId": "351079",
    "propId": "details.roster.1.number",
    "propValue": "24"
  },
  {
    "id": "351079-pants.braid.position",
    "name": "351079-pants.braid.position",
    "productId": "351079",
    "propId": "pants.braid.position",
    "propValue": "[\"sideSeam\"]"
  },
  {
    "id": "351079-pants.braid.color1",
    "name": "351079-pants.braid.color1",
    "productId": "351079",
    "propId": "pants.braid.color1",
    "propValue": "vegasGold"
  },
  {
    "id": "351079-pants.braid.color2",
    "name": "351079-pants.braid.color2",
    "productId": "351079",
    "propId": "pants.braid.color2",
    "propValue": "black"
  },
  {
    "id": "351079-pants.braid.color3",
    "name": "351079-pants.braid.color3",
    "productId": "351079",
    "propId": "pants.braid.color3",
    "propValue": "vegasGold"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ProductIconRow[]>> = data;

export type ProductIcon = typeof typed[number];

export const PRODUCT_ICON_INDEX_KEY = "id";
export type ProductIconIndexKey = "id";
export type ProductIconId = ProductIcon["id"];

let i = 0;
export const PRODUCT_ICON_DICT = {
  "350771-jersey.fabric": typed[i++],
  "350771-jersey.design.design": typed[i++],
  "350771-jersey.design.color1": typed[i++],
  "350771-jersey.design.color2": typed[i++],
  "350771-jersey.body.color1": typed[i++],
  "350771-jersey.sleeve.color1": typed[i++],
  "350771-jersey.collar.color1": typed[i++],
  "350771-jersey.runbird.innerColor": typed[i++],
  "350771-jersey.runbird.outerColor": typed[i++],
  "350771-jersey.frontChest.content": typed[i++],
  "350771-jersey.frontChest.fillColor1": typed[i++],
  "350771-jersey.frontChest.font": typed[i++],
  "350771-jersey.frontChest.outlineColor1": typed[i++],
  "350771-jersey.frontChest.outlineColor2": typed[i++],
  "350771-jersey.frontMiddleLeft.content": typed[i++],
  "350771-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350771-jersey.frontMiddleLeft.font": typed[i++],
  "350771-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350771-jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "350771-jersey.upperBack.content": typed[i++],
  "350771-jersey.upperBack.fillColor1": typed[i++],
  "350771-jersey.upperBack.font": typed[i++],
  "350771-jersey.upperBack.outlineColor1": typed[i++],
  "350771-jersey.middleBack.content": typed[i++],
  "350771-jersey.middleBack.fillColor1": typed[i++],
  "350771-jersey.middleBack.font": typed[i++],
  "350771-jersey.middleBack.outlineColor1": typed[i++],
  "350771-jersey.middleBack.outlineColor2": typed[i++],
  "350771-details.teamName.text": typed[i++],
  "350771-details.roster.1.quantity": typed[i++],
  "350771-details.roster.1.jerseySize": typed[i++],
  "350771-details.roster.1.name": typed[i++],
  "350771-details.roster.1.number": typed[i++],
  "350773-jersey.fabric": typed[i++],
  "350773-jersey.design.design": typed[i++],
  "350773-jersey.design.color1": typed[i++],
  "350773-jersey.design.color2": typed[i++],
  "350773-jersey.body.fill": typed[i++],
  "350773-jersey.body.color1": typed[i++],
  "350773-jersey.body.color2": typed[i++],
  "350773-jersey.sleeve.fill": typed[i++],
  "350773-jersey.sleeve.color1": typed[i++],
  "350773-jersey.sleeve.color2": typed[i++],
  "350773-jersey.collar.fill": typed[i++],
  "350773-jersey.collar.color1": typed[i++],
  "350773-jersey.collar.color2": typed[i++],
  "350773-jersey.runbird.innerColor": typed[i++],
  "350773-jersey.runbird.outerColor": typed[i++],
  "350773-jersey.frontChest.content": typed[i++],
  "350773-jersey.frontChest.fillColor1": typed[i++],
  "350773-jersey.frontChest.font": typed[i++],
  "350773-jersey.frontChest.outlineColor1": typed[i++],
  "350773-jersey.frontChest.outlineColor2": typed[i++],
  "350773-jersey.frontMiddleLeft.content": typed[i++],
  "350773-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350773-jersey.frontMiddleLeft.font": typed[i++],
  "350773-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350773-jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "350773-jersey.upperBack.content": typed[i++],
  "350773-jersey.upperBack.fillColor1": typed[i++],
  "350773-jersey.upperBack.font": typed[i++],
  "350773-jersey.upperBack.outlineColor1": typed[i++],
  "350773-jersey.middleBack.content": typed[i++],
  "350773-jersey.middleBack.fillColor1": typed[i++],
  "350773-jersey.middleBack.font": typed[i++],
  "350773-jersey.middleBack.outlineColor1": typed[i++],
  "350773-jersey.middleBack.outlineColor2": typed[i++],
  "350773-details.teamName.text": typed[i++],
  "350773-details.roster.1.quantity": typed[i++],
  "350773-details.roster.1.jerseySize": typed[i++],
  "350773-details.roster.1.name": typed[i++],
  "350773-details.roster.1.number": typed[i++],
  "350775-jersey.fabric": typed[i++],
  "350775-jersey.design.design": typed[i++],
  "350775-jersey.design.color1": typed[i++],
  "350775-jersey.design.color2": typed[i++],
  "350775-jersey.body.fill": typed[i++],
  "350775-jersey.body.color1": typed[i++],
  "350775-jersey.body.color2": typed[i++],
  "350775-jersey.body.color3": typed[i++],
  "350775-jersey.body.color4": typed[i++],
  "350775-jersey.sleeve.fill": typed[i++],
  "350775-jersey.sleeve.color1": typed[i++],
  "350775-jersey.sleeve.color2": typed[i++],
  "350775-jersey.sleeve.color3": typed[i++],
  "350775-jersey.sleeve.color4": typed[i++],
  "350775-jersey.collar.color1": typed[i++],
  "350775-jersey.runbird.innerColor": typed[i++],
  "350775-jersey.runbird.outerColor": typed[i++],
  "350775-jersey.frontChest.content": typed[i++],
  "350775-jersey.frontChest.fillColor1": typed[i++],
  "350775-jersey.frontChest.font": typed[i++],
  "350775-jersey.frontChest.outlineColor1": typed[i++],
  "350775-jersey.frontChest.outlineColor2": typed[i++],
  "350775-jersey.frontMiddleLeft.content": typed[i++],
  "350775-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350775-jersey.frontMiddleLeft.font": typed[i++],
  "350775-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350775-jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "350775-jersey.middleBack.content": typed[i++],
  "350775-jersey.middleBack.fillColor1": typed[i++],
  "350775-jersey.middleBack.font": typed[i++],
  "350775-jersey.middleBack.outlineColor1": typed[i++],
  "350775-jersey.middleBack.outlineColor2": typed[i++],
  "350775-details.teamName.text": typed[i++],
  "350775-details.roster.1.quantity": typed[i++],
  "350775-details.roster.1.jerseySize": typed[i++],
  "350775-details.roster.1.number": typed[i++],
  "350777-jersey.fabric": typed[i++],
  "350777-jersey.body.color1": typed[i++],
  "350777-jersey.sleeve.color1": typed[i++],
  "350777-jersey.collar.color1": typed[i++],
  "350777-jersey.runbird.innerColor": typed[i++],
  "350777-jersey.runbird.outerColor": typed[i++],
  "350777-jersey.braid.position": typed[i++],
  "350777-jersey.braid.color1": typed[i++],
  "350777-jersey.braid.color2": typed[i++],
  "350777-jersey.braid.color3": typed[i++],
  "350777-jersey.frontChest.content": typed[i++],
  "350777-jersey.frontChest.fill": typed[i++],
  "350777-jersey.frontChest.fillColor1": typed[i++],
  "350777-jersey.frontChest.fillColor2": typed[i++],
  "350777-jersey.frontChest.font": typed[i++],
  "350777-jersey.frontChest.outlineColor1": typed[i++],
  "350777-jersey.frontMiddleLeft.content": typed[i++],
  "350777-jersey.frontMiddleLeft.fill": typed[i++],
  "350777-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350777-jersey.frontMiddleLeft.fillColor2": typed[i++],
  "350777-jersey.frontMiddleLeft.font": typed[i++],
  "350777-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350777-jersey.upperBack.content": typed[i++],
  "350777-jersey.upperBack.fillColor1": typed[i++],
  "350777-jersey.upperBack.font": typed[i++],
  "350777-jersey.upperBack.outlineColor1": typed[i++],
  "350777-jersey.middleBack.content": typed[i++],
  "350777-jersey.middleBack.fill": typed[i++],
  "350777-jersey.middleBack.fillColor1": typed[i++],
  "350777-jersey.middleBack.fillColor2": typed[i++],
  "350777-jersey.middleBack.font": typed[i++],
  "350777-jersey.middleBack.outlineColor1": typed[i++],
  "350777-details.teamName.text": typed[i++],
  "350777-details.roster.1.quantity": typed[i++],
  "350777-details.roster.1.jerseySize": typed[i++],
  "350777-details.roster.1.name": typed[i++],
  "350777-details.roster.1.number": typed[i++],
  "350785-jersey.fabric": typed[i++],
  "350785-jersey.body.fill": typed[i++],
  "350785-jersey.body.color1": typed[i++],
  "350785-jersey.body.color2": typed[i++],
  "350785-jersey.sleeve.color1": typed[i++],
  "350785-jersey.collar.color1": typed[i++],
  "350785-jersey.runbird.innerColor": typed[i++],
  "350785-jersey.runbird.outerColor": typed[i++],
  "350785-jersey.frontChest.content": typed[i++],
  "350785-jersey.frontChest.fillColor1": typed[i++],
  "350785-jersey.frontChest.font": typed[i++],
  "350785-jersey.frontChest.outlineColor1": typed[i++],
  "350785-jersey.frontMiddleLeft.content": typed[i++],
  "350785-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350785-jersey.frontMiddleLeft.font": typed[i++],
  "350785-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350785-jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "350785-jersey.upperBack.content": typed[i++],
  "350785-jersey.upperBack.fillColor1": typed[i++],
  "350785-jersey.upperBack.font": typed[i++],
  "350785-jersey.upperBack.outlineColor1": typed[i++],
  "350785-jersey.middleBack.content": typed[i++],
  "350785-jersey.middleBack.fillColor1": typed[i++],
  "350785-jersey.middleBack.font": typed[i++],
  "350785-jersey.middleBack.outlineColor1": typed[i++],
  "350785-details.teamName.text": typed[i++],
  "350785-details.roster.1.quantity": typed[i++],
  "350785-details.roster.1.jerseySize": typed[i++],
  "350785-details.roster.1.name": typed[i++],
  "350785-details.roster.1.number": typed[i++],
  "350786-jersey.fabric": typed[i++],
  "350786-jersey.design.design": typed[i++],
  "350786-jersey.design.color1": typed[i++],
  "350786-jersey.design.color2": typed[i++],
  "350786-jersey.body.color1": typed[i++],
  "350786-jersey.sleeve.color1": typed[i++],
  "350786-jersey.collar.color1": typed[i++],
  "350786-jersey.runbird.innerColor": typed[i++],
  "350786-jersey.runbird.outerColor": typed[i++],
  "350786-jersey.frontChest.content": typed[i++],
  "350786-jersey.frontChest.fillColor1": typed[i++],
  "350786-jersey.frontChest.font": typed[i++],
  "350786-jersey.frontChest.outlineColor1": typed[i++],
  "350786-jersey.frontMiddleLeft.content": typed[i++],
  "350786-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350786-jersey.frontMiddleLeft.font": typed[i++],
  "350786-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350786-jersey.middleBack.content": typed[i++],
  "350786-jersey.middleBack.fillColor1": typed[i++],
  "350786-jersey.middleBack.font": typed[i++],
  "350786-jersey.middleBack.outlineColor1": typed[i++],
  "350786-jersey.middleBack.outlineColor2": typed[i++],
  "350786-details.teamName.text": typed[i++],
  "350786-details.roster.1.quantity": typed[i++],
  "350786-details.roster.1.jerseySize": typed[i++],
  "350786-details.roster.1.number": typed[i++],
  "350787-jersey.fabric": typed[i++],
  "350787-jersey.design.design": typed[i++],
  "350787-jersey.design.color1": typed[i++],
  "350787-jersey.design.color2": typed[i++],
  "350787-jersey.body.fill": typed[i++],
  "350787-jersey.body.color1": typed[i++],
  "350787-jersey.body.color2": typed[i++],
  "350787-jersey.body.color3": typed[i++],
  "350787-jersey.body.color4": typed[i++],
  "350787-jersey.sleeve.color1": typed[i++],
  "350787-jersey.collar.fill": typed[i++],
  "350787-jersey.collar.color1": typed[i++],
  "350787-jersey.collar.color2": typed[i++],
  "350787-jersey.collar.color3": typed[i++],
  "350787-jersey.collar.color4": typed[i++],
  "350787-jersey.runbird.innerColor": typed[i++],
  "350787-jersey.runbird.outerColor": typed[i++],
  "350787-jersey.frontChest.content": typed[i++],
  "350787-jersey.frontChest.fillColor1": typed[i++],
  "350787-jersey.frontChest.font": typed[i++],
  "350787-jersey.frontChest.outlineColor1": typed[i++],
  "350787-jersey.frontChest.outlineColor2": typed[i++],
  "350787-jersey.frontMiddleRight.content": typed[i++],
  "350787-jersey.frontMiddleRight.fillColor1": typed[i++],
  "350787-jersey.frontMiddleRight.font": typed[i++],
  "350787-jersey.frontMiddleRight.outlineColor1": typed[i++],
  "350787-jersey.frontMiddleRight.outlineColor2": typed[i++],
  "350787-jersey.upperBack.content": typed[i++],
  "350787-jersey.upperBack.fillColor1": typed[i++],
  "350787-jersey.upperBack.font": typed[i++],
  "350787-jersey.upperBack.outlineColor1": typed[i++],
  "350787-jersey.middleBack.content": typed[i++],
  "350787-jersey.middleBack.fillColor1": typed[i++],
  "350787-jersey.middleBack.font": typed[i++],
  "350787-jersey.middleBack.outlineColor1": typed[i++],
  "350787-jersey.middleBack.outlineColor2": typed[i++],
  "350787-details.teamName.text": typed[i++],
  "350787-details.roster.1.quantity": typed[i++],
  "350787-details.roster.1.jerseySize": typed[i++],
  "350787-details.roster.1.name": typed[i++],
  "350787-details.roster.1.number": typed[i++],
  "350788-jersey.fabric": typed[i++],
  "350788-jersey.design.design": typed[i++],
  "350788-jersey.design.color1": typed[i++],
  "350788-jersey.design.color2": typed[i++],
  "350788-jersey.body.color1": typed[i++],
  "350788-jersey.sleeve.color1": typed[i++],
  "350788-jersey.collar.color1": typed[i++],
  "350788-jersey.runbird.innerColor": typed[i++],
  "350788-jersey.runbird.outerColor": typed[i++],
  "350788-jersey.frontChest.content": typed[i++],
  "350788-jersey.frontChest.fillColor1": typed[i++],
  "350788-jersey.frontChest.font": typed[i++],
  "350788-jersey.frontChest.outlineColor1": typed[i++],
  "350788-jersey.frontMiddleLeft.content": typed[i++],
  "350788-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350788-jersey.frontMiddleLeft.font": typed[i++],
  "350788-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350788-jersey.middleBack.content": typed[i++],
  "350788-jersey.middleBack.fillColor1": typed[i++],
  "350788-jersey.middleBack.font": typed[i++],
  "350788-jersey.middleBack.outlineColor1": typed[i++],
  "350788-details.teamName.text": typed[i++],
  "350788-details.roster.1.quantity": typed[i++],
  "350788-details.roster.1.jerseySize": typed[i++],
  "350788-details.roster.1.number": typed[i++],
  "350789-jersey.fabric": typed[i++],
  "350789-jersey.design.design": typed[i++],
  "350789-jersey.design.color1": typed[i++],
  "350789-jersey.design.color2": typed[i++],
  "350789-jersey.body.color1": typed[i++],
  "350789-jersey.sleeve.color1": typed[i++],
  "350789-jersey.collar.color1": typed[i++],
  "350789-jersey.runbird.innerColor": typed[i++],
  "350789-jersey.runbird.outerColor": typed[i++],
  "350789-jersey.frontChest.content": typed[i++],
  "350789-jersey.frontChest.fillColor1": typed[i++],
  "350789-jersey.frontChest.font": typed[i++],
  "350789-jersey.frontChest.outlineColor1": typed[i++],
  "350789-jersey.frontChest.outlineColor2": typed[i++],
  "350789-jersey.middleBack.content": typed[i++],
  "350789-jersey.middleBack.fillColor1": typed[i++],
  "350789-jersey.middleBack.font": typed[i++],
  "350789-jersey.middleBack.outlineColor1": typed[i++],
  "350789-jersey.middleBack.outlineColor2": typed[i++],
  "350789-details.teamName.text": typed[i++],
  "350789-details.roster.1.quantity": typed[i++],
  "350789-details.roster.1.jerseySize": typed[i++],
  "350789-details.roster.1.number": typed[i++],
  "350790-jersey.fabric": typed[i++],
  "350790-jersey.design.design": typed[i++],
  "350790-jersey.design.color1": typed[i++],
  "350790-jersey.design.color2": typed[i++],
  "350790-jersey.body.color1": typed[i++],
  "350790-jersey.sleeve.color1": typed[i++],
  "350790-jersey.collar.color1": typed[i++],
  "350790-jersey.runbird.innerColor": typed[i++],
  "350790-jersey.runbird.outerColor": typed[i++],
  "350790-jersey.frontChest.content": typed[i++],
  "350790-jersey.frontChest.fill": typed[i++],
  "350790-jersey.frontChest.fillColor1": typed[i++],
  "350790-jersey.frontChest.fillColor2": typed[i++],
  "350790-jersey.frontChest.font": typed[i++],
  "350790-jersey.frontChest.outlineColor1": typed[i++],
  "350790-jersey.frontMiddleLeft.content": typed[i++],
  "350790-jersey.frontMiddleLeft.fill": typed[i++],
  "350790-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350790-jersey.frontMiddleLeft.fillColor2": typed[i++],
  "350790-jersey.frontMiddleLeft.font": typed[i++],
  "350790-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350790-jersey.upperBack.content": typed[i++],
  "350790-jersey.upperBack.fillColor1": typed[i++],
  "350790-jersey.upperBack.font": typed[i++],
  "350790-jersey.upperBack.outlineColor1": typed[i++],
  "350790-jersey.middleBack.content": typed[i++],
  "350790-jersey.middleBack.fill": typed[i++],
  "350790-jersey.middleBack.fillColor1": typed[i++],
  "350790-jersey.middleBack.fillColor2": typed[i++],
  "350790-jersey.middleBack.font": typed[i++],
  "350790-jersey.middleBack.outlineColor1": typed[i++],
  "350790-details.teamName.text": typed[i++],
  "350790-details.roster.1.quantity": typed[i++],
  "350790-details.roster.1.jerseySize": typed[i++],
  "350790-details.roster.1.name": typed[i++],
  "350790-details.roster.1.number": typed[i++],
  "350797-jersey.fabric": typed[i++],
  "350797-jersey.body.fill": typed[i++],
  "350797-jersey.body.color1": typed[i++],
  "350797-jersey.body.color2": typed[i++],
  "350797-jersey.body.color3": typed[i++],
  "350797-jersey.sleeve.fill": typed[i++],
  "350797-jersey.sleeve.color1": typed[i++],
  "350797-jersey.sleeve.color2": typed[i++],
  "350797-jersey.sleeve.color3": typed[i++],
  "350797-jersey.collar.fill": typed[i++],
  "350797-jersey.collar.color1": typed[i++],
  "350797-jersey.collar.color2": typed[i++],
  "350797-jersey.collar.color3": typed[i++],
  "350797-jersey.runbird.innerColor": typed[i++],
  "350797-jersey.runbird.outerColor": typed[i++],
  "350797-jersey.frontChest.content": typed[i++],
  "350797-jersey.frontChest.fillColor1": typed[i++],
  "350797-jersey.frontChest.font": typed[i++],
  "350797-jersey.frontChest.outlineColor1": typed[i++],
  "350797-jersey.frontChest.outlineColor2": typed[i++],
  "350797-jersey.frontMiddleLeft.content": typed[i++],
  "350797-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350797-jersey.frontMiddleLeft.font": typed[i++],
  "350797-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350797-jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "350797-jersey.upperBack.content": typed[i++],
  "350797-jersey.upperBack.fillColor1": typed[i++],
  "350797-jersey.upperBack.font": typed[i++],
  "350797-jersey.upperBack.outlineColor1": typed[i++],
  "350797-jersey.middleBack.content": typed[i++],
  "350797-jersey.middleBack.fillColor1": typed[i++],
  "350797-jersey.middleBack.font": typed[i++],
  "350797-jersey.middleBack.outlineColor1": typed[i++],
  "350797-jersey.middleBack.outlineColor2": typed[i++],
  "350797-details.teamName.text": typed[i++],
  "350797-details.roster.1.quantity": typed[i++],
  "350797-details.roster.1.jerseySize": typed[i++],
  "350797-details.roster.1.name": typed[i++],
  "350797-details.roster.1.number": typed[i++],
  "350798-jersey.fabric": typed[i++],
  "350798-jersey.design.design": typed[i++],
  "350798-jersey.design.color1": typed[i++],
  "350798-jersey.design.color2": typed[i++],
  "350798-jersey.design.color3": typed[i++],
  "350798-jersey.body.fill": typed[i++],
  "350798-jersey.body.color1": typed[i++],
  "350798-jersey.body.color2": typed[i++],
  "350798-jersey.sleeve.color1": typed[i++],
  "350798-jersey.collar.color1": typed[i++],
  "350798-jersey.runbird.innerColor": typed[i++],
  "350798-jersey.runbird.outerColor": typed[i++],
  "350798-jersey.frontChest.content": typed[i++],
  "350798-jersey.frontChest.fillColor1": typed[i++],
  "350798-jersey.frontChest.font": typed[i++],
  "350798-jersey.frontChest.outlineColor1": typed[i++],
  "350798-jersey.frontChest.outlineColor2": typed[i++],
  "350798-jersey.frontMiddleLeft.content": typed[i++],
  "350798-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350798-jersey.frontMiddleLeft.font": typed[i++],
  "350798-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350798-jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "350798-jersey.upperBack.content": typed[i++],
  "350798-jersey.upperBack.fillColor1": typed[i++],
  "350798-jersey.upperBack.font": typed[i++],
  "350798-jersey.upperBack.outlineColor1": typed[i++],
  "350798-jersey.middleBack.content": typed[i++],
  "350798-jersey.middleBack.fillColor1": typed[i++],
  "350798-jersey.middleBack.font": typed[i++],
  "350798-jersey.middleBack.outlineColor1": typed[i++],
  "350798-jersey.middleBack.outlineColor2": typed[i++],
  "350798-details.teamName.text": typed[i++],
  "350798-details.roster.1.quantity": typed[i++],
  "350798-details.roster.1.jerseySize": typed[i++],
  "350798-details.roster.1.name": typed[i++],
  "350798-details.roster.1.number": typed[i++],
  "350799-jersey.fabric": typed[i++],
  "350799-jersey.design.design": typed[i++],
  "350799-jersey.design.color1": typed[i++],
  "350799-jersey.design.color2": typed[i++],
  "350799-jersey.body.fill": typed[i++],
  "350799-jersey.body.color1": typed[i++],
  "350799-jersey.body.color2": typed[i++],
  "350799-jersey.sleeve.fill": typed[i++],
  "350799-jersey.sleeve.color1": typed[i++],
  "350799-jersey.sleeve.color2": typed[i++],
  "350799-jersey.collar.color1": typed[i++],
  "350799-jersey.runbird.innerColor": typed[i++],
  "350799-jersey.runbird.outerColor": typed[i++],
  "350799-jersey.frontChest.content": typed[i++],
  "350799-jersey.frontChest.fillColor1": typed[i++],
  "350799-jersey.frontChest.font": typed[i++],
  "350799-jersey.frontChest.outlineColor1": typed[i++],
  "350799-jersey.frontMiddleLeft.content": typed[i++],
  "350799-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350799-jersey.frontMiddleLeft.font": typed[i++],
  "350799-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350799-jersey.middleBack.content": typed[i++],
  "350799-jersey.middleBack.fillColor1": typed[i++],
  "350799-jersey.middleBack.font": typed[i++],
  "350799-jersey.middleBack.outlineColor1": typed[i++],
  "350799-details.teamName.text": typed[i++],
  "350799-details.roster.1.quantity": typed[i++],
  "350799-details.roster.1.jerseySize": typed[i++],
  "350799-details.roster.1.number": typed[i++],
  "350800-jersey.fabric": typed[i++],
  "350800-jersey.design.design": typed[i++],
  "350800-jersey.design.color1": typed[i++],
  "350800-jersey.design.color2": typed[i++],
  "350800-jersey.body.color1": typed[i++],
  "350800-jersey.sleeve.color1": typed[i++],
  "350800-jersey.collar.color1": typed[i++],
  "350800-jersey.runbird.innerColor": typed[i++],
  "350800-jersey.runbird.outerColor": typed[i++],
  "350800-jersey.frontChest.content": typed[i++],
  "350800-jersey.frontChest.fillColor1": typed[i++],
  "350800-jersey.frontChest.font": typed[i++],
  "350800-jersey.frontChest.outlineColor1": typed[i++],
  "350800-jersey.frontMiddleLeft.content": typed[i++],
  "350800-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350800-jersey.frontMiddleLeft.font": typed[i++],
  "350800-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350800-jersey.middleBack.content": typed[i++],
  "350800-jersey.middleBack.fillColor1": typed[i++],
  "350800-jersey.middleBack.font": typed[i++],
  "350800-jersey.middleBack.outlineColor1": typed[i++],
  "350800-details.teamName.text": typed[i++],
  "350800-details.roster.1.quantity": typed[i++],
  "350800-details.roster.1.jerseySize": typed[i++],
  "350800-details.roster.1.number": typed[i++],
  "350801-jersey.fabric": typed[i++],
  "350801-jersey.design.design": typed[i++],
  "350801-jersey.design.color1": typed[i++],
  "350801-jersey.design.color2": typed[i++],
  "350801-jersey.body.fill": typed[i++],
  "350801-jersey.body.color1": typed[i++],
  "350801-jersey.body.color2": typed[i++],
  "350801-jersey.body.color3": typed[i++],
  "350801-jersey.body.color4": typed[i++],
  "350801-jersey.sleeve.color1": typed[i++],
  "350801-jersey.collar.fill": typed[i++],
  "350801-jersey.collar.color1": typed[i++],
  "350801-jersey.collar.color2": typed[i++],
  "350801-jersey.collar.color3": typed[i++],
  "350801-jersey.collar.color4": typed[i++],
  "350801-jersey.runbird.innerColor": typed[i++],
  "350801-jersey.runbird.outerColor": typed[i++],
  "350801-jersey.frontChest.content": typed[i++],
  "350801-jersey.frontChest.fillColor1": typed[i++],
  "350801-jersey.frontChest.font": typed[i++],
  "350801-jersey.frontChest.outlineColor1": typed[i++],
  "350801-jersey.frontMiddleLeft.content": typed[i++],
  "350801-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350801-jersey.frontMiddleLeft.font": typed[i++],
  "350801-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350801-jersey.upperBack.content": typed[i++],
  "350801-jersey.upperBack.fillColor1": typed[i++],
  "350801-jersey.upperBack.font": typed[i++],
  "350801-jersey.upperBack.outlineColor1": typed[i++],
  "350801-jersey.middleBack.content": typed[i++],
  "350801-jersey.middleBack.fillColor1": typed[i++],
  "350801-jersey.middleBack.font": typed[i++],
  "350801-jersey.middleBack.outlineColor1": typed[i++],
  "350801-details.teamName.text": typed[i++],
  "350801-details.roster.1.quantity": typed[i++],
  "350801-details.roster.1.jerseySize": typed[i++],
  "350801-details.roster.1.name": typed[i++],
  "350801-details.roster.1.number": typed[i++],
  "350802-jersey.fabric": typed[i++],
  "350802-jersey.body.fill": typed[i++],
  "350802-jersey.body.color1": typed[i++],
  "350802-jersey.body.color2": typed[i++],
  "350802-jersey.sleeve.color1": typed[i++],
  "350802-jersey.collar.color1": typed[i++],
  "350802-jersey.runbird.innerColor": typed[i++],
  "350802-jersey.runbird.outerColor": typed[i++],
  "350802-jersey.frontChest.content": typed[i++],
  "350802-jersey.frontChest.fill": typed[i++],
  "350802-jersey.frontChest.fillColor1": typed[i++],
  "350802-jersey.frontChest.fillColor2": typed[i++],
  "350802-jersey.frontChest.fillColor3": typed[i++],
  "350802-jersey.frontChest.font": typed[i++],
  "350802-jersey.frontChest.outlineColor1": typed[i++],
  "350802-jersey.frontMiddleLeft.content": typed[i++],
  "350802-jersey.frontMiddleLeft.fill": typed[i++],
  "350802-jersey.frontMiddleLeft.fillColor1": typed[i++],
  "350802-jersey.frontMiddleLeft.fillColor2": typed[i++],
  "350802-jersey.frontMiddleLeft.fillColor3": typed[i++],
  "350802-jersey.frontMiddleLeft.font": typed[i++],
  "350802-jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "350802-jersey.upperBack.content": typed[i++],
  "350802-jersey.upperBack.fill": typed[i++],
  "350802-jersey.upperBack.fillColor1": typed[i++],
  "350802-jersey.upperBack.fillColor2": typed[i++],
  "350802-jersey.upperBack.fillColor3": typed[i++],
  "350802-jersey.upperBack.font": typed[i++],
  "350802-jersey.upperBack.outlineColor1": typed[i++],
  "350802-jersey.middleBack.content": typed[i++],
  "350802-jersey.middleBack.fill": typed[i++],
  "350802-jersey.middleBack.fillColor1": typed[i++],
  "350802-jersey.middleBack.fillColor2": typed[i++],
  "350802-jersey.middleBack.fillColor3": typed[i++],
  "350802-jersey.middleBack.font": typed[i++],
  "350802-jersey.middleBack.outlineColor1": typed[i++],
  "350802-details.teamName.text": typed[i++],
  "350802-details.roster.1.quantity": typed[i++],
  "350802-details.roster.1.jerseySize": typed[i++],
  "350802-details.roster.1.name": typed[i++],
  "350802-details.roster.1.number": typed[i++],
  "350843-pants.body.fill": typed[i++],
  "350843-pants.body.color1": typed[i++],
  "350843-pants.body.color2": typed[i++],
  "350843-pants.beltLoop.fill": typed[i++],
  "350843-pants.beltLoop.color1": typed[i++],
  "350843-pants.beltLoop.color2": typed[i++],
  "350843-pants.runbird.innerColor": typed[i++],
  "350843-pants.runbird.outerColor": typed[i++],
  "350843-pants.backBeltLoop.content": typed[i++],
  "350843-pants.backBeltLoop.fillColor1": typed[i++],
  "350843-pants.backBeltLoop.font": typed[i++],
  "350843-pants.backBeltLoop.outlineColor1": typed[i++],
  "350843-pants.leftLeg.content": typed[i++],
  "350843-pants.leftLeg.fillColor1": typed[i++],
  "350843-pants.leftLeg.font": typed[i++],
  "350843-pants.leftLeg.outlineColor1": typed[i++],
  "350843-details.teamName.text": typed[i++],
  "350843-details.roster.1.quantity": typed[i++],
  "350843-details.roster.1.pantsSize": typed[i++],
  "350843-details.roster.1.number": typed[i++],
  "350845-pants.design.design": typed[i++],
  "350845-pants.design.color1": typed[i++],
  "350845-pants.design.color2": typed[i++],
  "350845-pants.body.color1": typed[i++],
  "350845-pants.beltLoop.color1": typed[i++],
  "350845-pants.runbird.innerColor": typed[i++],
  "350845-pants.runbird.outerColor": typed[i++],
  "350847-pants.design.design": typed[i++],
  "350847-pants.design.color1": typed[i++],
  "350847-pants.design.color2": typed[i++],
  "350847-pants.body.color1": typed[i++],
  "350847-pants.beltLoop.color1": typed[i++],
  "350847-pants.runbird.innerColor": typed[i++],
  "350847-pants.runbird.outerColor": typed[i++],
  "351079-pants.design.design": typed[i++],
  "351079-pants.body.color1": typed[i++],
  "351079-pants.beltLoop.color1": typed[i++],
  "351079-pants.runbird.innerColor": typed[i++],
  "351079-pants.runbird.outerColor": typed[i++],
  "351079-pants.rightLeg.content": typed[i++],
  "351079-pants.rightLeg.fillColor1": typed[i++],
  "351079-pants.rightLeg.font": typed[i++],
  "351079-pants.rightLeg.outlineColor1": typed[i++],
  "351079-details.roster.1.quantity": typed[i++],
  "351079-details.roster.1.pantsSize": typed[i++],
  "351079-details.roster.1.number": typed[i++],
  "351079-pants.braid.position": typed[i++],
  "351079-pants.braid.color1": typed[i++],
  "351079-pants.braid.color2": typed[i++],
  "351079-pants.braid.color3": typed[i++]
} as const;

export { typed as PRODUCT_ICONS };
