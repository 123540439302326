import { SvgElement, TextElement, TextLayout } from '@orangelv/svg-renderer'
import { in2px } from '@orangelv/utils-geometry'

import {
  COLOR_DICT,
  FILL_DICT,
  FONT_DICT,
  Placement as PlacementType,
  Product,
  SizeId,
  SIZE_DICT,
  TAIL_DICT,
  PLACEMENTS,
  DecorationId,
  PlacementId,
  FillId,
  ColorId,
  FontId,
} from '../common/sheets'
import { Recipe } from '../common/typings'
import { getAnchorAndBoundingBox } from './anchors'
import { getDrawableFontChars, getFontFilename, getLogoPath } from './utils'
import getFill from './getFill'
import { Immutable } from '@orangelv/utils'

// Dynamic things come first so that Mizuno logo and size tag are last which
// makes them always on top.
const PLACEMENTS_SORTED = PLACEMENTS.toSorted((a) => -a.isDynamic)

export const lookUpFill = (id: FillId | null | undefined) =>
  id ? FILL_DICT[id] : undefined

export const lookUpColor = (id: ColorId | null | undefined) =>
  id ? COLOR_DICT[id] : undefined

export const lookUpFont = (id: FontId | null | undefined) =>
  id ? FONT_DICT[id] : undefined

const getElementsForPlacement = (
  recipe: Recipe,
  product: Product,
  sizeId: SizeId,
  placement: PlacementType,
  pieceName: string,
) => {
  const basePath = `${product.props.type}.${placement.id}` as const

  const content = recipe[`${basePath}.content`]

  if (placement.isDynamic && !content) {
    return {}
  }

  const outlineColor1 = lookUpColor(recipe[`${basePath}.outlineColor1`])
  const outlineColor2 = lookUpColor(recipe[`${basePath}.outlineColor2`])
  const textLayout = recipe[`${basePath}.layout`]
  const layout =
    textLayout === 'straight' ? undefined : (textLayout as TextLayout)
  const tail = recipe[`${basePath}.tail`]
  const tailText = recipe[`${basePath}.tailText`]
  const tailTextFont = recipe[`${basePath}.tailTextFont`]
  const tailTextColor = recipe[`${basePath}.tailTextColor`]
  const font = lookUpFont(recipe[`${basePath}.font`])
  const file = recipe[`${basePath}.file`]
  const position = recipe[`${basePath}.position`] || undefined
  const braidPosition = recipe[`${product.props.type}.braid.position`] || []

  const anchorAndBoundingBox = getAnchorAndBoundingBox(
    product.id,
    content,
    placement.id,
    pieceName,
    sizeId,
    position,
  )

  if (!anchorAndBoundingBox) {
    return undefined
  }

  const { anchor, boundingBox } = anchorAndBoundingBox

  const isScriptFont = font?.isScript
  const textOutlineColor1 = outlineColor1?.props.hex
  const textOutlineColor2 = outlineColor2?.props.hex
  const textFont = font && getFontFilename(font.id)
  const logoPath = file && getLogoPath(file)

  let imageElement: SvgElement | undefined
  let textElement: TextElement | undefined

  const size = SIZE_DICT[sizeId]

  let textSize = 2.5
  const textFill = getFill(
    recipe[`${basePath}.fill`],
    recipe[`${basePath}.fillColor1`],
    recipe[`${basePath}.fillColor2`],
    recipe[`${basePath}.fillColor3`],
    recipe[`${basePath}.fillColor4`],
  )

  if (content === 'teamName') {
    if (!textFill) return {}
    let letterSpacingRatio = 0.09
    if (placement.id === 'frontChest') {
      textSize = 3.5
    } else if (placement.id === 'lowerBack') {
      textSize = size.isYouth ? 1.75 : 2
      letterSpacingRatio = 0.15
    } else if (placement.id === 'backBeltLoop') {
      textSize = 1.5
      letterSpacingRatio = 0.2
    }

    textElement = {
      size: in2px(textSize),
      anchor,
      boundingBox,
      scaleFactor: recipe[`${basePath}.scaleFactor`],
      text: `"${recipe['details.teamName.text'].replaceAll('"', '\\"')}"`,
      color: textFill,
      outlineColor1: textOutlineColor1,
      outlineColor2: textOutlineColor2,
      layout,
      tail:
        tail ?
          {
            name: `tails/${tail}`,
            isConnected: TAIL_DICT[tail].isConnected,
            ...(tailText ?
              {
                text: tailText,
                textFont: getFontFilename(FONT_DICT[tailTextFont].id),
                textColor: COLOR_DICT[tailTextColor].props.hex,
              }
            : undefined),
          }
        : undefined,
      font: textFont ?? '',
      letterSpacing:
        !isScriptFont ?
          letterSpacingRatio *
          (outlineColor2 ? 2
          : outlineColor1 ? 1
          : 0)
        : undefined,
      boxSizing: 'border-box',
    }
  } else if (content === 'playerName') {
    if (!textFill) return {}
    let letterSpacingRatio = 0.09
    if (placement.id === 'upperBack') {
      textSize = size.isYouth ? 1.75 : 2
      letterSpacingRatio = 0.15
    }

    textElement = {
      size: in2px(textSize),
      anchor,
      boundingBox,
      scaleFactor: recipe[`${basePath}.scaleFactor`],
      text: 'playerName',
      color: textFill,
      layout,
      outlineColor1: textOutlineColor1,
      outlineColor2: textOutlineColor2,
      font: textFont ?? '',
      letterSpacing:
        !isScriptFont ?
          letterSpacingRatio *
          (outlineColor2 ? 2
          : outlineColor1 ? 1
          : 0)
        : undefined,
      boxSizing: 'border-box',
    }
  } else if (content === 'playerNumber') {
    if (!textFill) return {}
    let letterSpacingRatio = 0.06
    if (
      placement.id === 'frontMiddleLeft' ||
      placement.id === 'frontMiddleRight'
    ) {
      textSize = size.isYouth ? 3.25 : 4
      letterSpacingRatio = 0.045
    } else if (placement.id === 'frontMiddle') {
      textSize = 4
      letterSpacingRatio = 0.045
    } else if (placement.id === 'middleBack') {
      textSize = size.isYouth ? 6 : 8
      letterSpacingRatio = 0.015
    } else if (placement.id === 'backBeltLoop') {
      textSize = 1.5
      letterSpacingRatio = 0.15
    } else if (placement.id === 'leftLeg' || placement.id === 'rightLeg') {
      letterSpacingRatio = 0.08
    }

    // Player numbers should always be positioned based on how tall they are.
    // Do not trust the anchors sheet for this because it might be wrong because of
    // human error and _we don't want to change this by accident_.
    const boundingBoxForPlayerNumber = {
      height: `in2px(${textSize})`,
      // Width is a much larger number that height so that the text is never squished on Y axis.
      width: `in2px(100)`,
    }

    textElement = {
      size: in2px(textSize),
      anchor,
      boundingBox: boundingBoxForPlayerNumber,
      scaleFactor: recipe[`${basePath}.scaleFactor`],
      text: 'playerNumber',
      layout,
      color: textFill,
      outlineColor1: textOutlineColor1,
      outlineColor2: textOutlineColor2,
      font: textFont ?? '',
      letterSpacing:
        !isScriptFont ?
          letterSpacingRatio *
          (outlineColor2 ? 2
          : outlineColor1 ? 1
          : 0)
        : undefined,
      boxSizing: 'border-box',
    }
  } else if (content === 'customLogo' && logoPath) {
    let scaleFactor = recipe[`${basePath}.scaleFactor`]

    imageElement = {
      anchor,
      boundingBox,
      scaleFactor,
      svg: {
        name: `"${logoPath.replaceAll('"', '\\"')}"`,
        colors: {},
      },
    }
  } else if (placement.id === 'sizeTag') {
    imageElement = {
      anchor,
      boundingBox,
      svg: {
        name: '"tags-sizes/" || size',
        colors: {},
      },
    }
  } else if (placement.id === 'jerseyRunbird') {
    imageElement = {
      anchor,
      boundingBox,
      svg: {
        name: '"tags-logos/" || ageGroup',
        colors: {
          '#logo-inner':
            COLOR_DICT[recipe['jersey.runbird.innerColor']].props.hex,
          '#logo-outer':
            COLOR_DICT[recipe['jersey.runbird.outerColor']].props.hex,
        },
      },
    }
  } else if (placement.id === 'pantsRunbird') {
    imageElement = {
      anchor,
      boundingBox,
      svg: {
        name: '"tags-logos/" || ageGroup',
        colors: {
          '#logo-inner':
            COLOR_DICT[recipe['pants.runbird.innerColor']].props.hex,
          '#logo-outer':
            COLOR_DICT[recipe['pants.runbird.outerColor']].props.hex,
        },
      },
    }
  }

  if (textElement) {
    let text = textElement.text

    if (content === 'teamName') {
      // Do this only for team name for now because other options are string replaced via svg-renderer.
      text = getDrawableFontChars(font!.id, text)
    }

    textElement.text = text
  }

  const imageOrTextElement = (imageElement || textElement) as
    | SvgElement
    | TextElement
    | undefined

  if (
    imageOrTextElement &&
    braidPosition.includes('neck') &&
    pieceName === 'back' &&
    product.props.style === 'twoButton'
  ) {
    // Move everything on back piece down a bit when neck/collar braid is enabled.
    imageOrTextElement.anchor.offset.y += ' + in2px(1)'
  }

  if (
    imageOrTextElement &&
    (content === 'teamName' ||
      content === 'playerNumber' ||
      content === 'customLogo') &&
    braidPosition.includes('neck') &&
    pieceName === 'front' &&
    product.props.style === 'twoButton'
  ) {
    imageOrTextElement.anchor.offset.y += ' + in2px(0.5)'
  }

  if (
    imageOrTextElement &&
    content === 'customLogo' &&
    braidPosition.includes('neck') &&
    pieceName === 'frontLeft' &&
    product.props.style === 'fullButton'
  ) {
    imageOrTextElement.anchor.offset.x += ' + in2px(0.5)'
  }

  return { imageElement, textElement, content }
}

export type Placement = {
  content: DecorationId
  placementId: PlacementId
} & (
  | { type: 'image'; element: SvgElement }
  | { type: 'text'; element: TextElement }
)

const getPlacements = (
  recipe: Recipe,
  product: Product,
  sizeId: SizeId,
  pieceName: string,
): Placement[] =>
  PLACEMENTS_SORTED.flatMap((placement) => {
    if (
      !placement.isAvailable.productType[product.props.type] ||
      !(placement.pieceNames as Immutable<string[]>).includes(pieceName)
    ) {
      return []
    }

    const elements = getElementsForPlacement(
      recipe,
      product,
      sizeId,
      placement,
      pieceName,
    )

    if (!elements) return []
    const { imageElement, textElement, content } = elements
    const result: Placement[] = []

    if (imageElement) {
      result.push({
        type: 'image',
        content,
        placementId: placement.id,
        element: imageElement,
      })
    }

    if (textElement) {
      result.push({
        type: 'text',
        content,
        placementId: placement.id,
        element: textElement,
      })
    }

    return result
  })

export default getPlacements
