import { hasLengthAtLeast } from '@orangelv/utils';
import {
  add2,
  angle2,
  angleLines2,
  denoisePolyline,
  distance2,
  in2px,
  interpolatePolylineBetweenCorners,
  intersectCircleWithSegment2,
  intersectLines2,
  intersectSegments2,
  magnitude2,
  multiply2,
  rotate2,
  subtract2,
  type Vector2,
} from '@orangelv/utils-geometry';
import { toSVGPolyline } from '@orangelv/utils-svg';

const CURVED_ENOUGH = 0.01;

/**
 * Find a new point which forms a segment with the outermost vertex of the path
 * which is the same length as the outermost segment of the path and is at the
 * same angle to the outermost segment as the outermost segment is to the
 * segment that comes after it. If the angle is too small, then replace the the
 * innermost vertex of the second segment with the next vertex, until an angle
 * greater than 0.01 radians is found.
 *
 * @remarks
 *
 * Like so: {@link https://github.com/OrangeLV/blocks/assets/2584727/fe2003d3-9ca6-42d9-8334-5e4ae909139c}
 *
 * @param path - Path to extend
 * @param reverse - Which side of the path to extend
 * @returns The extension point
 */
function extrapolateCurvature(path: Vector2[], direction: 1 | -1): Vector2 {
  if (path.length < 2) throw new Error('Path too short');
  let nextIndex = direction === 1 ? 0 : path.length - 1;
  const a = path[nextIndex];
  nextIndex += direction;
  const b = path[nextIndex];
  if (!a || !b) throw new Error('Logic error');
  const firstSegmentAngle = -angle2(a, b);
  let edgeAngle = 0;
  while (Math.abs(edgeAngle) < CURVED_ENOUGH) {
    nextIndex += direction;
    if (nextIndex < 0 || nextIndex >= path.length) break;
    const c = path[nextIndex];
    if (!c) throw new Error('Logic error');
    edgeAngle = firstSegmentAngle + angle2(b, c);
  }

  const edgeVector = rotate2(subtract2(a, b), -edgeAngle);
  return add2(a, edgeVector);
}

/**
 * Find a new point that's `length` away from the origin `point` in the
 * direction of the vector `ab`.
 *
 * @param point - Origin
 * @param a - First segment point
 * @param b - Second segment point
 * @param multiplier - Segment's length multiplier
 * @returns The new point
 */
export function shootAlongVector(
  point: Vector2,
  a: Vector2,
  b: Vector2,
  length: number,
): Vector2 {
  const vector = subtract2(b, a);
  const originalLength = magnitude2(vector);
  const vectorScaled = multiply2(length / originalLength, vector);
  return add2(point, vectorScaled);
}

/**
 * Walk `path` segment by segment in the specified direction starting at
 * `originIndex`, until an intersection is found between the segment and the
 * circle with its center in `path`[`originIndex`] of the specified radius.
 * Throws if not found.
 *
 * @param path - Path
 * @param originIndex - The point to walk from, center of the circle
 * @param direction - Direction to walk
 * @param radius - The radius of the circle
 * @returns The point of intersection, and the index of the vertex that's
 *     further away from the origin in the array of points
 */
function findCircleSegmentIntersection(
  path: Vector2[],
  originIndex: number,
  direction: 1 | -1,
  radius: number,
  ppu: number,
): { intersection: Vector2; index: number } {
  const center = path[originIndex];
  if (!center) throw new Error('Bad originIndex');
  let a = center;
  for (
    let index = originIndex + direction;
    direction === 1 ? index < path.length : index >= 0;
    index += direction
  ) {
    const b = path[index];
    if (!b) throw new Error('Logic error');
    const [intersection] = intersectCircleWithSegment2(
      center,
      radius,
      a,
      b,
      ppu,
    );
    if (intersection === undefined) {
      a = b;
      continue;
    }

    return { intersection, index };
  }

  throw new Error('Could not find circle-segment intersection');
}

const OFFSET_PATH_TAIL_LENGTH = in2px(1);
const SHORT_SEGMENT_LENGTH = 5;

/**
 * Offset a path by N pixels toward the "left" of the direction of the points (Y
 * axis pointing down), collapse self-intersecting portions, round the collapsed
 * portions, extrapolate the curvature of the edges if needed.
 *
 * @param path - The path to "hug"
 * @param distance - Number of pixels to offset by
 * @param roundingRadiusPixels - Radius of the circle that is used to find the
 *     points where to cut the braid and to connect with bezier curve, it does
 *     NOT correspond to the radius of the resulting rounding
 * @param curveEdges - Extrapolate the curvature of the edges of the path
 * @returns The new path
 */
export function getOffsetPath(
  path: Vector2[],
  distance: number,
  roundingRadiusPixels: number,
  curveEdges: boolean,
  ppu: number,
): string {
  if (!hasLengthAtLeast(path, 2)) return '';

  const { vertices: pathInterpolated } = interpolatePolylineBetweenCorners(
    denoisePolyline({ isClosed: false, vertices: path }, ppu),
    ppu,
  );

  // Would be nice to do this for all pieces and not think, but, unfortunately,
  // it causes problems sometimes, for example left raglan sleeve not matching
  // with the frontLeft piece. Why is this needed at all? Because sometimes
  // sewlines don't contain enough information in them for the brain path,
  // resulting in "misses" like this:
  // https://github.com/OrangeLV/blocks/assets/2584727/35525777-f00b-458d-bdcc-6a9fdacddfd4
  const pathCurved =
    curveEdges ?
      ([...pathInterpolated] as typeof pathInterpolated)
    : pathInterpolated;
  if (curveEdges) {
    for (let count = 0; count < 4; count += 1) {
      pathCurved.unshift(extrapolateCurvature(pathCurved, 1));
      pathCurved.push(extrapolateCurvature(pathCurved, -1));
    }
  }

  // https://user-images.githubusercontent.com/2584727/221308502-a2ce09d5-b464-459e-bd55-64ca271c91ba.png
  const newPath = pathCurved.slice(1, -1).map((point, index) => {
    // This is not a bug, the index is off by one due to slicing
    const previous = pathCurved[index];
    const next = pathCurved[index + 2];
    if (!previous || !next) throw new Error('Logic error');
    const alpha = angleLines2(previous, point, point, next);
    const beta = (Math.PI - alpha) / 2;
    const gamma = angle2(point, next);
    const intersectionDistance = distance / Math.cos(Math.PI / 2 - beta);
    return {
      x: point.x + Math.cos(beta + gamma) * intersectionDistance,
      y: point.y + Math.sin(beta + gamma) * intersectionDistance,
    };
  });

  // Happens when pathInterpolated.length === 2, for example
  // https://user-images.githubusercontent.com/2584727/221316878-e6ebadb6-f0cc-455a-b80d-820546602ef2.png
  if (newPath.length === 0) {
    const beta = angle2(pathCurved[0], pathCurved[1]) + Math.PI / 2;
    const dx = Math.cos(beta) * distance;
    const dy = Math.sin(beta) * distance;
    newPath.push(
      { x: pathCurved[0].x + dx, y: pathCurved[0].y + dy },
      { x: pathCurved[1].x + dx, y: pathCurved[1].y + dy },
    );
  }

  {
    const shootFrom = newPath[0];
    if (!shootFrom) throw new Error('Logic error');
    newPath.unshift(
      shootAlongVector(
        shootFrom,
        pathCurved[1],
        pathCurved[0],
        (pathInterpolated.length > 2 ?
          distance2(pathCurved[1], pathCurved[0])
        : 0) + OFFSET_PATH_TAIL_LENGTH,
      ),
    );
  }

  {
    const shootFrom = newPath.at(-1);
    const shootA = pathCurved.at(-2);
    const shootB = pathCurved.at(-1);
    if (!shootFrom || !shootA || !shootB) throw new Error('Logic error');
    newPath.push(
      shootAlongVector(
        shootFrom,
        shootA,
        shootB,
        (pathInterpolated.length > 2 ? distance2(shootA, shootB) : 0) +
          OFFSET_PATH_TAIL_LENGTH,
      ),
    );
  }

  // Return early for single-segment paths, no chance of intersections
  if (pathInterpolated.length === 2) return `M ${toSVGPolyline(newPath)}`;

  // Why all the short-circuiting stuff? Because otherwise you get this when
  // offsetting past a "focal point":
  // https://github.com/OrangeLV/blocks/assets/2584727/1453cd4a-8683-4f03-b805-c8e35522d0db
  // And the almostShort stuff? It's for cases where there is ALMOST a
  // self-intersection but not quite, resulting in a sharp-looking angle, which
  // is universally undesired so far.

  const almostShort = [];
  const intersections = [];
  const shortBeginnings = [];
  for (let indexA = 0; indexA < newPath.length - 1; indexA += 1) {
    const a = newPath[indexA];
    const b = newPath[indexA + 1];
    if (!a || !b) throw new Error('Logic error');

    // Segments shorter than SHORT_SEGMENT_LENGTH are treated as an indicator of
    // an "almost invalid loop". This is too naive, please refactor.
    const abLength = distance2(a, b);
    if (abLength < SHORT_SEGMENT_LENGTH) {
      almostShort.push({ length: abLength, index: indexA });
    }

    // Skipping i + 1 since it shouldn't intersect with i by definition
    for (let indexC = indexA + 2; indexC < newPath.length - 1; indexC += 1) {
      const c = newPath[indexC];
      const d = newPath[indexC + 1];
      if (!c || !d) throw new Error('Logic error');
      const intersection = intersectSegments2(a, b, c, d, ppu);
      if (intersection === undefined) continue;
      intersections.push({ point: intersection, ending: indexC });
      shortBeginnings.push(indexA);
      indexA = indexC; // Skip everything in-between
      break;
    }
  }

  if (intersections.length === 0 && almostShort.length === 0) {
    return `M ${toSVGPolyline(newPath)}`;
  }

  const almostShortPruned = [];
  let accumulator = [] as typeof almostShort;
  for (const nextAlmostShort of almostShort) {
    if (!hasLengthAtLeast(accumulator, 1)) {
      accumulator.push(nextAlmostShort);
      continue;
    }

    const accumulatorPrevious = accumulator.at(-1);
    if (!accumulatorPrevious) throw new Error('Logic error');
    if (nextAlmostShort.index === accumulatorPrevious.index + 1) {
      accumulator.push(nextAlmostShort);
      continue;
    }

    accumulator.sort((a, b) => a.length - b.length);
    almostShortPruned.push(accumulator[0]);
    accumulator = [nextAlmostShort];
  }

  if (almostShort.length > 0) {
    if (!hasLengthAtLeast(accumulator, 1)) throw new Error('Bad accumulator');
    accumulator.sort((a, b) => a.length - b.length);
    almostShortPruned.push(accumulator[0]);
  }

  const shortedPath = [];
  const acuteAngleVertexIndices = [];
  for (let index = 0; index < newPath.length; index += 1) {
    const point = newPath[index];
    if (!point) throw new Error('Logic error');
    shortedPath.push(point);
    const intersectionIndex = shortBeginnings.indexOf(index);
    if (intersectionIndex >= 0) {
      const intersection = intersections[intersectionIndex];
      if (!intersection) throw new Error('Logic error');
      // Index of the point below
      acuteAngleVertexIndices.push(shortedPath.length);
      shortedPath.push({
        x: intersection.point.x,
        y: intersection.point.y,
      });
      index = intersection.ending;
    } else if (almostShortPruned.some((x) => x.index === index)) {
      acuteAngleVertexIndices.push(shortedPath.length);
    }
  }

  const roundingBeginnings = [];
  const roundingSpots = [];
  for (const acuteAngleVertexIndex of acuteAngleVertexIndices) {
    const roundingA = findCircleSegmentIntersection(
      shortedPath,
      acuteAngleVertexIndex,
      -1,
      roundingRadiusPixels,
      ppu,
    );
    const roundingB = findCircleSegmentIntersection(
      shortedPath,
      acuteAngleVertexIndex,
      1,
      roundingRadiusPixels,
      ppu,
    );

    const intersectionA = shortedPath[roundingA.index];
    const intersectionD = shortedPath[roundingB.index];
    const intersectionBackup = shortedPath[acuteAngleVertexIndex];
    if (!intersectionA || !intersectionD || !intersectionBackup) {
      throw new Error('Logic error');
    }

    roundingBeginnings.push(roundingA.index);
    roundingSpots.push({
      a: roundingA.intersection,
      q:
        intersectLines2(
          intersectionA,
          roundingA.intersection,
          roundingB.intersection,
          intersectionD,
          ppu,
        ) ?? intersectionBackup,
      b: roundingB.intersection,
      ending: roundingB.index,
    });
  }

  let roundedPath = 'M ';
  for (let index = 0; index < shortedPath.length; index += 1) {
    const point = shortedPath[index];
    if (!point) throw new Error('Logic error');
    roundedPath += `${point.x},${point.y} `;
    const roundingIndex = roundingBeginnings.indexOf(index);
    if (roundingIndex >= 0) {
      const roundingSpot = roundingSpots[roundingIndex];
      if (!roundingSpot) throw new Error('Logic error');
      const { a, q, b, ending } = roundingSpot;
      roundedPath += `${a.x},${a.y} Q ${q.x},${q.y} ${b.x},${b.y} L `;
      index = ending - 1;
    }
  }

  return roundedPath;
}
