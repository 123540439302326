import * as _ from '@technically/lodash'

import { FontId, FONT_DICT } from '../common/sheets'

const getBlobFromCanvas = (canvas: HTMLCanvasElement) =>
  new Promise<Blob>((resolve) => {
    canvas.toBlob((blob) => resolve(blob!))
  })

const getFontFilename = (fontId: FontId) => {
  const font = FONT_DICT[fontId]
  return `${font.id}.${font.type}`
}

const getDrawableFontChars = (fontId: FontId, text: string) => {
  const font = FONT_DICT[fontId]

  /* eslint-disable no-param-reassign */
  if (!font?.isAvailable.lowercase) {
    text = _.toUpper(text)
  }

  if (!font?.isAvailable.uppercase) {
    text = _.toLower(text)
  }

  if (!font?.isAvailable.numbers) {
    text = text.replace(/[0-9]/g, '')
  }
  /* eslint-enable no-param-reassign */

  return text
}

export type UploadedFile = { id: string; filename: string }

const getLogoPath = ({ id }: UploadedFile) => `/api/images/${id}`

export { getBlobFromCanvas, getFontFilename, getDrawableFontChars, getLogoPath }
