// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorRow } from '../typings';

const data = [
  {
    "id": "white",
    "name": "White",
    "props": {
      "hex": "#f2f3fa"
    }
  },
  {
    "id": "white_+1",
    "name": "White +1",
    "props": {
      "hex": "#eceded"
    },
    "parentId": "white"
  },
  {
    "id": "white_+2",
    "name": "White +2",
    "props": {
      "hex": "#dcddde"
    },
    "parentId": "white"
  },
  {
    "id": "white_+3",
    "name": "White +3",
    "props": {
      "hex": "#cdced0"
    },
    "parentId": "white"
  },
  {
    "id": "white_+4",
    "name": "White +4",
    "props": {
      "hex": "#bcbdc0"
    },
    "parentId": "white"
  },
  {
    "id": "black",
    "name": "Black",
    "props": {
      "hex": "#000000"
    }
  },
  {
    "id": "black_-1",
    "name": "Black -1",
    "props": {
      "hex": "#192020"
    },
    "parentId": "black"
  },
  {
    "id": "black_-2",
    "name": "Black -2",
    "props": {
      "hex": "#1e2525"
    },
    "parentId": "black"
  },
  {
    "id": "black_-3",
    "name": "Black -3",
    "props": {
      "hex": "#2d3433"
    },
    "parentId": "black"
  },
  {
    "id": "black_-4",
    "name": "Black -4",
    "props": {
      "hex": "#363c3c"
    },
    "parentId": "black"
  },
  {
    "id": "darkCharcoal",
    "name": "Dark Charcoal",
    "props": {
      "hex": "#46454c"
    }
  },
  {
    "id": "darkCharcoal_-2",
    "name": "Dark Charcoal -2",
    "props": {
      "hex": "#575b67"
    },
    "parentId": "darkCharcoal"
  },
  {
    "id": "darkCharcoal_-1",
    "name": "Dark Charcoal -1",
    "props": {
      "hex": "#4a4f5e"
    },
    "parentId": "darkCharcoal"
  },
  {
    "id": "darkCharcoal_+1",
    "name": "Dark Charcoal +1",
    "props": {
      "hex": "#2b2d3c"
    },
    "parentId": "darkCharcoal"
  },
  {
    "id": "darkCharcoal_+2",
    "name": "Dark Charcoal +2",
    "props": {
      "hex": "#1d2230"
    },
    "parentId": "darkCharcoal"
  },
  {
    "id": "shade",
    "name": "Shade",
    "props": {
      "hex": "#66686e"
    }
  },
  {
    "id": "shade_-2",
    "name": "Shade -2",
    "props": {
      "hex": "#84888a"
    },
    "parentId": "shade"
  },
  {
    "id": "shade_-1",
    "name": "Shade -1",
    "props": {
      "hex": "#737c80"
    },
    "parentId": "shade"
  },
  {
    "id": "shade_+1",
    "name": "Shade +1",
    "props": {
      "hex": "#48535c"
    },
    "parentId": "shade"
  },
  {
    "id": "shade_+2",
    "name": "Shade +2",
    "props": {
      "hex": "#3e444a"
    },
    "parentId": "shade"
  },
  {
    "id": "charcoal",
    "name": "Charcoal",
    "props": {
      "hex": "#666567"
    }
  },
  {
    "id": "charcoal_-2",
    "name": "Charcoal -2",
    "props": {
      "hex": "#999a91"
    },
    "parentId": "charcoal"
  },
  {
    "id": "charcoal_-1",
    "name": "Charcoal -1",
    "props": {
      "hex": "#8c8e83"
    },
    "parentId": "charcoal"
  },
  {
    "id": "charcoal_+1",
    "name": "Charcoal +1",
    "props": {
      "hex": "#5c5d55"
    },
    "parentId": "charcoal"
  },
  {
    "id": "charcoal_+2",
    "name": "Charcoal +2",
    "props": {
      "hex": "#4e504c"
    },
    "parentId": "charcoal"
  },
  {
    "id": "silver",
    "name": "Silver",
    "props": {
      "hex": "#afb2b5"
    }
  },
  {
    "id": "silver_-2",
    "name": "Silver -2",
    "props": {
      "hex": "#dddedf"
    },
    "parentId": "silver"
  },
  {
    "id": "silver_-1",
    "name": "Silver -1",
    "props": {
      "hex": "#cdd0d0"
    },
    "parentId": "silver"
  },
  {
    "id": "silver_+1",
    "name": "Silver +1",
    "props": {
      "hex": "#a7a8a7"
    },
    "parentId": "silver"
  },
  {
    "id": "silver_+2",
    "name": "Silver +2",
    "props": {
      "hex": "#8f8e8e"
    },
    "parentId": "silver"
  },
  {
    "id": "gray",
    "name": "Gray",
    "props": {
      "hex": "#bbbcc1"
    }
  },
  {
    "id": "gray_-2",
    "name": "Gray -2",
    "props": {
      "hex": "#ebeced"
    },
    "parentId": "gray"
  },
  {
    "id": "gray_-1",
    "name": "Gray -1",
    "props": {
      "hex": "#dbdcd9"
    },
    "parentId": "gray"
  },
  {
    "id": "gray_+1",
    "name": "Gray +1",
    "props": {
      "hex": "#acaca8"
    },
    "parentId": "gray"
  },
  {
    "id": "gray_+2",
    "name": "Gray +2",
    "props": {
      "hex": "#90908f"
    },
    "parentId": "gray"
  },
  {
    "id": "maroon",
    "name": "Maroon",
    "props": {
      "hex": "#532e3b"
    }
  },
  {
    "id": "maroon_-2",
    "name": "Maroon -2",
    "props": {
      "hex": "#661346"
    },
    "parentId": "maroon"
  },
  {
    "id": "maroon_-1",
    "name": "Maroon -1",
    "props": {
      "hex": "#600633"
    },
    "parentId": "maroon"
  },
  {
    "id": "maroon_+1",
    "name": "Maroon +1",
    "props": {
      "hex": "#2a101b"
    },
    "parentId": "maroon"
  },
  {
    "id": "maroon_+2",
    "name": "Maroon +2",
    "props": {
      "hex": "#1c0c14"
    },
    "parentId": "maroon"
  },
  {
    "id": "cardinal",
    "name": "Cardinal",
    "props": {
      "hex": "#651a32"
    }
  },
  {
    "id": "cardinal_-2",
    "name": "Cardinal -2",
    "props": {
      "hex": "#7f184c"
    },
    "parentId": "cardinal"
  },
  {
    "id": "cardinal_-1",
    "name": "Cardinal -1",
    "props": {
      "hex": "#6d0935"
    },
    "parentId": "cardinal"
  },
  {
    "id": "cardinal_+1",
    "name": "Cardinal +1",
    "props": {
      "hex": "#3d0f1f"
    },
    "parentId": "cardinal"
  },
  {
    "id": "cardinal_+2",
    "name": "Cardinal +2",
    "props": {
      "hex": "#2d101d"
    },
    "parentId": "cardinal"
  },
  {
    "id": "red",
    "name": "Red",
    "props": {
      "hex": "#b41f3a"
    }
  },
  {
    "id": "red_-2",
    "name": "Red -2",
    "props": {
      "hex": "#d52847"
    },
    "parentId": "red"
  },
  {
    "id": "red_-1",
    "name": "Red -1",
    "props": {
      "hex": "#c8224a"
    },
    "parentId": "red"
  },
  {
    "id": "red_+1",
    "name": "Red +1",
    "props": {
      "hex": "#a11c42"
    },
    "parentId": "red"
  },
  {
    "id": "red_+2",
    "name": "Red +2",
    "props": {
      "hex": "#8a1b37"
    },
    "parentId": "red"
  },
  {
    "id": "blazingOrange",
    "name": "Blazing Orange",
    "props": {
      "hex": "#f2634a"
    }
  },
  {
    "id": "blazingOrange_-2",
    "name": "Blazing Orange -2",
    "props": {
      "hex": "#f59076"
    },
    "parentId": "blazingOrange"
  },
  {
    "id": "blazingOrange_-1",
    "name": "Blazing Orange -1",
    "props": {
      "hex": "#f58667"
    },
    "parentId": "blazingOrange"
  },
  {
    "id": "blazingOrange_+1",
    "name": "Blazing Orange +1",
    "props": {
      "hex": "#dc6648"
    },
    "parentId": "blazingOrange"
  },
  {
    "id": "blazingOrange_+2",
    "name": "Blazing Orange +2",
    "props": {
      "hex": "#c7523c"
    },
    "parentId": "blazingOrange"
  },
  {
    "id": "orange",
    "name": "Orange",
    "props": {
      "hex": "#d35a28"
    }
  },
  {
    "id": "orange_-2",
    "name": "Orange -2",
    "props": {
      "hex": "#e4946e"
    },
    "parentId": "orange"
  },
  {
    "id": "orange_-1",
    "name": "Orange -1",
    "props": {
      "hex": "#de8058"
    },
    "parentId": "orange"
  },
  {
    "id": "orange_+1",
    "name": "Orange +1",
    "props": {
      "hex": "#be5d43"
    },
    "parentId": "orange"
  },
  {
    "id": "orange_+2",
    "name": "Orange +2",
    "props": {
      "hex": "#a95143"
    },
    "parentId": "orange"
  },
  {
    "id": "texasOrange",
    "name": "Texas Orange",
    "props": {
      "hex": "#9f5130"
    }
  },
  {
    "id": "texasOrange_-2",
    "name": "Texas Orange -2",
    "props": {
      "hex": "#d5854f"
    },
    "parentId": "texasOrange"
  },
  {
    "id": "texasOrange_-1",
    "name": "Texas Orange -1",
    "props": {
      "hex": "#c3723a"
    },
    "parentId": "texasOrange"
  },
  {
    "id": "texasOrange_+1",
    "name": "Texas Orange +1",
    "props": {
      "hex": "#975126"
    },
    "parentId": "texasOrange"
  },
  {
    "id": "texasOrange_+2",
    "name": "Texas Orange +2",
    "props": {
      "hex": "#804220"
    },
    "parentId": "texasOrange"
  },
  {
    "id": "orangeHorizon",
    "name": "Orange Horizon",
    "props": {
      "hex": "#f04e23"
    }
  },
  {
    "id": "orangeHorizon_-2",
    "name": "Orange Horizon -2",
    "props": {
      "hex": "#f6915e"
    },
    "parentId": "orangeHorizon"
  },
  {
    "id": "orangeHorizon_-1",
    "name": "Orange Horizon -1",
    "props": {
      "hex": "#f4803e"
    },
    "parentId": "orangeHorizon"
  },
  {
    "id": "orangeHorizon_+1",
    "name": "Orange Horizon +1",
    "props": {
      "hex": "#d25d25"
    },
    "parentId": "orangeHorizon"
  },
  {
    "id": "orangeHorizon_+2",
    "name": "Orange Horizon +2",
    "props": {
      "hex": "#b64e26"
    },
    "parentId": "orangeHorizon"
  },
  {
    "id": "lightOrange",
    "name": "Light Orange",
    "props": {
      "hex": "#f68a41"
    }
  },
  {
    "id": "lightOrange_-2",
    "name": "Light Orange -2",
    "props": {
      "hex": "#f9a267"
    },
    "parentId": "lightOrange"
  },
  {
    "id": "lightOrange_-1",
    "name": "Light Orange -1",
    "props": {
      "hex": "#f6944d"
    },
    "parentId": "lightOrange"
  },
  {
    "id": "lightOrange_+1",
    "name": "Light Orange +1",
    "props": {
      "hex": "#e8732c"
    },
    "parentId": "lightOrange"
  },
  {
    "id": "lightOrange_+2",
    "name": "Light Orange +2",
    "props": {
      "hex": "#dc6027"
    },
    "parentId": "lightOrange"
  },
  {
    "id": "athleticGold",
    "name": "Athletic Gold",
    "props": {
      "hex": "#faac2d"
    }
  },
  {
    "id": "athleticGold_-2",
    "name": "Athletic Gold -2",
    "props": {
      "hex": "#fdcc6c"
    },
    "parentId": "athleticGold"
  },
  {
    "id": "athleticGold_-1",
    "name": "Athletic Gold -1",
    "props": {
      "hex": "#fdc44b"
    },
    "parentId": "athleticGold"
  },
  {
    "id": "athleticGold_+1",
    "name": "Athletic Gold +1",
    "props": {
      "hex": "#eca726"
    },
    "parentId": "athleticGold"
  },
  {
    "id": "athleticGold_+2",
    "name": "Athletic Gold +2",
    "props": {
      "hex": "#dc9528"
    },
    "parentId": "athleticGold"
  },
  {
    "id": "vegasGold",
    "name": "Vegas Gold",
    "props": {
      "hex": "#ccb97e"
    }
  },
  {
    "id": "vegasGold_-2",
    "name": "Vegas Gold -2",
    "props": {
      "hex": "#ffe1a1"
    },
    "parentId": "vegasGold"
  },
  {
    "id": "vegasGold_-1",
    "name": "Vegas Gold -1",
    "props": {
      "hex": "#efd190"
    },
    "parentId": "vegasGold"
  },
  {
    "id": "vegasGold_+1",
    "name": "Vegas Gold +1",
    "props": {
      "hex": "#c1a46c"
    },
    "parentId": "vegasGold"
  },
  {
    "id": "vegasGold_+2",
    "name": "Vegas Gold +2",
    "props": {
      "hex": "#a68b5b"
    },
    "parentId": "vegasGold"
  },
  {
    "id": "yellow",
    "name": "Yellow",
    "props": {
      "hex": "#fece07"
    }
  },
  {
    "id": "yellow_-2",
    "name": "Yellow -2",
    "props": {
      "hex": "#fce775"
    },
    "parentId": "yellow"
  },
  {
    "id": "yellow_-1",
    "name": "Yellow -1",
    "props": {
      "hex": "#fade4f"
    },
    "parentId": "yellow"
  },
  {
    "id": "yellow_+1",
    "name": "Yellow +1",
    "props": {
      "hex": "#ecc421"
    },
    "parentId": "yellow"
  },
  {
    "id": "yellow_+2",
    "name": "Yellow +2",
    "props": {
      "hex": "#e0b224"
    },
    "parentId": "yellow"
  },
  {
    "id": "opticYellow",
    "name": "Optic Yellow",
    "props": {
      "hex": "#d7da21"
    }
  },
  {
    "id": "opticYellow_-2",
    "name": "Optic Yellow -2",
    "props": {
      "hex": "#f7f385"
    },
    "parentId": "opticYellow"
  },
  {
    "id": "opticYellow_-1",
    "name": "Optic Yellow -1",
    "props": {
      "hex": "#f3ee54"
    },
    "parentId": "opticYellow"
  },
  {
    "id": "opticYellow_+1",
    "name": "Optic Yellow +1",
    "props": {
      "hex": "#ddcf21"
    },
    "parentId": "opticYellow"
  },
  {
    "id": "opticYellow_+2",
    "name": "Optic Yellow +2",
    "props": {
      "hex": "#ccb82d"
    },
    "parentId": "opticYellow"
  },
  {
    "id": "lemon",
    "name": "Lemon",
    "props": {
      "hex": "#e1e42f"
    }
  },
  {
    "id": "lemon_-2",
    "name": "Lemon -2",
    "props": {
      "hex": "#fbf6a7"
    },
    "parentId": "lemon"
  },
  {
    "id": "lemon_-1",
    "name": "Lemon -1",
    "props": {
      "hex": "#fcf58d"
    },
    "parentId": "lemon"
  },
  {
    "id": "lemon_+1",
    "name": "Lemon +1",
    "props": {
      "hex": "#ecdd64"
    },
    "parentId": "lemon"
  },
  {
    "id": "lemon_+2",
    "name": "Lemon +2",
    "props": {
      "hex": "#dac859"
    },
    "parentId": "lemon"
  },
  {
    "id": "electricGreen",
    "name": "Electric Green",
    "props": {
      "hex": "#80c349"
    }
  },
  {
    "id": "electricGreen_-2",
    "name": "Electric Green -2",
    "props": {
      "hex": "#d7e478"
    },
    "parentId": "electricGreen"
  },
  {
    "id": "electricGreen_-1",
    "name": "Electric Green -1",
    "props": {
      "hex": "#c4dc6a"
    },
    "parentId": "electricGreen"
  },
  {
    "id": "electricGreen_+1",
    "name": "Electric Green +1",
    "props": {
      "hex": "#a6c04e"
    },
    "parentId": "electricGreen"
  },
  {
    "id": "electricGreen_+2",
    "name": "Electric Green +2",
    "props": {
      "hex": "#9baf44"
    },
    "parentId": "electricGreen"
  },
  {
    "id": "kellyGreen",
    "name": "Kelly Green",
    "props": {
      "hex": "#0b8d45"
    }
  },
  {
    "id": "kellyGreen_-2",
    "name": "Kelly Green -2",
    "props": {
      "hex": "#7dc56d"
    },
    "parentId": "kellyGreen"
  },
  {
    "id": "kellyGreen_-1",
    "name": "Kelly Green -1",
    "props": {
      "hex": "#67be60"
    },
    "parentId": "kellyGreen"
  },
  {
    "id": "kellyGreen_+1",
    "name": "Kelly Green +1",
    "props": {
      "hex": "#429b4a"
    },
    "parentId": "kellyGreen"
  },
  {
    "id": "kellyGreen_+2",
    "name": "Kelly Green +2",
    "props": {
      "hex": "#33843f"
    },
    "parentId": "kellyGreen"
  },
  {
    "id": "forest",
    "name": "Forest",
    "props": {
      "hex": "#10584a"
    }
  },
  {
    "id": "forest_-2",
    "name": "Forest -2",
    "props": {
      "hex": "#2e835f"
    },
    "parentId": "forest"
  },
  {
    "id": "forest_-1",
    "name": "Forest -1",
    "props": {
      "hex": "#297153"
    },
    "parentId": "forest"
  },
  {
    "id": "forest_+1",
    "name": "Forest +1",
    "props": {
      "hex": "#144935"
    },
    "parentId": "forest"
  },
  {
    "id": "forest_+2",
    "name": "Forest +2",
    "props": {
      "hex": "#113d2b"
    },
    "parentId": "forest"
  },
  {
    "id": "teal",
    "name": "Teal",
    "props": {
      "hex": "#077680"
    }
  },
  {
    "id": "teal_-2",
    "name": "Teal -2",
    "props": {
      "hex": "#3fbfa8"
    },
    "parentId": "teal"
  },
  {
    "id": "teal_-1",
    "name": "Teal -1",
    "props": {
      "hex": "#04ae9a"
    },
    "parentId": "teal"
  },
  {
    "id": "teal_+1",
    "name": "Teal +1",
    "props": {
      "hex": "#0b8576"
    },
    "parentId": "teal"
  },
  {
    "id": "teal_+2",
    "name": "Teal +2",
    "props": {
      "hex": "#246b60"
    },
    "parentId": "teal"
  },
  {
    "id": "divaBlue",
    "name": "Diva Blue",
    "props": {
      "hex": "#0e75b0"
    }
  },
  {
    "id": "divaBlue_-2",
    "name": "Diva Blue -2",
    "props": {
      "hex": "#62cadc"
    },
    "parentId": "divaBlue"
  },
  {
    "id": "divaBlue_-1",
    "name": "Diva Blue -1",
    "props": {
      "hex": "#20bcd2"
    },
    "parentId": "divaBlue"
  },
  {
    "id": "divaBlue_+1",
    "name": "Diva Blue +1",
    "props": {
      "hex": "#0598ac"
    },
    "parentId": "divaBlue"
  },
  {
    "id": "divaBlue_+2",
    "name": "Diva Blue +2",
    "props": {
      "hex": "#087f93"
    },
    "parentId": "divaBlue"
  },
  {
    "id": "columbiaBlue",
    "name": "Columbia Blue",
    "props": {
      "hex": "#6ca9d4"
    }
  },
  {
    "id": "columbiaBlue_-2",
    "name": "Columbia Blue -2",
    "props": {
      "hex": "#c5e9f8"
    },
    "parentId": "columbiaBlue"
  },
  {
    "id": "columbiaBlue_-1",
    "name": "Columbia Blue -1",
    "props": {
      "hex": "#a9d7ec"
    },
    "parentId": "columbiaBlue"
  },
  {
    "id": "columbiaBlue_+1",
    "name": "Columbia Blue +1",
    "props": {
      "hex": "#7db2c8"
    },
    "parentId": "columbiaBlue"
  },
  {
    "id": "columbiaBlue_+2",
    "name": "Columbia Blue +2",
    "props": {
      "hex": "#6f9eb3"
    },
    "parentId": "columbiaBlue"
  },
  {
    "id": "royal",
    "name": "Royal",
    "props": {
      "hex": "#223f88"
    }
  },
  {
    "id": "royal_-2",
    "name": "Royal -2",
    "props": {
      "hex": "#4d98cf"
    },
    "parentId": "royal"
  },
  {
    "id": "royal_-1",
    "name": "Royal -1",
    "props": {
      "hex": "#1583b6"
    },
    "parentId": "royal"
  },
  {
    "id": "royal_+1",
    "name": "Royal +1",
    "props": {
      "hex": "#00547e"
    },
    "parentId": "royal"
  },
  {
    "id": "royal_+2",
    "name": "Royal +2",
    "props": {
      "hex": "#03446e"
    },
    "parentId": "royal"
  },
  {
    "id": "mediumBlue",
    "name": "Medium Blue",
    "props": {
      "hex": "#0a568d"
    }
  },
  {
    "id": "mediumBlue_-2",
    "name": "Medium Blue -2",
    "props": {
      "hex": "#2596a9"
    },
    "parentId": "mediumBlue"
  },
  {
    "id": "mediumBlue_-1",
    "name": "Medium Blue -1",
    "props": {
      "hex": "#008497"
    },
    "parentId": "mediumBlue"
  },
  {
    "id": "mediumBlue_+1",
    "name": "Medium Blue +1",
    "props": {
      "hex": "#0b5e6d"
    },
    "parentId": "mediumBlue"
  },
  {
    "id": "mediumBlue_+2",
    "name": "Medium Blue +2",
    "props": {
      "hex": "#154d5b"
    },
    "parentId": "mediumBlue"
  },
  {
    "id": "midnightBlue",
    "name": "Midnight Blue",
    "props": {
      "hex": "#10294b"
    }
  },
  {
    "id": "midnightBlue_+1",
    "name": "Midnight Blue +1",
    "props": {
      "hex": "#092433"
    },
    "parentId": "midnightBlue"
  },
  {
    "id": "midnightBlue_+2",
    "name": "Midnight Blue +2",
    "props": {
      "hex": "#0a1f28"
    },
    "parentId": "midnightBlue"
  },
  {
    "id": "midnightBlue_+3",
    "name": "Midnight Blue +3",
    "props": {
      "hex": "#07354f"
    },
    "parentId": "midnightBlue"
  },
  {
    "id": "midnightBlue_+4",
    "name": "Midnight Blue +4",
    "props": {
      "hex": "#12415e"
    },
    "parentId": "midnightBlue"
  },
  {
    "id": "navy",
    "name": "Navy",
    "props": {
      "hex": "#29293e"
    }
  },
  {
    "id": "navy_-2",
    "name": "Navy -2",
    "props": {
      "hex": "#17335b"
    },
    "parentId": "navy"
  },
  {
    "id": "navy_-1",
    "name": "Navy -1",
    "props": {
      "hex": "#0b2345"
    },
    "parentId": "navy"
  },
  {
    "id": "navy_+1",
    "name": "Navy +1",
    "props": {
      "hex": "#081022"
    },
    "parentId": "navy"
  },
  {
    "id": "navy_+2",
    "name": "Navy +2",
    "props": {
      "hex": "#0e0f14"
    },
    "parentId": "navy"
  },
  {
    "id": "purple",
    "name": "Purple",
    "props": {
      "hex": "#44387e"
    }
  },
  {
    "id": "purple_-2",
    "name": "Purple -2",
    "props": {
      "hex": "#6375b4"
    },
    "parentId": "purple"
  },
  {
    "id": "purple_-1",
    "name": "Purple -1",
    "props": {
      "hex": "#5562a0"
    },
    "parentId": "purple"
  },
  {
    "id": "purple_+1",
    "name": "Purple +1",
    "props": {
      "hex": "#394375"
    },
    "parentId": "purple"
  },
  {
    "id": "purple_+2",
    "name": "Purple +2",
    "props": {
      "hex": "#2c375e"
    },
    "parentId": "purple"
  },
  {
    "id": "violet",
    "name": "Violet",
    "props": {
      "hex": "#573e84"
    }
  },
  {
    "id": "violet_-2",
    "name": "Violet -2",
    "props": {
      "hex": "#827bba"
    },
    "parentId": "violet"
  },
  {
    "id": "violet_-1",
    "name": "Violet -1",
    "props": {
      "hex": "#7166a2"
    },
    "parentId": "violet"
  },
  {
    "id": "violet_+1",
    "name": "Violet +1",
    "props": {
      "hex": "#4d4476"
    },
    "parentId": "violet"
  },
  {
    "id": "violet_+2",
    "name": "Violet +2",
    "props": {
      "hex": "#3b3660"
    },
    "parentId": "violet"
  },
  {
    "id": "lilac",
    "name": "Lilac",
    "props": {
      "hex": "#7a65ad"
    }
  },
  {
    "id": "lilac_-2",
    "name": "Lilac -2",
    "props": {
      "hex": "#aaa9d5"
    },
    "parentId": "lilac"
  },
  {
    "id": "lilac_-1",
    "name": "Lilac -1",
    "props": {
      "hex": "#908ec3"
    },
    "parentId": "lilac"
  },
  {
    "id": "lilac_+1",
    "name": "Lilac +1",
    "props": {
      "hex": "#636197"
    },
    "parentId": "lilac"
  },
  {
    "id": "lilac_+2",
    "name": "Lilac +2",
    "props": {
      "hex": "#4c4d81"
    },
    "parentId": "lilac"
  },
  {
    "id": "electricPurple",
    "name": "Electric Purple",
    "props": {
      "hex": "#942a8f"
    }
  },
  {
    "id": "electricPurple_-2",
    "name": "Electric Purple -2",
    "props": {
      "hex": "#c283b9"
    },
    "parentId": "electricPurple"
  },
  {
    "id": "electricPurple_-1",
    "name": "Electric Purple -1",
    "props": {
      "hex": "#ae6cad"
    },
    "parentId": "electricPurple"
  },
  {
    "id": "electricPurple_+1",
    "name": "Electric Purple +1",
    "props": {
      "hex": "#7d4482"
    },
    "parentId": "electricPurple"
  },
  {
    "id": "electricPurple_+2",
    "name": "Electric Purple +2",
    "props": {
      "hex": "#603469"
    },
    "parentId": "electricPurple"
  },
  {
    "id": "lightViolet",
    "name": "Light Violet",
    "props": {
      "hex": "#c0a1cc"
    }
  },
  {
    "id": "lightViolet_-2",
    "name": "Light Violet -2",
    "props": {
      "hex": "#e4d7e9"
    },
    "parentId": "lightViolet"
  },
  {
    "id": "lightViolet_-1",
    "name": "Light Violet -1",
    "props": {
      "hex": "#d4c6e1"
    },
    "parentId": "lightViolet"
  },
  {
    "id": "lightViolet_+1",
    "name": "Light Violet +1",
    "props": {
      "hex": "#a396b6"
    },
    "parentId": "lightViolet"
  },
  {
    "id": "lightViolet_+2",
    "name": "Light Violet +2",
    "props": {
      "hex": "#867b9a"
    },
    "parentId": "lightViolet"
  },
  {
    "id": "shockingPink",
    "name": "Shocking Pink",
    "props": {
      "hex": "#e31679"
    }
  },
  {
    "id": "shockingPink_-2",
    "name": "Shocking Pink -2",
    "props": {
      "hex": "#f291bc"
    },
    "parentId": "shockingPink"
  },
  {
    "id": "shockingPink_-1",
    "name": "Shocking Pink -1",
    "props": {
      "hex": "#eb79af"
    },
    "parentId": "shockingPink"
  },
  {
    "id": "shockingPink_+1",
    "name": "Shocking Pink +1",
    "props": {
      "hex": "#c94b90"
    },
    "parentId": "shockingPink"
  },
  {
    "id": "shockingPink_+2",
    "name": "Shocking Pink +2",
    "props": {
      "hex": "#b03981"
    },
    "parentId": "shockingPink"
  },
  {
    "id": "firePink",
    "name": "Fire Pink",
    "props": {
      "hex": "#ef4e99"
    }
  },
  {
    "id": "firePink_-2",
    "name": "Fire Pink -2",
    "props": {
      "hex": "#f8bcd6"
    },
    "parentId": "firePink"
  },
  {
    "id": "firePink_-1",
    "name": "Fire Pink -1",
    "props": {
      "hex": "#f19cc2"
    },
    "parentId": "firePink"
  },
  {
    "id": "firePink_+1",
    "name": "Fire Pink +1",
    "props": {
      "hex": "#d3669e"
    },
    "parentId": "firePink"
  },
  {
    "id": "firePink_+2",
    "name": "Fire Pink +2",
    "props": {
      "hex": "#c0508e"
    },
    "parentId": "firePink"
  },
  {
    "id": "pink",
    "name": "Pink",
    "props": {
      "hex": "#e96a97"
    }
  },
  {
    "id": "pink_-2",
    "name": "Pink -2",
    "props": {
      "hex": "#f8bdd7"
    },
    "parentId": "pink"
  },
  {
    "id": "pink_-1",
    "name": "Pink -1",
    "props": {
      "hex": "#eb9db8"
    },
    "parentId": "pink"
  },
  {
    "id": "pink_+1",
    "name": "Pink +1",
    "props": {
      "hex": "#c56b8b"
    },
    "parentId": "pink"
  },
  {
    "id": "pink_+2",
    "name": "Pink +2",
    "props": {
      "hex": "#aa577a"
    },
    "parentId": "pink"
  },
  {
    "id": "lightPink",
    "name": "Light Pink",
    "props": {
      "hex": "#eea7ca"
    }
  },
  {
    "id": "lightPink_-2",
    "name": "Light Pink -2",
    "props": {
      "hex": "#f7dbe9"
    },
    "parentId": "lightPink"
  },
  {
    "id": "lightPink_-1",
    "name": "Light Pink -1",
    "props": {
      "hex": "#f4cde0"
    },
    "parentId": "lightPink"
  },
  {
    "id": "lightPink_+1",
    "name": "Light Pink +1",
    "props": {
      "hex": "#e1b2ca"
    },
    "parentId": "lightPink"
  },
  {
    "id": "lightPink_+2",
    "name": "Light Pink +2",
    "props": {
      "hex": "#d3a3bd"
    },
    "parentId": "lightPink"
  },
  {
    "id": "mahogany",
    "name": "Mahogany",
    "props": {
      "hex": "#473729"
    }
  },
  {
    "id": "mahogany_-2",
    "name": "Mahogany -2",
    "props": {
      "hex": "#614e40"
    },
    "parentId": "mahogany"
  },
  {
    "id": "mahogany_-1",
    "name": "Mahogany -1",
    "props": {
      "hex": "#4e3e30"
    },
    "parentId": "mahogany"
  },
  {
    "id": "mahogany_+1",
    "name": "Mahogany +1",
    "props": {
      "hex": "#2d2319"
    },
    "parentId": "mahogany"
  },
  {
    "id": "mahogany_+2",
    "name": "Mahogany +2",
    "props": {
      "hex": "#1d130f"
    },
    "parentId": "mahogany"
  },
  {
    "id": "brown",
    "name": "Brown",
    "props": {
      "hex": "#5a4732"
    }
  },
  {
    "id": "brown_-2",
    "name": "Brown -2",
    "props": {
      "hex": "#6e563f"
    },
    "parentId": "brown"
  },
  {
    "id": "brown_-1",
    "name": "Brown -1",
    "props": {
      "hex": "#5a442d"
    },
    "parentId": "brown"
  },
  {
    "id": "brown_+1",
    "name": "Brown +1",
    "props": {
      "hex": "#372615"
    },
    "parentId": "brown"
  },
  {
    "id": "brown_+2",
    "name": "Brown +2",
    "props": {
      "hex": "#25170f"
    },
    "parentId": "brown"
  },
  {
    "id": "espresso",
    "name": "Espresso",
    "props": {
      "hex": "#40312f"
    }
  },
  {
    "id": "espresso_-2",
    "name": "Espresso -2",
    "props": {
      "hex": "#70605f"
    },
    "parentId": "espresso"
  },
  {
    "id": "espresso_-1",
    "name": "Espresso -1",
    "props": {
      "hex": "#624c46"
    },
    "parentId": "espresso"
  },
  {
    "id": "espresso_+1",
    "name": "Espresso +1",
    "props": {
      "hex": "#372724"
    },
    "parentId": "espresso"
  },
  {
    "id": "espresso_+2",
    "name": "Espresso +2",
    "props": {
      "hex": "#281d1d"
    },
    "parentId": "espresso"
  },
  {
    "id": "cream",
    "name": "Cream",
    "props": {
      "hex": "#e7dcc5"
    }
  },
  {
    "id": "cream_-1",
    "name": "Cream -1",
    "props": {
      "hex": "#faf4e7"
    },
    "parentId": "cream"
  },
  {
    "id": "cream_+1",
    "name": "Cream +1",
    "props": {
      "hex": "#f1e7d2"
    },
    "parentId": "cream"
  },
  {
    "id": "cream_+2",
    "name": "Cream +2",
    "props": {
      "hex": "#d8d0bc"
    },
    "parentId": "cream"
  },
  {
    "id": "cream_+3",
    "name": "Cream +3",
    "props": {
      "hex": "#ccc5b4"
    },
    "parentId": "cream"
  },
  {
    "id": "coolGray",
    "name": "Cool Gray",
    "props": {
      "hex": "#a7a9a9"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorRow[]>> = data;

export type Color = typeof typed[number];

export const COLOR_INDEX_KEY = "id";
export type ColorIndexKey = "id";
export type ColorId = Color["id"];

let i = 0;
export const COLOR_DICT = {
  "white": typed[i++],
  "white_+1": typed[i++],
  "white_+2": typed[i++],
  "white_+3": typed[i++],
  "white_+4": typed[i++],
  "black": typed[i++],
  "black_-1": typed[i++],
  "black_-2": typed[i++],
  "black_-3": typed[i++],
  "black_-4": typed[i++],
  "darkCharcoal": typed[i++],
  "darkCharcoal_-2": typed[i++],
  "darkCharcoal_-1": typed[i++],
  "darkCharcoal_+1": typed[i++],
  "darkCharcoal_+2": typed[i++],
  "shade": typed[i++],
  "shade_-2": typed[i++],
  "shade_-1": typed[i++],
  "shade_+1": typed[i++],
  "shade_+2": typed[i++],
  "charcoal": typed[i++],
  "charcoal_-2": typed[i++],
  "charcoal_-1": typed[i++],
  "charcoal_+1": typed[i++],
  "charcoal_+2": typed[i++],
  "silver": typed[i++],
  "silver_-2": typed[i++],
  "silver_-1": typed[i++],
  "silver_+1": typed[i++],
  "silver_+2": typed[i++],
  "gray": typed[i++],
  "gray_-2": typed[i++],
  "gray_-1": typed[i++],
  "gray_+1": typed[i++],
  "gray_+2": typed[i++],
  "maroon": typed[i++],
  "maroon_-2": typed[i++],
  "maroon_-1": typed[i++],
  "maroon_+1": typed[i++],
  "maroon_+2": typed[i++],
  "cardinal": typed[i++],
  "cardinal_-2": typed[i++],
  "cardinal_-1": typed[i++],
  "cardinal_+1": typed[i++],
  "cardinal_+2": typed[i++],
  "red": typed[i++],
  "red_-2": typed[i++],
  "red_-1": typed[i++],
  "red_+1": typed[i++],
  "red_+2": typed[i++],
  "blazingOrange": typed[i++],
  "blazingOrange_-2": typed[i++],
  "blazingOrange_-1": typed[i++],
  "blazingOrange_+1": typed[i++],
  "blazingOrange_+2": typed[i++],
  "orange": typed[i++],
  "orange_-2": typed[i++],
  "orange_-1": typed[i++],
  "orange_+1": typed[i++],
  "orange_+2": typed[i++],
  "texasOrange": typed[i++],
  "texasOrange_-2": typed[i++],
  "texasOrange_-1": typed[i++],
  "texasOrange_+1": typed[i++],
  "texasOrange_+2": typed[i++],
  "orangeHorizon": typed[i++],
  "orangeHorizon_-2": typed[i++],
  "orangeHorizon_-1": typed[i++],
  "orangeHorizon_+1": typed[i++],
  "orangeHorizon_+2": typed[i++],
  "lightOrange": typed[i++],
  "lightOrange_-2": typed[i++],
  "lightOrange_-1": typed[i++],
  "lightOrange_+1": typed[i++],
  "lightOrange_+2": typed[i++],
  "athleticGold": typed[i++],
  "athleticGold_-2": typed[i++],
  "athleticGold_-1": typed[i++],
  "athleticGold_+1": typed[i++],
  "athleticGold_+2": typed[i++],
  "vegasGold": typed[i++],
  "vegasGold_-2": typed[i++],
  "vegasGold_-1": typed[i++],
  "vegasGold_+1": typed[i++],
  "vegasGold_+2": typed[i++],
  "yellow": typed[i++],
  "yellow_-2": typed[i++],
  "yellow_-1": typed[i++],
  "yellow_+1": typed[i++],
  "yellow_+2": typed[i++],
  "opticYellow": typed[i++],
  "opticYellow_-2": typed[i++],
  "opticYellow_-1": typed[i++],
  "opticYellow_+1": typed[i++],
  "opticYellow_+2": typed[i++],
  "lemon": typed[i++],
  "lemon_-2": typed[i++],
  "lemon_-1": typed[i++],
  "lemon_+1": typed[i++],
  "lemon_+2": typed[i++],
  "electricGreen": typed[i++],
  "electricGreen_-2": typed[i++],
  "electricGreen_-1": typed[i++],
  "electricGreen_+1": typed[i++],
  "electricGreen_+2": typed[i++],
  "kellyGreen": typed[i++],
  "kellyGreen_-2": typed[i++],
  "kellyGreen_-1": typed[i++],
  "kellyGreen_+1": typed[i++],
  "kellyGreen_+2": typed[i++],
  "forest": typed[i++],
  "forest_-2": typed[i++],
  "forest_-1": typed[i++],
  "forest_+1": typed[i++],
  "forest_+2": typed[i++],
  "teal": typed[i++],
  "teal_-2": typed[i++],
  "teal_-1": typed[i++],
  "teal_+1": typed[i++],
  "teal_+2": typed[i++],
  "divaBlue": typed[i++],
  "divaBlue_-2": typed[i++],
  "divaBlue_-1": typed[i++],
  "divaBlue_+1": typed[i++],
  "divaBlue_+2": typed[i++],
  "columbiaBlue": typed[i++],
  "columbiaBlue_-2": typed[i++],
  "columbiaBlue_-1": typed[i++],
  "columbiaBlue_+1": typed[i++],
  "columbiaBlue_+2": typed[i++],
  "royal": typed[i++],
  "royal_-2": typed[i++],
  "royal_-1": typed[i++],
  "royal_+1": typed[i++],
  "royal_+2": typed[i++],
  "mediumBlue": typed[i++],
  "mediumBlue_-2": typed[i++],
  "mediumBlue_-1": typed[i++],
  "mediumBlue_+1": typed[i++],
  "mediumBlue_+2": typed[i++],
  "midnightBlue": typed[i++],
  "midnightBlue_+1": typed[i++],
  "midnightBlue_+2": typed[i++],
  "midnightBlue_+3": typed[i++],
  "midnightBlue_+4": typed[i++],
  "navy": typed[i++],
  "navy_-2": typed[i++],
  "navy_-1": typed[i++],
  "navy_+1": typed[i++],
  "navy_+2": typed[i++],
  "purple": typed[i++],
  "purple_-2": typed[i++],
  "purple_-1": typed[i++],
  "purple_+1": typed[i++],
  "purple_+2": typed[i++],
  "violet": typed[i++],
  "violet_-2": typed[i++],
  "violet_-1": typed[i++],
  "violet_+1": typed[i++],
  "violet_+2": typed[i++],
  "lilac": typed[i++],
  "lilac_-2": typed[i++],
  "lilac_-1": typed[i++],
  "lilac_+1": typed[i++],
  "lilac_+2": typed[i++],
  "electricPurple": typed[i++],
  "electricPurple_-2": typed[i++],
  "electricPurple_-1": typed[i++],
  "electricPurple_+1": typed[i++],
  "electricPurple_+2": typed[i++],
  "lightViolet": typed[i++],
  "lightViolet_-2": typed[i++],
  "lightViolet_-1": typed[i++],
  "lightViolet_+1": typed[i++],
  "lightViolet_+2": typed[i++],
  "shockingPink": typed[i++],
  "shockingPink_-2": typed[i++],
  "shockingPink_-1": typed[i++],
  "shockingPink_+1": typed[i++],
  "shockingPink_+2": typed[i++],
  "firePink": typed[i++],
  "firePink_-2": typed[i++],
  "firePink_-1": typed[i++],
  "firePink_+1": typed[i++],
  "firePink_+2": typed[i++],
  "pink": typed[i++],
  "pink_-2": typed[i++],
  "pink_-1": typed[i++],
  "pink_+1": typed[i++],
  "pink_+2": typed[i++],
  "lightPink": typed[i++],
  "lightPink_-2": typed[i++],
  "lightPink_-1": typed[i++],
  "lightPink_+1": typed[i++],
  "lightPink_+2": typed[i++],
  "mahogany": typed[i++],
  "mahogany_-2": typed[i++],
  "mahogany_-1": typed[i++],
  "mahogany_+1": typed[i++],
  "mahogany_+2": typed[i++],
  "brown": typed[i++],
  "brown_-2": typed[i++],
  "brown_-1": typed[i++],
  "brown_+1": typed[i++],
  "brown_+2": typed[i++],
  "espresso": typed[i++],
  "espresso_-2": typed[i++],
  "espresso_-1": typed[i++],
  "espresso_+1": typed[i++],
  "espresso_+2": typed[i++],
  "cream": typed[i++],
  "cream_-1": typed[i++],
  "cream_+1": typed[i++],
  "cream_+2": typed[i++],
  "cream_+3": typed[i++],
  "coolGray": typed[i++]
} as const;

export { typed as COLORS };
