import * as _ from '@technically/lodash'

const getPropValue = (propValue: string) => {
  if (_.startsWith(propValue, '[') && _.endsWith(propValue, ']')) {
    return JSON.parse(propValue)
  }

  return propValue
}

export default getPropValue
