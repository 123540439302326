// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { BraidPartRow } from '../typings';

const data = [
  {
    "productId": "350771",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350773",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(0.5)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350785",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350786",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "isAdult ? in2px(2.25) : in2px(2.02)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350787",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(0.5)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350788",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(0.5)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350797",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(1.85)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350798",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(0.5)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350800",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(1.85)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350801",
    "pieceName": "back",
    "position": "neck",
    "from": "leftNeck",
    "to": "rightNeck",
    "offset": "in2px(0.5)",
    "curveEdges": true,
    "reverseColors": false
  },
  {
    "productId": "350773",
    "pieceName": "front",
    "position": "neck",
    "from": "neckRight",
    "to": "neckLeft",
    "offset": "in2px(0.5)",
    "radius": "in2px(0.42)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350787",
    "pieceName": "front",
    "position": "neck",
    "from": "neckRight",
    "to": "neckLeft",
    "offset": "in2px(0.5)",
    "radius": "in2px(0.42)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350788",
    "pieceName": "front",
    "position": "neck",
    "from": "neckRight",
    "to": "neckLeft",
    "offset": "in2px(0.5)",
    "radius": "in2px(0.42)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350798",
    "pieceName": "front",
    "position": "neck",
    "from": "neckRight",
    "to": "neckLeft",
    "offset": "in2px(0.5)",
    "radius": "in2px(0.42)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350801",
    "pieceName": "front",
    "position": "neck",
    "from": "neckRight",
    "to": "neckLeft",
    "offset": "in2px(0.5)",
    "radius": "in2px(0.42)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350771",
    "pieceName": "frontLeft",
    "position": "neck",
    "from": "rightBottom",
    "to": "neck",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350785",
    "pieceName": "frontLeft",
    "position": "neck",
    "from": "rightBottom",
    "to": "neck",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350786",
    "pieceName": "frontLeft",
    "position": "neck",
    "from": "rightBottom",
    "to": "neck",
    "offset": "isAdult ? in2px(2.25) : in2px(2.02)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350797",
    "pieceName": "frontLeft",
    "position": "neck",
    "from": "rightBottom",
    "to": "neck",
    "offset": "in2px(1.85)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350800",
    "pieceName": "frontLeft",
    "position": "neck",
    "from": "rightBottom",
    "to": "neck",
    "offset": "in2px(1.85)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350771",
    "pieceName": "frontRight",
    "position": "neck",
    "from": "neck",
    "to": "leftBottom",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350785",
    "pieceName": "frontRight",
    "position": "neck",
    "from": "neck",
    "to": "leftBottom",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350786",
    "pieceName": "frontRight",
    "position": "neck",
    "from": "neck",
    "to": "leftBottom",
    "offset": "isAdult ? in2px(2.25) : in2px(2.02)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350797",
    "pieceName": "frontRight",
    "position": "neck",
    "from": "neck",
    "to": "leftBottom",
    "offset": "in2px(1.85)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350800",
    "pieceName": "frontRight",
    "position": "neck",
    "from": "neck",
    "to": "leftBottom",
    "offset": "in2px(1.85)",
    "radius": "in2px(0.47)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350785",
    "pieceName": "sleeveLeft",
    "position": "neck",
    "from": "neckLeft",
    "to": "neckRight",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350788",
    "pieceName": "sleeveLeft",
    "position": "neck",
    "from": "neckLeft",
    "to": "neckRight",
    "offset": "in2px(0.5)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350785",
    "pieceName": "sleeveRight",
    "position": "neck",
    "from": "neckLeft",
    "to": "neckRight",
    "offset": "isAdult ? in2px(2.508) : in2px(2.02)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350788",
    "pieceName": "sleeveRight",
    "position": "neck",
    "from": "neckLeft",
    "to": "neckRight",
    "offset": "in2px(0.5)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350775",
    "pieceName": "backCollar",
    "position": "neck",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "h / 4 - t / 2",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350777",
    "pieceName": "backCollar",
    "position": "neck",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "h / 4 - t / 2",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350789",
    "pieceName": "backCollar",
    "position": "neck",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "h / 4 - t / 2",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350790",
    "pieceName": "backCollar",
    "position": "neck",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "h / 4 - t / 2",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350799",
    "pieceName": "backCollar",
    "position": "neck",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "h / 4 - t / 2",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350802",
    "pieceName": "backCollar",
    "position": "neck",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "h / 4 - t / 2",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350786",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armRightTop",
    "to": "armRightBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350787",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armRightTop",
    "to": "armRightBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350800",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armRightTop",
    "to": "armRightBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350801",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armRightTop",
    "to": "armRightBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350802",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armRightTop",
    "to": "armRightBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350786",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armLeftBottom",
    "to": "armLeftTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350787",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armLeftBottom",
    "to": "armLeftTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350800",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armLeftBottom",
    "to": "armLeftTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350801",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armLeftBottom",
    "to": "armLeftTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350802",
    "pieceName": "back",
    "position": "sleeve",
    "from": "armLeftBottom",
    "to": "armLeftTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350787",
    "pieceName": "front",
    "position": "sleeve",
    "from": "armLeftTop",
    "to": "armLeftBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350801",
    "pieceName": "front",
    "position": "sleeve",
    "from": "armLeftTop",
    "to": "armLeftBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350802",
    "pieceName": "front",
    "position": "sleeve",
    "from": "armLeftTop",
    "to": "armLeftBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350787",
    "pieceName": "front",
    "position": "sleeve",
    "from": "armRightBottom",
    "to": "armRightTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350801",
    "pieceName": "front",
    "position": "sleeve",
    "from": "armRightBottom",
    "to": "armRightTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350802",
    "pieceName": "front",
    "position": "sleeve",
    "from": "armRightBottom",
    "to": "armRightTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350786",
    "pieceName": "frontLeft",
    "position": "sleeve",
    "from": "armLeftTop",
    "to": "armLeftBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350800",
    "pieceName": "frontLeft",
    "position": "sleeve",
    "from": "armLeftTop",
    "to": "armLeftBottom",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350786",
    "pieceName": "frontRight",
    "position": "sleeve",
    "from": "armRightBottom",
    "to": "armRightTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350800",
    "pieceName": "frontRight",
    "position": "sleeve",
    "from": "armRightBottom",
    "to": "armRightTop",
    "offset": "in2px(0.75)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350771",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350773",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350775",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350777",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350785",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350788",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350789",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350790",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350797",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350798",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350799",
    "pieceName": "sleeveLeft",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350771",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350773",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350775",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350777",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350785",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350788",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350789",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350790",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350797",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350798",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350799",
    "pieceName": "sleeveRight",
    "position": "sleeve",
    "from": "rightBottom",
    "to": "leftBottom",
    "offset": "in2px(1.2)",
    "curveEdges": false,
    "reverseColors": true
  },
  {
    "productId": "350843",
    "pieceName": "pLeftFront",
    "position": "sideSeam",
    "from": "leftTop",
    "to": "leftBottom",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350845",
    "pieceName": "pLeftFront",
    "position": "sideSeam",
    "from": "leftTop",
    "to": "leftBottom",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350847",
    "pieceName": "pLeftFront",
    "position": "sideSeam",
    "from": "leftTop",
    "to": "leftBottom",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "351079",
    "pieceName": "pLeftFront",
    "position": "sideSeam",
    "from": "leftTop",
    "to": "leftBottom",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350843",
    "pieceName": "pRightFront",
    "position": "sideSeam",
    "from": "rightBottom",
    "to": "rightTop",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350845",
    "pieceName": "pRightFront",
    "position": "sideSeam",
    "from": "rightBottom",
    "to": "rightTop",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "350847",
    "pieceName": "pRightFront",
    "position": "sideSeam",
    "from": "rightBottom",
    "to": "rightTop",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  },
  {
    "productId": "351079",
    "pieceName": "pRightFront",
    "position": "sideSeam",
    "from": "rightBottom",
    "to": "rightTop",
    "offset": "in2px(0.25)",
    "curveEdges": false,
    "reverseColors": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<BraidPartRow[]>> = data;

export type BraidPart = typeof typed[number];

export { typed as BRAID_PARTS };
