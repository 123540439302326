// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { NavTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "product",
    "childId": "type",
    "name": "Product Filter",
    "propId": "filters.productType",
    "id": "product.type",
    "contentType": "TileSelectControl"
  },
  {
    "childId": "jersey",
    "name": "Jersey",
    "id": "jersey"
  },
  {
    "parentId": "jersey",
    "childId": "fabric",
    "name": "Fabric",
    "propId": "jersey.fabric",
    "id": "jersey.fabric",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "jersey",
    "childId": "design",
    "name": "Design",
    "id": "jersey.design"
  },
  {
    "parentId": "jersey.design",
    "childId": "design",
    "name": "Design",
    "propId": "jersey.design.design",
    "id": "jersey.design.design",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "jersey.design",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.design.color1",
    "id": "jersey.design.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.design",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.design.color2",
    "id": "jersey.design.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.design",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.design.color3",
    "id": "jersey.design.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.design",
    "childId": "file",
    "name": "File",
    "propId": "jersey.design.file",
    "id": "jersey.design.file",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "jersey",
    "childId": "body",
    "name": "Body",
    "id": "jersey.body"
  },
  {
    "parentId": "jersey.body",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.body.fill",
    "id": "jersey.body.fill",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "jersey.body",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.body.color1",
    "id": "jersey.body.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.body",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.body.color2",
    "id": "jersey.body.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.body",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.body.color3",
    "id": "jersey.body.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.body",
    "childId": "color4",
    "name": "Color 4",
    "propId": "jersey.body.color4",
    "id": "jersey.body.color4",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey",
    "childId": "sleeve",
    "name": "Sleeve",
    "id": "jersey.sleeve"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.sleeve.fill",
    "id": "jersey.sleeve.fill",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.sleeve.color1",
    "id": "jersey.sleeve.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.sleeve.color2",
    "id": "jersey.sleeve.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.sleeve.color3",
    "id": "jersey.sleeve.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color4",
    "name": "Color 4",
    "propId": "jersey.sleeve.color4",
    "id": "jersey.sleeve.color4",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey",
    "childId": "collar",
    "name": "Collar",
    "id": "jersey.collar"
  },
  {
    "parentId": "jersey.collar",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.collar.fill",
    "id": "jersey.collar.fill",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.collar.color1",
    "id": "jersey.collar.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.collar.color2",
    "id": "jersey.collar.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.collar.color3",
    "id": "jersey.collar.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color4",
    "name": "Color 4",
    "propId": "jersey.collar.color4",
    "id": "jersey.collar.color4",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey",
    "childId": "runbird",
    "name": "Mizuno Logo",
    "id": "jersey.runbird"
  },
  {
    "parentId": "jersey.runbird",
    "childId": "innerColor",
    "name": "Inner Color",
    "propId": "jersey.runbird.innerColor",
    "id": "jersey.runbird.innerColor",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.runbird",
    "childId": "outerColor",
    "name": "Outer Color",
    "propId": "jersey.runbird.outerColor",
    "id": "jersey.runbird.outerColor",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey",
    "childId": "braid",
    "name": "Braid",
    "id": "jersey.braid"
  },
  {
    "parentId": "jersey.braid",
    "childId": "position",
    "name": "Position",
    "propId": "jersey.braid.position",
    "id": "jersey.braid.position",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "jersey.braid",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.braid.color1",
    "id": "jersey.braid.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.braid",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.braid.color2",
    "id": "jersey.braid.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "jersey.braid",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.braid.color3",
    "id": "jersey.braid.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "childId": "pants",
    "name": "Pants",
    "id": "pants"
  },
  {
    "parentId": "pants",
    "childId": "fabric",
    "name": "Fabric",
    "propId": "pants.fabric",
    "id": "pants.fabric",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "pants",
    "childId": "design",
    "name": "Design",
    "id": "pants.design"
  },
  {
    "parentId": "pants.design",
    "childId": "design",
    "name": "Design",
    "propId": "pants.design.design",
    "id": "pants.design.design",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "pants.design",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.design.color1",
    "id": "pants.design.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.design",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.design.color2",
    "id": "pants.design.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.design",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.design.color3",
    "id": "pants.design.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.design",
    "childId": "file",
    "name": "File",
    "propId": "pants.design.file",
    "id": "pants.design.file",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "pants",
    "childId": "body",
    "name": "Body",
    "id": "pants.body"
  },
  {
    "parentId": "pants.body",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.body.fill",
    "id": "pants.body.fill",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "pants.body",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.body.color1",
    "id": "pants.body.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.body",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.body.color2",
    "id": "pants.body.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.body",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.body.color3",
    "id": "pants.body.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.body",
    "childId": "color4",
    "name": "Color 4",
    "propId": "pants.body.color4",
    "id": "pants.body.color4",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants",
    "childId": "beltLoop",
    "name": "Belt Loop",
    "id": "pants.beltLoop"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.beltLoop.fill",
    "id": "pants.beltLoop.fill",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.beltLoop.color1",
    "id": "pants.beltLoop.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.beltLoop.color2",
    "id": "pants.beltLoop.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.beltLoop.color3",
    "id": "pants.beltLoop.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color4",
    "name": "Color 4",
    "propId": "pants.beltLoop.color4",
    "id": "pants.beltLoop.color4",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants",
    "childId": "runbird",
    "name": "Mizuno Logo",
    "id": "pants.runbird"
  },
  {
    "parentId": "pants.runbird",
    "childId": "innerColor",
    "name": "Inner Color",
    "propId": "pants.runbird.innerColor",
    "id": "pants.runbird.innerColor",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.runbird",
    "childId": "outerColor",
    "name": "Outer Color",
    "propId": "pants.runbird.outerColor",
    "id": "pants.runbird.outerColor",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants",
    "childId": "braid",
    "name": "Braid",
    "id": "pants.braid"
  },
  {
    "parentId": "pants.braid",
    "childId": "position",
    "name": "Position",
    "propId": "pants.braid.position",
    "id": "pants.braid.position",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "pants.braid",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.braid.color1",
    "id": "pants.braid.color1",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.braid",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.braid.color2",
    "id": "pants.braid.color2",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "parentId": "pants.braid",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.braid.color3",
    "id": "pants.braid.color3",
    "contentType": "ColorSelectWithTonesControl"
  },
  {
    "childId": "details",
    "name": "Roster",
    "id": "details"
  },
  {
    "parentId": "details",
    "childId": "designName",
    "name": "Design Name",
    "propId": "details.recipeName.text",
    "id": "details.designName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "details",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "details.teamName.text",
    "id": "details.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "details",
    "childId": "roster",
    "name": "Team Roster",
    "id": "details.roster",
    "contentType": "RosterControl"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.type": typed[i++],
  "jersey": typed[i++],
  "jersey.fabric": typed[i++],
  "jersey.design": typed[i++],
  "jersey.design.design": typed[i++],
  "jersey.design.color1": typed[i++],
  "jersey.design.color2": typed[i++],
  "jersey.design.color3": typed[i++],
  "jersey.design.file": typed[i++],
  "jersey.body": typed[i++],
  "jersey.body.fill": typed[i++],
  "jersey.body.color1": typed[i++],
  "jersey.body.color2": typed[i++],
  "jersey.body.color3": typed[i++],
  "jersey.body.color4": typed[i++],
  "jersey.sleeve": typed[i++],
  "jersey.sleeve.fill": typed[i++],
  "jersey.sleeve.color1": typed[i++],
  "jersey.sleeve.color2": typed[i++],
  "jersey.sleeve.color3": typed[i++],
  "jersey.sleeve.color4": typed[i++],
  "jersey.collar": typed[i++],
  "jersey.collar.fill": typed[i++],
  "jersey.collar.color1": typed[i++],
  "jersey.collar.color2": typed[i++],
  "jersey.collar.color3": typed[i++],
  "jersey.collar.color4": typed[i++],
  "jersey.runbird": typed[i++],
  "jersey.runbird.innerColor": typed[i++],
  "jersey.runbird.outerColor": typed[i++],
  "jersey.braid": typed[i++],
  "jersey.braid.position": typed[i++],
  "jersey.braid.color1": typed[i++],
  "jersey.braid.color2": typed[i++],
  "jersey.braid.color3": typed[i++],
  "pants": typed[i++],
  "pants.fabric": typed[i++],
  "pants.design": typed[i++],
  "pants.design.design": typed[i++],
  "pants.design.color1": typed[i++],
  "pants.design.color2": typed[i++],
  "pants.design.color3": typed[i++],
  "pants.design.file": typed[i++],
  "pants.body": typed[i++],
  "pants.body.fill": typed[i++],
  "pants.body.color1": typed[i++],
  "pants.body.color2": typed[i++],
  "pants.body.color3": typed[i++],
  "pants.body.color4": typed[i++],
  "pants.beltLoop": typed[i++],
  "pants.beltLoop.fill": typed[i++],
  "pants.beltLoop.color1": typed[i++],
  "pants.beltLoop.color2": typed[i++],
  "pants.beltLoop.color3": typed[i++],
  "pants.beltLoop.color4": typed[i++],
  "pants.runbird": typed[i++],
  "pants.runbird.innerColor": typed[i++],
  "pants.runbird.outerColor": typed[i++],
  "pants.braid": typed[i++],
  "pants.braid.position": typed[i++],
  "pants.braid.color1": typed[i++],
  "pants.braid.color2": typed[i++],
  "pants.braid.color3": typed[i++],
  "details": typed[i++],
  "details.designName": typed[i++],
  "details.teamName": typed[i++],
  "details.roster": typed[i++]
} as const;

export { typed as NAV_TREE };
