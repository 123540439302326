// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PlacementDecorationRow } from '../typings';

const data = [
  {
    "id": "350771-frontChest",
    "name": "350771-frontChest",
    "productId": "350771",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350771-upperFrontLeftChest",
    "name": "350771-upperFrontLeftChest",
    "productId": "350771",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350771-frontMiddleLeft",
    "name": "350771-frontMiddleLeft",
    "productId": "350771",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350771-frontMiddleRight",
    "name": "350771-frontMiddleRight",
    "productId": "350771",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350771-upperBack",
    "name": "350771-upperBack",
    "productId": "350771",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350771-middleBack",
    "name": "350771-middleBack",
    "productId": "350771",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350771-lowerBack",
    "name": "350771-lowerBack",
    "productId": "350771",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350771-leftSleeve",
    "name": "350771-leftSleeve",
    "productId": "350771",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350771-rightSleeve",
    "name": "350771-rightSleeve",
    "productId": "350771",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350773-frontChest",
    "name": "350773-frontChest",
    "productId": "350773",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350773-upperFrontLeftChest",
    "name": "350773-upperFrontLeftChest",
    "productId": "350773",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350773-frontMiddleLeft",
    "name": "350773-frontMiddleLeft",
    "productId": "350773",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350773-frontMiddleRight",
    "name": "350773-frontMiddleRight",
    "productId": "350773",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350773-upperBack",
    "name": "350773-upperBack",
    "productId": "350773",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350773-middleBack",
    "name": "350773-middleBack",
    "productId": "350773",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350773-lowerBack",
    "name": "350773-lowerBack",
    "productId": "350773",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350773-leftSleeve",
    "name": "350773-leftSleeve",
    "productId": "350773",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350773-rightSleeve",
    "name": "350773-rightSleeve",
    "productId": "350773",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350775-frontChest",
    "name": "350775-frontChest",
    "productId": "350775",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350775-upperFrontLeftChest",
    "name": "350775-upperFrontLeftChest",
    "productId": "350775",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350775-frontMiddleLeft",
    "name": "350775-frontMiddleLeft",
    "productId": "350775",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350775-frontMiddleRight",
    "name": "350775-frontMiddleRight",
    "productId": "350775",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350775-upperBack",
    "name": "350775-upperBack",
    "productId": "350775",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350775-middleBack",
    "name": "350775-middleBack",
    "productId": "350775",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350775-lowerBack",
    "name": "350775-lowerBack",
    "productId": "350775",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350775-leftSleeve",
    "name": "350775-leftSleeve",
    "productId": "350775",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350775-rightSleeve",
    "name": "350775-rightSleeve",
    "productId": "350775",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350777-frontChest",
    "name": "350777-frontChest",
    "productId": "350777",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350777-upperFrontLeftChest",
    "name": "350777-upperFrontLeftChest",
    "productId": "350777",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350777-frontMiddleLeft",
    "name": "350777-frontMiddleLeft",
    "productId": "350777",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350777-frontMiddleRight",
    "name": "350777-frontMiddleRight",
    "productId": "350777",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350777-upperBack",
    "name": "350777-upperBack",
    "productId": "350777",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350777-middleBack",
    "name": "350777-middleBack",
    "productId": "350777",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350777-lowerBack",
    "name": "350777-lowerBack",
    "productId": "350777",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350777-leftSleeve",
    "name": "350777-leftSleeve",
    "productId": "350777",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350777-rightSleeve",
    "name": "350777-rightSleeve",
    "productId": "350777",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350785-frontChest",
    "name": "350785-frontChest",
    "productId": "350785",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350785-upperFrontLeftChest",
    "name": "350785-upperFrontLeftChest",
    "productId": "350785",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350785-frontMiddleLeft",
    "name": "350785-frontMiddleLeft",
    "productId": "350785",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350785-frontMiddleRight",
    "name": "350785-frontMiddleRight",
    "productId": "350785",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350785-upperBack",
    "name": "350785-upperBack",
    "productId": "350785",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350785-middleBack",
    "name": "350785-middleBack",
    "productId": "350785",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350785-lowerBack",
    "name": "350785-lowerBack",
    "productId": "350785",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350785-leftSleeve",
    "name": "350785-leftSleeve",
    "productId": "350785",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350785-rightSleeve",
    "name": "350785-rightSleeve",
    "productId": "350785",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350786-frontChest",
    "name": "350786-frontChest",
    "productId": "350786",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350786-upperFrontLeftChest",
    "name": "350786-upperFrontLeftChest",
    "productId": "350786",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350786-frontMiddleLeft",
    "name": "350786-frontMiddleLeft",
    "productId": "350786",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350786-frontMiddleRight",
    "name": "350786-frontMiddleRight",
    "productId": "350786",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350786-upperBack",
    "name": "350786-upperBack",
    "productId": "350786",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350786-middleBack",
    "name": "350786-middleBack",
    "productId": "350786",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350786-lowerBack",
    "name": "350786-lowerBack",
    "productId": "350786",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350787-frontChest",
    "name": "350787-frontChest",
    "productId": "350787",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350787-upperFrontLeftChest",
    "name": "350787-upperFrontLeftChest",
    "productId": "350787",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350787-frontMiddle",
    "name": "350787-frontMiddle",
    "productId": "350787",
    "placementId": "frontMiddle",
    "decorationIds": [
      "playerNumber",
      "customLogo"
    ]
  },
  {
    "id": "350787-frontMiddleLeft",
    "name": "350787-frontMiddleLeft",
    "productId": "350787",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350787-frontMiddleRight",
    "name": "350787-frontMiddleRight",
    "productId": "350787",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350787-upperBack",
    "name": "350787-upperBack",
    "productId": "350787",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350787-middleBack",
    "name": "350787-middleBack",
    "productId": "350787",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350787-lowerBack",
    "name": "350787-lowerBack",
    "productId": "350787",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350788-frontChest",
    "name": "350788-frontChest",
    "productId": "350788",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350788-upperFrontLeftChest",
    "name": "350788-upperFrontLeftChest",
    "productId": "350788",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350788-frontMiddleLeft",
    "name": "350788-frontMiddleLeft",
    "productId": "350788",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350788-frontMiddleRight",
    "name": "350788-frontMiddleRight",
    "productId": "350788",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350788-upperBack",
    "name": "350788-upperBack",
    "productId": "350788",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350788-middleBack",
    "name": "350788-middleBack",
    "productId": "350788",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350788-lowerBack",
    "name": "350788-lowerBack",
    "productId": "350788",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350788-leftSleeve",
    "name": "350788-leftSleeve",
    "productId": "350788",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350788-rightSleeve",
    "name": "350788-rightSleeve",
    "productId": "350788",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350789-frontChest",
    "name": "350789-frontChest",
    "productId": "350789",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350789-upperFrontLeftChest",
    "name": "350789-upperFrontLeftChest",
    "productId": "350789",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350789-frontMiddleLeft",
    "name": "350789-frontMiddleLeft",
    "productId": "350789",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350789-frontMiddleRight",
    "name": "350789-frontMiddleRight",
    "productId": "350789",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350789-upperBack",
    "name": "350789-upperBack",
    "productId": "350789",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350789-middleBack",
    "name": "350789-middleBack",
    "productId": "350789",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350789-lowerBack",
    "name": "350789-lowerBack",
    "productId": "350789",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350789-leftSleeve",
    "name": "350789-leftSleeve",
    "productId": "350789",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350789-rightSleeve",
    "name": "350789-rightSleeve",
    "productId": "350789",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350790-frontChest",
    "name": "350790-frontChest",
    "productId": "350790",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350790-upperFrontLeftChest",
    "name": "350790-upperFrontLeftChest",
    "productId": "350790",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350790-frontMiddleLeft",
    "name": "350790-frontMiddleLeft",
    "productId": "350790",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350790-frontMiddleRight",
    "name": "350790-frontMiddleRight",
    "productId": "350790",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350790-upperBack",
    "name": "350790-upperBack",
    "productId": "350790",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350790-middleBack",
    "name": "350790-middleBack",
    "productId": "350790",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350790-lowerBack",
    "name": "350790-lowerBack",
    "productId": "350790",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350790-leftSleeve",
    "name": "350790-leftSleeve",
    "productId": "350790",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350790-rightSleeve",
    "name": "350790-rightSleeve",
    "productId": "350790",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350797-frontChest",
    "name": "350797-frontChest",
    "productId": "350797",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350797-upperFrontLeftChest",
    "name": "350797-upperFrontLeftChest",
    "productId": "350797",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350797-frontMiddleLeft",
    "name": "350797-frontMiddleLeft",
    "productId": "350797",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350797-frontMiddleRight",
    "name": "350797-frontMiddleRight",
    "productId": "350797",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350797-upperBack",
    "name": "350797-upperBack",
    "productId": "350797",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350797-middleBack",
    "name": "350797-middleBack",
    "productId": "350797",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350797-lowerBack",
    "name": "350797-lowerBack",
    "productId": "350797",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350797-leftSleeve",
    "name": "350797-leftSleeve",
    "productId": "350797",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350797-rightSleeve",
    "name": "350797-rightSleeve",
    "productId": "350797",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350798-frontChest",
    "name": "350798-frontChest",
    "productId": "350798",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350798-upperFrontLeftChest",
    "name": "350798-upperFrontLeftChest",
    "productId": "350798",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350798-frontMiddle",
    "name": "350798-frontMiddle",
    "productId": "350798",
    "placementId": "frontMiddle",
    "decorationIds": [
      "playerNumber",
      "customLogo"
    ]
  },
  {
    "id": "350798-frontMiddleLeft",
    "name": "350798-frontMiddleLeft",
    "productId": "350798",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350798-frontMiddleRight",
    "name": "350798-frontMiddleRight",
    "productId": "350798",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350798-upperBack",
    "name": "350798-upperBack",
    "productId": "350798",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350798-middleBack",
    "name": "350798-middleBack",
    "productId": "350798",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350798-lowerBack",
    "name": "350798-lowerBack",
    "productId": "350798",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350798-leftSleeve",
    "name": "350798-leftSleeve",
    "productId": "350798",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350798-rightSleeve",
    "name": "350798-rightSleeve",
    "productId": "350798",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350799-frontChest",
    "name": "350799-frontChest",
    "productId": "350799",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350799-upperFrontLeftChest",
    "name": "350799-upperFrontLeftChest",
    "productId": "350799",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350799-frontMiddle",
    "name": "350799-frontMiddle",
    "productId": "350799",
    "placementId": "frontMiddle",
    "decorationIds": [
      "playerNumber",
      "customLogo"
    ]
  },
  {
    "id": "350799-frontMiddleLeft",
    "name": "350799-frontMiddleLeft",
    "productId": "350799",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350799-frontMiddleRight",
    "name": "350799-frontMiddleRight",
    "productId": "350799",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350799-upperBack",
    "name": "350799-upperBack",
    "productId": "350799",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350799-middleBack",
    "name": "350799-middleBack",
    "productId": "350799",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350799-lowerBack",
    "name": "350799-lowerBack",
    "productId": "350799",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350799-leftSleeve",
    "name": "350799-leftSleeve",
    "productId": "350799",
    "placementId": "leftSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350799-rightSleeve",
    "name": "350799-rightSleeve",
    "productId": "350799",
    "placementId": "rightSleeve",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350800-frontChest",
    "name": "350800-frontChest",
    "productId": "350800",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350800-frontMiddleLeft",
    "name": "350800-frontMiddleLeft",
    "productId": "350800",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350800-frontMiddleRight",
    "name": "350800-frontMiddleRight",
    "productId": "350800",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350800-upperBack",
    "name": "350800-upperBack",
    "productId": "350800",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350800-middleBack",
    "name": "350800-middleBack",
    "productId": "350800",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350800-lowerBack",
    "name": "350800-lowerBack",
    "productId": "350800",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350801-frontChest",
    "name": "350801-frontChest",
    "productId": "350801",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350801-upperFrontLeftChest",
    "name": "350801-upperFrontLeftChest",
    "productId": "350801",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350801-frontMiddle",
    "name": "350801-frontMiddle",
    "productId": "350801",
    "placementId": "frontMiddle",
    "decorationIds": [
      "playerNumber",
      "customLogo"
    ]
  },
  {
    "id": "350801-frontMiddleLeft",
    "name": "350801-frontMiddleLeft",
    "productId": "350801",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350801-frontMiddleRight",
    "name": "350801-frontMiddleRight",
    "productId": "350801",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350801-upperBack",
    "name": "350801-upperBack",
    "productId": "350801",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350801-middleBack",
    "name": "350801-middleBack",
    "productId": "350801",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350801-lowerBack",
    "name": "350801-lowerBack",
    "productId": "350801",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350802-frontChest",
    "name": "350802-frontChest",
    "productId": "350802",
    "placementId": "frontChest",
    "decorationIds": [
      "teamName",
      "customLogo"
    ]
  },
  {
    "id": "350802-upperFrontLeftChest",
    "name": "350802-upperFrontLeftChest",
    "productId": "350802",
    "placementId": "upperFrontLeftChest",
    "decorationIds": [
      "customLogo"
    ]
  },
  {
    "id": "350802-frontMiddle",
    "name": "350802-frontMiddle",
    "productId": "350802",
    "placementId": "frontMiddle",
    "decorationIds": [
      "playerNumber",
      "customLogo"
    ]
  },
  {
    "id": "350802-frontMiddleLeft",
    "name": "350802-frontMiddleLeft",
    "productId": "350802",
    "placementId": "frontMiddleLeft",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350802-frontMiddleRight",
    "name": "350802-frontMiddleRight",
    "productId": "350802",
    "placementId": "frontMiddleRight",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350802-upperBack",
    "name": "350802-upperBack",
    "productId": "350802",
    "placementId": "upperBack",
    "decorationIds": [
      "playerName",
      "customLogo"
    ]
  },
  {
    "id": "350802-middleBack",
    "name": "350802-middleBack",
    "productId": "350802",
    "placementId": "middleBack",
    "decorationIds": [
      "playerNumber"
    ]
  },
  {
    "id": "350802-lowerBack",
    "name": "350802-lowerBack",
    "productId": "350802",
    "placementId": "lowerBack",
    "decorationIds": [
      "teamName"
    ]
  },
  {
    "id": "350843-backBeltLoop",
    "name": "350843-backBeltLoop",
    "productId": "350843",
    "placementId": "backBeltLoop",
    "decorationIds": [
      "customLogo",
      "playerNumber",
      "teamName"
    ]
  },
  {
    "id": "350843-leftLeg",
    "name": "350843-leftLeg",
    "productId": "350843",
    "placementId": "leftLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "350843-rightLeg",
    "name": "350843-rightLeg",
    "productId": "350843",
    "placementId": "rightLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "350845-backBeltLoop",
    "name": "350845-backBeltLoop",
    "productId": "350845",
    "placementId": "backBeltLoop",
    "decorationIds": [
      "customLogo",
      "playerNumber",
      "teamName"
    ]
  },
  {
    "id": "350845-leftLeg",
    "name": "350845-leftLeg",
    "productId": "350845",
    "placementId": "leftLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "350845-rightLeg",
    "name": "350845-rightLeg",
    "productId": "350845",
    "placementId": "rightLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "350847-backBeltLoop",
    "name": "350847-backBeltLoop",
    "productId": "350847",
    "placementId": "backBeltLoop",
    "decorationIds": [
      "customLogo",
      "playerNumber",
      "teamName"
    ]
  },
  {
    "id": "350847-leftLeg",
    "name": "350847-leftLeg",
    "productId": "350847",
    "placementId": "leftLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "350847-rightLeg",
    "name": "350847-rightLeg",
    "productId": "350847",
    "placementId": "rightLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "351079-backBeltLoop",
    "name": "351079-backBeltLoop",
    "productId": "351079",
    "placementId": "backBeltLoop",
    "decorationIds": [
      "customLogo",
      "playerNumber",
      "teamName"
    ]
  },
  {
    "id": "351079-leftLeg",
    "name": "351079-leftLeg",
    "productId": "351079",
    "placementId": "leftLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  },
  {
    "id": "351079-rightLeg",
    "name": "351079-rightLeg",
    "productId": "351079",
    "placementId": "rightLeg",
    "decorationIds": [
      "customLogo",
      "playerNumber"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PlacementDecorationRow[]>> = data;

export type PlacementDecoration = typeof typed[number];

export const PLACEMENT_DECORATION_INDEX_KEY = "id";
export type PlacementDecorationIndexKey = "id";
export type PlacementDecorationId = PlacementDecoration["id"];

let i = 0;
export const PLACEMENT_DECORATION_DICT = {
  "350771-frontChest": typed[i++],
  "350771-upperFrontLeftChest": typed[i++],
  "350771-frontMiddleLeft": typed[i++],
  "350771-frontMiddleRight": typed[i++],
  "350771-upperBack": typed[i++],
  "350771-middleBack": typed[i++],
  "350771-lowerBack": typed[i++],
  "350771-leftSleeve": typed[i++],
  "350771-rightSleeve": typed[i++],
  "350773-frontChest": typed[i++],
  "350773-upperFrontLeftChest": typed[i++],
  "350773-frontMiddleLeft": typed[i++],
  "350773-frontMiddleRight": typed[i++],
  "350773-upperBack": typed[i++],
  "350773-middleBack": typed[i++],
  "350773-lowerBack": typed[i++],
  "350773-leftSleeve": typed[i++],
  "350773-rightSleeve": typed[i++],
  "350775-frontChest": typed[i++],
  "350775-upperFrontLeftChest": typed[i++],
  "350775-frontMiddleLeft": typed[i++],
  "350775-frontMiddleRight": typed[i++],
  "350775-upperBack": typed[i++],
  "350775-middleBack": typed[i++],
  "350775-lowerBack": typed[i++],
  "350775-leftSleeve": typed[i++],
  "350775-rightSleeve": typed[i++],
  "350777-frontChest": typed[i++],
  "350777-upperFrontLeftChest": typed[i++],
  "350777-frontMiddleLeft": typed[i++],
  "350777-frontMiddleRight": typed[i++],
  "350777-upperBack": typed[i++],
  "350777-middleBack": typed[i++],
  "350777-lowerBack": typed[i++],
  "350777-leftSleeve": typed[i++],
  "350777-rightSleeve": typed[i++],
  "350785-frontChest": typed[i++],
  "350785-upperFrontLeftChest": typed[i++],
  "350785-frontMiddleLeft": typed[i++],
  "350785-frontMiddleRight": typed[i++],
  "350785-upperBack": typed[i++],
  "350785-middleBack": typed[i++],
  "350785-lowerBack": typed[i++],
  "350785-leftSleeve": typed[i++],
  "350785-rightSleeve": typed[i++],
  "350786-frontChest": typed[i++],
  "350786-upperFrontLeftChest": typed[i++],
  "350786-frontMiddleLeft": typed[i++],
  "350786-frontMiddleRight": typed[i++],
  "350786-upperBack": typed[i++],
  "350786-middleBack": typed[i++],
  "350786-lowerBack": typed[i++],
  "350787-frontChest": typed[i++],
  "350787-upperFrontLeftChest": typed[i++],
  "350787-frontMiddle": typed[i++],
  "350787-frontMiddleLeft": typed[i++],
  "350787-frontMiddleRight": typed[i++],
  "350787-upperBack": typed[i++],
  "350787-middleBack": typed[i++],
  "350787-lowerBack": typed[i++],
  "350788-frontChest": typed[i++],
  "350788-upperFrontLeftChest": typed[i++],
  "350788-frontMiddleLeft": typed[i++],
  "350788-frontMiddleRight": typed[i++],
  "350788-upperBack": typed[i++],
  "350788-middleBack": typed[i++],
  "350788-lowerBack": typed[i++],
  "350788-leftSleeve": typed[i++],
  "350788-rightSleeve": typed[i++],
  "350789-frontChest": typed[i++],
  "350789-upperFrontLeftChest": typed[i++],
  "350789-frontMiddleLeft": typed[i++],
  "350789-frontMiddleRight": typed[i++],
  "350789-upperBack": typed[i++],
  "350789-middleBack": typed[i++],
  "350789-lowerBack": typed[i++],
  "350789-leftSleeve": typed[i++],
  "350789-rightSleeve": typed[i++],
  "350790-frontChest": typed[i++],
  "350790-upperFrontLeftChest": typed[i++],
  "350790-frontMiddleLeft": typed[i++],
  "350790-frontMiddleRight": typed[i++],
  "350790-upperBack": typed[i++],
  "350790-middleBack": typed[i++],
  "350790-lowerBack": typed[i++],
  "350790-leftSleeve": typed[i++],
  "350790-rightSleeve": typed[i++],
  "350797-frontChest": typed[i++],
  "350797-upperFrontLeftChest": typed[i++],
  "350797-frontMiddleLeft": typed[i++],
  "350797-frontMiddleRight": typed[i++],
  "350797-upperBack": typed[i++],
  "350797-middleBack": typed[i++],
  "350797-lowerBack": typed[i++],
  "350797-leftSleeve": typed[i++],
  "350797-rightSleeve": typed[i++],
  "350798-frontChest": typed[i++],
  "350798-upperFrontLeftChest": typed[i++],
  "350798-frontMiddle": typed[i++],
  "350798-frontMiddleLeft": typed[i++],
  "350798-frontMiddleRight": typed[i++],
  "350798-upperBack": typed[i++],
  "350798-middleBack": typed[i++],
  "350798-lowerBack": typed[i++],
  "350798-leftSleeve": typed[i++],
  "350798-rightSleeve": typed[i++],
  "350799-frontChest": typed[i++],
  "350799-upperFrontLeftChest": typed[i++],
  "350799-frontMiddle": typed[i++],
  "350799-frontMiddleLeft": typed[i++],
  "350799-frontMiddleRight": typed[i++],
  "350799-upperBack": typed[i++],
  "350799-middleBack": typed[i++],
  "350799-lowerBack": typed[i++],
  "350799-leftSleeve": typed[i++],
  "350799-rightSleeve": typed[i++],
  "350800-frontChest": typed[i++],
  "350800-frontMiddleLeft": typed[i++],
  "350800-frontMiddleRight": typed[i++],
  "350800-upperBack": typed[i++],
  "350800-middleBack": typed[i++],
  "350800-lowerBack": typed[i++],
  "350801-frontChest": typed[i++],
  "350801-upperFrontLeftChest": typed[i++],
  "350801-frontMiddle": typed[i++],
  "350801-frontMiddleLeft": typed[i++],
  "350801-frontMiddleRight": typed[i++],
  "350801-upperBack": typed[i++],
  "350801-middleBack": typed[i++],
  "350801-lowerBack": typed[i++],
  "350802-frontChest": typed[i++],
  "350802-upperFrontLeftChest": typed[i++],
  "350802-frontMiddle": typed[i++],
  "350802-frontMiddleLeft": typed[i++],
  "350802-frontMiddleRight": typed[i++],
  "350802-upperBack": typed[i++],
  "350802-middleBack": typed[i++],
  "350802-lowerBack": typed[i++],
  "350843-backBeltLoop": typed[i++],
  "350843-leftLeg": typed[i++],
  "350843-rightLeg": typed[i++],
  "350845-backBeltLoop": typed[i++],
  "350845-leftLeg": typed[i++],
  "350845-rightLeg": typed[i++],
  "350847-backBeltLoop": typed[i++],
  "350847-leftLeg": typed[i++],
  "350847-rightLeg": typed[i++],
  "351079-backBeltLoop": typed[i++],
  "351079-leftLeg": typed[i++],
  "351079-rightLeg": typed[i++]
} as const;

export { typed as PLACEMENT_DECORATIONS };
