import { createSelector } from 'reselect'

import { State } from './updater'
import { querySelector } from '../../../platform/client/common/selectors'

export const selectIsRosterApproved = (state: State) => state.isRosterApproved
export const selectAreUploadColorsApproved = (state: State) =>
  state.areUploadColorsApproved
export const selectIsFinalApproved = (state: State) => state.isFinalApproved
export const selectIsApproved = createSelector(
  selectIsRosterApproved,
  selectAreUploadColorsApproved,
  selectIsFinalApproved,
  (a, b, c) => a && b && c,
)

const selectSearch = (state: State) => state.history.location.search
const selectSearchParams = createSelector(
  selectSearch,
  (search) => new URLSearchParams(search),
)

export const APPROVAL_ERROR_PARAM = 'showApprovalError'
export const selectShowApprovalError = createSelector(
  selectSearchParams,
  (searchParams) => searchParams.has(APPROVAL_ERROR_PARAM),
)

export const rosterPreviewIdSelector = (state: State) => {
  // Roster IDs are stored as numbers in CT
  const requestedRosterPreviewId = +(querySelector(state)
    .rosterPreview as string)
  const rosterIds = state.controlTree.repeatedNodes['details.roster']
  // toString because CT paths are strings
  return (
    requestedRosterPreviewId && rosterIds.includes(requestedRosterPreviewId) ?
      requestedRosterPreviewId
      // It's guaranteed that there's at least 1 item
    : Math.min(...rosterIds)).toString()
}
