import { useSelector } from 'react-redux'

import InfoBox from '../../../_mizuno/client/components/InfoBox'
import {
  setAreUploadColorsApproved,
  setIsFinalApproved,
  setIsRosterApproved,
} from '../actions'
import {
  selectAreUploadColorsApproved,
  selectIsApproved,
  selectIsFinalApproved,
  selectIsRosterApproved,
  selectShowApprovalError,
} from '../selectors'
import { useAppDispatch } from '../../../../platform/client/configureStore'

import './Approval.css'

const ApprovalItem = ({
  selector,
  actionCreator,
  children,
}: {
  selector: (state: any) => boolean
  actionCreator: (payload: boolean) => any
  children: React.ReactNode
}) => {
  const checked = useSelector(selector)
  const dispatch = useAppDispatch()
  return (
    <label className="Approval-item">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => dispatch(actionCreator(!checked))}
      />
      <span>
        {children}
        <span className="Approval-asterisk">*</span>
      </span>
    </label>
  )
}

const Approval = () => {
  const isApproved = useSelector(selectIsApproved)
  const showApprovalError = useSelector(selectShowApprovalError)
  return (
    <div className="Approval">
      <ApprovalItem
        selector={selectIsRosterApproved}
        actionCreator={setIsRosterApproved}
      >
        I confirm that all roster fields are accurate.
      </ApprovalItem>
      <ApprovalItem
        selector={selectAreUploadColorsApproved}
        actionCreator={setAreUploadColorsApproved}
      >
        I confirm that I have the legal right to use all uploaded images (if
        any). I also confirm the colors of all uploaded images as is. If you
        need help adjusting the colors of your images to match your uniform
        colors please contact your Mizuno representative before submitting your
        order.
      </ApprovalItem>
      <ApprovalItem
        selector={selectIsFinalApproved}
        actionCreator={setIsFinalApproved}
      >
        This is my <b>final</b> approval of all aspects of the uniform,
        including colors, patterns, decoration positions and dimensions,
        spelling, etcetera. Once submitted for order entry, no revisions can be
        made and Mizuno is not responsible for replacing any uniforms.
      </ApprovalItem>
      {!isApproved && showApprovalError && (
        <InfoBox type="error">
          Please read and confirm all of the above.
        </InfoBox>
      )}
    </div>
  )
}

export default Approval
