import * as _ from '@technically/lodash'
import { Fragment } from 'react'

import { push } from '../../../../platform/client/history'

import Roster from '../../../_mizuno/client/components/atoms/Roster'
import RosterRow from '../../../_mizuno/client/components/atoms/RosterRow'
import RosterAdd from '../../../_mizuno/client/components/atoms/RosterAdd'
import Icon from '../../../_mizuno/client/components/atoms/Icon'
import Button from '../../../_mizuno/client/components/atoms/Button'

import {
  SelectInputControl,
  TextInputControl,
} from '../../../_mizuno/client/components/Sidebar'

import RosterUpload from './RosterUpload'

import './Roster.css'

type Props = {
  add: () => void
  controlIds: number[]
  isPreviewEnabled: boolean
  isRemovingDisabled: boolean
  isRosterErrorVisible: boolean
  isRosterValid: boolean
  previewId: string
  remove: (id: number) => void
  visibleSizes: { path: string; title: string }[]
  setRosterValues: (values: Record<string, string>) => void
}

const ApparelsRoster = (props: Props) => {
  const {
    visibleSizes,
    isPreviewEnabled,
    controlIds,
    previewId,
    isRemovingDisabled,
    isRosterErrorVisible,
    isRosterValid,
    remove,
    add,
    setRosterValues,
  } = props

  let rosterError = ''
  if (isRosterErrorVisible) {
    if (!isRosterValid) {
      rosterError = `Please select the garment size${
        visibleSizes.length > 1 || controlIds.length > 1 ? 's' : ''
      }!`
    }
  }

  return (
    <Fragment>
      <Roster
        classMods={[
          visibleSizes.length === 2 && 'twoSizes',
          isPreviewEnabled && 'withPreview',
          'flex',
        ].filter(Boolean)}
      >
        {_.map(controlIds, (id) => (
          <RosterRow key={id}>
            <td>
              <table>
                <tbody>
                  <tr className="roster-row-section">
                    {isPreviewEnabled && (
                      <td className="roster-cell roster-cell-preview">
                        <span className="roster-cell-input-label">&nbsp;</span>
                        <Button
                          classMods={[
                            'action',
                            String(id) === previewId ?
                              'rosterPreviewActive'
                            : 'rosterPreview',
                          ]}
                          onClick={() => {
                            push({ query: { rosterPreview: id } })
                          }}
                        >
                          <Icon name="eye" />
                        </Button>
                      </td>
                    )}
                    {visibleSizes.map((x) => (
                      <td className="roster-cell roster-cell-size" key={x.path}>
                        <span className="roster-cell-input-label">
                          {x.title}
                        </span>
                        <SelectInputControl
                          path={`details.roster.${id}.${x.path}`}
                          classMods={['fullBorder', 'fullWidth']}
                        />
                      </td>
                    ))}
                    <td className="roster-cell roster-cell-quantity">
                      <span className="roster-cell-input-label">Quantity</span>
                      <TextInputControl
                        path={`details.roster.${id}.quantity`}
                        classMods={['fullBorder']}
                      />
                    </td>
                    <td className="roster-cell roster-cell-number">
                      <span className="roster-cell-input-label">Number</span>
                      <TextInputControl
                        path={`details.roster.${id}.number`}
                        classMods={['fullBorder']}
                      />
                    </td>
                    <td className="roster-cell roster-cell-remove">
                      <span className="roster-cell-input-label">&nbsp;</span>
                      <Button
                        classMods={(isRemovingDisabled ?
                          ['disabled']
                        : []
                        ).concat(['action', 'remove'])}
                        onClick={() => {
                          if (isRemovingDisabled) {
                            return
                          }

                          remove(id)
                        }}
                      >
                        <Icon name="close" />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td className="roster-cell roster-cell-name">
              {/* <span className="roster-cell-input-label">Player Name</span> */}
              <TextInputControl
                path={`details.roster.${id}.name`}
                classMods={['fullBorder']}
                placeholder={'Player Name'}
              />
            </td>
          </RosterRow>
        ))}
      </Roster>

      <RosterAdd>
        <Button classMods={['action', 'add']} onClick={() => add()}>
          <Icon name="plus" />
        </Button>
      </RosterAdd>

      {rosterError && <p className="error">{rosterError}</p>}

      <RosterUpload setRosterValues={setRosterValues} />
    </Fragment>
  )
}

export default ApparelsRoster
