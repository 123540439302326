import * as _ from '@technically/lodash'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { svgStringToCanvas } from '@orangelv/utils-dom'

import assert from '../../../../platform/assert'
import getAsset from '../../../../platform/getAsset'
import http from '../../../../platform/client/http'

import { getBlobFromCanvas } from '../utils'
import { COLOR_DICT, Fill, FILLS } from '../../common/sheets'

const WIDTH = 512
const HEIGHT = 512

const replaceFill = (fill: Fill, el: any) => {
  for (const key of ['color1', 'color2', 'color3', 'color4'] as const) {
    const ids = fill.props[key]
    if (_.includes(ids, el.id)) {
      const colorId = fill.props.defaults[key]
      if (colorId) {
        const hex = COLOR_DICT[colorId].props.hex
        el.setAttribute('fill', hex)
      }
    }
  }
}

export default async () => {
  console.time('generateIconsForFills')

  const zip = new JSZip()

  for (const fill of FILLS) {
    const response = await http.get(getAsset(`fills/${fill.props.assetId}.svg`))
    const svgString = response.data

    const parser = new DOMParser()

    const doc = parser.parseFromString(svgString, 'text/xml')

    doc.querySelectorAll('*').forEach((el: any) => {
      replaceFill(fill, el)
    })

    const canvas = await svgStringToCanvas(doc.documentElement.outerHTML)

    const context = canvas.getContext('2d')
    assert(context)

    const pattern = context.createPattern(canvas, 'repeat')
    assert(pattern)

    const patternCanvas = document.createElement('canvas')
    const patternContext = patternCanvas.getContext('2d')
    assert(patternContext)

    patternCanvas.width = WIDTH
    patternCanvas.height = HEIGHT

    patternContext.fillStyle = pattern
    patternContext.fillRect(0, 0, WIDTH, HEIGHT)

    const blob = await getBlobFromCanvas(patternCanvas)

    zip.file(`${fill.id}.png`, blob)
  }

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'icons-for-fills.zip')

  console.timeEnd('generateIconsForFills')
}
