import { Immutable } from '@orangelv/utils'
import picomatch from 'picomatch' // Don't do { isMatch }, won't work in Node

export const filterDesignParts = <
  T extends {
    designId: string
    pieceNameGlob: string
    productIdGlob: string
  },
>(
  designParts: Immutable<T[]>,
  designId: string,
  pieceName: string,
  productId: string,
): T[] =>
  designParts.filter(
    (x) =>
      x.designId === designId &&
      picomatch.isMatch(pieceName, x.pieceNameGlob) &&
      picomatch.isMatch(productId, x.productIdGlob),
  ) as T[]
