import { in2px, type Vector2 } from '@orangelv/utils-geometry';
import type { Element, Root } from 'hast';

type DebugDotColorsConfig = {
  dot?: string;
  circle?: string;
  circleColor?: string;
  circleFill?: string;
};

export function renderDebugDot(
  ns: string,
  key: string | number,
  point: Vector2,
  colors: DebugDotColorsConfig = {},
): Root | Element {
  const dotColor = colors.dot ?? '#CF0134';
  const circleColor = colors.circleColor ?? colors.circle ?? '#CF0134';
  const circleFill = colors.circleFill ?? colors.circle ?? '#E6DAA6';

  return (
    <g id={`${ns}-DEBUG-DOT-${key}`}>
      <circle
        cx={point.x}
        cy={point.y}
        r={in2px(0.13)}
        stroke={circleColor}
        strokeWidth="3"
        fill={circleFill}
        opacity={0.5}
      />
      <circle cx={point.x} cy={point.y} r="2px" fill={dotColor} />
      <text
        x={point.x}
        y={point.y + in2px(0.1)}
        style='font-size: 9pt; font-family: "Consolas"; font-weight:700; '
        strokeLinejoin="round"
        strokeWidth="0.5pt"
        paintOrder="stroke"
        stroke="#FFFFFF"
        textAnchor="middle"
        dominantBaseline="hanging"
        fill="#000000"
      >
        {key}
      </text>
    </g>
  );
}
