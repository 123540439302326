// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FillRow } from '../typings';

const data = [
  {
    "id": "solid",
    "name": "Solid",
    "props": {
      "assetId": "solid",
      "color1": [
        "solid"
      ],
      "defaults": {
        "color1": "white"
      }
    }
  },
  {
    "id": "nineties",
    "name": "90s",
    "props": {
      "assetId": "90s",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1",
        "d-1"
      ],
      "defaults": {
        "color1": "divaBlue",
        "color2": "columbiaBlue",
        "color3": "royal"
      }
    }
  },
  {
    "id": "bio",
    "name": "Bio",
    "props": {
      "assetId": "bio",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "defaults": {
        "color1": "kellyGreen",
        "color2": "forest",
        "color3": "brown"
      }
    }
  },
  {
    "id": "digiCamo",
    "name": "Digi Camo",
    "props": {
      "assetId": "digi-camo",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1",
        "d-1"
      ],
      "defaults": {
        "color1": "electricGreen",
        "color2": "teal",
        "color3": "mahogany"
      }
    }
  },
  {
    "id": "electricStripe",
    "name": "Electric Stripe",
    "props": {
      "assetId": "electric-stripe",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "defaults": {
        "color1": "blazingOrange",
        "color2": "electricPurple",
        "color3": "orange"
      }
    }
  },
  {
    "id": "gator",
    "name": "Gator",
    "props": {
      "assetId": "gator",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "forest",
        "color2": "black"
      }
    }
  },
  {
    "id": "geoCamo",
    "name": "Geo Camo",
    "props": {
      "assetId": "geo-camo",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "color4": [
        "d-1"
      ],
      "defaults": {
        "color1": "vegasGold",
        "color2": "brown",
        "color3": "kellyGreen",
        "color4": "cream"
      }
    }
  },
  {
    "id": "honeyComb",
    "name": "Honey Comb",
    "props": {
      "assetId": "honey-comb",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "athleticGold",
        "color2": "mahogany"
      }
    }
  },
  {
    "id": "leopard",
    "name": "Leopard",
    "props": {
      "assetId": "leopard",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "vegasGold",
        "color2": "espresso"
      }
    }
  },
  {
    "id": "oldSchoolCamo",
    "name": "Old School Camo",
    "props": {
      "assetId": "old-school-camo",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "color4": [
        "d-1",
        "e-1"
      ],
      "defaults": {
        "color1": "vegasGold",
        "color2": "black",
        "color3": "brown",
        "color4": "espresso"
      }
    }
  },
  {
    "id": "paintedStripe",
    "name": "Painted Stripe",
    "props": {
      "assetId": "painted-stripe",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "teal"
      }
    }
  },
  {
    "id": "pinStripe",
    "name": "Pin Stripe",
    "props": {
      "assetId": "pin-stripe",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "lightViolet",
        "color2": "royal"
      }
    }
  },
  {
    "id": "razorStripe",
    "name": "Razor Stripe",
    "props": {
      "assetId": "razor-stripe",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "electricGreen",
        "color2": "black"
      }
    }
  },
  {
    "id": "snake",
    "name": "Snake",
    "props": {
      "assetId": "snake",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "forest",
        "color2": "black"
      }
    }
  },
  {
    "id": "spade",
    "name": "Spade",
    "props": {
      "assetId": "spade",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "lightOrange",
        "color2": "purple"
      }
    }
  },
  {
    "id": "tiger",
    "name": "Tiger",
    "props": {
      "assetId": "tiger",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "vegasGold",
        "color2": "orange"
      }
    }
  },
  {
    "id": "monoDigiCamo",
    "name": "Mono Digi Camo",
    "props": {
      "assetId": "mono-digi-camo",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "columbiaBlue",
        "color2": "midnightBlue"
      }
    }
  },
  {
    "id": "woodlandCamo",
    "name": "Woodland Camo",
    "props": {
      "assetId": "woodland-camo",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "color4": [
        "d-1"
      ],
      "defaults": {
        "color1": "maroon",
        "color2": "forest",
        "color3": "charcoal",
        "color4": "black"
      }
    }
  },
  {
    "id": "halftoneCamo",
    "name": "Halftone Camo",
    "props": {
      "assetId": "halftone-camo",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "navy",
        "color2": "shade"
      }
    }
  },
  {
    "id": "splatterCamo",
    "name": "Splatter Camo",
    "props": {
      "assetId": "splatter-camo",
      "color1": [
        "a-1",
        "a-2",
        "a-3"
      ],
      "color2": [
        "b-1",
        "b-2",
        "b-3",
        "b-4",
        "b-5",
        "b-6",
        "b-7"
      ],
      "color3": [
        "c-1",
        "c-2",
        "c-3",
        "d-1",
        "d-2",
        "d-3",
        "d-4",
        "d-5",
        "d-6"
      ],
      "defaults": {
        "color1": "maroon",
        "color2": "vegasGold",
        "color3": "forest"
      }
    }
  },
  {
    "id": "swirl",
    "name": "Swirl",
    "props": {
      "assetId": "swirl",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "defaults": {
        "color1": "violet",
        "color2": "lightViolet",
        "color3": "lilac"
      }
    }
  },
  {
    "id": "wool",
    "name": "Wool",
    "props": {
      "assetId": "wool",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "cream",
        "color2": "vegasGold"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FillRow[]>> = data;

export type Fill = typeof typed[number];

export const FILL_INDEX_KEY = "id";
export type FillIndexKey = "id";
export type FillId = Fill["id"];

let i = 0;
export const FILL_DICT = {
  "solid": typed[i++],
  "nineties": typed[i++],
  "bio": typed[i++],
  "digiCamo": typed[i++],
  "electricStripe": typed[i++],
  "gator": typed[i++],
  "geoCamo": typed[i++],
  "honeyComb": typed[i++],
  "leopard": typed[i++],
  "oldSchoolCamo": typed[i++],
  "paintedStripe": typed[i++],
  "pinStripe": typed[i++],
  "razorStripe": typed[i++],
  "snake": typed[i++],
  "spade": typed[i++],
  "tiger": typed[i++],
  "monoDigiCamo": typed[i++],
  "woodlandCamo": typed[i++],
  "halftoneCamo": typed[i++],
  "splatterCamo": typed[i++],
  "swirl": typed[i++],
  "wool": typed[i++]
} as const;

export { typed as FILLS };
