import React, { useState } from 'react'
import * as _ from '@technically/lodash'
import './PreviewImages.css'

type Props = {
  previewUrls: { [key: string]: string }
}

const PreviewImages = (props: Props) => {
  const { previewUrls } = props
  const [viewId, setViewId] = useState('front')

  return (
    <div className="previewImage">
      <div className="previewImageShow">
        <img src={previewUrls[viewId]}></img>
      </div>
      <div className="previewImageList">
        {_.map(
          _.omitBy(previewUrls, (_url, key) => key === viewId),
          (url, key) => (
            <div
              key={key}
              className="previewImageThumb"
              onClick={() => {
                setViewId(key)
              }}
            >
              <img src={url} />
            </div>
          ),
        )}
      </div>
    </div>
  )
}

export default PreviewImages
