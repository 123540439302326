import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { load as loadFont } from 'opentype.js'
import { s } from 'hastscript'

import { renderText } from '@orangelv/svg-renderer'
import { in2px } from '@orangelv/utils-geometry'
import { svgToCanvas } from '@orangelv/utils-dom'

import getAsset from '../../../../platform/getAsset'

import {
  getBlobFromCanvas,
  getDrawableFontChars,
  getFontFilename,
} from '../utils'
import { COLOR_DICT, FONTS } from '../../common/sheets'

const WIDTH = 512
const HEIGHT = 340
const PADDING = 0

export default async () => {
  console.time('generateIconsForFonts')

  const zip = new JSZip()

  for (const font of FONTS.filter((x) => x.isEnabled)) {
    const fontObject = await loadFont(
      getAsset(`fonts/${getFontFilename(font.id)}`),
    )

    const svgData = renderText(
      fontObject,
      getDrawableFontChars(font.id, font.iconText),
      {
        // Trying to be similar to team name on front chest.
        size: in2px(3.5),
        letterSpacing:
          !font.isScript && font.isAvailable.outline ? 0.05 : undefined,
        fill: COLOR_DICT.coolGray.props.hex,
        outlineColor1:
          font.isAvailable.outline ?
            COLOR_DICT['gray_+2'].props.hex
          : undefined,
      },
    )

    // Wrap in SVG tag with an increased viewport to get padding around the text.
    svgData.root = s(
      undefined,
      s(
        'svg',
        {
          xmlns: 'http://www.w3.org/2000/svg',
          'xmlns:xlink': 'http://www.w3.org/1999/xlink',
          viewBox: `${((svgData.width * PADDING) / 2) * -1} ${
            ((svgData.height * PADDING) / 2) * -1
          } ${svgData.width * (1 + PADDING)} ${svgData.height * (1 + PADDING)}`,
        },
        svgData.root,
      ),
    )

    const canvas = await svgToCanvas(svgData, { width: WIDTH, height: HEIGHT })

    const blob = await getBlobFromCanvas(canvas)

    zip.file(`${font.id}.png`, blob)
  }

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'icons-for-fonts.zip')

  console.timeEnd('generateIconsForFonts')
}
