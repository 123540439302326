// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SummaryTreeItemRow } from '../typings';

const data = [
  {
    "childId": "jersey",
    "name": "Jersey",
    "id": "jersey"
  },
  {
    "parentId": "jersey",
    "childId": "sport",
    "name": "Sport",
    "propId": "calc.jerseySport",
    "id": "jersey.sport"
  },
  {
    "parentId": "jersey",
    "childId": "sku",
    "name": "SKU",
    "propId": "jersey.sku",
    "id": "jersey.sku"
  },
  {
    "parentId": "jersey",
    "childId": "fabric",
    "name": "Fabric",
    "propId": "jersey.fabric",
    "id": "jersey.fabric"
  },
  {
    "parentId": "jersey",
    "childId": "design",
    "name": "Design",
    "id": "jersey.design"
  },
  {
    "parentId": "jersey.design",
    "childId": "design",
    "name": "Name",
    "propId": "jersey.design.design",
    "id": "jersey.design.design"
  },
  {
    "parentId": "jersey.design",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.design.color1",
    "id": "jersey.design.color1"
  },
  {
    "parentId": "jersey.design",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.design.color2",
    "id": "jersey.design.color2"
  },
  {
    "parentId": "jersey.design",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.design.color3",
    "id": "jersey.design.color3"
  },
  {
    "parentId": "jersey.design",
    "childId": "file",
    "name": "File",
    "propId": "jersey.design.file",
    "id": "jersey.design.file"
  },
  {
    "parentId": "jersey",
    "childId": "body",
    "name": "Body",
    "id": "jersey.body"
  },
  {
    "parentId": "jersey.body",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.body.fill",
    "id": "jersey.body.fill"
  },
  {
    "parentId": "jersey.body",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.body.color1",
    "id": "jersey.body.color1"
  },
  {
    "parentId": "jersey.body",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.body.color2",
    "id": "jersey.body.color2"
  },
  {
    "parentId": "jersey.body",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.body.color3",
    "id": "jersey.body.color3"
  },
  {
    "parentId": "jersey.body",
    "childId": "color4",
    "name": "Color 4",
    "propId": "jersey.body.color4",
    "id": "jersey.body.color4"
  },
  {
    "parentId": "jersey",
    "childId": "sleeve",
    "name": "Sleeve",
    "id": "jersey.sleeve"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.sleeve.fill",
    "id": "jersey.sleeve.fill"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.sleeve.color1",
    "id": "jersey.sleeve.color1"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.sleeve.color2",
    "id": "jersey.sleeve.color2"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.sleeve.color3",
    "id": "jersey.sleeve.color3"
  },
  {
    "parentId": "jersey.sleeve",
    "childId": "color4",
    "name": "Color 4",
    "propId": "jersey.sleeve.color4",
    "id": "jersey.sleeve.color4"
  },
  {
    "parentId": "jersey",
    "childId": "collar",
    "name": "Collar",
    "id": "jersey.collar"
  },
  {
    "parentId": "jersey.collar",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.collar.fill",
    "id": "jersey.collar.fill"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.collar.color1",
    "id": "jersey.collar.color1"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.collar.color2",
    "id": "jersey.collar.color2"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.collar.color3",
    "id": "jersey.collar.color3"
  },
  {
    "parentId": "jersey.collar",
    "childId": "color4",
    "name": "Color 4",
    "propId": "jersey.collar.color4",
    "id": "jersey.collar.color4"
  },
  {
    "parentId": "jersey",
    "childId": "runbird",
    "name": "Mizuno Logo",
    "id": "jersey.runbird"
  },
  {
    "parentId": "jersey.runbird",
    "childId": "innerColor",
    "name": "Inner Color",
    "propId": "jersey.runbird.innerColor",
    "id": "jersey.runbird.innerColor"
  },
  {
    "parentId": "jersey.runbird",
    "childId": "outerColor",
    "name": "Outer Color",
    "propId": "jersey.runbird.outerColor",
    "id": "jersey.runbird.outerColor"
  },
  {
    "parentId": "jersey",
    "childId": "braid",
    "name": "Braid",
    "id": "jersey.braid"
  },
  {
    "parentId": "jersey.braid",
    "childId": "position",
    "name": "Position",
    "propId": "jersey.braid.position",
    "id": "jersey.braid.position"
  },
  {
    "parentId": "jersey.braid",
    "childId": "color1",
    "name": "Color 1",
    "propId": "jersey.braid.color1",
    "id": "jersey.braid.color1"
  },
  {
    "parentId": "jersey.braid",
    "childId": "color2",
    "name": "Color 2",
    "propId": "jersey.braid.color2",
    "id": "jersey.braid.color2"
  },
  {
    "parentId": "jersey.braid",
    "childId": "color3",
    "name": "Color 3",
    "propId": "jersey.braid.color3",
    "id": "jersey.braid.color3"
  },
  {
    "parentId": "jersey",
    "childId": "frontChest",
    "name": "Front Chest",
    "id": "jersey.frontChest"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.frontChest.content",
    "id": "jersey.frontChest.content"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.frontChest.text",
    "id": "jersey.frontChest.text"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.frontChest.font",
    "id": "jersey.frontChest.font"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.frontChest.layout",
    "id": "jersey.frontChest.layout"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.frontChest.fill",
    "id": "jersey.frontChest.fill"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.frontChest.fillColor1",
    "id": "jersey.frontChest.fillColor1"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.frontChest.fillColor2",
    "id": "jersey.frontChest.fillColor2"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.frontChest.fillColor3",
    "id": "jersey.frontChest.fillColor3"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.frontChest.fillColor4",
    "id": "jersey.frontChest.fillColor4"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.frontChest.outlineColor1",
    "id": "jersey.frontChest.outlineColor1"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.frontChest.outlineColor2",
    "id": "jersey.frontChest.outlineColor2"
  },
  {
    "parentId": "jersey.frontChest",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.frontChest.file",
    "id": "jersey.frontChest.file"
  },
  {
    "parentId": "jersey",
    "childId": "upperFrontLeftChest",
    "name": "Upper Front Left Chest",
    "id": "jersey.upperFrontLeftChest"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.upperFrontLeftChest.content",
    "id": "jersey.upperFrontLeftChest.content"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.upperFrontLeftChest.text",
    "id": "jersey.upperFrontLeftChest.text"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.upperFrontLeftChest.font",
    "id": "jersey.upperFrontLeftChest.font"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.upperFrontLeftChest.layout",
    "id": "jersey.upperFrontLeftChest.layout"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.upperFrontLeftChest.fill",
    "id": "jersey.upperFrontLeftChest.fill"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.upperFrontLeftChest.fillColor1",
    "id": "jersey.upperFrontLeftChest.fillColor1"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.upperFrontLeftChest.fillColor2",
    "id": "jersey.upperFrontLeftChest.fillColor2"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.upperFrontLeftChest.fillColor3",
    "id": "jersey.upperFrontLeftChest.fillColor3"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.upperFrontLeftChest.fillColor4",
    "id": "jersey.upperFrontLeftChest.fillColor4"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.upperFrontLeftChest.outlineColor1",
    "id": "jersey.upperFrontLeftChest.outlineColor1"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.upperFrontLeftChest.outlineColor2",
    "id": "jersey.upperFrontLeftChest.outlineColor2"
  },
  {
    "parentId": "jersey.upperFrontLeftChest",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.upperFrontLeftChest.file",
    "id": "jersey.upperFrontLeftChest.file"
  },
  {
    "parentId": "jersey",
    "childId": "frontMiddle",
    "name": "Front Middle",
    "id": "jersey.frontMiddle"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.frontMiddle.content",
    "id": "jersey.frontMiddle.content"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.frontMiddle.text",
    "id": "jersey.frontMiddle.text"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.frontMiddle.font",
    "id": "jersey.frontMiddle.font"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.frontMiddle.layout",
    "id": "jersey.frontMiddle.layout"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.frontMiddle.fill",
    "id": "jersey.frontMiddle.fill"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.frontMiddle.fillColor1",
    "id": "jersey.frontMiddle.fillColor1"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.frontMiddle.fillColor2",
    "id": "jersey.frontMiddle.fillColor2"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.frontMiddle.fillColor3",
    "id": "jersey.frontMiddle.fillColor3"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.frontMiddle.fillColor4",
    "id": "jersey.frontMiddle.fillColor4"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.frontMiddle.outlineColor1",
    "id": "jersey.frontMiddle.outlineColor1"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.frontMiddle.outlineColor2",
    "id": "jersey.frontMiddle.outlineColor2"
  },
  {
    "parentId": "jersey.frontMiddle",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.frontMiddle.file",
    "id": "jersey.frontMiddle.file"
  },
  {
    "parentId": "jersey",
    "childId": "frontMiddleLeft",
    "name": "Front Middle Left",
    "id": "jersey.frontMiddleLeft"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.frontMiddleLeft.content",
    "id": "jersey.frontMiddleLeft.content"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.frontMiddleLeft.text",
    "id": "jersey.frontMiddleLeft.text"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.frontMiddleLeft.font",
    "id": "jersey.frontMiddleLeft.font"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.frontMiddleLeft.layout",
    "id": "jersey.frontMiddleLeft.layout"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.frontMiddleLeft.fill",
    "id": "jersey.frontMiddleLeft.fill"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.frontMiddleLeft.fillColor1",
    "id": "jersey.frontMiddleLeft.fillColor1"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.frontMiddleLeft.fillColor2",
    "id": "jersey.frontMiddleLeft.fillColor2"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.frontMiddleLeft.fillColor3",
    "id": "jersey.frontMiddleLeft.fillColor3"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.frontMiddleLeft.fillColor4",
    "id": "jersey.frontMiddleLeft.fillColor4"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.frontMiddleLeft.outlineColor1",
    "id": "jersey.frontMiddleLeft.outlineColor1"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.frontMiddleLeft.outlineColor2",
    "id": "jersey.frontMiddleLeft.outlineColor2"
  },
  {
    "parentId": "jersey.frontMiddleLeft",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.frontMiddleLeft.file",
    "id": "jersey.frontMiddleLeft.file"
  },
  {
    "parentId": "jersey",
    "childId": "frontMiddleRight",
    "name": "Front Middle Right",
    "id": "jersey.frontMiddleRight"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.frontMiddleRight.content",
    "id": "jersey.frontMiddleRight.content"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.frontMiddleRight.text",
    "id": "jersey.frontMiddleRight.text"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.frontMiddleRight.font",
    "id": "jersey.frontMiddleRight.font"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.frontMiddleRight.layout",
    "id": "jersey.frontMiddleRight.layout"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.frontMiddleRight.fill",
    "id": "jersey.frontMiddleRight.fill"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.frontMiddleRight.fillColor1",
    "id": "jersey.frontMiddleRight.fillColor1"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.frontMiddleRight.fillColor2",
    "id": "jersey.frontMiddleRight.fillColor2"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.frontMiddleRight.fillColor3",
    "id": "jersey.frontMiddleRight.fillColor3"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.frontMiddleRight.fillColor4",
    "id": "jersey.frontMiddleRight.fillColor4"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.frontMiddleRight.outlineColor1",
    "id": "jersey.frontMiddleRight.outlineColor1"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.frontMiddleRight.outlineColor2",
    "id": "jersey.frontMiddleRight.outlineColor2"
  },
  {
    "parentId": "jersey.frontMiddleRight",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.frontMiddleRight.file",
    "id": "jersey.frontMiddleRight.file"
  },
  {
    "parentId": "jersey",
    "childId": "upperBack",
    "name": "Upper Back",
    "id": "jersey.upperBack"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.upperBack.content",
    "id": "jersey.upperBack.content"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.upperBack.text",
    "id": "jersey.upperBack.text"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.upperBack.font",
    "id": "jersey.upperBack.font"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.upperBack.layout",
    "id": "jersey.upperBack.layout"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.upperBack.fill",
    "id": "jersey.upperBack.fill"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.upperBack.fillColor1",
    "id": "jersey.upperBack.fillColor1"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.upperBack.fillColor2",
    "id": "jersey.upperBack.fillColor2"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.upperBack.fillColor3",
    "id": "jersey.upperBack.fillColor3"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.upperBack.fillColor4",
    "id": "jersey.upperBack.fillColor4"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.upperBack.outlineColor1",
    "id": "jersey.upperBack.outlineColor1"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.upperBack.outlineColor2",
    "id": "jersey.upperBack.outlineColor2"
  },
  {
    "parentId": "jersey.upperBack",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.upperBack.file",
    "id": "jersey.upperBack.file"
  },
  {
    "parentId": "jersey",
    "childId": "middleBack",
    "name": "Middle Back",
    "id": "jersey.middleBack"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.middleBack.content",
    "id": "jersey.middleBack.content"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.middleBack.text",
    "id": "jersey.middleBack.text"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.middleBack.font",
    "id": "jersey.middleBack.font"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.middleBack.layout",
    "id": "jersey.middleBack.layout"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.middleBack.fill",
    "id": "jersey.middleBack.fill"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.middleBack.fillColor1",
    "id": "jersey.middleBack.fillColor1"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.middleBack.fillColor2",
    "id": "jersey.middleBack.fillColor2"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.middleBack.fillColor3",
    "id": "jersey.middleBack.fillColor3"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.middleBack.fillColor4",
    "id": "jersey.middleBack.fillColor4"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.middleBack.outlineColor1",
    "id": "jersey.middleBack.outlineColor1"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.middleBack.outlineColor2",
    "id": "jersey.middleBack.outlineColor2"
  },
  {
    "parentId": "jersey.middleBack",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.middleBack.file",
    "id": "jersey.middleBack.file"
  },
  {
    "parentId": "jersey",
    "childId": "lowerBack",
    "name": "Lower Back",
    "id": "jersey.lowerBack"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.lowerBack.content",
    "id": "jersey.lowerBack.content"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.lowerBack.text",
    "id": "jersey.lowerBack.text"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.lowerBack.font",
    "id": "jersey.lowerBack.font"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.lowerBack.layout",
    "id": "jersey.lowerBack.layout"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.lowerBack.fill",
    "id": "jersey.lowerBack.fill"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.lowerBack.fillColor1",
    "id": "jersey.lowerBack.fillColor1"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.lowerBack.fillColor2",
    "id": "jersey.lowerBack.fillColor2"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.lowerBack.fillColor3",
    "id": "jersey.lowerBack.fillColor3"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.lowerBack.fillColor4",
    "id": "jersey.lowerBack.fillColor4"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.lowerBack.outlineColor1",
    "id": "jersey.lowerBack.outlineColor1"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.lowerBack.outlineColor2",
    "id": "jersey.lowerBack.outlineColor2"
  },
  {
    "parentId": "jersey.lowerBack",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.lowerBack.file",
    "id": "jersey.lowerBack.file"
  },
  {
    "parentId": "jersey",
    "childId": "leftSleeve",
    "name": "Left Sleeve",
    "id": "jersey.leftSleeve"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.leftSleeve.content",
    "id": "jersey.leftSleeve.content"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.leftSleeve.text",
    "id": "jersey.leftSleeve.text"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.leftSleeve.font",
    "id": "jersey.leftSleeve.font"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.leftSleeve.layout",
    "id": "jersey.leftSleeve.layout"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.leftSleeve.fill",
    "id": "jersey.leftSleeve.fill"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.leftSleeve.fillColor1",
    "id": "jersey.leftSleeve.fillColor1"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.leftSleeve.fillColor2",
    "id": "jersey.leftSleeve.fillColor2"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.leftSleeve.fillColor3",
    "id": "jersey.leftSleeve.fillColor3"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.leftSleeve.fillColor4",
    "id": "jersey.leftSleeve.fillColor4"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.leftSleeve.outlineColor1",
    "id": "jersey.leftSleeve.outlineColor1"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.leftSleeve.outlineColor2",
    "id": "jersey.leftSleeve.outlineColor2"
  },
  {
    "parentId": "jersey.leftSleeve",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.leftSleeve.file",
    "id": "jersey.leftSleeve.file"
  },
  {
    "parentId": "jersey",
    "childId": "rightSleeve",
    "name": "Right Sleeve",
    "id": "jersey.rightSleeve"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "content",
    "name": "Content",
    "propId": "jersey.rightSleeve.content",
    "id": "jersey.rightSleeve.content"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "text",
    "name": "Text",
    "propId": "jersey.rightSleeve.text",
    "id": "jersey.rightSleeve.text"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "font",
    "name": "Font",
    "propId": "jersey.rightSleeve.font",
    "id": "jersey.rightSleeve.font"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "layout",
    "name": "Layout",
    "propId": "jersey.rightSleeve.layout",
    "id": "jersey.rightSleeve.layout"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "fill",
    "name": "Fill",
    "propId": "jersey.rightSleeve.fill",
    "id": "jersey.rightSleeve.fill"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "jersey.rightSleeve.fillColor1",
    "id": "jersey.rightSleeve.fillColor1"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "jersey.rightSleeve.fillColor2",
    "id": "jersey.rightSleeve.fillColor2"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "jersey.rightSleeve.fillColor3",
    "id": "jersey.rightSleeve.fillColor3"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "jersey.rightSleeve.fillColor4",
    "id": "jersey.rightSleeve.fillColor4"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "jersey.rightSleeve.outlineColor1",
    "id": "jersey.rightSleeve.outlineColor1"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "jersey.rightSleeve.outlineColor2",
    "id": "jersey.rightSleeve.outlineColor2"
  },
  {
    "parentId": "jersey.rightSleeve",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "jersey.rightSleeve.file",
    "id": "jersey.rightSleeve.file"
  },
  {
    "childId": "pants",
    "name": "Pants",
    "id": "pants"
  },
  {
    "parentId": "pants",
    "childId": "sport",
    "name": "Sport",
    "propId": "calc.pantsSport",
    "id": "pants.sport"
  },
  {
    "parentId": "pants",
    "childId": "sku",
    "name": "SKU",
    "propId": "pants.sku",
    "id": "pants.sku"
  },
  {
    "parentId": "pants",
    "childId": "fabric",
    "name": "Fabric",
    "propId": "pants.fabric",
    "id": "pants.fabric"
  },
  {
    "parentId": "pants",
    "childId": "design",
    "name": "Design",
    "id": "pants.design"
  },
  {
    "parentId": "pants.design",
    "childId": "design",
    "name": "Name",
    "propId": "pants.design.design",
    "id": "pants.design.design"
  },
  {
    "parentId": "pants.design",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.design.color1",
    "id": "pants.design.color1"
  },
  {
    "parentId": "pants.design",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.design.color2",
    "id": "pants.design.color2"
  },
  {
    "parentId": "pants.design",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.design.color3",
    "id": "pants.design.color3"
  },
  {
    "parentId": "pants.design",
    "childId": "file",
    "name": "File",
    "propId": "pants.design.file",
    "id": "pants.design.file"
  },
  {
    "parentId": "pants",
    "childId": "body",
    "name": "Body",
    "id": "pants.body"
  },
  {
    "parentId": "pants.body",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.body.fill",
    "id": "pants.body.fill"
  },
  {
    "parentId": "pants.body",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.body.color1",
    "id": "pants.body.color1"
  },
  {
    "parentId": "pants.body",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.body.color2",
    "id": "pants.body.color2"
  },
  {
    "parentId": "pants.body",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.body.color3",
    "id": "pants.body.color3"
  },
  {
    "parentId": "pants.body",
    "childId": "color4",
    "name": "Color 4",
    "propId": "pants.body.color4",
    "id": "pants.body.color4"
  },
  {
    "parentId": "pants",
    "childId": "beltLoop",
    "name": "Belt Loop",
    "id": "pants.beltLoop"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.beltLoop.fill",
    "id": "pants.beltLoop.fill"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.beltLoop.color1",
    "id": "pants.beltLoop.color1"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.beltLoop.color2",
    "id": "pants.beltLoop.color2"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.beltLoop.color3",
    "id": "pants.beltLoop.color3"
  },
  {
    "parentId": "pants.beltLoop",
    "childId": "color4",
    "name": "Color 4",
    "propId": "pants.beltLoop.color4",
    "id": "pants.beltLoop.color4"
  },
  {
    "parentId": "pants",
    "childId": "runbird",
    "name": "Mizuno Logo",
    "id": "pants.runbird"
  },
  {
    "parentId": "pants.runbird",
    "childId": "innerColor",
    "name": "Inner Color",
    "propId": "pants.runbird.innerColor",
    "id": "pants.runbird.innerColor"
  },
  {
    "parentId": "pants.runbird",
    "childId": "outerColor",
    "name": "Outer Color",
    "propId": "pants.runbird.outerColor",
    "id": "pants.runbird.outerColor"
  },
  {
    "parentId": "pants",
    "childId": "backBeltLoop",
    "name": "Back Belt Loop",
    "id": "pants.backBeltLoop"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "content",
    "name": "Content",
    "propId": "pants.backBeltLoop.content",
    "id": "pants.backBeltLoop.content"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "text",
    "name": "Text",
    "propId": "pants.backBeltLoop.text",
    "id": "pants.backBeltLoop.text"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "font",
    "name": "Font",
    "propId": "pants.backBeltLoop.font",
    "id": "pants.backBeltLoop.font"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "layout",
    "name": "Layout",
    "propId": "pants.backBeltLoop.layout",
    "id": "pants.backBeltLoop.layout"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.backBeltLoop.fill",
    "id": "pants.backBeltLoop.fill"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "pants.backBeltLoop.fillColor1",
    "id": "pants.backBeltLoop.fillColor1"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "pants.backBeltLoop.fillColor2",
    "id": "pants.backBeltLoop.fillColor2"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "pants.backBeltLoop.fillColor3",
    "id": "pants.backBeltLoop.fillColor3"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "pants.backBeltLoop.fillColor4",
    "id": "pants.backBeltLoop.fillColor4"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "pants.backBeltLoop.outlineColor1",
    "id": "pants.backBeltLoop.outlineColor1"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "pants.backBeltLoop.outlineColor2",
    "id": "pants.backBeltLoop.outlineColor2"
  },
  {
    "parentId": "pants.backBeltLoop",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "pants.backBeltLoop.file",
    "id": "pants.backBeltLoop.file"
  },
  {
    "parentId": "pants",
    "childId": "leftLeg",
    "name": "Left Leg",
    "id": "pants.leftLeg"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "content",
    "name": "Content",
    "propId": "pants.leftLeg.content",
    "id": "pants.leftLeg.content"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "text",
    "name": "Text",
    "propId": "pants.leftLeg.text",
    "id": "pants.leftLeg.text"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "font",
    "name": "Font",
    "propId": "pants.leftLeg.font",
    "id": "pants.leftLeg.font"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "layout",
    "name": "Layout",
    "propId": "pants.leftLeg.layout",
    "id": "pants.leftLeg.layout"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.leftLeg.fill",
    "id": "pants.leftLeg.fill"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "pants.leftLeg.fillColor1",
    "id": "pants.leftLeg.fillColor1"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "pants.leftLeg.fillColor2",
    "id": "pants.leftLeg.fillColor2"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "pants.leftLeg.fillColor3",
    "id": "pants.leftLeg.fillColor3"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "pants.leftLeg.fillColor4",
    "id": "pants.leftLeg.fillColor4"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "pants.leftLeg.outlineColor1",
    "id": "pants.leftLeg.outlineColor1"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "pants.leftLeg.outlineColor2",
    "id": "pants.leftLeg.outlineColor2"
  },
  {
    "parentId": "pants.leftLeg",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "pants.leftLeg.file",
    "id": "pants.leftLeg.file"
  },
  {
    "parentId": "pants",
    "childId": "rightLeg",
    "name": "Right Leg",
    "id": "pants.rightLeg"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "content",
    "name": "Content",
    "propId": "pants.rightLeg.content",
    "id": "pants.rightLeg.content"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "text",
    "name": "Text",
    "propId": "pants.rightLeg.text",
    "id": "pants.rightLeg.text"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "font",
    "name": "Font",
    "propId": "pants.rightLeg.font",
    "id": "pants.rightLeg.font"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "layout",
    "name": "Layout",
    "propId": "pants.rightLeg.layout",
    "id": "pants.rightLeg.layout"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "fill",
    "name": "Fill",
    "propId": "pants.rightLeg.fill",
    "id": "pants.rightLeg.fill"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "fillColor1",
    "name": "Fill Color 1",
    "propId": "pants.rightLeg.fillColor1",
    "id": "pants.rightLeg.fillColor1"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "fillColor2",
    "name": "Fill Color 2",
    "propId": "pants.rightLeg.fillColor2",
    "id": "pants.rightLeg.fillColor2"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "fillColor3",
    "name": "Fill Color 3",
    "propId": "pants.rightLeg.fillColor3",
    "id": "pants.rightLeg.fillColor3"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "fillColor4",
    "name": "Fill Color 4",
    "propId": "pants.rightLeg.fillColor4",
    "id": "pants.rightLeg.fillColor4"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "outlineColor1",
    "name": "Outline Color 1",
    "propId": "pants.rightLeg.outlineColor1",
    "id": "pants.rightLeg.outlineColor1"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "outlineColor2",
    "name": "Outline Color 2",
    "propId": "pants.rightLeg.outlineColor2",
    "id": "pants.rightLeg.outlineColor2"
  },
  {
    "parentId": "pants.rightLeg",
    "childId": "file",
    "name": "Custom Logo",
    "propId": "pants.rightLeg.file",
    "id": "pants.rightLeg.file"
  },
  {
    "parentId": "pants",
    "childId": "braid",
    "name": "Braid",
    "id": "pants.braid"
  },
  {
    "parentId": "pants.braid",
    "childId": "position",
    "name": "Position",
    "propId": "pants.braid.position",
    "id": "pants.braid.position"
  },
  {
    "parentId": "pants.braid",
    "childId": "color1",
    "name": "Color 1",
    "propId": "pants.braid.color1",
    "id": "pants.braid.color1"
  },
  {
    "parentId": "pants.braid",
    "childId": "color2",
    "name": "Color 2",
    "propId": "pants.braid.color2",
    "id": "pants.braid.color2"
  },
  {
    "parentId": "pants.braid",
    "childId": "color3",
    "name": "Color 3",
    "propId": "pants.braid.color3",
    "id": "pants.braid.color3"
  },
  {
    "childId": "details",
    "name": "Roster",
    "id": "details"
  },
  {
    "parentId": "details",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "details.teamName.text",
    "id": "details.teamName"
  },
  {
    "parentId": "details",
    "childId": "roster",
    "name": "Team Roster",
    "propId": "calc.roster",
    "id": "details.roster"
  },
  {
    "parentId": "details",
    "childId": "sizes",
    "name": "Size Summary",
    "propId": "calc.sizes",
    "id": "details.sizes"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SummaryTreeItemRow[]>> = data;

export type SummaryTreeItem = typeof typed[number];

export const SUMMARY_TREE_ITEM_INDEX_KEY = "id";
export type SummaryTreeItemIndexKey = "id";
export type SummaryTreeItemId = SummaryTreeItem["id"];

let i = 0;
export const SUMMARY_TREE_ITEM_DICT = {
  "jersey": typed[i++],
  "jersey.sport": typed[i++],
  "jersey.sku": typed[i++],
  "jersey.fabric": typed[i++],
  "jersey.design": typed[i++],
  "jersey.design.design": typed[i++],
  "jersey.design.color1": typed[i++],
  "jersey.design.color2": typed[i++],
  "jersey.design.color3": typed[i++],
  "jersey.design.file": typed[i++],
  "jersey.body": typed[i++],
  "jersey.body.fill": typed[i++],
  "jersey.body.color1": typed[i++],
  "jersey.body.color2": typed[i++],
  "jersey.body.color3": typed[i++],
  "jersey.body.color4": typed[i++],
  "jersey.sleeve": typed[i++],
  "jersey.sleeve.fill": typed[i++],
  "jersey.sleeve.color1": typed[i++],
  "jersey.sleeve.color2": typed[i++],
  "jersey.sleeve.color3": typed[i++],
  "jersey.sleeve.color4": typed[i++],
  "jersey.collar": typed[i++],
  "jersey.collar.fill": typed[i++],
  "jersey.collar.color1": typed[i++],
  "jersey.collar.color2": typed[i++],
  "jersey.collar.color3": typed[i++],
  "jersey.collar.color4": typed[i++],
  "jersey.runbird": typed[i++],
  "jersey.runbird.innerColor": typed[i++],
  "jersey.runbird.outerColor": typed[i++],
  "jersey.braid": typed[i++],
  "jersey.braid.position": typed[i++],
  "jersey.braid.color1": typed[i++],
  "jersey.braid.color2": typed[i++],
  "jersey.braid.color3": typed[i++],
  "jersey.frontChest": typed[i++],
  "jersey.frontChest.content": typed[i++],
  "jersey.frontChest.text": typed[i++],
  "jersey.frontChest.font": typed[i++],
  "jersey.frontChest.layout": typed[i++],
  "jersey.frontChest.fill": typed[i++],
  "jersey.frontChest.fillColor1": typed[i++],
  "jersey.frontChest.fillColor2": typed[i++],
  "jersey.frontChest.fillColor3": typed[i++],
  "jersey.frontChest.fillColor4": typed[i++],
  "jersey.frontChest.outlineColor1": typed[i++],
  "jersey.frontChest.outlineColor2": typed[i++],
  "jersey.frontChest.file": typed[i++],
  "jersey.upperFrontLeftChest": typed[i++],
  "jersey.upperFrontLeftChest.content": typed[i++],
  "jersey.upperFrontLeftChest.text": typed[i++],
  "jersey.upperFrontLeftChest.font": typed[i++],
  "jersey.upperFrontLeftChest.layout": typed[i++],
  "jersey.upperFrontLeftChest.fill": typed[i++],
  "jersey.upperFrontLeftChest.fillColor1": typed[i++],
  "jersey.upperFrontLeftChest.fillColor2": typed[i++],
  "jersey.upperFrontLeftChest.fillColor3": typed[i++],
  "jersey.upperFrontLeftChest.fillColor4": typed[i++],
  "jersey.upperFrontLeftChest.outlineColor1": typed[i++],
  "jersey.upperFrontLeftChest.outlineColor2": typed[i++],
  "jersey.upperFrontLeftChest.file": typed[i++],
  "jersey.frontMiddle": typed[i++],
  "jersey.frontMiddle.content": typed[i++],
  "jersey.frontMiddle.text": typed[i++],
  "jersey.frontMiddle.font": typed[i++],
  "jersey.frontMiddle.layout": typed[i++],
  "jersey.frontMiddle.fill": typed[i++],
  "jersey.frontMiddle.fillColor1": typed[i++],
  "jersey.frontMiddle.fillColor2": typed[i++],
  "jersey.frontMiddle.fillColor3": typed[i++],
  "jersey.frontMiddle.fillColor4": typed[i++],
  "jersey.frontMiddle.outlineColor1": typed[i++],
  "jersey.frontMiddle.outlineColor2": typed[i++],
  "jersey.frontMiddle.file": typed[i++],
  "jersey.frontMiddleLeft": typed[i++],
  "jersey.frontMiddleLeft.content": typed[i++],
  "jersey.frontMiddleLeft.text": typed[i++],
  "jersey.frontMiddleLeft.font": typed[i++],
  "jersey.frontMiddleLeft.layout": typed[i++],
  "jersey.frontMiddleLeft.fill": typed[i++],
  "jersey.frontMiddleLeft.fillColor1": typed[i++],
  "jersey.frontMiddleLeft.fillColor2": typed[i++],
  "jersey.frontMiddleLeft.fillColor3": typed[i++],
  "jersey.frontMiddleLeft.fillColor4": typed[i++],
  "jersey.frontMiddleLeft.outlineColor1": typed[i++],
  "jersey.frontMiddleLeft.outlineColor2": typed[i++],
  "jersey.frontMiddleLeft.file": typed[i++],
  "jersey.frontMiddleRight": typed[i++],
  "jersey.frontMiddleRight.content": typed[i++],
  "jersey.frontMiddleRight.text": typed[i++],
  "jersey.frontMiddleRight.font": typed[i++],
  "jersey.frontMiddleRight.layout": typed[i++],
  "jersey.frontMiddleRight.fill": typed[i++],
  "jersey.frontMiddleRight.fillColor1": typed[i++],
  "jersey.frontMiddleRight.fillColor2": typed[i++],
  "jersey.frontMiddleRight.fillColor3": typed[i++],
  "jersey.frontMiddleRight.fillColor4": typed[i++],
  "jersey.frontMiddleRight.outlineColor1": typed[i++],
  "jersey.frontMiddleRight.outlineColor2": typed[i++],
  "jersey.frontMiddleRight.file": typed[i++],
  "jersey.upperBack": typed[i++],
  "jersey.upperBack.content": typed[i++],
  "jersey.upperBack.text": typed[i++],
  "jersey.upperBack.font": typed[i++],
  "jersey.upperBack.layout": typed[i++],
  "jersey.upperBack.fill": typed[i++],
  "jersey.upperBack.fillColor1": typed[i++],
  "jersey.upperBack.fillColor2": typed[i++],
  "jersey.upperBack.fillColor3": typed[i++],
  "jersey.upperBack.fillColor4": typed[i++],
  "jersey.upperBack.outlineColor1": typed[i++],
  "jersey.upperBack.outlineColor2": typed[i++],
  "jersey.upperBack.file": typed[i++],
  "jersey.middleBack": typed[i++],
  "jersey.middleBack.content": typed[i++],
  "jersey.middleBack.text": typed[i++],
  "jersey.middleBack.font": typed[i++],
  "jersey.middleBack.layout": typed[i++],
  "jersey.middleBack.fill": typed[i++],
  "jersey.middleBack.fillColor1": typed[i++],
  "jersey.middleBack.fillColor2": typed[i++],
  "jersey.middleBack.fillColor3": typed[i++],
  "jersey.middleBack.fillColor4": typed[i++],
  "jersey.middleBack.outlineColor1": typed[i++],
  "jersey.middleBack.outlineColor2": typed[i++],
  "jersey.middleBack.file": typed[i++],
  "jersey.lowerBack": typed[i++],
  "jersey.lowerBack.content": typed[i++],
  "jersey.lowerBack.text": typed[i++],
  "jersey.lowerBack.font": typed[i++],
  "jersey.lowerBack.layout": typed[i++],
  "jersey.lowerBack.fill": typed[i++],
  "jersey.lowerBack.fillColor1": typed[i++],
  "jersey.lowerBack.fillColor2": typed[i++],
  "jersey.lowerBack.fillColor3": typed[i++],
  "jersey.lowerBack.fillColor4": typed[i++],
  "jersey.lowerBack.outlineColor1": typed[i++],
  "jersey.lowerBack.outlineColor2": typed[i++],
  "jersey.lowerBack.file": typed[i++],
  "jersey.leftSleeve": typed[i++],
  "jersey.leftSleeve.content": typed[i++],
  "jersey.leftSleeve.text": typed[i++],
  "jersey.leftSleeve.font": typed[i++],
  "jersey.leftSleeve.layout": typed[i++],
  "jersey.leftSleeve.fill": typed[i++],
  "jersey.leftSleeve.fillColor1": typed[i++],
  "jersey.leftSleeve.fillColor2": typed[i++],
  "jersey.leftSleeve.fillColor3": typed[i++],
  "jersey.leftSleeve.fillColor4": typed[i++],
  "jersey.leftSleeve.outlineColor1": typed[i++],
  "jersey.leftSleeve.outlineColor2": typed[i++],
  "jersey.leftSleeve.file": typed[i++],
  "jersey.rightSleeve": typed[i++],
  "jersey.rightSleeve.content": typed[i++],
  "jersey.rightSleeve.text": typed[i++],
  "jersey.rightSleeve.font": typed[i++],
  "jersey.rightSleeve.layout": typed[i++],
  "jersey.rightSleeve.fill": typed[i++],
  "jersey.rightSleeve.fillColor1": typed[i++],
  "jersey.rightSleeve.fillColor2": typed[i++],
  "jersey.rightSleeve.fillColor3": typed[i++],
  "jersey.rightSleeve.fillColor4": typed[i++],
  "jersey.rightSleeve.outlineColor1": typed[i++],
  "jersey.rightSleeve.outlineColor2": typed[i++],
  "jersey.rightSleeve.file": typed[i++],
  "pants": typed[i++],
  "pants.sport": typed[i++],
  "pants.sku": typed[i++],
  "pants.fabric": typed[i++],
  "pants.design": typed[i++],
  "pants.design.design": typed[i++],
  "pants.design.color1": typed[i++],
  "pants.design.color2": typed[i++],
  "pants.design.color3": typed[i++],
  "pants.design.file": typed[i++],
  "pants.body": typed[i++],
  "pants.body.fill": typed[i++],
  "pants.body.color1": typed[i++],
  "pants.body.color2": typed[i++],
  "pants.body.color3": typed[i++],
  "pants.body.color4": typed[i++],
  "pants.beltLoop": typed[i++],
  "pants.beltLoop.fill": typed[i++],
  "pants.beltLoop.color1": typed[i++],
  "pants.beltLoop.color2": typed[i++],
  "pants.beltLoop.color3": typed[i++],
  "pants.beltLoop.color4": typed[i++],
  "pants.runbird": typed[i++],
  "pants.runbird.innerColor": typed[i++],
  "pants.runbird.outerColor": typed[i++],
  "pants.backBeltLoop": typed[i++],
  "pants.backBeltLoop.content": typed[i++],
  "pants.backBeltLoop.text": typed[i++],
  "pants.backBeltLoop.font": typed[i++],
  "pants.backBeltLoop.layout": typed[i++],
  "pants.backBeltLoop.fill": typed[i++],
  "pants.backBeltLoop.fillColor1": typed[i++],
  "pants.backBeltLoop.fillColor2": typed[i++],
  "pants.backBeltLoop.fillColor3": typed[i++],
  "pants.backBeltLoop.fillColor4": typed[i++],
  "pants.backBeltLoop.outlineColor1": typed[i++],
  "pants.backBeltLoop.outlineColor2": typed[i++],
  "pants.backBeltLoop.file": typed[i++],
  "pants.leftLeg": typed[i++],
  "pants.leftLeg.content": typed[i++],
  "pants.leftLeg.text": typed[i++],
  "pants.leftLeg.font": typed[i++],
  "pants.leftLeg.layout": typed[i++],
  "pants.leftLeg.fill": typed[i++],
  "pants.leftLeg.fillColor1": typed[i++],
  "pants.leftLeg.fillColor2": typed[i++],
  "pants.leftLeg.fillColor3": typed[i++],
  "pants.leftLeg.fillColor4": typed[i++],
  "pants.leftLeg.outlineColor1": typed[i++],
  "pants.leftLeg.outlineColor2": typed[i++],
  "pants.leftLeg.file": typed[i++],
  "pants.rightLeg": typed[i++],
  "pants.rightLeg.content": typed[i++],
  "pants.rightLeg.text": typed[i++],
  "pants.rightLeg.font": typed[i++],
  "pants.rightLeg.layout": typed[i++],
  "pants.rightLeg.fill": typed[i++],
  "pants.rightLeg.fillColor1": typed[i++],
  "pants.rightLeg.fillColor2": typed[i++],
  "pants.rightLeg.fillColor3": typed[i++],
  "pants.rightLeg.fillColor4": typed[i++],
  "pants.rightLeg.outlineColor1": typed[i++],
  "pants.rightLeg.outlineColor2": typed[i++],
  "pants.rightLeg.file": typed[i++],
  "pants.braid": typed[i++],
  "pants.braid.position": typed[i++],
  "pants.braid.color1": typed[i++],
  "pants.braid.color2": typed[i++],
  "pants.braid.color3": typed[i++],
  "details": typed[i++],
  "details.teamName": typed[i++],
  "details.roster": typed[i++],
  "details.sizes": typed[i++]
} as const;

export { typed as SUMMARY_TREE };
