import { Nodes } from './common/typings'

const DEFAULT_MENU = 'product'

const DEFAULT_MENU_WHEN_SKU = (nodes: Nodes) => {
  const hasJersey = !!nodes['jersey.sku'].value
  const hasPants = !!nodes['pants.sku'].value
  return !hasJersey && hasPants ? 'pants' : 'jersey'
}

const SKU_MATCHES = ['product.sku']

export { DEFAULT_MENU, DEFAULT_MENU_WHEN_SKU, SKU_MATCHES }
