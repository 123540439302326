import { SvgElement } from '@orangelv/svg-renderer'

import assert from '../../../platform/assert'

import { ProductId, Design } from '../common/sheets'

import fullCustomTemplateOffsets from '../assets/full-custom-templates/offsets.json'

const youthScale = 0.7 // 30% smaller.

const getFullCustomImage = (
  productId: ProductId,
  pieceName: string,
  isYouth: boolean,
  design: Design,
  designFile: { id: string } | null | undefined,
): SvgElement | undefined => {
  if (design.id !== 'fullCustom' || !designFile) return

  const offsets = fullCustomTemplateOffsets[productId]

  assert(offsets, 'Full custom product data not defined!')

  const offset = offsets[pieceName]

  if (!offset) return

  const scaleFactor = isYouth ? youthScale : 1

  return {
    scaleFactor,
    anchor: {
      from: 'center-center' as const,
      to: 'document-center' as const,
      offset: {
        x: offset.x * -1 * scaleFactor,
        y: offset.y * -1 * scaleFactor,
      },
    },
    svg: {
      name:
        // This is needed only for generateIconsForDesigns.
        `"${(designFile.id === 'full-custom-example-design' ?
          designFile.id
        : `/api/images/${designFile.id}`
        ).replaceAll('"', '\\"')}"`,
      colors: {},
      extraAttributes: {
        // Illustrator removes CSS classes, but we can abuse IDs like hidden0, hidden1, hidden2 etc. which don't get removed.
        '[id^=hidden]': {
          visibility: 'hidden',
        },
      },
    },
  }
}

export default getFullCustomImage
