import 'handjs'
import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import { createRoot } from 'react-dom/client'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'
import useResizeObserver from 'use-resize-observer/polyfilled'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import handleErrors from '../../../platform/client/handleErrors'
import * as history from '../../../platform/client/history'
import getAsset from '../../../platform/getAsset'
import {
  isRecipeFinalizedSelector,
  isSkuSelector,
  createMenuSelector,
  getPreviewUrlsSelector,
  isAppLoadingSelector,
  isEmbedSelector,
} from '../../../platform/client/common/selectors'
import {
  togglePreviewMinimization,
  setOriginValues,
  loadRecipe,
  saveRecipe,
  modifyRecipe,
  toggleShare,
  setPreviewMinimization,
  copyLinkToClipboard,
  openMenu,
} from '../../../platform/client/common/actions'
import Application from '../../../platform/client/containers/Application'
import ErrorBoundary from '../../../platform/client/components/ErrorBoundary'
import { connectOrders } from '../../../platform/client/component-enhancers'
import { cn, states } from '../../../platform/client/components/utils'
import PreviewImages from '../../mizuno-apparels/client/components/PreviewImages'
import { createUtils as createControlTreeDebugUtils } from '../../../platform/client/control-tree/debugUtils'
import configureRouter from '../../../platform/client/configureRouter'
import { useAppDispatch } from '../../../platform/client/configureStore'

import Layout from '../../_mizuno/client/components/Layout'
import Sidebar from '../../_mizuno/client/components/Sidebar'
import ProductSet from '../../_mizuno/client/components/molecules/ProductSet'
import ProductTile from '../../_mizuno/client/components/molecules/ProductTile'
import _ProductTitle from '../../_mizuno/client/components/organisms/ProductTitle'
import SocialIcons from '../../_mizuno/client/containers/SocialIcons'
import Summary from '../../_mizuno/client/components/Summary'

import ContactForm from '../../mizuno-uniforms/client/containers/ContactForm'
import _Finalize from '../../mizuno-uniforms/client/containers/Finalize'

import { resume } from './actions'
import { getSku, getSkuValues, garmentSizes } from '../common/meta'
import { Product, PRODUCT_DICT } from '../common/sheets'
import { Nodes, Recipe } from '../common/typings'
import controlTree from './controlTree'
import viewAngles, { defaultViewAngleId } from '../viewAngles'
import store from './store'
import { State } from './updater'
import { Renderer } from '../renderer'
import debugTextures from '../renderer/debugTextures'
import generateFullCustomTemplates from '../renderer/generateFullCustomTemplates'
import { DEFAULT_MENU_WHEN_SKU } from '../exports'
import Approval from './components/Approval'
import SportFilter from './components/SportFilter'
import SportChanger from './components/SportChanger'
import ProductSelection from './components/ProductSelection'
import SidebarPlacements from './components/SidebarPlacements'
import RecipeFinder from './components/RecipeFinder'
import Roster from './components/Roster'
import OrderStatus from './components/OrderStatus'

import '~mizuno/client/components/Root.css'
import './index.css'
import {
  APPROVAL_ERROR_PARAM,
  rosterPreviewIdSelector,
  selectIsApproved,
} from './selectors'

handleErrors(store)

window['debugTextures'] = debugTextures

window['generateFullCustomTemplates'] = generateFullCustomTemplates

// For debugging.
window['store'] = store

const controlTreeDebugUtils = createControlTreeDebugUtils(store, controlTree)

// One should be using control-tree/debugUtils via window like window.getNodes().
window['getControlTree'] = () => controlTree
_.forEach(controlTreeDebugUtils, (fn, name) => {
  window[name] = fn
})

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

viewportUnitsBuggyfill.init()

const returnToSiteName = 'mizunousa.com'
const returnToSiteLink = 'https://www.mizunousa.com/'

const isOrderDisabled = true

const saveButtonLabel = 'Save my uniform'

const isRecipeId = ({ recipeId }) =>
  /[a-z0-9]+/.test(recipeId) && recipeId.length >= 3

const routes = [
  ['/design/:recipeId(/:status)', isRecipeId],
  ['/admin/design/:recipeId(/:status)', isRecipeId],
  '/sku/:sku',
]

const { initialMatches } = configureRouter(controlTree, store, routes)

const initialState = store.getState()

if (initialMatches && initialMatches.recipeId) {
  store.dispatch(loadRecipe(controlTree, initialMatches.recipeId))
} else if (initialMatches && isSkuSelector(initialState)) {
  const { sku } = initialMatches
  const { pants, jersey } = getSkuValues(sku)
  const skuProduct = (jersey || pants) as Product
  store.dispatch(
    controlTree.setValues({
      'product.sku': sku,
      'filters.productType': skuProduct.props.type,
      'filters.sport': skuProduct.props.sport,
    }),
  )
  store.dispatch(setOriginValues(controlTree))
} else {
  store.dispatch(setOriginValues(controlTree))
}

window.addEventListener('message', (ev) => {
  if (fp.has('type', ev.data)) {
    store.dispatch(ev.data)
  }
})

{
  const mql = window.matchMedia('(min-width: 1000px)')
  if (mql.matches) store.dispatch(setPreviewMinimization(false))
  mql.addListener((x) => store.dispatch(setPreviewMinimization(!x.matches)))
}

const ProductTitle = connectOrders(controlTree, _ProductTitle)
const Finalize = connectOrders(controlTree, _Finalize)

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = (state: State) => {
  const nodes = controlTree.getNodes(state)

  const jerseyNode = nodes['jersey.sku']
  const pantsNode = nodes['pants.sku']

  const productsNode = nodes['filters.products']
  const products = productsNode.visibleOptions || productsNode.options

  const isAdmin = _.startsWith(state.history.location.pathname, '/admin/')

  const isSportFilterSet = nodes['filters.sport'].value !== null

  return {
    nodes,
    jerseyNode,
    pantsNode,
    isAppLoading: isAppLoadingSelector(state),
    isRecipeFinalized: isRecipeFinalizedSelector(state),
    isPreviewMinimized: state.isPreviewMinimized,
    previewUrls: getPreviewUrlsSelector(viewAngles, defaultViewAngleId)(state),
    menu: menuSelector(state),
    products,
    isAdmin,
    recipe: controlTree.getPublicRecipe(state),
    isSportFilterSet,
    rosterPreviewId: rosterPreviewIdSelector(state),
    isEmbed: isEmbedSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveRecipe: () => {
    const state = store.getState()

    const nodes = controlTree.getNodes(state)

    const isRosterValidNode = nodes['calc.isRosterValid']

    if (!isRosterValidNode.value) {
      history.push({
        query: {
          menu: 'details/roster',
          rosterError: 1,
          [APPROVAL_ERROR_PARAM]: undefined,
        },
      })
      return
    } else if (!selectIsApproved(state)) {
      history.push({
        query: {
          menu: 'details',
          rosterError: undefined,
          [APPROVAL_ERROR_PARAM]: 1,
        },
      })
      return
    }

    history.push({
      query: { rosterError: undefined, [APPROVAL_ERROR_PARAM]: undefined },
    })

    dispatch(saveRecipe(controlTree))
  },
  modifyRecipe: () => dispatch(modifyRecipe()),
  copyLinkToClipboard: () => dispatch(copyLinkToClipboard()),
  togglePreviewMinimization: () => dispatch(togglePreviewMinimization()),
  toggleShare: () => dispatch(toggleShare()),
  openMenu: (itemId: string) => dispatch(openMenu(controlTree, itemId)),
  changeProduct: (product: Product) => {
    // dispatch(ensurePreviewIntroduced())

    const nodes = controlTree.getNodes(store.getState())

    const {
      props: { type: productType },
    } = product

    const jerseyId =
      productType === 'jersey' ? product.id : nodes['jersey.sku'].value
    const pantsId =
      productType === 'pants' ? product.id : nodes['pants.sku'].value

    const sku = getSku(jerseyId, pantsId)

    dispatch(controlTree.commitChanges())
    dispatch(controlTree.setValues({ 'product.sku': sku }))

    history.push({ path: `/sku/${sku}`, query: { menu: productType } })
  },
})

const getStyledProductName = (jersey?: Product, pants?: Product) => {
  const line1 = jersey?.name ?? pants?.name ?? ''
  const line2 = jersey ? pants?.name : ''
  return (
    <span>
      {line1}
      {line2 && (
        <>
          <span className="styledProductNameSeparator"> +</span>
          <br />
          {line2}
        </>
      )}
    </span>
  )
}

type Props = {
  menu: string
  nodes: Nodes
  recipe: Recipe
  isAppLoading: boolean
  isRecipeFinalized: boolean
  isPreviewMinimized: boolean
  isSportFilterSet: boolean
  jerseyNode: Nodes['jersey.node']
  pantsNode: Nodes['pants.node']
  rosterPreviewId: string
  previewUrls: Record<string, string>
  products: Product[]
  isAdmin: boolean
  openMenu: (menu: string) => void
  changeProduct: (product: Product) => void
  saveRecipe: () => void
  copyLinkToClipboard: () => void
  togglePreviewMinimization: () => void
  isEmbed: boolean
}

const Root = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const rendererResizer = useResizeObserver()

  const isProductScreen = props.menu === 'product' && !props.isRecipeFinalized
  const isFinalizeScreen = props.isRecipeFinalized

  const isRendererHidden = isProductScreen || isFinalizeScreen

  const isJerseyMenu = props.menu === 'jersey'
  const isPantsMenu = props.menu === 'pants'

  const { jerseyNode, pantsNode, rosterPreviewId } = props

  const jerseyId =
    (props.nodes['jersey.sku'].value as string | null | undefined) ?? null
  const pantsId =
    (props.nodes['pants.sku'].value as string | null | undefined) ?? null

  const hasJersey = !!jerseyId
  const hasPants = !!pantsId

  const dispatch = useAppDispatch()

  return (
    <Layout
      {...props}
      isProductScreen={isProductScreen}
      isFinalizeScreen={isFinalizeScreen}
    >
      <ProductTitle
        previewState={props.isPreviewMinimized ? 'off' : 'on'}
        title={props.recipe['details.recipeName.text'] || 'My custom uniform'}
        name={getStyledProductName(
          PRODUCT_DICT[jerseyNode.value],
          PRODUCT_DICT[pantsNode.value],
        )}
        saveButtonLabel={saveButtonLabel}
        onSave={() => props.saveRecipe()}
        onShare={() => {
          props.copyLinkToClipboard()
        }}
        onPreviewToggle={() => props.togglePreviewMinimization()}
        returnToSiteName={returnToSiteName}
        returnToSiteLink={returnToSiteLink}
        priceFormatted={undefined}
        notes={[]}
        isOrderDisabled={isOrderDisabled}
        isEmbed={props.isEmbed}
      />

      <div className="viewer">
        <div className="preview">
          <div
            ref={rendererResizer.ref}
            className={cn([
              'renderer',
              states([props.isPreviewMinimized ? 'off' : 'on']),
            ])}
          >
            <Renderer
              isHidden={isRendererHidden}
              autoResize={`${isRendererHidden}/${rendererResizer.width}/${rendererResizer.height}`}
            />

            {isFinalizeScreen && props.isPreviewMinimized && (
              <img
                className="preview-image-corner"
                src={_.values(props.previewUrls)[0]}
              />
            )}

            {isFinalizeScreen && !props.isPreviewMinimized && (
              <div className="preview-images-outer">
                {!window.serverConfig?.hideSocial && (
                  <SocialIcons
                    classMods={['largeScreen']}
                    downloadUrl={props.previewUrls[defaultViewAngleId]}
                  />
                )}
                <PreviewImages previewUrls={props.previewUrls} />
              </div>
            )}
          </div>
        </div>

        <div className="productSelect">
          {!props.isSportFilterSet ?
            <SportFilter nodes={props.nodes} />
          : <ProductSet>
              {_.map(props.products, (product) => (
                <ProductTile
                  key={product.id}
                  name={product.name}
                  title={product.id}
                  onClick={() => {
                    props.changeProduct(product)
                  }}
                  imageUrl={getAsset(`icons/products/${product.id}.png`)}
                  buttonText={`Build ${
                    product.props.type === 'jersey' ?
                      'this jersey'
                    : 'these pants'
                  }`}
                />
              ))}
            </ProductSet>
          }
        </div>
      </div>

      <div className="sidebar">
        <div className="sidebar-body">
          <ProductSelection
            jerseyNode={jerseyNode}
            pantsNode={pantsNode}
            isJerseyMenu={isJerseyMenu}
            isPantsMenu={isPantsMenu}
          />

          <Sidebar
            nodes={props.nodes}
            RosterComponent={Roster}
            garmentSizes={garmentSizes}
            isRosterPreviewEnabled
            rosterPreviewId={props.rosterPreviewId}
            navOverrides={{
              jersey: () => {
                if (hasJersey) {
                  props.openMenu('jersey')
                } else {
                  store.dispatch(controlTree.commitChanges())
                  store.dispatch(
                    controlTree.setValues({ 'filters.productType': 'jersey' }),
                  )

                  history.push({ query: { menu: 'product' } })
                }
              },
              pants: () => {
                if (hasPants) {
                  props.openMenu('pants')
                } else {
                  store.dispatch(controlTree.commitChanges())
                  store.dispatch(
                    controlTree.setValues({ 'filters.productType': 'pants' }),
                  )

                  history.push({ query: { menu: 'product' } })
                }
              },
              details: () => {
                if (!hasJersey && !hasPants) {
                  return
                }

                props.openMenu('details')
              },
            }}
          />

          {(isJerseyMenu || isPantsMenu) && (
            <SidebarPlacements
              nodes={props.nodes}
              basePath={isJerseyMenu ? 'jersey' : 'pants'}
              rosterPreviewId={rosterPreviewId}
            />
          )}

          {props.isSportFilterSet && props.menu === 'product' && (
            <>
              <SportChanger />
            </>
          )}

          {props.menu === 'product' && (
            <>
              <RecipeFinder />
            </>
          )}

          {props.menu === 'details' && <Approval />}
        </div>
      </div>

      <Finalize
        controlTree={controlTree}
        onSave={props.saveRecipe}
        resetMenu={DEFAULT_MENU_WHEN_SKU(props.nodes)}
        saveButtonLabel={saveButtonLabel}
        resumeButtonLabel="Edit my uniform"
        isAdmin={props.isAdmin}
        onAfterResume={() => dispatch(resume())}
        isOrderDisabled={isOrderDisabled}
      >
        {isFinalizeScreen && !props.isAppLoading && (
          <>
            <ContactForm nonOrder />
            <OrderStatus orderId={initialMatches?.status} />
            <Summary nodes={props.nodes} />
          </>
        )}
      </Finalize>
    </Layout>
  )
})

function onReady() {
  createRoot(document.getElementById('root')!).render(
    <Application store={store}>
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    </Application>,
  )
}

document.addEventListener('DOMContentLoaded', onReady)
