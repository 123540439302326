// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PropDefRow } from '../typings';

const data = [
  {
    "id": "env_currency",
    "name": "Currency",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "product_sku",
    "name": "Product SKU",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_sku",
    "name": "Jersey SKU",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_fabric",
    "name": "Fabric",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_design_design",
    "name": "Design",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_design_color1",
    "name": "Design Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_design_color2",
    "name": "Design Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_design_color3",
    "name": "Design Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_design_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_body_fill",
    "name": "Body Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_body_color1",
    "name": "Body Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_body_color2",
    "name": "Body Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_body_color3",
    "name": "Body Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_body_color4",
    "name": "Body Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_sleeve_fill",
    "name": "Sleeve Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_sleeve_color1",
    "name": "Sleeve Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_sleeve_color2",
    "name": "Sleeve Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_sleeve_color3",
    "name": "Sleeve Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_sleeve_color4",
    "name": "Sleeve Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_collar_fill",
    "name": "Collar Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_collar_color1",
    "name": "Collar Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_collar_color2",
    "name": "Collar Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_collar_color3",
    "name": "Collar Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_collar_color4",
    "name": "Collar Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_runbird_innerColor",
    "name": "Inner Color",
    "tileType": "color",
    "defaultValueId": "black",
    "isOptional": false
  },
  {
    "id": "jersey_runbird_outerColor",
    "name": "Outer Color",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_content",
    "name": "Front Chest",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_frontChest_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "allCourt",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_tail",
    "name": "Tail",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_frontChest_tailText",
    "name": "Tail Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_tailTextFont",
    "name": "Tail Text Font",
    "tileType": "name",
    "defaultValueId": "allCourt",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_frontChest_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_frontChest_tailTextColor",
    "name": "Tail Text Color",
    "tileType": "name",
    "defaultValueId": "darkCharcoal",
    "isOptional": false
  },
  {
    "id": "jersey_frontChest_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_content",
    "name": "Upper Front Left Chest",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_upperFrontLeftChest_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_upperFrontLeftChest_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_upperFrontLeftChest_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_upperFrontLeftChest_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_content",
    "name": "Front Middle",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddle_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddle_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddle_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddle_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_content",
    "name": "Front Middle Left",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddleLeft_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleLeft_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddleLeft_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddleLeft_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_content",
    "name": "Front Middle Right",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddleRight_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_frontMiddleRight_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddleRight_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_frontMiddleRight_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_content",
    "name": "Upper Back",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_upperBack_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "allCourt",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_upperBack_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_upperBack_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_upperBack_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_content",
    "name": "Middle Back",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_middleBack_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_middleBack_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_middleBack_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_middleBack_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_content",
    "name": "Lower Back",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_lowerBack_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_lowerBack_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_lowerBack_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_lowerBack_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_content",
    "name": "Left Sleeve",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_leftSleeve_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_leftSleeve_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_leftSleeve_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_leftSleeve_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_content",
    "name": "Right Sleeve",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_rightSleeve_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "jersey_rightSleeve_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "jersey_rightSleeve_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_rightSleeve_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "jersey_braid_position",
    "name": "Braid Position",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "jersey_braid_color1",
    "name": "Color 1",
    "tileType": "color",
    "defaultValueId": "charcoal",
    "isOptional": true
  },
  {
    "id": "jersey_braid_color2",
    "name": "Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "jersey_braid_color3",
    "name": "Color 3",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "pants_sku",
    "name": "Pants SKU",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "pants_fabric",
    "name": "Fabric",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_design_design",
    "name": "Design",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "pants_design_color1",
    "name": "Design Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_design_color2",
    "name": "Design Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_design_color3",
    "name": "Design Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_design_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_body_fill",
    "name": "Body Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "pants_body_color1",
    "name": "Body Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_body_color2",
    "name": "Body Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_body_color3",
    "name": "Body Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_body_color4",
    "name": "Body Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_beltLoop_fill",
    "name": "Belt Loop Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "pants_beltLoop_color1",
    "name": "Belt Loop Color 1",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_beltLoop_color2",
    "name": "Belt Loop Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_beltLoop_color3",
    "name": "Belt Loop Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_beltLoop_color4",
    "name": "Belt Loop Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_runbird_innerColor",
    "name": "Inner Color",
    "tileType": "color",
    "defaultValueId": "black",
    "isOptional": false
  },
  {
    "id": "pants_runbird_outerColor",
    "name": "Outer Color",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_content",
    "name": "Back Belt Loop",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "pants_backBeltLoop_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_backBeltLoop_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "pants_backBeltLoop_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "pants_backBeltLoop_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_content",
    "name": "Left Leg",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "pants_leftLeg_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_leftLeg_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "pants_leftLeg_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "pants_leftLeg_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_content",
    "name": "Right Leg",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "pants_rightLeg_text",
    "name": "Text",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_font",
    "name": "Font",
    "tileType": "name",
    "defaultValueId": "proBlock",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_layout",
    "name": "Layout",
    "tileType": "name",
    "defaultValueId": "straight",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_fill",
    "name": "Fill",
    "tileType": "name",
    "defaultValueId": "solid",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_fillColor1",
    "name": "Fill Color 1",
    "tileType": "color",
    "defaultValueId": "white",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_fillColor2",
    "name": "Fill Color 2",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_fillColor3",
    "name": "Fill Color 3",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_fillColor4",
    "name": "Fill Color 4",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "pants_rightLeg_outlineColor1",
    "name": "Outline Color 1",
    "tileType": "color",
    "defaultValueId": "darkCharcoal",
    "isOptional": true
  },
  {
    "id": "pants_rightLeg_outlineColor2",
    "name": "Outline Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "pants_rightLeg_file",
    "name": "File",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "pants_braid_position",
    "name": "Braid Position",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "pants_braid_color1",
    "name": "Color 1",
    "tileType": "color",
    "defaultValueId": "charcoal",
    "isOptional": true
  },
  {
    "id": "pants_braid_color2",
    "name": "Color 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "pants_braid_color3",
    "name": "Color 3",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "details_recipeName_text",
    "name": "Recipe Name",
    "description": "Maximum 30 characters.",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_teamName_text",
    "name": "Team Name",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_roster_jerseySize",
    "name": "Jersey Size",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "details_roster_pantsSize",
    "name": "Pants Size",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "details_roster_quantity",
    "name": "Quantity",
    "tileType": "text",
    "defaultValueId": "1",
    "isOptional": false
  },
  {
    "id": "details_roster_number",
    "name": "Number",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_roster_name",
    "name": "Name",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "filters_products",
    "name": "Producs",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "filters_productType",
    "name": "Product Type",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "filters_sport",
    "name": "Sport",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "calc_priceFormatted",
    "name": "Price Formatted",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_jerseySport",
    "name": "Jersey Sport",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_pantsSport",
    "name": "Pants Sport",
    "tileType": "name",
    "isOptional": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "env_currency": typed[i++],
  "product_sku": typed[i++],
  "jersey_sku": typed[i++],
  "jersey_fabric": typed[i++],
  "jersey_design_design": typed[i++],
  "jersey_design_color1": typed[i++],
  "jersey_design_color2": typed[i++],
  "jersey_design_color3": typed[i++],
  "jersey_design_file": typed[i++],
  "jersey_body_fill": typed[i++],
  "jersey_body_color1": typed[i++],
  "jersey_body_color2": typed[i++],
  "jersey_body_color3": typed[i++],
  "jersey_body_color4": typed[i++],
  "jersey_sleeve_fill": typed[i++],
  "jersey_sleeve_color1": typed[i++],
  "jersey_sleeve_color2": typed[i++],
  "jersey_sleeve_color3": typed[i++],
  "jersey_sleeve_color4": typed[i++],
  "jersey_collar_fill": typed[i++],
  "jersey_collar_color1": typed[i++],
  "jersey_collar_color2": typed[i++],
  "jersey_collar_color3": typed[i++],
  "jersey_collar_color4": typed[i++],
  "jersey_runbird_innerColor": typed[i++],
  "jersey_runbird_outerColor": typed[i++],
  "jersey_frontChest_content": typed[i++],
  "jersey_frontChest_text": typed[i++],
  "jersey_frontChest_font": typed[i++],
  "jersey_frontChest_layout": typed[i++],
  "jersey_frontChest_tail": typed[i++],
  "jersey_frontChest_tailText": typed[i++],
  "jersey_frontChest_tailTextFont": typed[i++],
  "jersey_frontChest_fill": typed[i++],
  "jersey_frontChest_fillColor1": typed[i++],
  "jersey_frontChest_fillColor2": typed[i++],
  "jersey_frontChest_fillColor3": typed[i++],
  "jersey_frontChest_fillColor4": typed[i++],
  "jersey_frontChest_outlineColor1": typed[i++],
  "jersey_frontChest_outlineColor2": typed[i++],
  "jersey_frontChest_tailTextColor": typed[i++],
  "jersey_frontChest_file": typed[i++],
  "jersey_upperFrontLeftChest_content": typed[i++],
  "jersey_upperFrontLeftChest_text": typed[i++],
  "jersey_upperFrontLeftChest_font": typed[i++],
  "jersey_upperFrontLeftChest_layout": typed[i++],
  "jersey_upperFrontLeftChest_fill": typed[i++],
  "jersey_upperFrontLeftChest_fillColor1": typed[i++],
  "jersey_upperFrontLeftChest_fillColor2": typed[i++],
  "jersey_upperFrontLeftChest_fillColor3": typed[i++],
  "jersey_upperFrontLeftChest_fillColor4": typed[i++],
  "jersey_upperFrontLeftChest_outlineColor1": typed[i++],
  "jersey_upperFrontLeftChest_outlineColor2": typed[i++],
  "jersey_upperFrontLeftChest_file": typed[i++],
  "jersey_frontMiddle_content": typed[i++],
  "jersey_frontMiddle_text": typed[i++],
  "jersey_frontMiddle_font": typed[i++],
  "jersey_frontMiddle_layout": typed[i++],
  "jersey_frontMiddle_fill": typed[i++],
  "jersey_frontMiddle_fillColor1": typed[i++],
  "jersey_frontMiddle_fillColor2": typed[i++],
  "jersey_frontMiddle_fillColor3": typed[i++],
  "jersey_frontMiddle_fillColor4": typed[i++],
  "jersey_frontMiddle_outlineColor1": typed[i++],
  "jersey_frontMiddle_outlineColor2": typed[i++],
  "jersey_frontMiddle_file": typed[i++],
  "jersey_frontMiddleLeft_content": typed[i++],
  "jersey_frontMiddleLeft_text": typed[i++],
  "jersey_frontMiddleLeft_font": typed[i++],
  "jersey_frontMiddleLeft_layout": typed[i++],
  "jersey_frontMiddleLeft_fill": typed[i++],
  "jersey_frontMiddleLeft_fillColor1": typed[i++],
  "jersey_frontMiddleLeft_fillColor2": typed[i++],
  "jersey_frontMiddleLeft_fillColor3": typed[i++],
  "jersey_frontMiddleLeft_fillColor4": typed[i++],
  "jersey_frontMiddleLeft_outlineColor1": typed[i++],
  "jersey_frontMiddleLeft_outlineColor2": typed[i++],
  "jersey_frontMiddleLeft_file": typed[i++],
  "jersey_frontMiddleRight_content": typed[i++],
  "jersey_frontMiddleRight_text": typed[i++],
  "jersey_frontMiddleRight_font": typed[i++],
  "jersey_frontMiddleRight_layout": typed[i++],
  "jersey_frontMiddleRight_fill": typed[i++],
  "jersey_frontMiddleRight_fillColor1": typed[i++],
  "jersey_frontMiddleRight_fillColor2": typed[i++],
  "jersey_frontMiddleRight_fillColor3": typed[i++],
  "jersey_frontMiddleRight_fillColor4": typed[i++],
  "jersey_frontMiddleRight_outlineColor1": typed[i++],
  "jersey_frontMiddleRight_outlineColor2": typed[i++],
  "jersey_frontMiddleRight_file": typed[i++],
  "jersey_upperBack_content": typed[i++],
  "jersey_upperBack_text": typed[i++],
  "jersey_upperBack_font": typed[i++],
  "jersey_upperBack_layout": typed[i++],
  "jersey_upperBack_fill": typed[i++],
  "jersey_upperBack_fillColor1": typed[i++],
  "jersey_upperBack_fillColor2": typed[i++],
  "jersey_upperBack_fillColor3": typed[i++],
  "jersey_upperBack_fillColor4": typed[i++],
  "jersey_upperBack_outlineColor1": typed[i++],
  "jersey_upperBack_outlineColor2": typed[i++],
  "jersey_upperBack_file": typed[i++],
  "jersey_middleBack_content": typed[i++],
  "jersey_middleBack_text": typed[i++],
  "jersey_middleBack_font": typed[i++],
  "jersey_middleBack_layout": typed[i++],
  "jersey_middleBack_fill": typed[i++],
  "jersey_middleBack_fillColor1": typed[i++],
  "jersey_middleBack_fillColor2": typed[i++],
  "jersey_middleBack_fillColor3": typed[i++],
  "jersey_middleBack_fillColor4": typed[i++],
  "jersey_middleBack_outlineColor1": typed[i++],
  "jersey_middleBack_outlineColor2": typed[i++],
  "jersey_middleBack_file": typed[i++],
  "jersey_lowerBack_content": typed[i++],
  "jersey_lowerBack_text": typed[i++],
  "jersey_lowerBack_font": typed[i++],
  "jersey_lowerBack_layout": typed[i++],
  "jersey_lowerBack_fill": typed[i++],
  "jersey_lowerBack_fillColor1": typed[i++],
  "jersey_lowerBack_fillColor2": typed[i++],
  "jersey_lowerBack_fillColor3": typed[i++],
  "jersey_lowerBack_fillColor4": typed[i++],
  "jersey_lowerBack_outlineColor1": typed[i++],
  "jersey_lowerBack_outlineColor2": typed[i++],
  "jersey_lowerBack_file": typed[i++],
  "jersey_leftSleeve_content": typed[i++],
  "jersey_leftSleeve_text": typed[i++],
  "jersey_leftSleeve_font": typed[i++],
  "jersey_leftSleeve_layout": typed[i++],
  "jersey_leftSleeve_fill": typed[i++],
  "jersey_leftSleeve_fillColor1": typed[i++],
  "jersey_leftSleeve_fillColor2": typed[i++],
  "jersey_leftSleeve_fillColor3": typed[i++],
  "jersey_leftSleeve_fillColor4": typed[i++],
  "jersey_leftSleeve_outlineColor1": typed[i++],
  "jersey_leftSleeve_outlineColor2": typed[i++],
  "jersey_leftSleeve_file": typed[i++],
  "jersey_rightSleeve_content": typed[i++],
  "jersey_rightSleeve_text": typed[i++],
  "jersey_rightSleeve_font": typed[i++],
  "jersey_rightSleeve_layout": typed[i++],
  "jersey_rightSleeve_fill": typed[i++],
  "jersey_rightSleeve_fillColor1": typed[i++],
  "jersey_rightSleeve_fillColor2": typed[i++],
  "jersey_rightSleeve_fillColor3": typed[i++],
  "jersey_rightSleeve_fillColor4": typed[i++],
  "jersey_rightSleeve_outlineColor1": typed[i++],
  "jersey_rightSleeve_outlineColor2": typed[i++],
  "jersey_rightSleeve_file": typed[i++],
  "jersey_braid_position": typed[i++],
  "jersey_braid_color1": typed[i++],
  "jersey_braid_color2": typed[i++],
  "jersey_braid_color3": typed[i++],
  "pants_sku": typed[i++],
  "pants_fabric": typed[i++],
  "pants_design_design": typed[i++],
  "pants_design_color1": typed[i++],
  "pants_design_color2": typed[i++],
  "pants_design_color3": typed[i++],
  "pants_design_file": typed[i++],
  "pants_body_fill": typed[i++],
  "pants_body_color1": typed[i++],
  "pants_body_color2": typed[i++],
  "pants_body_color3": typed[i++],
  "pants_body_color4": typed[i++],
  "pants_beltLoop_fill": typed[i++],
  "pants_beltLoop_color1": typed[i++],
  "pants_beltLoop_color2": typed[i++],
  "pants_beltLoop_color3": typed[i++],
  "pants_beltLoop_color4": typed[i++],
  "pants_runbird_innerColor": typed[i++],
  "pants_runbird_outerColor": typed[i++],
  "pants_backBeltLoop_content": typed[i++],
  "pants_backBeltLoop_text": typed[i++],
  "pants_backBeltLoop_font": typed[i++],
  "pants_backBeltLoop_layout": typed[i++],
  "pants_backBeltLoop_fill": typed[i++],
  "pants_backBeltLoop_fillColor1": typed[i++],
  "pants_backBeltLoop_fillColor2": typed[i++],
  "pants_backBeltLoop_fillColor3": typed[i++],
  "pants_backBeltLoop_fillColor4": typed[i++],
  "pants_backBeltLoop_outlineColor1": typed[i++],
  "pants_backBeltLoop_outlineColor2": typed[i++],
  "pants_backBeltLoop_file": typed[i++],
  "pants_leftLeg_content": typed[i++],
  "pants_leftLeg_text": typed[i++],
  "pants_leftLeg_font": typed[i++],
  "pants_leftLeg_layout": typed[i++],
  "pants_leftLeg_fill": typed[i++],
  "pants_leftLeg_fillColor1": typed[i++],
  "pants_leftLeg_fillColor2": typed[i++],
  "pants_leftLeg_fillColor3": typed[i++],
  "pants_leftLeg_fillColor4": typed[i++],
  "pants_leftLeg_outlineColor1": typed[i++],
  "pants_leftLeg_outlineColor2": typed[i++],
  "pants_leftLeg_file": typed[i++],
  "pants_rightLeg_content": typed[i++],
  "pants_rightLeg_text": typed[i++],
  "pants_rightLeg_font": typed[i++],
  "pants_rightLeg_layout": typed[i++],
  "pants_rightLeg_fill": typed[i++],
  "pants_rightLeg_fillColor1": typed[i++],
  "pants_rightLeg_fillColor2": typed[i++],
  "pants_rightLeg_fillColor3": typed[i++],
  "pants_rightLeg_fillColor4": typed[i++],
  "pants_rightLeg_outlineColor1": typed[i++],
  "pants_rightLeg_outlineColor2": typed[i++],
  "pants_rightLeg_file": typed[i++],
  "pants_braid_position": typed[i++],
  "pants_braid_color1": typed[i++],
  "pants_braid_color2": typed[i++],
  "pants_braid_color3": typed[i++],
  "details_recipeName_text": typed[i++],
  "details_teamName_text": typed[i++],
  "details_roster_jerseySize": typed[i++],
  "details_roster_pantsSize": typed[i++],
  "details_roster_quantity": typed[i++],
  "details_roster_number": typed[i++],
  "details_roster_name": typed[i++],
  "filters_products": typed[i++],
  "filters_productType": typed[i++],
  "filters_sport": typed[i++],
  "calc_priceFormatted": typed[i++],
  "calc_jerseySport": typed[i++],
  "calc_pantsSport": typed[i++]
} as const;

export { typed as PROP_DEFS };
