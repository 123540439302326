import React from 'react'

import * as history from '../../../../platform/client/history'

import store from '../store'
import controlTree from '../controlTree'
import { getSku } from '../../common/meta'

import './ProductSelection.css'

type ProductNode = {
  value: string | undefined
}

type Props = {
  jerseyNode: ProductNode | undefined
  pantsNode: ProductNode | undefined
  isJerseyMenu: boolean
  isPantsMenu: boolean
}

const ProductSelection = (props: Props) => {
  const { jerseyNode, pantsNode, isJerseyMenu, isPantsMenu } = props

  if (!isJerseyMenu && !isPantsMenu) {
    return null
  }

  let productNode: ProductNode | undefined
  if (isJerseyMenu) {
    productNode = jerseyNode
  } else if (isPantsMenu) {
    productNode = pantsNode
  }

  const isProductSelected = productNode?.value !== undefined

  const isProductUnselectable =
    isJerseyMenu ?
      pantsNode?.value === undefined
    : jerseyNode?.value === undefined

  return (
    <div className="productSelection">
      Selected {isJerseyMenu ? 'Jersey' : 'Pants'}:{' '}
      <strong>{isProductSelected ? productNode!.value : 'None'}</strong>{' '}
      {isProductSelected ?
        !isProductUnselectable ?
          <button
            onClick={() => {
              const nodes = controlTree.getNodes(store.getState())

              const jerseyId =
                isJerseyMenu ? undefined : nodes['jersey.sku'].value
              const pantsId = isPantsMenu ? undefined : nodes['pants.sku'].value

              const sku = getSku(jerseyId, pantsId)

              const values = {
                'product.sku': sku,
              }

              store.dispatch(controlTree.commitChanges())
              store.dispatch(controlTree.setValues(values))

              history.push({ path: `/sku/${sku}` })
            }}
          >
            Unselect
          </button>
        : null
      : <button
          onClick={() => {
            const values = {
              'filters.productType': isJerseyMenu ? 'jersey' : 'pants',
            }

            store.dispatch(controlTree.setValues(values))

            history.push({ query: { menu: 'product' } })
          }}
        >
          Select
        </button>
      }
    </div>
  )
}

export default ProductSelection
