// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ProductStyleRow } from '../typings';

const data = [
  {
    "id": "fullButton",
    "name": "Full Button"
  },
  {
    "id": "twoButton",
    "name": "2 Button"
  },
  {
    "id": "neck",
    "name": "Neck"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ProductStyleRow[]>> = data;

export type ProductStyle = typeof typed[number];

export const PRODUCT_STYLE_INDEX_KEY = "id";
export type ProductStyleIndexKey = "id";
export type ProductStyleId = ProductStyle["id"];

let i = 0;
export const PRODUCT_STYLE_DICT = {
  "fullButton": typed[i++],
  "twoButton": typed[i++],
  "neck": typed[i++]
} as const;

export { typed as PRODUCT_STYLES };
