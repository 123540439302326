import * as _ from '@technically/lodash'

import getAsset from '../../../../platform/getAsset'

import { Nodes } from '../../common/typings'
import store from '../store'
import controlTree from '../controlTree'

import './SportFilter.css'

const FILTERS_SPORT_NODE_KEY = 'filters.sport'

type Props = {
  nodes: Nodes
}

const SportFilter = (props: Props) => {
  const sportNode = props.nodes[FILTERS_SPORT_NODE_KEY]
  const options = sportNode.visibleOptions || sportNode.options

  return (
    <div className="sportFilter">
      {_.map(options, (option) => (
        <div
          key={option.id}
          className="sportItem"
          onClick={() => {
            store.dispatch(
              controlTree.change(FILTERS_SPORT_NODE_KEY, option.id),
            )
          }}
        >
          <img
            src={getAsset(`icons/sports/${option.id}.jpg`)}
            alt={option.name}
          />
          <div className="sportName">{option.name}</div>
        </div>
      ))}
    </div>
  )
}

export default SportFilter
