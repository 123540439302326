import * as _ from '@technically/lodash'
import FileSaver from 'file-saver'
import JSZip from 'jszip'

import { SvgElement } from '@orangelv/svg-renderer'
import { svgToString } from '@orangelv/utils-svg'
import { getPatternName } from '@orangelv/mizuno-utils'

import assert from '../../../platform/assert'

import store from '../client/store'
import controlTree from '../client/controlTree'
import { getJerseyPlayers, getPantsPlayers, Player } from './players'
import {
  DebugView,
  debugHtml,
  loadFont,
  loadSvg,
  loadPattern,
} from '../../../platform/client/svg-renderer-utils'
import getPlacements from './getPlacements'
import {
  SizeId,
  Product,
  Size,
  FABRICS,
  FREE_FORM_PIECE_DATA,
  SIZES,
  PRODUCT_DICT,
} from '../common/sheets'
import { Recipe } from '../common/typings'
import { PATTERN_PACKAGE } from '../consts'
import { renderPieceFromRecipe } from '../common/svg-renderer-utils'
import { getSize } from '../client/svg-renderer-utils'

type Config = {
  debug?: boolean
  outputType?: 'html' | 'zip'
  fabricGroups?: string[]
  sizes?: SizeId[]
  pieceNames?: string[]
  view?: DebugView
}

const outputZip = async (output: Record<string, string>) => {
  const zip = new JSZip()
  _.each(output, (value, key) => {
    zip.file(`${key}.svg`, value)
  })

  const zipBlob = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(zipBlob, 'debug-textures.zip')
}

const debugTexturesForProduct = async (
  config: Config,
  output: Record<string, string>,
  recipe: Recipe,
  product: Product,
  player: Player,
  fabricGroups: string[],
  sizes: Size[],
) => {
  const { pieceNames: pieceNamesWanted, debug } = config

  for (const fabricGroup of fabricGroups) {
    const fabric = FABRICS.find((x) => x.groupId === fabricGroup)
    if (!fabric) throw new Error('Fabric not found')

    for (const size of sizes) {
      const patternFamilyName = size.isYouth ? product.youthSku : product.id

      assert(
        patternFamilyName !== undefined,
        `Product ${patternFamilyName} has youth size - ${size.id}, but no youthSku!`,
      )

      const patternName = getPatternName(patternFamilyName, fabric.id)
      const pattern = await loadPattern(PATTERN_PACKAGE, patternName)

      for (const pieceMappingItem of pattern.pieceMapping) {
        const pieceName = pieceMappingItem.name
        if (pieceNamesWanted && !pieceNamesWanted.includes(pieceName)) continue
        console.log(
          `Generating debugTexture for ${patternFamilyName}/${fabric.id}/${size.id}/${pieceName}`,
        )

        const extraImageElements: SvgElement[] = []
        if (debug) {
          const freeFormPieceData = _.find(
            FREE_FORM_PIECE_DATA,
            (data) =>
              data.productId === product.id &&
              _.includes(data.pieceNames, pieceName),
          )

          if (freeFormPieceData) {
            const { adult: adultBb, youth: youthBb } = freeFormPieceData

            const ratio =
              size.isYouth ?
                Math.min(
                  youthBb.width / adultBb.width,
                  youthBb.height / adultBb.height,
                )
              : 1

            const bb = size.isYouth ? youthBb : adultBb

            let offsetX = bb.x
            const otherPieceOffsetX =
              (freeFormPieceData?.offsetX &&
                freeFormPieceData?.offsetX[size.id]) ??
              0
            if (pieceName === 'frontRight') {
              offsetX += otherPieceOffsetX
            } else if (pieceName === 'frontLeft') {
              offsetX -= otherPieceOffsetX
            }
            const offsetY = bb.y

            const freeFormBoundingBoxImage: SvgElement = {
              svg: {
                name: '"designs/solid"',
                colors: {},
                extraAttributes: {
                  '*': {
                    visibility: 'hidden',
                  },
                },
              },
              anchor: {
                from: 'center-center' as const,
                to: 'document-center' as const,
                offset: {
                  x: offsetX * ratio,
                  y: offsetY * ratio,
                },
              },
              boundingBox: {
                width: bb.width * ratio,
                height: bb.height * ratio,
              },
            }

            extraImageElements.push(freeFormBoundingBoxImage)
          }
        }

        const svg = await renderPieceFromRecipe({
          product,
          pattern,
          patternName,
          pieceMappingItem,
          sizeName: size.id,
          recipe,
          player,
          salesId: 'debug',
          getSize,
          loadSvg,
          loadFont,
          placements: getPlacements(recipe, product, size.id, pieceName),
          extraImageElements,
          debug,
        })

        const outputKey = `${patternFamilyName}/${fabricGroup}/${size.id}/${pieceName}`

        output[outputKey] = svgToString(svg)
      }
    }
  }
}

async function debugTextures(config: Config = {}) {
  console.time('debugTextures')

  const output = {}

  const state = store.getState()
  const recipe = controlTree.getPublicRecipe(state)

  const jerseyId = recipe['jersey.sku']
  const pantsId = recipe['pants.sku']

  const playerForJersey = getJerseyPlayers(recipe)[0]
  const playerForPants = getPantsPlayers(recipe)[0]

  const fabricGroups = config.fabricGroups ?? ['P-2027']

  const sizesForJersey = SIZES.filter(
    (size) =>
      size.availableFor.product[jerseyId] &&
      (config.sizes ? config.sizes.includes(size.id) : true),
  )
  const sizesForPants = SIZES.filter(
    (size) =>
      size.availableFor.product[pantsId] &&
      (config.sizes ? config.sizes.includes(size.id) : true),
  )

  if (jerseyId) {
    const jersey = PRODUCT_DICT[jerseyId]

    await debugTexturesForProduct(
      config,
      output,
      recipe,
      jersey,
      playerForJersey,
      fabricGroups,
      sizesForJersey,
    )
  }

  if (pantsId) {
    const pants = PRODUCT_DICT[pantsId]

    await debugTexturesForProduct(
      config,
      output,
      recipe,
      pants,
      playerForPants,
      fabricGroups,
      sizesForPants,
    )
  }

  if ((config?.outputType ?? 'html') === 'html') {
    debugHtml(output, config?.view)
  } else if (config?.outputType === 'zip') {
    outputZip(output)
  } else {
    throw Error('Unknown config.outputType!')
  }

  console.timeEnd('debugTextures')
}

export default debugTextures
