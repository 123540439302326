// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { LayoutRow } from '../typings';

const data = [
  {
    "id": "straight",
    "name": "Straight",
    "iconText": "Straight",
    "isEnabled": true,
    "isScriptCompatible": true,
    "isTailCompatible": true
  },
  {
    "id": "bridge",
    "name": "Bridge",
    "iconText": "Bridge",
    "isEnabled": true,
    "isScriptCompatible": false,
    "isTailCompatible": false
  },
  {
    "id": "bridgeBookends",
    "name": "Bridge Bookends",
    "iconText": "Bookends",
    "isEnabled": true,
    "isScriptCompatible": false,
    "isTailCompatible": false
  },
  {
    "id": "reversedBookends",
    "name": "Reversed Bookends",
    "iconText": "Bookends",
    "isEnabled": true,
    "isScriptCompatible": false,
    "isTailCompatible": false
  },
  {
    "id": "archedBookends",
    "name": "Arched Bookends",
    "iconText": "Bookends",
    "isEnabled": true,
    "isScriptCompatible": false,
    "isTailCompatible": false
  },
  {
    "id": "sweep",
    "name": "Sweep",
    "iconText": "Sweep",
    "isEnabled": true,
    "isScriptCompatible": true,
    "isTailCompatible": true
  },
  {
    "id": "arched",
    "name": "Arched",
    "iconText": "Arched",
    "isEnabled": true,
    "isScriptCompatible": false,
    "isTailCompatible": false
  },
  {
    "id": "verticalArch",
    "name": "Vertical Arch",
    "iconText": "Arch",
    "isEnabled": true,
    "isScriptCompatible": false,
    "isTailCompatible": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LayoutRow[]>> = data;

export type Layout = typeof typed[number];

export const LAYOUT_INDEX_KEY = "id";
export type LayoutIndexKey = "id";
export type LayoutId = Layout["id"];

let i = 0;
export const LAYOUT_DICT = {
  "straight": typed[i++],
  "bridge": typed[i++],
  "bridgeBookends": typed[i++],
  "reversedBookends": typed[i++],
  "archedBookends": typed[i++],
  "sweep": typed[i++],
  "arched": typed[i++],
  "verticalArch": typed[i++]
} as const;

export { typed as LAYOUTS };
