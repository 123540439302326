import { useEffect, useState } from 'react'
import moment from 'moment-timezone'

import http from '../../../../platform/client/http'
import './OrderStatus.css'

type Row = {
  id: number
  start: string | null
  end: string | null
  process: { name: string }
}

const OrderStatusNonNull = ({ orderId }: { orderId: string }) => {
  const [data, setData] = useState<Row[]>([])
  const [error, setError] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        const response = await http.get(`/api/order-status/${orderId}`)
        setData(response.data)
        setError('')
      } catch (error) {
        if (error.response.status === 404) {
          setError('Order does not have any status information yet')
        } else if (error.response.status >= 500) {
          setError('Order status is not available currently')
        }
      }
    })()
  }, [orderId])

  return (
    <div id="OrderStatus">
      <div className="OrderHeader">
        <div className="Title">Order Status</div>
      </div>

      <div className="OrderBody">
        {error && <p className="OrderError">{error}</p>}
        {data.length > 0 && (
          <table>
            <tbody>
              <tr>
                <th>Process</th>
                <th>Start</th>
                <th>End</th>
              </tr>
              {data.map((row) => (
                <tr key={row.id}>
                  <td>{row.process.name}</td>
                  <td>{row.start ? moment(row.start).calendar() : '-'}</td>
                  <td>{row.end ? moment(row.end).calendar() : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

const OrderStatus = ({ orderId }: { orderId?: string }) =>
  orderId === undefined ? null : <OrderStatusNonNull orderId={orderId} />

export default OrderStatus
