import { useState } from 'react'

import http from '../../../../platform/client/http'

import Button from '../../../_mizuno/client/components/atoms/Button'
import Icon from '../../../_mizuno/client/components/atoms/Icon'
import TextInput from '../../../_mizuno/client/components/molecules/TextInput'

import './RecipeFinder.css'

const Input = () => {
  const [orderId, setOrderId] = useState('')
  const [isInvalid, setInvalid] = useState(false)

  const submit = async () => {
    setInvalid(false)

    try {
      const response = await http(`/api/find-recipe-by-order-id/${orderId}`)
      const { recipeId } = response.data

      const currentLocation = window.location
      currentLocation.href = `/design/${recipeId}/${orderId}`
    } catch (e) {
      setInvalid(true)
    }
  }

  return (
    <div className="input">
      <TextInput
        subline="Sales ID"
        value={orderId}
        placeholder="54321"
        classMods={[isInvalid && 'invalid']}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={(v: string) => {
          setOrderId(v)
          setInvalid(false)
        }}
        onSubmit={() => {
          submit()
        }}
      />
      <Button
        classMods={['black', 'narrow']}
        onClick={() => {
          submit()
        }}
      >
        <Icon name="eye" />
      </Button>
    </div>
  )
}

const RecipeFinder = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="recipeFinder">
      <button
        className="inputToggle"
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        Find by Sales ID
      </button>

      {isOpen && <Input />}
    </div>
  )
}

export default RecipeFinder
