// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PlacementRow } from '../typings';

const data = [
  {
    "id": "jerseyRunbird",
    "groupId": "front",
    "name": "Mizuno Logo",
    "shortName": "Mizuno Logo",
    "isDynamic": false,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front",
      "frontRight"
    ]
  },
  {
    "id": "sizeTag",
    "groupId": "front",
    "name": "Size Tag",
    "shortName": "Size Tag",
    "isDynamic": false,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front",
      "frontLeft"
    ]
  },
  {
    "id": "frontChest",
    "groupId": "front",
    "name": "Front Chest",
    "shortName": "Chest",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front",
      "frontLeft",
      "frontRight"
    ]
  },
  {
    "id": "upperFrontLeftChest",
    "groupId": "front",
    "name": "Upper Front Left Chest",
    "shortName": "Upper-Left Chest",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front",
      "frontLeft",
      "frontRight"
    ]
  },
  {
    "id": "frontMiddle",
    "groupId": "front",
    "name": "Front Middle",
    "shortName": "Middle",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front"
    ]
  },
  {
    "id": "frontMiddleLeft",
    "groupId": "front",
    "name": "Front Middle Left",
    "shortName": "Middle-Left",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front",
      "frontLeft",
      "frontRight"
    ]
  },
  {
    "id": "frontMiddleRight",
    "groupId": "front",
    "name": "Front Middle Right",
    "shortName": "Middle-Right",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "front",
      "frontRight",
      "frontLeft"
    ]
  },
  {
    "id": "upperBack",
    "groupId": "back",
    "name": "Upper Back",
    "shortName": "Upper",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "back"
    ]
  },
  {
    "id": "middleBack",
    "groupId": "back",
    "name": "Middle Back",
    "shortName": "Middle",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "back"
    ]
  },
  {
    "id": "lowerBack",
    "groupId": "back",
    "name": "Lower Back",
    "shortName": "Lower",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "back"
    ]
  },
  {
    "id": "leftSleeve",
    "groupId": "sleeves",
    "name": "Left Sleeve",
    "shortName": "Left",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "sleeveLeft"
    ]
  },
  {
    "id": "rightSleeve",
    "groupId": "sleeves",
    "name": "Right Sleeve",
    "shortName": "Right",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": true,
        "pants": false
      }
    },
    "pieceNames": [
      "sleeveRight"
    ]
  },
  {
    "id": "pantsRunbird",
    "groupId": "legs",
    "name": "Mizuno Logo",
    "shortName": "Mizuno Logo",
    "isDynamic": false,
    "isAvailable": {
      "productType": {
        "jersey": false,
        "pants": true
      }
    },
    "pieceNames": [
      "pLeftFront"
    ]
  },
  {
    "id": "leftLeg",
    "groupId": "legs",
    "name": "Left Leg",
    "shortName": "Left",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": false,
        "pants": true
      }
    },
    "pieceNames": [
      "pLeftFront"
    ]
  },
  {
    "id": "rightLeg",
    "groupId": "legs",
    "name": "Right Leg",
    "shortName": "Right",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": false,
        "pants": true
      }
    },
    "pieceNames": [
      "pRightFront"
    ]
  },
  {
    "id": "backBeltLoop",
    "groupId": "belt",
    "name": "Back Belt Loop",
    "shortName": "Back Loop",
    "isDynamic": true,
    "isAvailable": {
      "productType": {
        "jersey": false,
        "pants": true
      }
    },
    "pieceNames": [
      "pBackLoop"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PlacementRow[]>> = data;

export type Placement = typeof typed[number];

export const PLACEMENT_INDEX_KEY = "id";
export type PlacementIndexKey = "id";
export type PlacementId = Placement["id"];

let i = 0;
export const PLACEMENT_DICT = {
  "jerseyRunbird": typed[i++],
  "sizeTag": typed[i++],
  "frontChest": typed[i++],
  "upperFrontLeftChest": typed[i++],
  "frontMiddle": typed[i++],
  "frontMiddleLeft": typed[i++],
  "frontMiddleRight": typed[i++],
  "upperBack": typed[i++],
  "middleBack": typed[i++],
  "lowerBack": typed[i++],
  "leftSleeve": typed[i++],
  "rightSleeve": typed[i++],
  "pantsRunbird": typed[i++],
  "leftLeg": typed[i++],
  "rightLeg": typed[i++],
  "backBeltLoop": typed[i++]
} as const;

export { typed as PLACEMENTS };
