import { getAnchors } from '@orangelv/mizuno-utils'
import { createGetSize } from '@orangelv/utils-olvpf'

import { sharedMemo } from '../../../platform/common/shared-memo'
import { loadSize } from '../../../platform/client/svg-renderer-utils'

export const getSize = createGetSize(sharedMemo, loadSize, {
  getAnchors,
  anchorToSewLines: true,
})
