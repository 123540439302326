// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignRow } from '../typings';

const data = [
  {
    "id": "solid",
    "name": "Solid",
    "props": {
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": true
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "trifade",
    "name": "Tri-fade",
    "props": {
      "color1": [
        "TOP"
      ],
      "color2": [
        "BOTTOM"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "warrior",
    "name": "Warrior",
    "props": {
      "color1": [
        "outer"
      ],
      "color2": [
        "inner"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": true
      }
    }
  },
  {
    "id": "horizon",
    "name": "Horizon",
    "props": {
      "color1": [
        "a-1",
        "a-2",
        "a-3"
      ],
      "color2": [
        "b-1",
        "b-2",
        "b-3"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "throwback",
    "name": "Throwback",
    "props": {
      "color1": [
        "a-1",
        "a-2",
        "a-3",
        "a-4",
        "a-5",
        "a-6",
        "a-7",
        "a-8"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "bigStripe",
    "name": "Big Stripe",
    "props": {
      "color1": [
        "a-1",
        "a-2"
      ],
      "color2": [
        "b-1",
        "b-2",
        "b-3"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "varsity",
    "name": "Varsity",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "dualStripe",
    "name": "Dual Stripe",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "lemon",
        "color2": "yellow"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": true
      }
    }
  },
  {
    "id": "racer",
    "name": "Racer",
    "props": {
      "color1": [
        "a-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": true
      }
    }
  },
  {
    "id": "banded",
    "name": "Banded",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "white",
        "color3": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": true
      }
    }
  },
  {
    "id": "burst",
    "name": "Burst",
    "props": {
      "color1": [
        "a-1",
        "a-2",
        "a-3",
        "a-4",
        "a-5",
        "a-6",
        "a-7"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": false,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "blocked",
    "name": "Blocked",
    "props": {
      "color1": [
        "a-1",
        "a-2"
      ],
      "color2": [
        "b-1",
        "b-2"
      ],
      "color3": [
        "c-1",
        "c-2"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red",
        "color3": "kellyGreen"
      },
      "isAvailable": {
        "sport": {
          "baseball": false,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "stripedFade",
    "name": "Striped Fade",
    "props": {
      "color1": [
        "a-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": false,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": false
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "pSolid",
    "name": "Solid",
    "props": {
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "p-2",
    "name": "Tri-fade",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "p-6",
    "name": "p-6",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1",
        "b-2"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red",
        "color3": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": false,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "p-8",
    "name": "p-8",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "p-9",
    "name": "p-9",
    "props": {
      "color1": [
        "a-1"
      ],
      "defaults": {
        "color1": "royal"
      },
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": false,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "p-stripedFade",
    "name": "Striped Fade",
    "props": {
      "color1": [
        "a-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red"
      },
      "isAvailable": {
        "sport": {
          "baseball": false,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "p-blocked",
    "name": "Blocked",
    "props": {
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "defaults": {
        "color1": "royal",
        "color2": "red",
        "color3": "kellyGreen"
      },
      "isAvailable": {
        "sport": {
          "baseball": false,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": false,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  },
  {
    "id": "fullCustom",
    "name": "Full Custom",
    "props": {
      "isAvailable": {
        "sport": {
          "baseball": true,
          "softball": true,
          "volleyball": false
        },
        "productType": {
          "jersey": true,
          "pants": true
        },
        "isSleevesOnly": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignRow[]>> = data;

export type Design = typeof typed[number];

export const DESIGN_INDEX_KEY = "id";
export type DesignIndexKey = "id";
export type DesignId = Design["id"];

let i = 0;
export const DESIGN_DICT = {
  "solid": typed[i++],
  "trifade": typed[i++],
  "warrior": typed[i++],
  "horizon": typed[i++],
  "throwback": typed[i++],
  "bigStripe": typed[i++],
  "varsity": typed[i++],
  "dualStripe": typed[i++],
  "racer": typed[i++],
  "banded": typed[i++],
  "burst": typed[i++],
  "blocked": typed[i++],
  "stripedFade": typed[i++],
  "pSolid": typed[i++],
  "p-2": typed[i++],
  "p-6": typed[i++],
  "p-8": typed[i++],
  "p-9": typed[i++],
  "p-stripedFade": typed[i++],
  "p-blocked": typed[i++],
  "fullCustom": typed[i++]
} as const;

export { typed as DESIGNS };
