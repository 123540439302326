// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignPartRow } from '../typings';

const data = [
  {
    "designId": "trifade",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/trifade-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 7.59, 7.26, 6.93, 6.61, 6.19, 5.91, 5.63][sizeIndex])"
  },
  {
    "designId": "trifade",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/trifade-2_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "translateY": "in2px(-4)"
  },
  {
    "designId": "warrior",
    "pieceNameGlob": "sleeveLeft",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/zigzag_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(5)"
  },
  {
    "designId": "warrior",
    "pieceNameGlob": "sleeveRight",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/zigzag_adult",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(5)"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "!350{773,775,787,788,789,798,801}",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.43, 4.07, 3.74, 3.42, 2.98, 2.72, 2.44][sizeIndex])"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350773",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.414, 4.184, 4.009, 3.795, 3.492, 3.187][sizeIndex])"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350775",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.43, 4.07, 3.74, 3.42, 2.98, 2.72, 2.44][sizeIndex]-0.5)"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350787",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.455, 4.248, 4.053, 3.768, 3.468, 3.203][sizeIndex])"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350788",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.34, 4.105, 3.864, 3.512, 3.023, 2.586][sizeIndex])"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350789",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.43, 4.07, 3.74, 3.42, 2.98, 2.72, 2.44][sizeIndex]+0.5)"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350{798,801}",
    "youth": false,
    "assetName": "designs/horizon-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.455, 4.248, 4.053, 3.768, 3.468, 3.203][sizeIndex])"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "!350785",
    "youth": false,
    "assetName": "designs/horizon-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "bottom",
    "translateY": "in2px(-2.45)",
    "spreadX": "in2px(0.6)"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "350785",
    "youth": false,
    "assetName": "designs/horizon-arch-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.33)",
    "spreadX": "in2px(0.6)",
    "scale": "0.9"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "!350{773,775,777,787,788,789,790,798,799,801,802}",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.75, 4.4, 4.1, 3.76, 3.35, 3.05, 2.8][sizeIndex])"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350773",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.414, 4.184, 4.009, 3.795, 3.492, 3.187][sizeIndex])"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350{787,798,801}",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.455, 4.248, 4.053, 3.768, 3.468, 3.203][sizeIndex])"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350788",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.34, 4.105, 3.864, 3.512, 3.023, 2.586][sizeIndex])"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350{775,777,789}",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.43, 4.07, 3.74, 3.42, 2.98, 2.72, 2.44][sizeIndex]-2)"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350790",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.43, 4.07, 3.74, 3.42, 2.98, 2.72, 2.44][sizeIndex]-1.8)"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350{799,802}",
    "youth": false,
    "assetName": "designs/throwback-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.43, 4.07, 3.74, 3.42, 2.98, 2.72, 2.44][sizeIndex]-1.5)"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "!350785",
    "youth": false,
    "assetName": "designs/throwback-2_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(0.844)"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "350785",
    "youth": false,
    "assetName": "designs/throwback-arch-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2-0.027)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "!350{773,777,785,786,787,788,789,797,798,800,801,802}",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.75, 4.4, 4.1, 3.76, 3.35, 3.05, 2.8][sizeIndex]-2)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350773",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.414, 4.184, 4.009, 3.795, 3.492, 3.187][sizeIndex]-1)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350777",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.75, 4.4, 4.1, 3.76, 3.35, 3.05, 2.8][sizeIndex]-4.2)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "350{785,786,797,800}",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.75, 4.4, 4.1, 3.76, 3.35, 3.05, 2.8][sizeIndex]-0.5)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350{787,798,801}",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.455, 4.248, 4.053, 3.768, 3.468, 3.203][sizeIndex]-1)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350788",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.776, 4.34, 4.105, 3.864, 3.512, 3.023, 2.586][sizeIndex]-1)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350789",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.75, 4.4, 4.1, 3.76, 3.35, 3.05, 2.8][sizeIndex]-2.2)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350802",
    "youth": false,
    "assetName": "designs/bigStripe-1_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 4.75, 4.4, 4.1, 3.76, 3.35, 3.05, 2.8][sizeIndex]-1.8)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "!350785",
    "youth": false,
    "assetName": "designs/bigStripe-2_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(1.15)"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "350785",
    "youth": false,
    "assetName": "designs/bigStripe-arch-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2.22)"
  },
  {
    "designId": "varsity",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "!350785",
    "youth": false,
    "assetName": "designs/varsity_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.2)"
  },
  {
    "designId": "varsity",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "350785",
    "youth": false,
    "assetName": "designs/varsity-arch_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom"
  },
  {
    "designId": "dualStripe",
    "pieceNameGlob": "sleeveLeft",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/dualStripe_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": true,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "bottom"
  },
  {
    "designId": "dualStripe",
    "pieceNameGlob": "sleeveRight",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/dualStripe_adult",
    "flipX": true,
    "repeatX": false,
    "repeatY": true,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "bottom"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "sleeveLeft",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/racer_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.5)"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "sleeveRight",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/racer_adult",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.5)"
  },
  {
    "designId": "banded",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "!350785",
    "youth": false,
    "assetName": "designs/banded_adult",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(1.5)"
  },
  {
    "designId": "banded",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "350785",
    "youth": false,
    "assetName": "designs/banded-arch_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2.55)"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/stripedFade-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateX": "in2px(-3.0815)",
    "translateY": "in2px([0, -0.42, -0.67, -0.92, -1.21, -1.72, -2.13, -2.52][sizeIndex])"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "frontLeft",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/stripedFade-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateX": "in2px(-9.0625)",
    "translateY": "in2px([0, -0.42, -0.67, -0.92, -1.21, -1.72, -2.13, -2.52][sizeIndex])"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "frontRight",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/stripedFade-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateY": "in2px([0, -0.42, -0.67, -0.92, -1.21, -1.72, -2.13, -2.52][sizeIndex])"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/stripedFade-back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateX": "in2px(-3.0815)",
    "translateY": "in2px([0, -0.42, -0.67, -0.92, -1.21, -1.72, -2.13, -2.52][sizeIndex])"
  },
  {
    "designId": "burst",
    "pieceNameGlob": "front{,Left}",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/burst",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-right",
    "anchorTarget": "leftBottom",
    "translateY": "in2px([0, 5, 5, 5, 5, 5, 5, 5][sizeIndex])"
  },
  {
    "designId": "burst",
    "pieceNameGlob": "frontRight",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/burst",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-right",
    "anchorTarget": "leftBottom",
    "translateX": "in2px(9.503302083)",
    "translateY": "in2px(3.625479167)"
  },
  {
    "designId": "burst",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/burst",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-left",
    "anchorTarget": "leftBottom",
    "translateY": "in2px([0, 5, 5, 5, 5, 5, 5, 5][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "front{,Left}",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/blocked-frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "in2px([0, 0.5, 0.25, 0, -0.25, -0.5, -0.75, -1.5][sizeIndex])",
    "translateY": "in2px([0, 5.02, 5.4, 5.2, 5.91, 6.22, 6.45, 7.39][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "front{,Right}",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/blocked-frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armRightBottom",
    "translateX": "in2px([0, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1.5][sizeIndex])",
    "translateY": "in2px([0, 5.02, 5.4, 5.2, 5.91, 6.22, 6.45, 7.39][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/blocked-backLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "in2px([0, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1.5][sizeIndex])",
    "translateY": "in2px([0, 6.3, 6, 5.2, 5.4, 5.15, 4.8, 4][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/blocked-backRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armRightBottom",
    "translateX": "in2px([0, 0.5, 0.25, 0, -0.25, -0.5, -0.75, -1.5][sizeIndex])",
    "translateY": "in2px([0, 6.3, 6, 5.2, 5.4, 5.15, 4.8, 4][sizeIndex])"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Front",
    "productIdGlob": "!350847",
    "youth": false,
    "assetName": "designs/p-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-14.75)"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Front",
    "productIdGlob": "350847",
    "youth": false,
    "assetName": "designs/p-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-15)"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Back",
    "productIdGlob": "!35{0847,1079}",
    "youth": false,
    "assetName": "designs/p-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-15.15)"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Back",
    "productIdGlob": "350847",
    "youth": false,
    "assetName": "designs/p-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-16.4)"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Back",
    "productIdGlob": "351079",
    "youth": false,
    "assetName": "designs/p-2_adult",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-15.65)"
  },
  {
    "designId": "p-6",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-6",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(14)"
  },
  {
    "designId": "p-6",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "!351079",
    "youth": false,
    "assetName": "designs/p-6",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(13.558)"
  },
  {
    "designId": "p-6",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "351079",
    "youth": false,
    "assetName": "designs/p-6",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(13.058)"
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-8-front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-1)",
    "maskParts": [
      "leftTop ~ leftBottom : in2px(1.22)"
    ]
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-8-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-1)",
    "maskParts": [
      "rightBottom ~ rightTop : in2px(1.22)"
    ]
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-8-back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateX": "in2px(1)",
    "translateY": "in2px(-1)",
    "maskParts": [
      "leftBottom ~ leftTop : in2px(1.22)"
    ]
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-8-back",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateX": "in2px(-1)",
    "translateY": "in2px(-1)",
    "maskParts": [
      "rightTop ~ rightBottom : in2px(1.22)"
    ]
  },
  {
    "designId": "p-9",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-9",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "leftTop",
    "translateX": "in2px(-0.3125)",
    "translateY": "in2px(6.541666667)"
  },
  {
    "designId": "p-9",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-9",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "rightTop",
    "translateX": "in2px(0.3125)",
    "translateY": "in2px(6.541666667)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-stripedFade_frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-stripedFade_backLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-stripedFade_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-stripedFade_backRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "leftTop ~ leftBottom : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "leftBottom ~ leftTop : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "rightBottom ~ rightTop : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "rightTop ~ rightBottom : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-6)",
    "maskParts": [
      "leftTop ~ leftBottom : in2px(1)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-5.4)",
    "maskParts": [
      "leftBottom ~ leftTop : in2px(1)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-6)",
    "maskParts": [
      "rightBottom ~ rightTop : in2px(1)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": false,
    "assetName": "designs/p-blocked",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-5.4)",
    "maskParts": [
      "rightTop ~ rightBottom : in2px(1)"
    ]
  },
  {
    "designId": "trifade",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/trifade-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 7, 6.67, 6.36, 5.96, 0, 0][sizeIndex])",
    "scale": "1.14"
  },
  {
    "designId": "trifade",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/trifade-2_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false
  },
  {
    "designId": "warrior",
    "pieceNameGlob": "sleeveLeft",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/zigzag_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(4.1)"
  },
  {
    "designId": "warrior",
    "pieceNameGlob": "sleeveRight",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/zigzag_youth",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(4.1)"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "!350{773,777,785,786,787,788,789,790,797,798,799,800,801,802}",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.216, 3.876, 3.616, 3.186, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350773",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.399,4.328, 4.161, 4.038, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350777",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.216, 3.876, 3.616, 3.186, 0, 0][sizeIndex]-0.8)",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "350{785,790,797,799,800}",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.216, 3.876, 3.616, 3.186, 0, 0][sizeIndex]-1)",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "350786",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.216, 3.876, 3.616, 3.186, 0, 0][sizeIndex]-0.9)",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350787",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 5.028, 4.978, 4.84, 4.701, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350788",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.037, 3.987, 3.794, 3.582, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350789",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.216, 3.876, 3.616, 3.186, 0, 0][sizeIndex]-0.5)",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350798",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.654, 3.593, 3.429, 3.276, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350801",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.1, 4.039, 3.875, 3.722, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350802",
    "youth": true,
    "assetName": "designs/horizon-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.216, 3.876, 3.616, 3.186, 0, 0][sizeIndex]-1.1)",
    "scale": "1.15"
  },
  {
    "designId": "horizon",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/horizon-2_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "bottom",
    "translateY": "in2px(-2.42)",
    "spreadX": "in2px(2.1)"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "!350{773,775,777,787,788,789,790,798,799,801,802}",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350773",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.399, 4.328, 4.161, 4.038, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350775",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-0.5)",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350777",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.8)",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350787",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 5.028, 4.978, 4.84, 4.701, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350788",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.037, 3.987, 3.794, 3.582, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350{789,790,802}",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.7)",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350798",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.654, 3.593, 3.429, 3.276, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350799",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.5)",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350801",
    "youth": true,
    "assetName": "designs/throwback-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.1, 4.039, 3.875, 3.722, 0, 0][sizeIndex])",
    "scale": "1.15"
  },
  {
    "designId": "throwback",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/throwback-2_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(0.944)",
    "scale": "1.1"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "!350{773,777,787,788,789,790,798,799,800,801,802}",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex])",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350773",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.399, 4.328, 4.161, 4.038, 0, 0][sizeIndex]-1)",
    "scale": "1.15"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350777",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-3.2)",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350787",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 5.028, 4.978, 4.84, 4.701, 0, 0][sizeIndex]-1)",
    "scale": "1.15"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350788",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.037, 3.987, 3.794, 3.582, 0, 0][sizeIndex]-1)",
    "scale": "1.15"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350789",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.2)",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350790",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.5)",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350798",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.654, 3.593, 3.429, 3.276, 0, 0][sizeIndex]-1)",
    "scale": "1.15"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350799",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.4)",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front{,Left,Right},back}",
    "productIdGlob": "350800",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-0.5)",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350801",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 4.1, 4.039, 3.875, 3.722, 0, 0][sizeIndex]-1)",
    "scale": "1.15"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "{front,back}",
    "productIdGlob": "350802",
    "youth": true,
    "assetName": "designs/bigStripe-1_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "armBottomMiddle",
    "translateY": "in2px([0, 0, 3.454, 3.122, 2.782, 2.366, 0, 0][sizeIndex]-1.7)",
    "scale": "1.03"
  },
  {
    "designId": "bigStripe",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/bigStripe-2_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(1.15)",
    "scale": "1.04"
  },
  {
    "designId": "varsity",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/varsity_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.305)"
  },
  {
    "designId": "dualStripe",
    "pieceNameGlob": "sleeveLeft",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/dualStripe_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": true,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "bottom"
  },
  {
    "designId": "dualStripe",
    "pieceNameGlob": "sleeveRight",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/dualStripe_youth",
    "flipX": true,
    "repeatX": false,
    "repeatY": true,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "bottom"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "sleeveLeft",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/racer_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.55)"
  },
  {
    "designId": "racer",
    "pieceNameGlob": "sleeveRight",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/racer_youth",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(-1.55)"
  },
  {
    "designId": "banded",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/banded_youth",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(1.163)",
    "scale": "1.1"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/stripedFade-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateX": "in2px(-3.0815)",
    "translateY": "in2px([0, 0, 2.26, 2.42, 2.55, 2.95, 0, 0][sizeIndex])",
    "scale": "0.78"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "frontLeft",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/stripedFade-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateX": "in2px(-9.0625)",
    "translateY": "in2px([0, 0, 2.26, 2.42, 2.55, 2.95, 0, 0][sizeIndex])",
    "scale": "0.78"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "frontRight",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/stripedFade-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateY": "in2px([0, 0, 2.26, 2.42, 2.55, 2.95, 0, 0][sizeIndex])",
    "scale": "0.78"
  },
  {
    "designId": "stripedFade",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/stripedFade-back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "translateX": "in2px(-3.0815)",
    "translateY": "in2px([0, 0, 2.26, 2.42, 2.55, 2.95, 0, 0][sizeIndex])",
    "scale": "0.78"
  },
  {
    "designId": "burst",
    "pieceNameGlob": "front{,Left}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/burst",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-right",
    "anchorTarget": "leftBottom",
    "translateY": "in2px(5)"
  },
  {
    "designId": "burst",
    "pieceNameGlob": "frontRight",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/burst",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-right",
    "anchorTarget": "leftBottom",
    "translateX": "in2px(9.503302083)",
    "translateY": "in2px(3.625479167)"
  },
  {
    "designId": "burst",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/burst",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-left",
    "anchorTarget": "leftBottom",
    "translateY": "in2px(5)"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "front{,Left}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/blocked-frontLeft_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "in2px([0, 0, 0.25, 0, -0.25, -0.5, 0, 0][sizeIndex])",
    "translateY": "in2px([0, 0, 5.9, 5.2, 5.3, 5.02, 0, 0][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "front{,Right}",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/blocked-frontRight_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armRightBottom",
    "translateX": "in2px([0, 0, -0.25, 0, 0.25, 0.5, 0, 0][sizeIndex])",
    "translateY": "in2px([0, 0, 5.9, 5.2, 5.3, 5.02, 0, 0][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/blocked-backLeft_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armLeftBottom",
    "translateX": "in2px([0, 0, -0.25, 0, 0.25, 0.5, 0, 0][sizeIndex])",
    "translateY": "in2px([0, 0, 5.4, 5.2, 6, 6.25, 0, 0][sizeIndex])"
  },
  {
    "designId": "blocked",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/blocked-backRight_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "center-center",
    "anchorTarget": "armRightBottom",
    "translateX": "in2px([0, 0, 0.25, 0, -0.25, -0.5, 0, 0][sizeIndex])",
    "translateY": "in2px([0, 0, 5.4, 5.2, 6, 6.25, 0, 0][sizeIndex])"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Front",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-2_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-11.167)"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Back",
    "productIdGlob": "!35{0847,1079}",
    "youth": true,
    "assetName": "designs/p-2_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-8.567)"
  },
  {
    "designId": "p-2",
    "pieceNameGlob": "p{Left,Right}Back",
    "productIdGlob": "35{0847,1079}",
    "youth": true,
    "assetName": "designs/p-2_youth",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(-11.246)"
  },
  {
    "designId": "p-6",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-6",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(12.5)"
  },
  {
    "designId": "p-6",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-6",
    "flipX": false,
    "repeatX": true,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "riseBottomMiddle",
    "translateY": "in2px(12.058)"
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-8-front",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-1)",
    "maskParts": [
      "leftTop ~ leftBottom : in2px(1)"
    ]
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-8-front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-1)",
    "maskParts": [
      "rightBottom ~ rightTop : in2px(1)"
    ]
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-8-back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-1)",
    "maskParts": [
      "leftBottom ~ leftTop : in2px(1)"
    ]
  },
  {
    "designId": "p-8",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-8-back",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-1)",
    "maskParts": [
      "rightTop ~ rightBottom : in2px(1)"
    ]
  },
  {
    "designId": "p-9",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-9",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-left",
    "anchorTarget": "leftTop",
    "translateX": "in2px(-0.3125)",
    "translateY": "in2px(6.541666667)"
  },
  {
    "designId": "p-9",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-9",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-right",
    "anchorTarget": "rightTop",
    "translateX": "in2px(0.3125)",
    "translateY": "in2px(6.541666667)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-stripedFade_frontLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-stripedFade_frontRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-stripedFade_backLeft",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-stripedFade",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-stripedFade_backRight",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "bottom-center",
    "anchorTarget": "middleBottom",
    "translateY": "in2px(2)"
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "leftTop ~ leftBottom : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-6)",
    "maskParts": [
      "leftTop ~ leftBottom : in2px(1)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "rightBottom ~ rightTop : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightFront",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-6)",
    "maskParts": [
      "rightBottom ~ rightTop : in2px(1)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "leftBottom ~ leftTop : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pLeftBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked",
    "flipX": true,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "leftTop",
    "translateY": "in2px(-5.4)",
    "maskParts": [
      "leftBottom ~ leftTop : in2px(1)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked_braid",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-2)",
    "maskParts": [
      "rightTop ~ rightBottom : in2px(1.35)"
    ]
  },
  {
    "designId": "p-blocked",
    "pieceNameGlob": "pRightBack",
    "productIdGlob": "*",
    "youth": true,
    "assetName": "designs/p-blocked",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "anchorOrigin": "top-center",
    "anchorTarget": "rightTop",
    "translateY": "in2px(-5.4)",
    "maskParts": [
      "rightTop ~ rightBottom : in2px(1)"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignPartRow[]>> = data;

export type DesignPart = typeof typed[number];

export { typed as DESIGN_PARTS };
