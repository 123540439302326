import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BjsRenderer, RendererConfig } from '@orangelv/bjs-renderer'

import LoadingIndicator from '../../_mizuno/client/components/atoms/LoadingIndicator'
import ViewNavigation from '../../_mizuno/client/components/atoms/ViewNavigation'
import Icon from '../../_mizuno/client/components/atoms/Icon'
import onGeneratePreviews from './onGeneratePreviews'
import generateIconsForFills from './icons/generateIconsForFills'
import generateIconsForFonts from './icons/generateIconsForFonts'
import generateIconsForLayouts from './icons/generateIconsForLayouts'
import generateIconsForTails from './icons/generateIconsForTails'
import getRendererConfig from './getRendererConfig'
import controlTree from '../client/controlTree'
import { createMenuSelector } from '../../../platform/client/common/selectors'
import { rosterPreviewIdSelector } from '../client/selectors'
import { Recipe } from '../common/typings'

import './Renderer.css'

const generators = {
  generateIconsForFills,
  generateIconsForFonts,
  generateIconsForLayouts,
  generateIconsForTails,
}

type Generators = typeof generators
declare global {
  interface Window extends Generators {}
}

Object.assign(window, generators)

type Props = {
  isHidden: boolean
  autoResize: string
}

const menuSelector = createMenuSelector(controlTree)

const Renderer = (props: Props) => {
  const { isHidden, autoResize } = props

  const [isLoading, setIsLoading] = useState(true)

  const nodes = useSelector(controlTree.getNodes)
  const recipe: Recipe = useSelector(controlTree.getPublicRecipe)
  const menu = useSelector(menuSelector)
  const includeJersey = menu !== 'pants'
  const includePants = menu !== 'jersey'
  const rosterPreviewId = useSelector(rosterPreviewIdSelector)

  // https://react.dev/reference/react/useEffect#fetching-data-with-effects
  const [config, setConfig] = useState<RendererConfig>()
  useEffect(() => {
    let ignore = false
    ;(async () => {
      const result = await getRendererConfig(
        nodes,
        recipe,
        includeJersey,
        includePants,
        rosterPreviewId,
      )
      if (!ignore) setConfig(result)
    })()
    return () => {
      ignore = true
    }
  }, [nodes, recipe, includeJersey, includePants, rosterPreviewId])

  return (
    <div
      id="renderer"
      style={{ display: isHidden ? 'none' : 'block', touchAction: 'none' }}
    >
      {isLoading && (
        <div className="loader-wrapper">
          <LoadingIndicator />
        </div>
      )}

      {config && (
        <BjsRenderer
          config={config}
          onLoading={(isLoading) => {
            setIsLoading(isLoading)
          }}
          onGeneratePreviews={onGeneratePreviews}
          onLog={(level, message) => {
            const formattedMessage = `${level.toUpperCase()}: ${message}`
            if (level === 'verbose') {
              console.info(formattedMessage)
            } else {
              console.log(formattedMessage)
            }
          }}
          onLogGroup={(starts) => {
            if (starts) {
              console.group('bjsRenderer')
            } else {
              console.groupEnd()
            }
          }}
          isHidden={isHidden}
          autoResize={autoResize}
        />
      )}

      {!isLoading && (
        <ViewNavigation>
          <div className="renderer-help">
            <Icon name="3d" />
            <span className="help-text is-desktop">
              Click and drag to rotate, mouse wheel to zoom
            </span>
            <span className="help-text is-mobile">
              Swipe to rotate, pinch to zoom
            </span>
          </div>
        </ViewNavigation>
      )}
    </div>
  )
}

export { Renderer, type Props }
