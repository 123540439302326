import { GeneratePreviews } from '@orangelv/bjs-renderer'

import { setPreviewGenerator } from '../../../platform/client/common/actions'
import store from '../client/store'
import controlTree from '../client/controlTree'
import getRendererConfig from './getRendererConfig'
import generateIconsForProducts from './icons/generateIconsForProducts'
import generateIconsForDesigns from './icons/generateIconsForDesigns'

declare global {
  interface Window {
    generateIconsForProducts: ReturnType<typeof generateIconsForProducts>
    generateIconsForDesigns: ReturnType<typeof generateIconsForDesigns>
  }
}

const defaultCameras = {
  front: {
    defaultAlpha: Math.PI / 2,
  },
  back: {
    defaultAlpha: -Math.PI / 2,
  },
  left: {
    defaultAlpha: 0,
  },
  right: {
    defaultAlpha: Math.PI,
  },
}

const jerseyCameras = {
  jerseyFront: {
    defaultAlpha: Math.PI / 2,
  },
  jerseyBack: {
    defaultAlpha: -Math.PI / 2,
  },
  jerseyLeft: {
    defaultAlpha: 0,
  },
  jerseyRight: {
    defaultAlpha: Math.PI,
  },
}

const pantsCameras = {
  pantsFront: {
    defaultAlpha: Math.PI / 2,
  },
  pantsBack: {
    defaultAlpha: -Math.PI / 2,
  },
  pantsLeft: {
    defaultAlpha: 0,
  },
  pantsRight: {
    defaultAlpha: Math.PI,
  },
}

const onGeneratePreviews = (generatePreviews: GeneratePreviews) => {
  store.dispatch(
    // TODO: Fix TypeScript thinking that viewIds is any.
    setPreviewGenerator(async (viewIds: string[]) => {
      const size = { width: 1024, height: 1024 }

      const state = store.getState()
      const nodes = controlTree.getNodes(state)
      const recipe = controlTree.getPublicRecipe(state)

      const hasJersey = !!nodes['jersey.sku'].value
      const hasPants = !!nodes['pants.sku'].value
      const hasBothProducts = hasJersey && hasPants

      const rosterPreviewId = Math.min(
        ...state.controlTree.repeatedNodes['details.roster'],
      ).toString()

      const previews = {}

      for (const viewId of viewIds) {
        const isJerseyView = viewId in jerseyCameras
        const isPantsView = viewId in pantsCameras
        const isExtraView = isJerseyView || isPantsView

        if (isExtraView && !hasBothProducts) {
          // We want to generate separate jersey and pants previews only if we have both products.
          continue
        }

        const cameraConfig =
          defaultCameras[viewId] ||
          jerseyCameras[viewId] ||
          pantsCameras[viewId]

        const rendererConfig = await getRendererConfig(
          nodes,
          recipe,
          isExtraView ? isJerseyView : hasJersey,
          isExtraView ? isPantsView : hasPants,
          rosterPreviewId,
        )

        rendererConfig.camera = {
          ...rendererConfig.camera,
          ...cameraConfig,
        }

        previews[viewId] = (
          await generatePreviews({ size, rendererConfig })
        ).default
      }

      return previews
    }),
  )

  window.generateIconsForProducts = generateIconsForProducts(generatePreviews)
  window.generateIconsForDesigns = generateIconsForDesigns(generatePreviews)
}

export default onGeneratePreviews
