import { SIZES, ProductId, Product, PRODUCTS, SIZE_DICT } from './sheets'

export const getIndexTitle = (config) =>
  `${config.titleBase} ${config.titlePostfix}` +
  `${config.hideVia || !config.vendorName ? '' : ` via ${config.vendorName}`}`

export const getIndexDescription = (config) => config.getIndexDescription()

type SkuValues = {
  canonicalSku: string
  jersey?: Product
  pants?: Product
}

const SKU_SEPERATOR = '-and-'

export const getSku = (jerseyId?: ProductId, pantsId?: ProductId) => {
  return [jerseyId, pantsId].filter((x) => x).join(SKU_SEPERATOR)
}

export const findJersey = (jerseyId: string) =>
  PRODUCTS.find((x) => x.id === jerseyId && x.props.type === 'jersey')

export const findPants = (pantsId: string) =>
  PRODUCTS.find((x) => x.id === pantsId && x.props.type === 'pants')

export const getSkuValues = (sku: string): SkuValues => {
  const skuParts = sku.split(SKU_SEPERATOR)

  let jersey: Product | undefined
  let pants: Product | undefined

  if (skuParts.length === 2) {
    jersey = findJersey(skuParts[0])
    pants = findPants(skuParts[1])
  } else if (skuParts.length === 1) {
    jersey = findJersey(skuParts[0])
    if (!jersey) {
      pants = findPants(skuParts[0])
    }
  }

  const isSkuValid = !!(
    sku === 'undefined' ||
    (skuParts.length === 2 && jersey && pants) ||
    (skuParts.length === 1 && (jersey || pants))
  )

  if (!isSkuValid) {
    throw new Error(`SKU is not valid: "${sku}"`)
  }

  const canonicalSku = getSku(jersey?.id, pants?.id)

  return {
    canonicalSku,
    jersey,
    pants,
  }
}

export const getProductName = (jersey?: Product, pants?: Product) => {
  const productName = [jersey?.name, pants?.name]
    .filter((x) => x !== undefined)
    .join(' + ')

  return productName
}

export const getSkuTitle = (config, params) => {
  const sku = params[0]
  const { jersey, pants } = getSkuValues(sku)
  const productName = getProductName(jersey, pants)

  return (
    `${productName} - ${config.titleBase} ` +
    `Template${
      config.hideVia || !config.vendorName ? '' : ` via ${config.vendorName}`
    }`
  )
}

export const getSkuDescription = (config) => {
  return config.getSkuDescription()
}

export const getRecipeTitle = (config, recipe, id, hasChanges) => {
  return (
    `${id}${hasChanges ? ' with changes' : ''} - ` +
    `${config.titleBase} ` +
    `${config.hideVia || !config.vendorName ? '' : ` via ${config.vendorName}`}`
  )
}

export const getRecipeDescription = (config) => {
  return config.getRecipeDescription()
}

export const garmentSizes = [
  {
    title: 'Jersey',
    path: 'jerseySize',
    sheet: SIZE_DICT,
    sheetArray: SIZES,
  },
  {
    title: 'Pants',
    path: 'pantsSize',
    sheet: SIZE_DICT,
    sheetArray: SIZES,
  },
]

export const getStaticTags = () => ''
